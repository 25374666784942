import React, { useState, useEffect } from 'react';
import {
  Grid,
  Segment,
  Header,
  Message,
  Image,
  Form,
  Button
} from 'semantic-ui-react';
import { Link, Navigate } from 'react-router-dom';

import axios from 'axios';
import useUserData from '../state-manipulation/hooks/useUserData';
import {
  setUserToken,
  setUserInfo,
  setIsAdmin,
  setUserOrgs,
  setIsSuperAdmin,
  selectCurrentOrg
} from '../state-manipulation/reducers/user-data/types';

import './style/Login.scss';
import isAnyBoolTrue from '../utils/isAnyBoolTrue';
import ltgLogo from '../images/ltg-logo.svg';
import AxiosWrapper from '../utils/AxiosWrapper';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [isInvalidLogin, setIsInvalidLogin] = useState(false);
  const [userData, userDispatch] = useUserData();

  const handleLogin = async () => {
    try {
      let res = await AxiosWrapper({
        method: 'POST',
        url: `${process.env.API_URL}/auth`,
        data: {
          email: email,
          password: password
        },
      });

      if (res.data.token) {
        setIsInvalidLogin(false);
        const {
          email,
          firstName,
          lastName,
          avatarUrl,
          userTags
        } = res.data.user;
        userDispatch({
          type: setUserInfo,
          payload: {
            userInfo: { email, firstName, lastName, avatarUrl, userTags }
          }
        });

        const { isAdmin = false } = res.data.user.userTags;
        userDispatch({
          type: setIsAdmin,
          payload: {
            isAdmin: isAnyBoolTrue(isAdmin)
          }
        });

        const { isSuperAdmin = false } = res.data.user.userTags;
        userDispatch({
          type: setIsSuperAdmin,
          payload: {
            isSuperAdmin: isAnyBoolTrue(isSuperAdmin)
          }
        });

        const { experienceGroups } = res.data.user;
        userDispatch({
          type: setUserOrgs,
          payload: {
            orgs: experienceGroups
          }
        });

        const selectedOrg = experienceGroups[0];
        userDispatch({
          type: selectCurrentOrg,
          payload: {
            selectedOrg: selectedOrg
          }
        });

        userDispatch({
          type: setUserToken,
          payload: {
            token: res.data.token
          }
        });
      }
    } catch (err) {
      console.error(err);
      setIsInvalidLogin(true);
    }
  };

  const handleEmailChange = async e => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = e => {
    setPassword(e.target.value);
  };

  useEffect(() => {
    if (userData.token && userData.orgs.length > 0) {
      setRedirectUrl(`/${userData.orgs[0].name.toLowerCase()}`);
    }
  }, [userData]);

  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      {userData.token && redirectUrl && <Navigate to={redirectUrl} />}
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal" textAlign="center">
          <Image src={ltgLogo} /> Log-in to your account
        </Header>
        {isInvalidLogin && (
          <Message warning>
            <Message.Header>Invalid credentials</Message.Header>
            <p>Is your caps lock on?</p>
          </Message>
        )}
        <Form size="large">
          <Segment stacked>
            <Form.Input
              fluid
              icon="user"
              type="email"
              iconPosition="left"
              placeholder="E-mail address"
              value={email}
              onChange={handleEmailChange}
            />
            <Form.Input
              fluid
              type="password"
              icon="lock"
              iconPosition="left"
              placeholder="password"
              value={password}
              onChange={handlePasswordChange}
            />
            <Button color="teal" fluid size="large" onClick={handleLogin}>
              Login
            </Button>
          </Segment>
        </Form>
        <Message>
          <Link to="/forgot-password">Forgot your password?</Link>
        </Message>
      </Grid.Column>
    </Grid>
  );
}
