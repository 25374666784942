import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Header, Responsive, Dimmer, Loader, Table, Modal, Segment, Tab, Button, Form, Checkbox, Radio, TextArea, Icon } from "semantic-ui-react";
import { LG_WIDTH } from "../../constants";
import useUserData from "../../state-manipulation/hooks/useUserData";
import usePageData from "../../state-manipulation/hooks/usePageData";
import { setSelectedPage } from "../../state-manipulation/reducers/page-data/types";
import { async } from "q";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import PageHeader from "../common/PageHeader";
import AxiosWrapper from "../../utils/AxiosWrapper";

export default function ManagePayments({ className }) {
  const [ userData, userDispatch ] = useUserData();
  const [ pageData, pageDispatch ] = usePageData();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ paymentList, setPaymentList ] = useState([]);
  const [ voidReason, setVoidReason ] = useState('');
  const [ voidReasonOther, setVoidReasonOther ] = useState('');
  const [ payment, setPayment ] = useState(null);
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const [ isUnvoidModalOpen, setIsUnvoidModalOpen ] = useState(false);
  const [ isVoidFormValid, setIsVoidFormValid ] = useState(false);
  const reasonRef = useRef(null);

  const getChecks = async (company) => {
    try {
      setIsLoading(true);
      let res = await AxiosWrapper({
        method: 'GET',
        url: `${process.env.API_URL}/${company}/checks`,
        headers: {
          'Authorization': `Basic ${userData.token}`
        }
      });

      setPaymentList(res.data.items);
      setIsLoading(false);

    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const voidPayment = async (company) => {
    try {
      setIsLoading(true);
      let res = await AxiosWrapper({
        method: 'PATCH',
        url: `${process.env.API_URL}/${company}/payments/${payment.id}?action=void`,
        headers: {
          'Authorization': `Basic ${userData.token}`
        },
        data: {
          voidReason: voidReason,
          voidReasonOther: voidReasonOther,
          payment: payment
        }
      });

      setIsModalOpen(false);
      setVoidReason('');
      setVoidReasonOther('');
      setPayment(null);
      setIsLoading(false);
      getChecks(userData.selectedOrg.name.toLowerCase());

    } catch (error) {
      setIsLoading(false);
      setIsModalOpen(false);
      setPayment(null);
      console.error(error);
    }
  };

  const unvoidPayment = async (company) => {
    try {
      setIsLoading(true);
      let res = await AxiosWrapper({
        method: 'PATCH',
        url: `${process.env.API_URL}/${company}/payments/${payment.id}?action=unvoid`,
        headers: {
          'Authorization': `Basic ${userData.token}`
        },
        data: {
          unvoid: true,
          payment: payment
        }
      });

      setIsUnvoidModalOpen(false);
      setPayment(null);
      setIsLoading(false);
      getChecks(userData.selectedOrg.name.toLowerCase());

    } catch (error) {
      setIsLoading(false);
      setIsUnvoidModalOpen(false);
      setPayment(null);
      console.error(error);
    }
  };

  const handleVoidReasonChange = (e, {label}) => {
    setVoidReason(label);
  };

  const handleVoidReasonOtherChange = ( e, { value }) => {
    setVoidReasonOther(value);
  };

  const handleVoidPayment = () => {
    voidPayment(userData.selectedOrg.name.toLowerCase());
  };

  const handleModalOpen = (e, { currentpayment }) => {
    setPayment(currentpayment);
    setVoidReason('');
    setVoidReasonOther('');
    setIsModalOpen(true);
  };

  const handleModalClose = (e) => {
    e.preventDefault();
    setPayment(null);
    setIsModalOpen(false);
  };

  const handleUnvoidModalOpen = (e, { currentpayment }) => {
    setPayment(currentpayment);
    setIsUnvoidModalOpen(true);
  };

  const handleUnvoidModalClose = () => {
    setPayment(null);
    setIsUnvoidModalOpen(false);
  };

  const handleUnvoidPayment = () => {
    unvoidPayment(userData.selectedOrg.name.toLowerCase());
  };

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: 'Payments'
      }
    });
  }, []);

  useEffect(() => {
    if (userData.selectedOrg) {
      getChecks(userData.selectedOrg.name.toLowerCase());
    }
  }, [userData.selectedOrg]);

  useEffect(() => {
    if (voidReason === 'Other') {
      reasonRef.current.focus();

      if (voidReasonOther.trim() !== '') {
        setIsVoidFormValid(true);
      } else {
        setIsVoidFormValid(false);
      }
    } else if (voidReason !== '') {
      setIsVoidFormValid(true);
    } else {
      setIsVoidFormValid(false);
    }
  }, [voidReason, voidReasonOther]);

  return (
    <div className={"content-container " + className}>
      <PageHeader pageName="Payments" orgName={userData.selectedOrg?.name} />
      <Segment>
        <Dimmer active={isLoading}>
          <Loader />
        </Dimmer>
        <Table className="payments">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                Payment Date
              </Table.HeaderCell>
              <Table.HeaderCell>
                Payee
              </Table.HeaderCell>
              <Table.HeaderCell>
                Check #
              </Table.HeaderCell>
              <Table.HeaderCell>
                Amount
              </Table.HeaderCell>
              <Table.HeaderCell>
                Purchase Account
              </Table.HeaderCell>
              <Table.HeaderCell>
                Memo
              </Table.HeaderCell>
              <Table.HeaderCell>
                Check Issued By
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {paymentList && paymentList.map((payment, paymentIndex) => {
              return (
              <Table.Row key={paymentIndex} className={payment.isVoided ? 'voided-row' : ''}>
                <Table.Cell>
                   <Moment format="MM/DD/YYYY">
                   { payment.checkDate }
                   </Moment>
                </Table.Cell>
                <Table.Cell>
                  { payment.payee }
                </Table.Cell>
                <Table.Cell>
                  { payment.checkNumber !== -1 && payment.checkNumber }
                  { payment.checkNumber === -1 && 'Transfer' }
                </Table.Cell>
                <Table.Cell textAlign="right">
                  $<NumberFormat thousandSeparator={true} value={payment.amount.toFixed(2)} displayType="text"></NumberFormat>
                </Table.Cell>
                <Table.Cell>
                  { payment.purchaseAccount }
                </Table.Cell>
                <Table.Cell>
                  { payment.memo }
                  { payment.isVoided &&
                    <>
                      <br/>
                      <span className="voided">Voided: { payment.voidReason } {payment.voidReasonOther }</span>
                    </>
                  }
                </Table.Cell>
                <Table.Cell>
                  { payment.personWhoIssuedCheck }
                </Table.Cell>
                <Table.Cell>
                {!payment.isVoided &&
                  <Button currentpayment={payment} onClick={handleModalOpen}>Void</Button>
                }
                {payment.isVoided &&
                  <Button currentpayment={payment} onClick={handleUnvoidModalOpen}>Un-Void</Button>
                }
                </Table.Cell>
              </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <Modal centered={false}
          open={isModalOpen}
          >
          <Modal.Header>Select a Reason</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Header>Void Reason</Header>
              <Form>
                <Form.Field>
                  <Radio label='Check did not print'
                    checked={voidReason === 'Check did not print'}
                    onChange={handleVoidReasonChange} />
                </Form.Field>
                <Form.Field>
                  <Radio label='Wrong vendor'
                    checked={voidReason === 'Wrong vendor'}
                    onChange={handleVoidReasonChange} />
                </Form.Field>
                <Form.Field>
                  <Radio label='Wrong amount'
                    checked={voidReason === 'Wrong amount'}
                    onChange={handleVoidReasonChange} />
                </Form.Field>
                <Form.Field>
                  <Radio label='Wrong date'
                    checked={voidReason === 'Wrong date'}
                    onChange={handleVoidReasonChange} />
                </Form.Field>
                <Form.Field>
                  <Radio label='Wrong memo'
                    checked={voidReason === 'Wrong memo'}
                    onChange={handleVoidReasonChange} />
                </Form.Field>
                <Form.Field>
                  <Radio label='Other'
                    checked={voidReason === 'Other'}
                    onChange={handleVoidReasonChange} />
                </Form.Field>
                <Form.Field>
                  <TextArea ref={reasonRef} placeholder='Tell us more' value={voidReasonOther} onChange={handleVoidReasonOtherChange} disabled={voidReason !== 'Other'} />
                </Form.Field>
                <Button disabled={!isVoidFormValid} primary onClick={handleVoidPayment}>Save</Button>
                <Button secondary onClick={handleModalClose}>Cancel</Button>
              </Form>
            </Modal.Description>
          </Modal.Content>
        </Modal>
        <Modal basic size='small' open={isUnvoidModalOpen}>
          <Header icon='archive' content='Unvoid Payment' />
          <Modal.Content>
            <p>
              Do you want to unvoid this payment?</p>
              {payment &&
                <ul>
                  <li>{ payment.payee }</li>
                  <li>${ payment.amount.toFixed(2) }</li>
                  <li>Memo: { payment.memo }</li>
                </ul>
              }
          </Modal.Content>
          <Modal.Actions>
            <Button basic color='red' inverted onClick={handleUnvoidModalClose}>
              <Icon name='remove' /> No
            </Button>
            <Button color='green' inverted onClick={handleUnvoidPayment} >
              <Icon name='checkmark' /> Yes
            </Button>
          </Modal.Actions>
        </Modal>
      </Segment>
    </div>
  );
}

ManagePayments.propTypes = {
  className: PropTypes.string
};

ManagePayments.defaultProps = {
  className: ''
};
