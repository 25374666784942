import React from "react";
import PropTypes from "prop-types";
import { Menu } from "semantic-ui-react";
import moment from "moment";
import "moment-timezone";

import useDateRangeData from "../../state-manipulation/hooks/useDateRangeData";
import { setDateStart, setDateEnd } from "../../state-manipulation/reducers/date-range-data/types";

export function DateMenuForm({
    handleTodayClick,
    handleYesterdayClick,
    handle52WeeksAgoClick,
    handleWeekToDateClick,
    handleLastWeekClick,
    handleLast7DaysClick,
    handleMonthToDateClick,
    handleLastMonthClick,
    handleLast30DaysClick,
    handleLast60DaysClick,
    handleLast90DaysClick
  }) {
  return (
    <Menu
      className="date-menu"
      fluid>
      <Menu.Item onClick={handleTodayClick}>
        Today
      </Menu.Item>
      <Menu.Item onClick={handleYesterdayClick}>
        Yesterday
      </Menu.Item>
      <Menu.Item onClick={handle52WeeksAgoClick}>
        52 Weeks Ago
      </Menu.Item>
      <Menu.Item onClick={handleWeekToDateClick}>
        Week to Date
      </Menu.Item>
      <Menu.Item onClick={handleLastWeekClick}>
        Last Week
      </Menu.Item>
      <Menu.Item onClick={handleLast7DaysClick}>
        Last 7 Days
      </Menu.Item>
      <Menu.Item onClick={handleMonthToDateClick}>
        Month to Date
      </Menu.Item>
      <Menu.Item onClick={handleLastMonthClick}>
        Last Month
      </Menu.Item>
      <Menu.Item onClick={handleLast30DaysClick}>
        Last 30 Days
      </Menu.Item>
      {handleLast60DaysClick && (
        <Menu.Item onClick={handleLast60DaysClick}>
          Last 60 Days
        </Menu.Item>
      )}
      {handleLast90DaysClick && (
        <Menu.Item onClick={handleLast90DaysClick}>
          Last 90 Days
        </Menu.Item>
      )}
    </Menu>
  );
}

DateMenuForm.propTypes = {
  handleTodayClick: PropTypes.func,
  handleYesterdayClick: PropTypes.func,
  handle52WeeksAgoClick: PropTypes.func,
  handleWeekToDateClick: PropTypes.func,
  handleLastWeekClick: PropTypes.func,
  handleLast7DaysClick: PropTypes.func,
  handleMonthToDateClick: PropTypes.func,
  handleLastMonthClick: PropTypes.func,
  handleLast30DaysClick: PropTypes.func,
  handleLast60DaysClick: PropTypes.func,
  handleLast90DaysClick: PropTypes.func
};

DateMenuForm.defaultProps = {
  handleTodayClick: () => {},
  handleYesterdayClick: () => {},
  handle52WeeksAgoClick: () => {},
  handleWeekToDateClick: () => {},
  handleLastWeekClick: () => {},
  handleLast7DaysClick: () => {},
  handleMonthToDateClick: () => {},
  handleLastMonthClick: () => {},
  handleLast30DaysClick: () => {}
};

export default function DateMenu() {
  const [ dateRangeData, dateRangeDispatch ] = useDateRangeData();
  const currentHour = moment().tz("America/New_York").hours();
  const defaultStartDate = (currentHour >= 0 && currentHour  < 4) ? moment().subtract(1, "days") : moment();
  const defaultEndDate = (currentHour >= 0 && currentHour  < 4)  ? moment().subtract(1, "days") : moment();

  const handleTodayClick = () => {
    dateRangeDispatch({
      type: setDateStart,
      payload: {
        dateStart: defaultStartDate.format('MM/DD/YY')
      }
    });

    dateRangeDispatch({
      type: setDateEnd,
      payload: {
        dateEnd: defaultEndDate.format('MM/DD/YY')
      }
    });

  };

  const handleYesterdayClick = () => {
    dateRangeDispatch({
      type: setDateStart,
      payload: {
        dateStart: defaultStartDate.subtract(1, 'days').format('MM/DD/YY')
      }
    });

    dateRangeDispatch({
      type: setDateEnd,
      payload: {
        dateEnd: defaultEndDate.subtract(1, 'days').format('MM/DD/YY')
      }
    });
  };

  const handle52WeeksAgoClick = () => {
    dateRangeDispatch({
      type: setDateStart,
      payload: {
        dateStart: defaultStartDate.subtract(52, 'weeks').format('MM/DD/YY')
      }
    });

    dateRangeDispatch({
      type: setDateEnd,
      payload: {
        dateEnd: defaultEndDate.subtract(52, 'weeks').format('MM/DD/YY')
      }
    });
  };

  const handleWeekToDateClick = () => {
    let _startDate = (defaultStartDate.format('ddd').toUpperCase() === 'SUN') ? defaultStartDate.subtract(6, 'days').format('MM/DD/YY') : defaultStartDate.startOf('week').add(1, 'days').format('MM/DD/YY');
    dateRangeDispatch({
      type: setDateStart,
      payload: {
        dateStart: _startDate
      }
    });

    dateRangeDispatch({
      type: setDateEnd,
      payload: {
        dateEnd: defaultEndDate.format('MM/DD/YY')
      }
    });
  };

  const handleLastWeekClick = () => {
    dateRangeDispatch({
      type: setDateStart,
      payload: {
        dateStart: defaultStartDate.startOf('week').subtract(6, 'days').format('MM/DD/YY')
      }
    });

    dateRangeDispatch({
      type: setDateEnd,
      payload: {
        dateEnd: defaultEndDate.startOf('week').format('MM/DD/YY')
      }
    });
  };

  const handleLast7DaysClick = () => {
    dateRangeDispatch({
      type: setDateStart,
      payload: {
        dateStart: defaultStartDate.subtract(7, 'days').format('MM/DD/YY')
      }
    });

    dateRangeDispatch({
      type: setDateEnd,
      payload: {
        dateEnd: defaultEndDate.format('MM/DD/YY')
      }
    });
  };

  const handleLastMonthClick = () => {
    dateRangeDispatch({
      type: setDateStart,
      payload: {
        dateStart: defaultStartDate.startOf('month').subtract(1, 'month').format('MM/DD/YY')
      }
    });

    dateRangeDispatch({
      type: setDateEnd,
      payload: {
        dateEnd: defaultEndDate.endOf('month').subtract(1, 'month').format('MM/DD/YY')
      }
    });
  };

  const handleLast30DaysClick = () => {
    dateRangeDispatch({
      type: setDateStart,
      payload: {
        dateStart: defaultStartDate.subtract(30, 'days').format('MM/DD/YY')
      }
    });

    dateRangeDispatch({
      type: setDateEnd,
      payload: {
        dateEnd: defaultEndDate.format('MM/DD/YY')
      }
    });
  };

  const handleMonthToDateClick = () => {
    dateRangeDispatch({
      type: setDateStart,
      payload: {
        dateStart: defaultStartDate.startOf('month').format('MM/DD/YY')
      }
    });

    dateRangeDispatch({
      type: setDateEnd,
      payload: {
        dateEnd: defaultEndDate.format('MM/DD/YY')
      }
    });
  };

  return (
    <DateMenuForm
      handleTodayClick={handleTodayClick}
      handleYesterdayClick={handleYesterdayClick}
      handle52WeeksAgoClick={handle52WeeksAgoClick}
      handleWeekToDateClick={handleWeekToDateClick}
      handleLastWeekClick={handleLastWeekClick}
      handleLast7DaysClick={handleLast7DaysClick}
      handleMonthToDateClick={handleMonthToDateClick}
      handleLastMonthClick={handleLastMonthClick}
      handleLast30DaysClick={handleLast30DaysClick}
    />
  );
}