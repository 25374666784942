import AxiosWrapper from '../utils/AxiosWrapper';

export async function PostUser(user) {
  try {
    let url = `${process.env.API_URL}/users`;

    let res = await AxiosWrapper({
      method: 'POST',
      url: url,
      data: user,
      isAuthRoute: true,
    });
    return res;
  } catch (e) {
    console.error(e);
  }
}
