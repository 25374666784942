import React from "react";
import { Link, useParams } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";
import usePageData from "../../state-manipulation/hooks/usePageData";
import useUserData from "../../state-manipulation/hooks/useUserData";
import IsAuthorized from "../common/IsAuthorized";

export default function SalesAndDiscountsMenu({ onClick }) {
  let { company } = useParams();
  const [pageData] = usePageData();
  const [userData, userDispatch] = useUserData();

  if (!company) {
    company = userData.orgs[0].name.toLowerCase();
  }

  return (
    <IsAuthorized permission="hasSales">
      <Menu.Item>
        <Menu.Header>Sales &amp; Discounts</Menu.Header>
        <Menu.Menu>
          <IsAuthorized permission="hasSalesOverview">
            <Menu.Item
              as={Link}
              to={"/" + company + "/sales"}
              onClick={onClick}
              active={
                pageData.selectedPage.toUpperCase() === "SALES OVERVIEW"
                  ? true
                  : false
              }
            >
              <Icon name="dollar sign" />
              Overview
            </Menu.Item>
          </IsAuthorized>
          <IsAuthorized permission="hasSalesStats">
            <Menu.Item
              as={Link}
              to={"/" + company + "/sales-stats"}
              onClick={onClick}
              active={
                pageData.selectedPage.toUpperCase() === "SALES STATS"
                  ? true
                  : false
              }
            >
              <Icon name="dollar sign" />
              Sales Stats
            </Menu.Item>
          </IsAuthorized>
          <IsAuthorized permission="hasSalesProductMix">
            <Menu.Item
              as={Link}
              to={"/" + company + "/sales/product-mix"}
              onClick={onClick}
              active={
                pageData.selectedPage.toUpperCase() === "PRODUCT MIX"
                  ? true
                  : false
              }
            >
              <Icon name="numbered list" />
              Product Mix
            </Menu.Item>
          </IsAuthorized>
          <IsAuthorized permission="hasSalesDiscounts">
            <Menu.Item
              as={Link}
              to={"/" + company + "/sales/discounts"}
              onClick={onClick}
              active={
                pageData.selectedPage.toUpperCase() === "DISCOUNTS"
                  ? true
                  : false
              }
            >
              <Icon name="tags" />
              Discounts
            </Menu.Item>
          </IsAuthorized>
          <IsAuthorized permission="hasSalesVoids">
            <Menu.Item
              as={Link}
              to={"/" + company + "/sales/voids"}
              onClick={onClick}
              active={
                pageData.selectedPage.toUpperCase() === "VOIDS" ? true : false
              }
            >
              <Icon name="ban" />
              Voids
            </Menu.Item>
          </IsAuthorized>
          <IsAuthorized permission="hasSalesProductItemSales">
            <Menu.Item
              as={Link}
              to={"/" + company + "/sales/product-item-sales"}
              onClick={onClick}
              active={
                pageData.selectedPage.toUpperCase() === "PRODUCT ITEM SALES" ? true : false
              }
            >
              <Icon name="chart line" />
              Product Item Sales
            </Menu.Item>
          </IsAuthorized>
          <IsAuthorized permission="hasSalesPeriodReports">
            <Menu.Item
              as={Link}
              to={"/" + company + "/sales/period-reports"}
              onClick={onClick}
              active={
                pageData.selectedPage.toUpperCase() === "PERIOD REPORTS" ? true : false
              }
            >
              <Icon name="chart line" />
              Period Reports
            </Menu.Item>
          </IsAuthorized>
        </Menu.Menu>
      </Menu.Item>
    </IsAuthorized>
  );
}
