import React from 'react';
import PropTypes from 'prop-types';
import { Table, Tab } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown';
import './styles/TaskListDescriptionsPrint.scss';

export default class TaskListDescriptionsPrint extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={4}>
                {this.props.taskList &&
                  this.props.taskList.name &&
                  this.props.taskList.name}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell width={5}>Task</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.taskList &&
              this.props.taskList.tasks &&
              this.props.taskList.tasks.map((t, tIndex) => {
                return (
                  <React.Fragment key={tIndex}>
                    {t.type === 'section' ? (
                      <Table.Row className="task-section">
                        <Table.Cell colSpan={2}>{t.name}</Table.Cell>
                      </Table.Row>
                    ) : (
                      <>
                        <Table.Row>
                          <Table.Cell>{t.name}</Table.Cell>
                          <Table.Cell>
                            {t.description && <ReactMarkdown>{t.description}</ReactMarkdown> }
                          </Table.Cell>
                        </Table.Row>
                        {t.additionalFields &&
                          t.additionalFields.map((af, afIndex) => {
                            return (
                              <Table.Row key={afIndex}>
                                <Table.Cell>
                                  &nbsp;&nbsp;• <em>{af.name}</em>
                                </Table.Cell>
                                <Table.Cell>{af.description && <ReactMarkdown>{af.description}</ReactMarkdown> }</Table.Cell>
                              </Table.Row>
                            );
                          })}
                      </>
                    )}
                  </React.Fragment>
                );
              })}
          </Table.Body>
        </Table>
      </>
    );
  }
}

TaskListDescriptionsPrint.propTypes = {
  taskList: PropTypes.object,
};
