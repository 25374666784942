import AxiosWrapper from '../utils/AxiosWrapper';

export async function GetUniqueProducts({ dateStart, dateEnd, company}) {
  try {
    const url = `${process.env.LTG_NEXT_APP_URL}/api/toast/menu-items?company=${company.toUpperCase()}&startDate=${dateStart}&endDate=${dateEnd}`;

    const res = await AxiosWrapper({
      url: url,
      method: "GET",
      isAuthRoute: true
    });
    return res;
  } catch (e) {
    console.error(e);
  }
}

export async function GetProductItemSales({ dateStart, dateEnd, company, selectedMenuItems}) {
  try {
    const url = `${process.env.LTG_NEXT_APP_URL}/api/reports/product-item-report?company=${company.toUpperCase()}&startDate=${dateStart}&endDate=${dateEnd}&queries=${encodeURIComponent(JSON.stringify(selectedMenuItems))}`;
    const res = await AxiosWrapper({
      url: url,
      method: "GET",
      isAuthRoute: true
    });
    return res;
  } catch (e) {
    console.error(e);
  }
}
