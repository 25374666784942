import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PageHeader from "../common/PageHeader";
import usePageData from "../../state-manipulation/hooks/usePageData";
import useUserData from "../../state-manipulation/hooks/useUserData";
import { setSelectedPage } from "../../state-manipulation/reducers/page-data/types";
import { GetTrelloCards, PushOnDeckDueDateBack } from "../../api/TrelloCardsAPI";
import { useParams } from "react-router-dom";
import { Button, Dimmer, Header, Loader, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "semantic-ui-react";
import Moment from "react-moment";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { async } from "regenerator-runtime";


export default function TrelloTools({ className }) {
  const [isLoading, setIsLoading] = useState(true);
  const [, pageDispatch] = usePageData();
  const [trelloActions, setTrelloActions] = useState([]);
  const [sprintCards, setSprintCards] = useState([]);
  const [oldCards, setOldCards] = useState([]);
  const [onDeckCards, setOnDeckCards] = useState([]);
  const [trelloCards, setTrelloCards] = useState({});
  const [since, setSince] = useState(null);
  const { company } = useParams();
  const [userData ] = useUserData();


  const getTrelloCards = async ({ company, since }) => {
    let trelloCards = await GetTrelloCards(company, { since: since })
    setTrelloActions(trelloCards.data.actions);
    setTrelloCards(trelloCards.data.cards);
    setSprintCards(trelloCards.data.sprintCards);
    setOldCards(trelloCards.data.oldCards);
    setOnDeckCards(trelloCards.data.onDeckCards);
    setIsLoading(false);
  }

  const pushOnDeckDueDateBack = async () => {
    let _onDeckCards = await PushOnDeckDueDateBack(company);
    setOnDeckCards(_onDeckCards.data);
  }

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: 'Trello'
      }
    });

  }, []);

  useEffect(() => {
    setIsLoading(true);
    getTrelloCards({ company, since });
  }, [company, since]);

  return (
    <div className={"content-container " + className}>
      <PageHeader pageName="Trello" orgName={userData.selectedOrg?.name} />
      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      {!isLoading && <></>}
      <Header as="h2">Recent Comments</Header>
      {since && (
        <Button onClick={() => { setSince(null)}}>Clear Filter</Button>
      )}
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>Time</TableHeaderCell>
            <TableHeaderCell>Card</TableHeaderCell>
            <TableHeaderCell>Comment</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {trelloActions && trelloActions.map((action, aIndex) => {
            let prevAction = aIndex < trelloActions.length ? trelloActions[aIndex + 1] : trelloActions[aIndex];
            return (
              <TableRow key={aIndex}>
                <TableCell singleLine verticalAlign="top"><a onClick={() => { 
                  setSince(prevAction.id)}
                }><Moment date={action.date} format="MM/DD - h:mm a" /></a></TableCell>
                <TableCell verticalAlign="top"><a href={`https://trello.com/c/${action.data.card.shortLink}`} target="_blank">{action.data.card.name}</a></TableCell>
                <TableCell verticalAlign="top"><em>{action.memberCreator.fullName} commented...</em><ReactMarkdown children={action.data.text} /></TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <Header as="h2">Comments By Card</Header>
      <ul>
        {trelloCards && Object.keys(trelloCards).map((cardKey, ckIndex) => {
          return (
            <li key={ckIndex}>
              <strong><a href={`https://trello.com/c/${trelloCards[cardKey][0].data.card.shortLink}`} target="_blank">{cardKey}</a></strong>
              <ul>
                {trelloCards[cardKey].map((card, cIndex) => {
                  return (
                    <li key={cIndex}>
                      <ReactMarkdown children={card.data.text} />
                    </li>
                  )
                })}
              </ul>
            </li>
          )
        })}
      </ul>
      <Header as="h2">Tasks For The Week</Header>
      <ul>
        {sprintCards && sprintCards.map((task, tIndex) => {
          return (
            <li key={tIndex}>
              <a href={task.shortUrl} target="_blank">{task.name}</a> - <em>{task.listName}</em>
            </li>
          )
        })}
      </ul>
      <Header as="h2">Cards with No Activity In Last 30 Days</Header>
      <ul>
        {oldCards && oldCards.map((task, tIndex) => {
          return (
            <li key={tIndex}>
              <a href={task.shortUrl} target="_blank">{task.name}</a>
            </li>
          )
        })}
      </ul>
      <Header as="h2">On Deck</Header>

      <Button onClick={pushOnDeckDueDateBack}>Update all out dated cards to 14 days from now</Button>
      <ul>
        {onDeckCards && onDeckCards.map((task, tIndex) => {
          return (
            <li key={tIndex}>
              <a href={task.shortUrl} target="_blank">{task.name}</a> {task.due && (
                <><em>- Due <Moment date={task.due} format="MM/DD" /></em></>
              )
              }
            </li>
          )
        })}
      </ul>
    </div>
  )
}

TrelloTools.propTypes = {
  className: PropTypes.string
}

TrelloTools.defaultProps = {
  className: ""
}
