import React, { useEffect, useState } from 'react';
import MediaQuery from "react-responsive";
import PropTypes from 'prop-types';
import usePageData from '../../state-manipulation/hooks/usePageData';
import { setSelectedPage } from '../../state-manipulation/reducers/page-data/types';
import {
  Header,
  Segment,
  Dimmer,
  Loader,
  Form,
  Input,
  Button,
  Progress,
  Label,
  Table,
  List,
} from 'semantic-ui-react';
import { LG_WIDTH } from '../../constants';
import useUserData from '../../state-manipulation/hooks/useUserData';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { GetCheckPrinterInfo, PrintCheck, UpdateCheckNumber } from '../../api/ChecksAPI';
import { GetPurchaseAccounts } from '../../api/PurchaseAccountsAPI';
import { GetVendors } from '../../api/VendorsAPI';
import PageHeader from '../common/PageHeader';

export default function PrintChecks({ className }) {
  const { company } = useParams();
  const [pageData, pageDispatch] = usePageData();
  const [userData, userDispatch] = useUserData();
  const [isLoading, setIsLoading] = useState(false);
  const [checkDate, setCheckDate] = useState(moment().format('MM/DD/YY'));
  const [vendorsList, setVendorsList] = useState([]);
  const [purchaseAccounts, setPurchaseAccounts] = useState([]);
  const [purchaseAccountCode, setPurchaseAccountCode] = useState('');
  const [purchaseAccountName, setPurchaseAccountName] = useState('');
  const [vendorId, setVendorId] = useState('');
  const [vendorName, setVendorName] = useState('');
  const [amount, setAmount] = useState('');
  const [isPrintDisabled, setIsPrintDisabled] = useState(true);
  const [checkNumber, setCheckNumber] = useState('');
  const [memo, setMemo] = useState('');
  const [isACH, setIsACH] = useState(false);
  const [printerInfo, setPrinterInfo] = useState({});
  const [printStatusMessage, setPrintStatusMessage] = useState('');
  const [isPrintError, setIsPrintError] = useState(false);
  const [statusPercentage, setStatusPercentage] = useState(0);

  const getVendors = async () => {
    try {
      setIsLoading(true);
      let res = await GetVendors({ company: company });

      let vendors = res.data.map((v) => {
        return {
          text: v.Name,
          key: v.ContactID,
          value: v.ContactID,
          name: v.Name,
          purchasesdefaultaccountcode: v.PurchasesDefaultAccountCode,
          contactid: v.ContactID,
          isach: v.isACH ? "true" : "false"
        };
      });

      setVendorsList(vendors);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const getPurchaseAccounts = async () => {
    try {
      setIsLoading(true);
      let res = await GetPurchaseAccounts({ company: company });

      let purchaseAccountCodes = res.data.map((v) => {
        return {
          text: v.Name,
          key: v.Code,
          value: v.Code,
          name: v.Name,
          code: v.Code,
        };
      });

      setPurchaseAccounts(purchaseAccountCodes);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const getCheckPrinterInfo = async () => {
    try {
      setIsLoading(true);
      let res = await GetCheckPrinterInfo({ company: company });
      setPrinterInfo(res.data);
      setCheckNumber(res.data.nextCheckNumber);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const initiateSSE = () => {
    let url = `https://api.losant.com/applications/${printerInfo.applicationId}/devices/${printerInfo.checkPrinterId}/stateStream?jwt=${printerInfo.token}`;
    let es = new EventSource(url);
    es.addEventListener('deviceState', function (e) {
      let data = JSON.parse(e.data).data;
      if (data.checkNumber) {
        setCheckNumber(data.checkNumber + 1);
      }
    });
  };

  const handleCheckDateChange = (e, { value }) => {
    setCheckDate(value);
  };

  const handleAmountChange = (e, { value }) => {
    setAmount(value);
  };

  const handleCheckNumberChange = (e, { value }) => {
    setCheckNumber(value);
  };

  const handleVendorChange = (e, { value }) => {
    setVendorId(value);
  };

  const handleVendorAddition = (e, { value }) => {
    setVendorsList([
      { text: value, name: value, value: -1, contactid: -1 },
      ...vendorsList,
    ]);
    setVendorId(-1);
  };

  const handlePurchaseAccountsChange = (e, { value }) => {
    setPurchaseAccountCode(value);
  };

  const handleMemoChange = (e, { value }) => {
    setMemo(value);
  };

  const validateForm = () => {
    if (amount <= 0) {
      setIsPrintDisabled(true);
      return;
    }
    if (checkNumber <= 0) {
      setIsPrintDisabled(true);
      return;
    }
    if (!checkDate) {
      setIsPrintDisabled(true);
      return;
    }
    if (!vendorId) {
      setIsPrintDisabled(true);
      return;
    }
    if (!purchaseAccountCode) {
      setIsPrintDisabled(true);
      return;
    }
    if (!memo) {
      setIsPrintDisabled(true);
      return;
    }
    setIsPrintDisabled(false);
  };

  const printCheck = async () => {
    try {
      let data = {
        checkDate: checkDate,
        checkNumber: checkNumber,
        amount: amount,
        vendorId: vendorId,
        vendorName: vendorName,
        purchaseAccountCode: purchaseAccountCode,
        purchaseAccount: purchaseAccountName,
        memo: memo,
        employeeByPin: userData.employeeByPin,
      };

      setStatusPercentage(50);
      setIsPrintDisabled(true);
      setPrintStatusMessage('Printing...');
      let res = await PrintCheck({ company: company, options: data });

      setIsPrintDisabled(false);
      setIsPrintError(false);
      setStatusPercentage(100);
      resetCheckFields();
      setPrintStatusMessage('Success');
      setTimeout(resetStatusIndicators, 2000);
    } catch (error) {
      setIsPrintDisabled(false);
      setIsPrintError(true);
      setStatusPercentage(100);
      setPrintStatusMessage('Error');
      console.error(error);
    }
  };

  const resetCheckFields = () => {
    setAmount('');
    setVendorId('');
    setPurchaseAccountCode('');
    setMemo('');
  };

  const resetStatusIndicators = () => {
    setStatusPercentage(0);
    setIsPrintError(false);
    setPrintStatusMessage('');
  };

  const showConnectionStatus = () => {
    let color;
    let printerStatus = 'checking';

    if (printerInfo.connected === 1) {
      color = 'green';
      printerStatus = 'connected';
    } else if (printerInfo.connected === 0) {
      color = 'red';
      printerStatus = 'disconnected';
    }

    return <Label color={color}>{printerStatus}</Label>;
  };

  const handleUpdateCheckNumber = async () => {
    let res = await UpdateCheckNumber({company: company, checkNumber: checkNumber });
    console.log("handleUpdateCheckNumber -> res", res)

    setStatusPercentage(100);
    if (res.data.success) {
      setPrintStatusMessage('Success');
    } else {
      setIsPrintError(true);
      setPrintStatusMessage('Error');
    }

    setTimeout(resetStatusIndicators, 2000);
  }

  const renderACHVendors = (vendors) => {
    let ACHVendors = vendors.filter(v => v.isach === "true");
    return (
      <List as="ul">
        {ACHVendors.map((v, vIndex) => {
          return <List.Item as="li" key={vIndex}>{v.name}</List.Item>
        })}
      </List>
    )
  }

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: 'Print Checks',
      },
    });
  }, []);

  useEffect(() => {
    if (company) {
      setIsACH(false);
      getCheckPrinterInfo();
      getVendors();
      getPurchaseAccounts();
    }
  }, [company]);

  useEffect(() => {
    validateForm();
  }, [checkDate, checkNumber, amount, vendorId, purchaseAccountCode, memo]);

  useEffect(() => {
    let _vendor = vendorsList.find((v) => {
      return v.contactid === vendorId;
    });

    if (_vendor) {
      setVendorName(_vendor.name);
      setPurchaseAccountCode(_vendor.purchasesdefaultaccountcode);
      setIsACH((_vendor.isach === "true") ? true : false)
    }
  }, [vendorId]);

  useEffect(() => {
    let _purchaseAccount = purchaseAccounts.find((p) => {
      return p.code === purchaseAccountCode;
    });

    if (_purchaseAccount) {
      setPurchaseAccountName(_purchaseAccount.name);
    }
  }, [purchaseAccountCode]);

  useEffect(() => {
    if (printerInfo.applicationId) {
      initiateSSE();
    }
  }, [printerInfo]);

  return (
    <div className={'content-container ' + className}>
      <PageHeader pageName="Print Checks" orgName={userData.selectedOrg?.name} appendContent={showConnectionStatus()} />
      <Segment>
        <Dimmer active={isLoading}>
          <Loader />
        </Dimmer>
        <Progress percent={statusPercentage} autoSuccess error={isPrintError}>
          {printStatusMessage}
        </Progress>
        <Form>
          <Form.Group widths="equal">
            <DateInput
              closable
              label="Date*"
              placeholder="start"
              dateFormat="MM/DD/YY"
              className="date-picker"
              value={checkDate}
              onChange={handleCheckDateChange}
              hideMobileKeyboard={true}
            />
            <Form.Input
              fluid
              label="Check Number*"
              placeholder=""
              type="number"
              value={checkNumber}
              inputMode="numeric"
              pattern="[0-9]*"
              onChange={handleCheckNumberChange}
            />
            <Form.Field>
              <label>Amount*</label>
              <Input
                fluid
                placeholder=""
                type="number"
                value={amount}
                onChange={handleAmountChange}
              >
                <input inputMode="decimal" />
              </Input>
            </Form.Field>
          </Form.Group>
          <Form.Dropdown
            placeholder="Select Vendor"
            fluid
            search
            allowAdditions
            label={isACH ? <>Vendor* <Label color="red" pointing="left" style={{marginBottom: "5px"}}>Vendor is on ACH</Label></> : 'Vendor*'}
            selection
            value={vendorId}
            options={vendorsList}
            onAddItem={handleVendorAddition}
            onChange={handleVendorChange}
          />
          <Form.Dropdown
            placeholder="Select one"
            fluid
            search
            label="Purchase Account*"
            selection
            options={purchaseAccounts}
            value={purchaseAccountCode}
            onChange={handlePurchaseAccountsChange}
          />
          <Form.Input
            fluid
            label="Memo (Invoice #)*"
            type="text"
            placeholder="Please enter invoice number. If there is none, enter in date."
            value={memo}
            onChange={handleMemoChange}
          />
          <Button primary disabled={isPrintDisabled} onClick={printCheck}>
            Print Check
          </Button>
          {userData && userData.userInfo.userTags.hasCashPaymentsUpdateCheckNumber === "1" && (
          <Button onClick={handleUpdateCheckNumber}>
            Update Check Number
          </Button>
          )}

          <p>* = required</p>
        </Form>
      </Segment>
      <Segment>
        <Header as="h3">ACH Vendors</Header>
        {renderACHVendors(vendorsList)}
        <Header as="h3">Checks</Header>
        <p>Order more checks when check number is above {printerInfo.orderCheckNumber}.</p>
        <List as="ul">
          <List.Item as="li">Last check number in the book: {printerInfo.lastCheckNumber}.</List.Item>
          <List.Item as="li">Minimum Par: {printerInfo.checkNumberPar} checks</List.Item>
          <List.Item as="li">Checks left until next order: {printerInfo.checksUntilOrder} checks</List.Item>
        </List>
      </Segment>
    </div>
  );
}

PrintChecks.propTypes = {
  className: PropTypes.string,
};

PrintChecks.defaultProps = {
  className: '',
};
