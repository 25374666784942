import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  Form,
  Icon,
  Checkbox,
  Label,
  Table,
  Grid,
  Button,
  Header,
  Ref,
  Dimmer,
  Loader,
  Modal,
  Input,
  Menu
} from 'semantic-ui-react';
import { TimeInput } from 'semantic-ui-calendar-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import TaskForm from './TaskForm';
import taskSchema from '../../schemas/task-list.json';
import useUserData from '../../state-manipulation/hooks/useUserData';
import { useNavigate, useParams } from 'react-router-dom';
import { PostTaskList } from '../../api/TaskListAPI';
import { GetTaskListTemplates, PatchTaskListTemplate } from '../../api/TaskListTemplateAPI';
import { LG_WIDTH, MD_WIDTH } from '../../constants';
import { toLowercaseHyphes } from '../../utils/StringUtils';
import TaskListPrint from './TaskListPrint';
import TaskListDescriptionsPrint from './TaskListDescriptionsPrint';
import ReactToPrint from 'react-to-print';
import AxiosWrapper from '../../utils/AxiosWrapper';
import TaskListWeeklyPrint from './TaskListWeeklyPrint';

const scheduleDropdownOptions = taskSchema.properties.frequency.enum.map(o => {
  return {
    text: o,
    value: o
  };
});

const daysOfWeek = taskSchema.properties.daysOfWeek.items.enum;
const assigneeRoles = taskSchema.properties.assigneeRoles.items.enum;

// conditional required
let frequencyDaysDepenencies = [];

taskSchema.allOf.forEach(aof => {
  if (aof.then.required.indexOf('daysOfWeek') > -1) {
    frequencyDaysDepenencies.push(aof.if.properties.frequency.const);
  }
});

export default function TaskListForm({ taskListTemplate }) {
  const [userData] = useUserData();
  let navigate = useNavigate();
  const { company } = useParams();
  const [taskListTemplateId, setTaskListTemplateId] = useState(null);
  const [taskTemplateName, setTaskTemplateName] = useState('');
  const [frequencyType, setFrequencyType] = useState('');
  const [daysSelected, setDaysSelected] = useState([]);
  const [selectedAssigneeRoles, setSelectedAssigneeRoles] = useState([]);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [tasks, setTasks] = useState([]);
  const [autogenerate, setAutogenerate] = useState(false);
  const defaultCurrentTask = {
    name: '',
    additionalFields: []
  };
  const [currentTask, setCurrentTask] = useState(defaultCurrentTask);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalTasks, setTotalTasks] = useState('');
  const [taskTemplates, setTaskTemplates] = useState([]);
  const [showClonedConfirm, setShowClonedConfirm] = useState([]);
  const componentRef = useRef();
  const taskListDecriptionsRef = useRef();
  const taskListWeeklyRef = useRef();

  const getTaskListTemplates = async () => {
    try {
      setIsLoading(true);
      let res = await GetTaskListTemplates({ company: company });

      setTaskTemplates(res.data);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const getAllTaskListTemplates = async () => {
    let _taskListTemplates = [];

    await userData.orgs.forEach(async o => {
      let _company = toLowercaseHyphes(o.name);
      let res = await GetTaskListTemplates({ company: _company });

      if (res.status === 200) {
        _taskListTemplates = _taskListTemplates.concat(res.data);
        setTaskTemplates(_taskListTemplates);
      }

    });
  }

  const saveTaskListTemplate = async (_taskListTemplate, company) => {
    try {
      let url = `${process.env.API_URL}/${company}/task-list-templates`;
      let method = 'POST';
      setIsLoading(true);
      if (_taskListTemplate.id) {
        url += `/${_taskListTemplate.id}`;
        method = 'PUT';
        delete _taskListTemplate.id;
      }

      let res = await AxiosWrapper({
        method: method,
        url: url,
        headers: {
          Authorization: `Basic ${userData.token}`
        },
        data: _taskListTemplate
      });

      let insertedId = res.data.insertedId;
      if (insertedId) {
        navigate(
          `/${company}/admin/task-list-templates/${insertedId.$oid}`, { replace: true }
        );
        setTaskListTemplateId(insertedId.$oid);
      }

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  const deleteTaskListTemplate = async (_taskListTemplateId, company) => {
    try {
      let url = `${process.env.API_URL}/${company}/task-list-templates/${_taskListTemplateId}`;
      let method = 'DELETE';
      setIsLoading(true);

      let res = await AxiosWrapper({
        method: method,
        url: url,
        headers: {
          Authorization: `Basic ${userData.token}`
        }
      });

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  const handleDaysSelectedChange = (e, { value }) => {
    let _daysSelected = [...daysSelected];
    let index = _daysSelected.indexOf(value);
    if (index === -1) {
      _daysSelected.push(value);
    } else {
      _daysSelected.splice(index, 1);
    }
    setDaysSelected(_daysSelected);
  };

  const handleAllDaysSelectedChange = (e, { checked }) => {
    if (checked) {
      setDaysSelected(daysOfWeek);
    } else {
      setDaysSelected([]);
    }
  };

  const handleAssigneeRolesSelectedChange = (e, { value }) => {
    let _selectedAssigneeRoles = [...selectedAssigneeRoles];
    let index = _selectedAssigneeRoles.indexOf(value);
    if (index === -1) {
      _selectedAssigneeRoles.push(value);
    } else {
      _selectedAssigneeRoles.splice(index, 1);
    }
    setSelectedAssigneeRoles(_selectedAssigneeRoles);
  };

  const handleCloneTask = async (e, { task, tasktemplateid, taskindex, destinationcompany }) => {
    if (tasktemplateid === taskListTemplateId) {
      let _tasks = [...tasks];
      _tasks.splice(taskindex, 0, task);
      setTasks(_tasks);
    } else {
      try {
        let res = await PatchTaskListTemplate({
          company: toLowercaseHyphes(destinationcompany),
          id: tasktemplateid,
          action: 'add',
          data: task
        });
        let _showClonedConfirm = [];
        _showClonedConfirm[taskindex] = true;
        setShowClonedConfirm(_showClonedConfirm);
        setTimeout(() => {
          let _showClonedConfirm = [];
          _showClonedConfirm[taskindex] = false;
          setShowClonedConfirm(_showClonedConfirm);
        }, 1000);
      } catch (e) {
        console.error(e);
      }

    }
  };

  const handleAllRolesSelectedChange = (e, { checked }) => {
    if (checked) {
      setSelectedAssigneeRoles(assigneeRoles);
    } else {
      setSelectedAssigneeRoles([]);
    }
  };

  const handleAutogenerateChange = (e, { checked }) => {
    setAutogenerate(checked);
  };

  const handleAddTask = e => {
    e.preventDefault();
    setCurrentTask(defaultCurrentTask);
    setIsModalOpen(true);
  };

  const addSection = () => {
    let _tasks = [...tasks];
    _tasks.push({
      name: 'Section',
      type: 'section'
    });
    setTasks(_tasks);
  };

  const handleAddSection = e => {
    e.preventDefault();
    addSection();
  };

  const handleAddTaskFromModal = task => {
    task.type = 'task';
    let _tasks = [...tasks];
    if (task.index === 0 || task.index > 0) {
      _tasks[task.index] = task;
    } else {
      _tasks.push(task);
    }
    setTasks(_tasks);
    setIsModalOpen(false);
    setCurrentTask(defaultCurrentTask);
  };

  const handleEditTask = (e, { task }) => {
    setCurrentTask(task);
    setIsModalOpen(true);
  };

  const handleDeleteTask = (e, { task, index }) => {
    console.log('handleDeleteTask -> index', task.index);
    let _tasks = [...tasks];
    _tasks.splice(task.index, 1);
    setTasks(_tasks);
  };

  const handleEditSection = (e, { task, value }) => {
    console.log('handleEditSection -> index', task.index);
    let _tasks = [...tasks];
    _tasks[task.index].name = value;
    setTasks(_tasks);
  };

  const handleDeleteTaskListTemplateStart = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteTaskListYes = async () => {
    setIsDeleteModalOpen(false);
    await deleteTaskListTemplate(
      taskListTemplateId,
      userData.selectedOrg.name.toLowerCase()
    );
    navigate(`/${company}/admin/task-list-templates/`, { replace: true });
  };

  const handleDeleteTaskListNo = () => {
    setIsDeleteModalOpen(false);
  };

  const onDragEnd = result => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    // remove source
    let _tasks = [...tasks];
    _tasks.splice(source.index, 1);
    // add destination
    _tasks.splice(destination.index, 0, [...tasks][source.index]);
    setTasks(_tasks);
  };

  const handleAddTaskKeyboard = () => {
    if (!isModalOpen) {
      handleAddTask();
    }
  };

  const validateForm = () => {
    let _isFormValid = true;
    if (!taskTemplateName) {
      _isFormValid = false;
    }

    if (!frequencyType) {
      _isFormValid = false;
    } else if (frequencyDaysDepenencies.indexOf(frequencyType) > -1) {
      if (daysSelected.length === 0) {
        _isFormValid = false;
      }
    }

    if (selectedAssigneeRoles.length === 0) {
      _isFormValid = false;
    }

    setIsFormValidated(_isFormValid);
  };

  const getTaskListTemplate = () => {
    return {
      id: taskListTemplateId,
      name: taskTemplateName,
      autogenerate: autogenerate,
      frequency: frequencyType,
      daysSelected: daysSelected,
      startTime: startTime,
      endTime: endTime,
      assigneeRoles: selectedAssigneeRoles,
      tasks: tasks
    };
  };

  const handleFormSubmit = async () => {
    if (isModalOpen) {
      return;
    }
    await saveTaskListTemplate(
      getTaskListTemplate(),
      userData.selectedOrg.name.toLowerCase()
    );
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    background: isDragging && 'lightblue',
    left: isDragging && 0
  });

  const generateTaskList = async _taskList => {
    try {
      setIsLoading(true);
      let res = await PostTaskList(_taskList, company);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  const handleGenerateTaskList = async () => {
    let taskList = getTaskListTemplate();
    delete taskList.id;
    await generateTaskList(taskList);
    navigate(`/${company}/admin/task-list-templates`, { replace: true });
  };

  const renderTaskTemplatesDropDown = (task, taskIndex, _taskTemplates) => {
    let t = task;

    // format task headers
    let taskTemplatesToRender = [];
    let prevCompany;

    _taskTemplates = _taskTemplates.sort((a, b) => {
      let aString = a.company.toUpperCase();
      let bString = b.company.toUpperCase();
      if (aString > bString) {
        return 1;
      } else if (bString > aString) {
        return -1;
      }
      return 0;
    });


    _taskTemplates.forEach(tt => {
      if (prevCompany !== tt.company) {
        taskTemplatesToRender.push({
          isHeader: true,
          name: tt.company
        });
      }
      prevCompany = tt.company;
      taskTemplatesToRender.push(tt);
    });

    const renderHeaderOrTaskTemplate = (tt, ttIndex) => {
      if (tt.isHeader) {
        return (<Dropdown.Header key={ttIndex} className="dropdown-header" content={`${tt.name} - Task List Templates`} />);
      }

      return (
        <Dropdown.Item
          key={ttIndex}
          onClick={handleCloneTask}
          tasktemplateid={tt._id.$oid}
          task={t}
          destinationcompany={tt.company}
          taskindex={taskIndex}
        >
          {tt.name}
        </Dropdown.Item>
      )
    }

    return (
      <Dropdown.Menu
        direction={window.innerWidth > MD_WIDTH ? 'left' : 'right'}
        className="task-list-template-options"
      >
        {taskTemplatesToRender.map((tt, ttIndex) => {
          return (
            renderHeaderOrTaskTemplate(tt, ttIndex)
          );
        })}
      </Dropdown.Menu>
    );
  };

  useEffect(() => {
    validateForm();
  }, [taskTemplateName, frequencyType, selectedAssigneeRoles, daysSelected]);

  useEffect(() => {
    console.log('taskListTemplate');
    console.log(taskListTemplate);
    if (taskListTemplate) {
      getAllTaskListTemplates();
      setTaskTemplateName(taskListTemplate.name);
      setFrequencyType(taskListTemplate.frequency);
      setDaysSelected(taskListTemplate.daysSelected);
      setSelectedAssigneeRoles(taskListTemplate.assigneeRoles);
      setStartTime(taskListTemplate.startTime);
      setEndTime(taskListTemplate.endTime);
      setTasks(taskListTemplate.tasks);
      setTaskListTemplateId(taskListTemplate._id.$oid);
      setAutogenerate(taskListTemplate.autogenerate);
    }
  }, [taskListTemplate]);

  useEffect(() => {
    let _totalTasks = tasks.filter(t => t.type === 'task');
    setTotalTasks(_totalTasks.length);
  }, [tasks]);

  return (
    <Form onSubmit={handleFormSubmit}>
      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      <KeyboardEventHandler
        handleKeys={['n']}
        onKeyEvent={handleAddTaskKeyboard}
      />
      <Form.Field>
        <label>Name *</label>
        <Form.Input
          placeholder="Task Template Name"
          value={taskTemplateName}
          onChange={(e, { value }) => {
            setTaskTemplateName(value);
          }}
        />
      </Form.Field>
      <Form.Field>
        <label>Schedule *</label>
        <Dropdown
          options={scheduleDropdownOptions}
          placeholder="Schedule"
          selection
          value={frequencyType}
          onChange={(e, { value }) => {
            setFrequencyType(value);
          }}
        ></Dropdown>
      </Form.Field>
      {frequencyDaysDepenencies.indexOf(frequencyType) > -1 && (
        <>
          <Form.Group inline>
            <label>Days</label>
            <Form.Field>
              <Checkbox
                label="All"
                value="ALL"
                onChange={handleAllDaysSelectedChange}
              ></Checkbox>
            </Form.Field>
            {daysOfWeek.map(d => {
              return (
                <Form.Field key={d}>
                  <Checkbox
                    label={d}
                    value={d}
                    checked={daysSelected.indexOf(d) > -1}
                    onChange={handleDaysSelectedChange}
                  ></Checkbox>
                </Form.Field>
              );
            })}
          </Form.Group>
        </>
      )}
      <Form.Group inline>
        <Form.Field>
          <label>Start Time</label>
          <TimeInput
            name="Start Time"
            closable
            hideMobileKeyboard
            timeFormat="ampm"
            value={startTime}
            onChange={(e, { value }) => {
              setStartTime(value);
            }}
          />
        </Form.Field>
        <Form.Field>
          <label>End Time</label>
          <TimeInput
            name="End Time"
            closable
            hideMobileKeyboard
            timeFormat="ampm"
            value={endTime}
            onChange={(e, { value }) => {
              setEndTime(value);
            }}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group inline>
        <label>Assigned To: *</label>
        <Form.Field>
          <Checkbox
            label="All"
            value="ALL"
            onChange={handleAllRolesSelectedChange}
          ></Checkbox>
        </Form.Field>
        {assigneeRoles.map(r => {
          return (
            <Form.Field key={r}>
              <Checkbox
                label={r}
                value={r}
                checked={selectedAssigneeRoles.indexOf(r) > -1}
                onChange={handleAssigneeRolesSelectedChange}
              ></Checkbox>
            </Form.Field>
          );
        })}
      </Form.Group>
      <Form.Field>
        <Checkbox
          checked={autogenerate}
          onChange={handleAutogenerateChange}
          label="Autogenerate"
        />
      </Form.Field>
      <Grid columns={2}>
        <Grid.Column>
          <Header as="h2">Tasks - {totalTasks}</Header>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Button onClick={handleAddTask} secondary type="button">
            Add Task
          </Button>
          <Button onClick={handleAddSection} secondary type="button">
            Add Section
          </Button>
          <Button.Group color="blue">
            <ReactToPrint
              trigger={() => <Button primary>Print task list</Button>}
              content={() => componentRef.current}
            />
            <Dropdown
              className="button icon"
              floating
              options={[
                {
                  key: 'print-description',
                  text: 'Print Descriptions',
                  value: 'Print Descriptions',
                  as: () => (
                    <ReactToPrint
                      trigger={() => <Menu.Item>Print Description</Menu.Item>}
                      content={() => taskListDecriptionsRef.current}
                    />
                  ),
                },
                {
                  key: 'print-weekly-list',
                  text: 'Print Weekly List',
                  value: 'Print Weekly List',
                  as: () => (
                    <ReactToPrint
                      trigger={() => <Menu.Item>Print Weekly List</Menu.Item>}
                      content={() => taskListWeeklyRef.current}
                    />
                  ),
                },
              ]}
              trigger={<React.Fragment />}
            />
          </Button.Group>
          <div style={{ display: 'none' }}>
            <TaskListPrint ref={componentRef} taskList={taskListTemplate} />
          </div>
          <div style={{ display: 'none' }}>
            <TaskListDescriptionsPrint
              ref={taskListDecriptionsRef}
              taskList={taskListTemplate}
            />
          </div>
          <div style={{ display: 'none' }}>
            <TaskListWeeklyPrint
              ref={taskListWeeklyRef}
              taskList={taskListTemplate}
            />
          </div>
        </Grid.Column>
      </Grid>
      <DragDropContext onDragEnd={onDragEnd}>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>Task</Table.HeaderCell>
              <Table.HeaderCell>Days of Week</Table.HeaderCell>
              <Table.HeaderCell>Additional Fields</Table.HeaderCell>
              <Table.HeaderCell>Created By</Table.HeaderCell>
              <Table.HeaderCell>Active</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Droppable droppableId="table">
            {(provided, snapshot) => (
              <Ref innerRef={provided.innerRef}>
                <Table.Body {...provided.droppableProps}>
                  {tasks.map((t, taskIndex) => {
                    return (
                      <Draggable
                        draggableId={`row-${taskIndex}`}
                        index={taskIndex}
                        key={taskIndex}
                      >
                        {(provided, snapshot) => (
                          <Ref innerRef={provided.innerRef}>
                            {t.type === 'section' ? (
                              <Table.Row
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                active={t.isHighlighted}
                                className="task-section"
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <Table.Cell collapsing>
                                  <Icon
                                    name="delete"
                                    task={{ ...t, index: taskIndex }}
                                    onClick={handleDeleteTask}
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  <Input
                                    value={t.name}
                                    task={{ ...t, index: taskIndex }}
                                    onChange={handleEditSection}
                                  />
                                </Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                              </Table.Row>
                            ) : (
                              <Table.Row
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                active={t.isHighlighted}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <Table.Cell collapsing>
                                  <Icon
                                    name="edit"
                                    task={{ ...t, index: taskIndex }}
                                    onClick={handleEditTask}
                                  />
                                  <Icon
                                    name="delete"
                                    task={{ ...t, index: taskIndex }}
                                    onClick={handleDeleteTask}
                                  />
                                </Table.Cell>
                                <Table.Cell>{t.name}</Table.Cell>
                                <Table.Cell>
                                  {t.taskDays && t.taskDays.join(' ')}
                                </Table.Cell>
                                <Table.Cell>
                                  {t.additionalFields.length}
                                </Table.Cell>
                                <Table.Cell>{t.createdBy}</Table.Cell>
                                <Table.Cell>{t.active}</Table.Cell>
                                <Table.Cell>
                                  {showClonedConfirm[taskIndex] && (
                                    <>
                                      <div style={{position: 'relative'}}>
                                        <Label className="task-cloned" color="green">Task Cloned</Label>
                                      </div>
                                    </>
                                  )}
                                  <Dropdown
                                    icon='clone outline'
                                    className='icon'
                                    >
                                   {renderTaskTemplatesDropDown(t, taskIndex, taskTemplates)}
                                  </Dropdown>
                                </Table.Cell>
                              </Table.Row>
                            )}
                          </Ref>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Table.Body>
              </Ref>
            )}
          </Droppable>
        </Table>
      </DragDropContext>
      <TaskForm
        task={currentTask}
        isModalOpen={isModalOpen}
        handleModalClose={() => {
          setIsModalOpen(false);
        }}
        handleAddTask={handleAddTaskFromModal}
      />
      <DeleteTaskConfirm
        isModalOpen={isDeleteModalOpen}
        handleDeleteTaskListYes={handleDeleteTaskListYes}
        handleDeleteTaskListNo={handleDeleteTaskListNo}
      />
      <Grid columns={2}>
        <Grid.Column>
          <Button
            primary
            disabled={!isFormValidated ? true : false}
            type="submit"
          >
            Save
          </Button>
          {taskListTemplateId && (
            <Button type="button" onClick={handleDeleteTaskListTemplateStart}>
              Delete
            </Button>
          )}
          <Button onClick={handleAddTask} secondary type="button">
            Add Task
          </Button>
          <Button onClick={handleAddSection} secondary type="button">
            Add Section
          </Button>
        </Grid.Column>
        {taskListTemplateId && (
          <Grid.Column textAlign="right">
            <Button type="button" onClick={handleGenerateTaskList}>
              Generate Task List
            </Button>
          </Grid.Column>
        )}
      </Grid>
    </Form>
  );
}

TaskListForm.propTypes = {
  taskListTemplate: PropTypes.object
};

function DeleteTaskConfirm({
  isModalOpen,
  handleDeleteTaskListYes,
  handleDeleteTaskListNo
}) {
  return (
    <Modal open={isModalOpen} onClose={handleDeleteTaskListNo}>
      <Modal.Header>Delete Task List?</Modal.Header>
      <Modal.Content>
        <Button onClick={handleDeleteTaskListYes}>Yes</Button>
        <Button onClick={handleDeleteTaskListNo}>No</Button>
      </Modal.Content>
    </Modal>
  );
}

DeleteTaskConfirm.propTypes = {
  isModalOpen: PropTypes.bool,
  handleDeleteTaskListYes: PropTypes.func,
  handleDeleteTaskListNo: PropTypes.func
};

DeleteTaskConfirm.defaultProps = {
  isModalOpen: false
};
