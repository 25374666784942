import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Link,
  useParams,
} from 'react-router-dom';

import useUserData from '../state-manipulation/hooks/useUserData';
import usePageData from '../state-manipulation/hooks/usePageData';
import { setSelectedPage } from '../state-manipulation/reducers/page-data/types';
import {
  Header,
  Segment,
  Dimmer,
  Loader,
  Statistic,
  Menu,
  Table,
  Icon,
} from 'semantic-ui-react';
import { LG_WIDTH } from '../constants';
import moment from 'moment';
import StockPotsLive from '../components/iot/StockPotsLive';
import StockPotsHistorical from '../components/iot/StockPotsHistorical';
import DateRangeDataProvider from '../state-manipulation/providers/DateRangeDataProvider';
import DateMenu from '../components/common/DateMenu';
import DateRangeSelector from '../components/common/DateRangeSelector';
import useDateRangeData from '../state-manipulation/hooks/useDateRangeData';
import { GetAlerts } from '../api/AlertAPI';
import Moment from 'react-moment';
import PageHeader from '../components/common/PageHeader';

export default function Alerts({ className }) {
  const { company } = useParams();
  const [userData, userDispatch] = useUserData();
  const [pageData, pageDispatch] = usePageData();
  const [dateRangeData] = useDateRangeData();
  const [isLoading, setIsLoading] = useState(false);
  const [stockPots, setStockPots] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [token, setToken] = useState('');

  const getAlerts = async () => {
    let res = await GetAlerts({
      company: company,
      startDate: dateRangeData.dateStart,
      endDate: dateRangeData.dateEnd
    });

    console.log('getAlerts -> res', res);
    setAlerts(res.data.items);
  };

  const renderIcon = (level) => {
    let iconName = 'warning';
    let color = 'yellow';

    if (level === 'warning') {
      iconName = 'exclamation circle';
      color = 'yellow';
    } else if (level === 'error') {
      iconName = 'exclamation triangle';
      color = 'red';
    } else if (level === 'critical') {
      iconName = 'emergency'
      color = 'red';
    }
    return <Icon name={iconName} color={color} />;
  }

  const renderMessageOrUpdate = (alert) => {

    if (alert.updates.length > 0) {
      return alert.updates[alert.updates.length - 1].comment;
    }

    return alert.message;
  }

  const renderResource = (alert) => {
    let a = alert;
    if (['refrigerator'].indexOf(a.eventTags.alertType) > -1) {
      return <Link to={`/${company}/refrigerators`}>{a.deviceName}</Link>
    }

    if (a.eventTags.alertType === 'tasklist') {
      return <Link to={`/${company}/task-lists/${a.eventTags.resourceId}`}>{a.eventTags.resourceName}</Link>
    }

    if (a.eventTags.alertType === '7shifts') {
      return <Link to={`/${company}/seven-shifts-sync`}>7Shifts Sync</Link>
    }

    if (a.eventTags.resourceLink) {
      return <Link to={`/${company}/${a.eventTags.resourceLink}`}>{a.eventTags.resourceName}</Link>
    }

    return '';
  }

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: 'Alerts',
      },
    });
  }, []);

  useEffect(() => {
    getAlerts();
  }, [company, dateRangeData.dateStart, dateRangeData.dateEnd]);

  return (
    <div className={'content-container ' + className}>
      <PageHeader pageName="Alerts" orgName={userData.selectedOrg?.name} />
      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      <DateMenu />
      <DateRangeSelector />
      <Table sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Subject</Table.HeaderCell>
            <Table.HeaderCell>Resource</Table.HeaderCell>
            <Table.HeaderCell>Level</Table.HeaderCell>
            <Table.HeaderCell>Open At</Table.HeaderCell>
            <Table.HeaderCell>Resolved At</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {alerts.map((a, aIndex) => {
            return (
              <Table.Row key={aIndex}>
                <Table.Cell>
                  <Moment format="MM/DD/YYYY">{a.creationDate}</Moment>
                </Table.Cell>
                <Table.Cell>{a.eventTags.alertType}</Table.Cell>
                <Table.Cell>
                  <strong>{a.subject}</strong>
                  <br />
                  {renderMessageOrUpdate(a)}
                </Table.Cell>
                <Table.Cell>
                  {renderResource(a)}
                </Table.Cell>
                <Table.Cell>{renderIcon(a.level)}</Table.Cell>
                <Table.Cell>
                  <Moment format="MM/DD/YY h:mm">{a.creationDate}</Moment>
                </Table.Cell>
                <Table.Cell>
                  {a.resolvedAt && (
                    <Moment format="MM/DD/YY h:mm">{a.resolvedAt}</Moment>
                  )}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}

Alerts.propTypes = {
  className: PropTypes.string,
};

Alerts.defaultProps = {
  className: '',
};
