import AxiosWrapper from '../utils/AxiosWrapper';

export async function getPeriodReport({ year, period, company }) {
  let res = await AxiosWrapper({
    url: `${
      process.env.LTG_NEXT_APP_URL
    }/api/reports/period-report?year=${year}&number=${period}&company=${company.toUpperCase()}`,
    method: "GET",
    isAuthRoute: true
  });
  return res;
}

export async function deleteCostSummaryReport({ groupId, company }) {
  let res = await AxiosWrapper({
    url: `${
      process.env.LTG_NEXT_APP_URL
    }/api/reports/cost-summary-reports/group-ids/${groupId}?company=${company.toUpperCase()}`,
    method: "DELETE",
    isAuthRoute: true
  });
  return res;
}