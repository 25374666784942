import AxiosWrapper from '../utils/AxiosWrapper';

export async function GetTrelloCards(company, { since }) {
  try {
    let url = `${process.env.API_URL}/${company}/trello-cards`;
    let filters = [];

    if (since) {
      filters.push(`since=${since}`);
    }

    if (filters.length > 0) {
      url += `?${filters.join('&')}`;
    }

    let res = await AxiosWrapper({
      method: 'GET',
      url: url,
      isAuthRoute: true
    });
    return res;
  } catch (e) {
    console.error(e);
  }
}

export async function PushOnDeckDueDateBack(company) {
  try {
    let url = `${process.env.API_URL}/${company}/update-on-deck-due-date`;
    let filters = [];


    if (filters.length > 0) {
      url += `?${filters.join('&')}`;
    }

    let res = await AxiosWrapper({
      method: 'PATCH',
      url: url,
      isAuthRoute: true
    });
    return res;
  } catch (e) {
    console.error(e);
  }
}
