import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Header, Table, Icon, Input, Confirm, Button, Dimmer, Loader } from "semantic-ui-react";
import { LG_WIDTH } from "../../constants";
import useUserData from "../../state-manipulation/hooks/useUserData";
import usePageData from "../../state-manipulation/hooks/usePageData";
import { setSelectedPage } from "../../state-manipulation/reducers/page-data/types";
import SyncErrorDetails from "./SyncErrorDetails";
import { clearUser } from "../../state-manipulation/reducers/user-data/types";
import PageHeader from "../common/PageHeader";

export default function SevenShiftsSync({className}) {
  const [ userData, userDispatch ] = useUserData();
  const [ syncErrors, setSyncErrors ] = useState({});
  const [ pageData, pageDispatch ] = usePageData();
  const [ isFatal, setIsFatal ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);

  const getEmployeeErrors = async (company) => {
    try {
      setIsLoading(true);
      let res = await axios({
        method: 'GET',
        url: `${process.env.API_URL}/${company}/employee-errors`,
        headers: {
          'Authorization': `Basic ${userData.token}`
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          userDispatch({
            type: clearUser,
            payload: {}
          });
        }
      });

      setSyncErrors(res.data);

      setIsFatal(res.data.isFatal);

      setIsLoading(false);

    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleSyncUsers = async () => {
    try {
      setIsLoading(true);
      let url = `https://triggers.losant.com/webhooks/yeR_kSg33ydVhawVj2nEXm4f_fy_a6b7MpckLJSUaA1$?company=${userData.selectedOrg.name.toUpperCase()}`;
      await AxiosWrapper({
        method: 'GET',
        url: url
      });

      getEmployeeErrors(userData.selectedOrg.name.toLowerCase());

    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleCheckData = async () => {
    try {
      setIsLoading(true);
      let url = `https://triggers.losant.com/webhooks/Pey2-Ik2xS0O1TV0d4QtO6TljxeKWnQ-lllobkp7aA1$?company=${userData.selectedOrg.name.toUpperCase()}`;
      await AxiosWrapper({
        method: 'GET',
        url: url
      });

      getEmployeeErrors(userData.selectedOrg.name.toLowerCase());

    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };


  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: 'SevenShifts Sync'
      }
    });
  }, []);

  useEffect(() => {
    if (userData.selectedOrg) {
      getEmployeeErrors(userData.selectedOrg.name.toLowerCase());
    }
  }, [userData]);

  useEffect(() => {
  }, []);


  return (
  <div className={"content-container " + className}>
    <PageHeader pageName="SevenShifts Sync" orgName={userData.selectedOrg?.name} />
    <Dimmer active={isLoading}>
      <Loader />
    </Dimmer>

    {syncErrors.dinerwareMissingEmail &&
      <SyncErrorDetails dinerwareUsers={syncErrors.dinerwareMissingEmail}
        header="Dinerware Users Missing Email"
        message={<p>These users need an email address</p>} />
    }

    {syncErrors.dinerwareMissingZipCode &&
      <SyncErrorDetails dinerwareUsers={syncErrors.dinerwareMissingZipCode}
        header="Dinerware Users Missing ZipCode"
        message={<p>These users need a zip code</p>}
        col1="zipCode" />
    }

    {syncErrors.dinerwareMissingNamePart &&
      <SyncErrorDetails dinerwareUsers={syncErrors.dinerwareMissingNamePart}
        header="Dinerware Users First or Last Name"
        message={<p>These users need a first and last name.</p>} />
    }

    {syncErrors.dinerwareNameNotUppercase &&
      <SyncErrorDetails dinerwareUsers={syncErrors.dinerwareNameNotUppercase}
        header="Dinerware Users Case Format Incorrect"
        message={<p>Please make sure these names are in title case (e.g. &quot;John Doe&quot; and not &quot;JOHN DOE&quot; or &quot;john doe&quot;).</p>} />
    }

    {syncErrors.dinerwareStateBadFormat &&
      <SyncErrorDetails dinerwareUsers={syncErrors.dinerwareStateBadFormat}
        header="Dinerware State Format Errors"
        message={<p>Change the state to a 2 letter capitalized case. (e.g. &quot;OH&quot; and not &quot;ohio&quot;)</p>}
        col1="state" />
    }
    {syncErrors.dinerwareCityNotUppercase &&
      <SyncErrorDetails dinerwareUsers={syncErrors.dinerwareCityNotUppercase}
        header="Dinerware City Format Errors"
        message={<p>Please make sure first letter of city is in uppercase (e.g. &quot;Cincinnati&quot; and not &quot;cincinnati&quot;)</p>}
        col1="city" />
    }

    {syncErrors.dinerwareNoJob &&
      <SyncErrorDetails dinerwareUsers={syncErrors.dinerwareNoJob}
        header="Dinerware Users Need a Job"
        message={<p>Please assign at least one job for these users</p>} />
    }

    {syncErrors.sevenShiftsNotInDinerware &&
      <SyncErrorDetails dinerwareUsers={syncErrors.sevenShiftsNotInDinerware}
        header="7Shifts users are not in Dinerware."
        message={<p>User could have updated email in 7shifts. Need to update their email address in Dinerware or deactivate in 7shifts.</p>} />
    }

    {syncErrors.dinerwareNotIn7Shifts &&
      <SyncErrorDetails dinerwareUsers={syncErrors.dinerwareNotIn7Shifts}
        header="Dinerware Users Not in 7Shifts"
        message={<p>These users are not in 7Shifts. Consider deactivating them in Dinerware. Or they will be added on the next sync.</p>}
        errorLevel="warning" />
    }

    {syncErrors.dinerwareMissingHireDate &&
      <SyncErrorDetails dinerwareUsers={syncErrors.dinerwareMissingHireDate}
        header="Dinerware Users Missing Hire Date"
        message={<p>These employ are need a hire date. If you need to find the date, run a shift report to find the earliest date worked.</p>}
        errorLevel="warning"
        col1="hireDate" />
    }
    <Button onClick={handleSyncUsers} color="blue" disabled={isFatal ? true : false}>Sync Users</Button>
    <Button onClick={handleCheckData} color="blue">Check Data</Button>
  </div>
  );
}

SevenShiftsSync.propTypes = {
  className: PropTypes.string
};

SevenShiftsSync.defaultProps = {
  className: ''
};