import { visit } from "unist-util-visit";

function getVideoID(userInput) {
  var res = userInput.match(
    /^.*(?:(?:youtu.be\/)|(?:v\/)|(?:\/u\/\w\/)|(?:embed\/)|(?:watch\?))\??v?=?([^#\&\?]*).*/
  );
  if (res) return res[1];
  return false;
}

function generateYoutubeVideoHTML(match, offset) {
  let options = {};
  if (offset.startsWith("youtube:")) {
    const videoID = getVideoID(offset.slice(8));
    const video = {};
    if (videoID) {
      return `<div style="width: ${options.width || "100%"}; margin: 0 ${
        options.align || "0"
      };"><div style="position: relative; padding-bottom: 56.25%; padding-top: 25px; height: 0;"><iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: none;" src="https://www.youtube.com/embed/${videoID}"></iframe></div></div>`;
    }
    return match;
  }
  return match;
}

export default function () {
  return (tree, file) => {
    visit(tree, ["inlineCode"], (node) => {
      // leaving this open to find ways to pass options
      let options = {};
      if (node.value.startsWith("youtube:")) {
        const videoID = getVideoID(node.value.slice(8));
        const video = {};
        if (videoID) {
          video.type = "html";
          video.value = `<div style="width: ${
            options.width || "100%"
          }; margin: 0 ${
            options.align || "0"
          };"><div style="position: relative; padding-bottom: 56.25%; padding-top: 25px; height: 0;"><iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: none;" src="https://www.youtube.com/embed/${videoID}"></iframe></div></div>`;
          return Object.assign(node, video);
        }
      }
    });

    visit(tree, "html", (node) => {
      console.log("plugin");
      console.log(node);
      // leaving this open to find ways to pass options
      let options = {};
      node.value = node.value.replace(/\`(.*?)\`/g, generateYoutubeVideoHTML);
      // console.log("🚀 ~ file: index.js ~ line 32 ~ visit ~ youtubeCodeBlocks", youtubeCodeBlocks)

      // youtubeCodeBlocks.forEach(t => {
      //   generateYoutubeVideoHTML(t);
      // })

      if (node.value?.startsWith("youtube:")) {
        const videoID = getVideoID(node.value.slice(8));
        const video = {};
        if (videoID) {
          video.type = "html";
          video.value = `<div style="width: ${
            options.width || "100%"
          }; margin: 0 ${
            options.align || "0"
          };"><div style="position: relative; padding-bottom: 56.25%; padding-top: 25px; height: 0;"><iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: none;" src="https://www.youtube.com/embed/${videoID}"></iframe></div></div>`;
          return Object.assign(node, video);
        }
      }
    });
  };
}
