import AxiosWrapper from '../utils/AxiosWrapper';

export async function GetCheckPrinterInfo({ company }) {
  try {
    let url = `${process.env.API_URL}/${company}/check-printer`;

    let res = await AxiosWrapper({
      method: 'GET',
      url: url,
      isAuthRoute: true,
    });

    return res;
  } catch (e) {
    console.error(e);
  }
}


export async function UpdateCheckNumber({ company, checkNumber }) {
  try {
    let url = `${process.env.API_URL}/${company}/check-printer`;

    let res = await AxiosWrapper({
      method: 'PATCH',
      url: url,
      isAuthRoute: true,
      data: {
        checkNumber: checkNumber
      },
    });

    return res;
  } catch (e) {
    console.error(e);
  }
}

export async function PrintCheck({ company, options }) {
  let {
    checkDate,
    checkNumber,
    amount,
    vendorId,
    vendorName,
    purchaseAccountCode,
    purchaseAccount,
    memo,
    employeeByPin,
  } = options;

  try {
    let url = `${process.env.API_URL}/${company}/checks?action=print`;

    let res = await AxiosWrapper({
      method: 'POST',
      url: url,
      isAuthRoute: true,
      data: {
        checkDate: checkDate,
        checkNumber: checkNumber,
        amount: amount,
        vendorId: vendorId,
        vendorName: vendorName,
        purchaseAccountCode: purchaseAccountCode,
        purchaseAccount: purchaseAccount,
        memo: memo,
        employeeByPin: employeeByPin,
      },
    });

    return res;
  } catch (e) {
    console.error(e);
  }
}
