import React from "react";
import DisplayTrainingStatusByPosition from "./DisplayTrainingStatusByPosition";

export default function DisplayTrainingStatus({ employee }) {

  return (
    <>
      {employee?.jobReferences && employee.jobReferences.map((jr, jrIndex) => {
        return (
          <DisplayTrainingStatusByPosition key={jrIndex} jobReference={jr} employee={employee} />
        )
      })}
    </>
  )
}
