import { setUserToken, clearUser, setIsAdmin, setUserInfo, setUserOrgs, setIsSuperAdmin, selectCurrentOrg, selectCurrentOrgByName, setEmployeeByPin, setUserScreenLocked } from "./types/";

export default function userDataReducer(state , { type, payload }) {

  switch (type) {
    case setUserToken:
      window.localStorage.setItem('token', payload.token);
      return { ...state, token: payload.token };
    case setUserInfo:
      window.localStorage.setItem('userInfo', JSON.stringify(payload.userInfo));
      return { ...state, userInfo: payload.userInfo };
    case setUserOrgs:
      window.localStorage.setItem('orgs', JSON.stringify(payload.orgs));
      return { ...state, orgs: payload.orgs };
    case setIsAdmin:
      window.localStorage.setItem('isAdmin', payload.isAdmin);
      return { ...state, isAdmin: payload.isAdmin };
    case setIsSuperAdmin:
      window.localStorage.setItem('isSuperAdmin', payload.isSuperAdmin);
      return { ...state, isSuperAdmin: payload.isSuperAdmin };
    case selectCurrentOrg:
      return { ...state, selectedOrg: payload.selectedOrg };
    case selectCurrentOrgByName:
      var selectedOrg = state.orgs.find((o) => {
        return o.name.toUpperCase() === payload.selectedOrgName.toUpperCase();
      });
      return { ...state, selectedOrg: selectedOrg };
    case setEmployeeByPin:
      return { ...state, employeeByPin: payload.employeeByPin };
    case setUserScreenLocked:
      return { ...state, isScreenLocked: payload.isScreenLocked };
    case clearUser:
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('isAdmin');
      window.localStorage.removeItem('userInfo');
      window.localStorage.removeItem('orgs');
      return { ...state, token: null, isAdmin: null, userInfo: null, orgs: null };
    default:
      throw new Error(`Unknown action ${type} in userDataReducer`);
  }
}
