import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Header, Dimmer, Loader, Table, Icon, Segment, Message } from "semantic-ui-react";
import { LG_WIDTH } from "../../constants";
import useUserData from "../../state-manipulation/hooks/useUserData";
import usePageData from "../../state-manipulation/hooks/usePageData";
import { setSelectedPage } from "../../state-manipulation/reducers/page-data/types";
import DateMenu from "../common/DateMenu";
import DateRangeSelector from "../common/DateRangeSelector";
import useDateRangeData from "../../state-manipulation/hooks/useDateRangeData";
import PageHeader from "../common/PageHeader";
import AxiosWrapper from "../../utils/AxiosWrapper";

export default function CashTransactions({ className }) {
  const [ userData, userDispatch ] = useUserData();
  const [ pageData, pageDispatch ] = usePageData();
  const [ dateRangeData, dateRangeDispatch ] = useDateRangeData();

  const [ days, setDays ] = useState(0);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ cashTransactions, setCashTransactions ] = useState([]);
  const [ cashTransactionsSummary, setCashTransactionsSummary ] = useState({});
  const [ timeOutId, setTimeoutId ] = useState('');

  const getCashTransactions = async (company, _dateStart, _dateEnd) => {
    try {
      setIsLoading(true);
      let res = await AxiosWrapper({
        method: 'GET',
        url: `${process.env.API_URL}/${company}/cash-transactions?startDate=${_dateStart}&endDate=${_dateEnd}`,
        headers: {
          'Authorization': `Basic ${userData.token}`
        }
      });

      setCashTransactionsSummary(res.data.data.summary);
      setIsLoading(false);

    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const deleteVendor = async (id) => {
    try {
      setIsLoading(true);
      let company = userData.selectedOrg.name.toLowerCase();
      let res = await AxiosWrapper({
        method: 'DELETE',
        url: `${process.env.API_URL}/${company}/vendors/${id}`,
        headers: {
          'Authorization': `Basic ${userData.token}`
        }
      });

      setIsLoading(false);

    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };


  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: 'POS Cash Transactions'
      }
    });
  }, []);

  useEffect(() => {
    let _days = moment(dateRangeData.dateEnd).diff(moment(dateRangeData.dateStart))/1000/60/60/24;

    if (_days >= 0 && _days <= 100 && userData.selectedOrg) {
      clearTimeout(timeOutId);
      setTimeoutId(setTimeout(() => {
        getCashTransactions(userData.selectedOrg.name.toLowerCase(), dateRangeData.dateStart, dateRangeData.dateEnd);
      }, 1000));
    }
    setDays( _days );
  }, [ dateRangeData, userData ]);

  return (
    <div className={"content-container edit-table-container " + className}>
      <DateMenu />
      <PageHeader pageName="Cash Transactions" orgName={userData.selectedOrg?.name} />
      {(days > 100) && (
        <Message warning>Date range must be 60 days or less.</Message>
      )}
      {(days < 0) && (
        <Message warning>End date must be the same or after start date.</Message>
      )}
      <DateRangeSelector />
      <Segment>
      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      <Table>
        <Table.Header>
          <Table.Row>
          <Table.HeaderCell textAlign="right">Cash In</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Cash Out</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Cash Received</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Deposit</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Total Credit Cards</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Tips</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell textAlign="right">{cashTransactionsSummary.cashIn && cashTransactionsSummary.cashIn.toFixed(2)}</Table.Cell>
            <Table.Cell textAlign="right">{cashTransactionsSummary.cashOut && cashTransactionsSummary.cashOut.toFixed(2)}</Table.Cell>
            <Table.Cell textAlign="right">{cashTransactionsSummary.cashReceived && cashTransactionsSummary.cashReceived.toFixed(2)}</Table.Cell>
            <Table.Cell textAlign="right">{cashTransactionsSummary.deposit && cashTransactionsSummary.deposit.toFixed(2)}</Table.Cell>
            <Table.Cell textAlign="right">{cashTransactionsSummary.totalCreditCards && cashTransactionsSummary.totalCreditCards.toFixed(2)}</Table.Cell>
            <Table.Cell textAlign="right">{cashTransactionsSummary.userTips && cashTransactionsSummary.userTips.toFixed(2)}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      </Segment>
    </div>
  );
}

CashTransactions.propTypes = {
  className: PropTypes.string
};

CashTransactions.defaultProps = {
  className: ''
};
