import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Checkbox, Container, Grid, Header, Input, Table } from "semantic-ui-react";
import usePageData from "../state-manipulation/hooks/usePageData";
import { setSelectedPage } from "../state-manipulation/reducers/page-data/types";
import AxiosWrapper from "../utils/AxiosWrapper";
import * as JsSearch from "js-search";

export default function Contacts({ selectedPage, pageName }) {
  const { company } = useParams();
  const contactsRef = useRef();
  const [contacts, setContacts] = useState([]);
  const [isPLTChecked, setIsPLTChecked] = useState(false);
  const [isHapaChecked, setIsHapaChecked] = useState(false);
  const [isHiMarkChecked, setIsHiMarkChecked] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [, pageDispatch] = usePageData();

  const searchRef = useRef();



  async function getContacts() {
    try {
      let res = await AxiosWrapper({
        method: "GET",
        url: `${process.env.API_URL}/${company}/contacts`,
        isAuthRoute: true,
      });

      contactsRef.current = res.data;

      setContacts(res.data);
    } catch (e) {
      console.error(e);
    }
  }

  const handleSearchChange = (e, { value }) => {
    setSearchVal(value);
  };

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: selectedPage,
      },
    });
    getContacts();

  }, []);

  useEffect(() => {
    if (company === "plt") {
      setIsPLTChecked(true);
    } else if (company === "hapa") {
      setIsHapaChecked(true);
    } else if (company === "hi-mark") {
      setIsHiMarkChecked(true);
    }
  }, [company]);

  useEffect(() => {
    if (contactsRef?.current?.length > 0) {
      let _contacts = contactsRef.current.filter((c) => {
        const restaurant = c["LTG Restaurant Using Service"];
        if (isPLTChecked === true) {
          if (restaurant?.toUpperCase().search("PLT") !== -1) {
            return true;
          }
        }
        if (isHapaChecked === true) {
          if (restaurant?.toUpperCase().search("HAPA") !== -1) {
            return true;
          }
        }
        if (isHiMarkChecked === true) {
          if (restaurant?.toUpperCase().search("HI-MARK") !== -1) {
            return true;
          } else if (restaurant?.toUpperCase().search("HIMARK") !== -1) {
            return true;
          }
        }

        return false;
      });

      searchRef.current = new JsSearch.Search("id");
      searchRef.current.addIndex("Company");
      searchRef.current.addIndex("Category");
      searchRef.current.addIndex("Contact Name");
      searchRef.current.addIndex("Email");
      searchRef.current.addIndex("Notes");
      searchRef.current.addDocuments(_contacts);
      setContacts(_contacts);
    }
  }, [isPLTChecked, isHapaChecked, isHiMarkChecked, contactsRef.current]);

  useEffect(() => {
    if (searchVal.trim().length === 0 && searchRef.current?._documents.length > 0) {
      setContacts(searchRef.current._documents);
      return;
    }

    let _contacts = searchRef.current?.search(searchVal);
    setContacts(_contacts);
  }, [searchVal]);

  return (
    <div className={"content-container"}>
      <Header as="h1">{pageName}</Header>
      <Container>
      <Grid columns={3}>
        <Grid.Row>
          <Grid.Column>
            <Input
              placeholder="Search"
              value={searchVal}
              onChange={handleSearchChange}
              icon="search"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Checkbox
              toggle
              label="HAPA"
              checked={isHapaChecked}
              onClick={() => {
                setIsHapaChecked(!isHapaChecked);
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <Checkbox
              toggle
              label="Hi-Mark"
              checked={isHiMarkChecked}
              onClick={() => {
                setIsHiMarkChecked(!isHiMarkChecked);
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <Checkbox
              toggle
              label="PLT"
              checked={isPLTChecked}
              onClick={() => {
                setIsPLTChecked(!isPLTChecked);
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      </Container>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Company</Table.HeaderCell>
            <Table.HeaderCell>Category</Table.HeaderCell>
            <Table.HeaderCell>Contact Name</Table.HeaderCell>
            <Table.HeaderCell>Restaurant Using Service</Table.HeaderCell>
            <Table.HeaderCell>Phone</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Notes</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {contacts &&
            contacts.map((c, cIndex) => {
              return (
                <Table.Row key={cIndex}>
                  <Table.Cell>{c["Company"]}</Table.Cell>
                  <Table.Cell>{c["Category"]}</Table.Cell>
                  <Table.Cell>{c["Contact Name"]}</Table.Cell>
                  <Table.Cell>{c["LTG Restaurant Using Service"]}</Table.Cell>
                  <Table.Cell>
                    <a href={`tel:${c["Phone"]}`}>{c["Phone"]}</a>
                  </Table.Cell>
                  <Table.Cell>{c["Email"]}</Table.Cell>
                  <Table.Cell>{c["Notes"]}</Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    </div>
  );
}
