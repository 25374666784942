import React from 'react';
import PropTypes from 'prop-types';
import { Table, Tab } from 'semantic-ui-react';
import './styles/TaskListWeeklyPrint.scss';

export default class TaskListWeeklyPrint extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={8}>
                {this.props.taskList &&
                  this.props.taskList.name &&
                  this.props.taskList.name}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Task</Table.HeaderCell>
              <Table.HeaderCell>M</Table.HeaderCell>
              <Table.HeaderCell>T</Table.HeaderCell>
              <Table.HeaderCell>W</Table.HeaderCell>
              <Table.HeaderCell>TH</Table.HeaderCell>
              <Table.HeaderCell>F</Table.HeaderCell>
              <Table.HeaderCell>SA</Table.HeaderCell>
              <Table.HeaderCell>SU</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.taskList &&
              this.props.taskList.tasks &&
              this.props.taskList.tasks.map((t, tIndex) => {
                return (
                  <React.Fragment key={tIndex}>
                    {t.type === 'section' ? (
                      <Table.Row className="task-section">
                        <Table.Cell colSpan={8}>{t.name}</Table.Cell>
                      </Table.Row>
                    ) : (
                      <>
                        <Table.Row>
                          <Table.Cell>{t.name}</Table.Cell>
                          <Table.Cell />
                          <Table.Cell />
                          <Table.Cell />
                          <Table.Cell />
                          <Table.Cell />
                          <Table.Cell />
                          <Table.Cell />
                        </Table.Row>
                        {t.additionalFields &&
                          t.additionalFields.map((af, afIndex) => {
                            return (
                              <Table.Row key={afIndex}>
                                <Table.Cell>
                                  &nbsp;&nbsp;• <em>{af.name}</em>{' '}
                                  {af.description && `(${af.description})`}
                                </Table.Cell>
                                <Table.Cell colSpan={3}></Table.Cell>
                              </Table.Row>
                            );
                          })}
                      </>
                    )}
                  </React.Fragment>
                );
              })}
          </Table.Body>
        </Table>
      </>
    );
  }
}

TaskListWeeklyPrint.propTypes = {
  taskList: PropTypes.object,
};
