import AxiosWrapper from '../utils/AxiosWrapper';

export async function PostTaskList(taskList, company) {
  try {
    console.log('taskList');
    console.log(taskList);
    let res = await AxiosWrapper({
      method: 'POST',
      url: `${process.env.API_URL}/${company}/task-lists`,
      isAuthRoute: true,
      data: taskList
    });
    return res;
  } catch (e) {
    console.error(e);
  }
}

export async function PatchTaskList({
  company,
  taskList,
  autoApprove = false
}) {
  try {
    let url = `${process.env.API_URL}/${company}/task-lists/${taskList._id.$oid}`;
    let options = [];

    if (autoApprove) {
      options.push(`autoApprove=true`);
    }

    if (options.length > 0) {
      url += `?${options.join('&')}`;
    }

    let res = await AxiosWrapper({
      method: 'PATCH',
      url: url,
      isAuthRoute: true,
      data: taskList
    });
    return res;
  } catch (e) {
    console.error(e);
  }
}

export async function GetTaskLists(company, { startDate, endDate }) {
  try {
    let url = `${process.env.API_URL}/${company}/task-lists`;
    let filters = [];

    if (startDate) {
      filters.push(`startDate=${startDate}`);
    }

    if (endDate) {
      filters.push(`endDate=${endDate}`);
    }

    if (filters.length > 0) {
      url += `?${filters.join('&')}`;
    }

    //startDate=${_dateStart}&endDate=${_dateEnd}`,
    let res = await AxiosWrapper({
      method: 'GET',
      url: url,
      isAuthRoute: true
    });
    return res;
  } catch (e) {
    console.error(e);
  }
}

export async function GetTaskListsById(company, id) {
  try {
    let url = `${process.env.API_URL}/${company}/task-lists/${id}`;
    let res = await AxiosWrapper({
      method: 'GET',
      url: url,
      isAuthRoute: true
    });
    return res;
  } catch (e) {
    console.error(e);
  }
}

export async function DeleteTaskList(company, id) {
  try {
    let url = `${process.env.API_URL}/${company}/task-lists/${id}`;
    let res = await AxiosWrapper({
      method: 'DELETE',
      url: url,
      isAuthRoute: true
    });
    return res;
  } catch (e) {
    console.error(e);
  }
}
