import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Header, Table, Icon } from "semantic-ui-react";


export default function SyncErrorDetails({ dinerwareUsers, header, errorLevel, message, col1 }) {
  return (
    <div className="panel">
      {header &&
        <Header as="h3">
          {errorLevel === "fatal" &&
            <Icon name="exclamation circle" className="red" />
          }
          {errorLevel === "warning" &&
            <Icon name="question circle" className="orange" />
          }
          {header}
        </Header>
      }
      {message}
      <Table size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Screen Name</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            {col1 &&
              <Table.HeaderCell>{col1}</Table.HeaderCell>
            }
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dinerwareUsers && dinerwareUsers.map((user, userIndex) => {
            return (
              <Table.Row key={userIndex}>
                <Table.Cell>{user.screenName}</Table.Cell>
                <Table.Cell>{user.firstName} {user.lastName}</Table.Cell>
                <Table.Cell>{user.email}</Table.Cell>
                {col1 &&
                  <Table.Cell>{user[col1]}</Table.Cell>
                }
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}

SyncErrorDetails.propTypes = {
  dinerwareUsers: PropTypes.array,
  message: PropTypes.element,
  header: PropTypes.string,
  errorLevel: PropTypes.string,
  col1: PropTypes.string
};

SyncErrorDetails.defaultProps = {
  message: null,
  header: null,
  errorLevel: "fatal",
  col1: null
};