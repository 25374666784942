import React from "react";
import NumberFormat from "react-number-format";
import { Icon, Image, Popup, Table } from "semantic-ui-react";
import ShowBudgetPercentageDifferences from "./ShowBudgetPerentageDifferences";
import sevenShiftsIcon from "../../images/7Shifts-icon.png";
import toastIcon from "../../images/toast-icon.svg";
import DisplayPercentage from "./DisplayPercentage";

export default function LaborData({ data, laborHourlyPercent }) {
  return (
    <Table.Row>
      <Table.Cell>
        Labor
        <Popup
          content={
            <span style={{ fontSize: "12px", fontWeight: "normal" }}>
              (sch) stands for scheduled in 7shifts
              <br />
              <br />
              7Shifts labor is the actual labor in 7shifts which includes
              non-mgmt salary. If there is a big discrepancy, the hourly rate in
              7shifts may need to be updated to match Toast.
              <br />
              <br />
              Toast labor is the actual labor that is submitted to payroll. It
              doesn't include the non-mgmt salary.
              <br />
              <br />
              To troubleshoot the differences, check "7shifts &lt; Reports &lt;
              Variance" and compare each person against the Toast Labor Summary
              Report.
            </span>
          }
          trigger={
            <Icon name="help circle" style={{ verticalAlign: "super" }} />
          }
        />
      </Table.Cell>
      {data?.weeks &&
        data.weeks.map((w, i) => {
          return (
            <Table.Cell key={i} textAlign="right">
              {w.laborSummary.actual_labor_cost > 0 && (
                <>
                  <Image
                    src={sevenShiftsIcon}
                    style={{ width: "15px", display: "inline" }}
                    title="7Shifts Labor"
                  />
                  <NumberFormat
                    thousandSeparator={true}
                    value={Math.round(w.laborSummary.actual_labor_cost)}
                    displayType="text"
                  />{" "}
                  <DisplayPercentage value={(
                    (w.laborSummary.actual_labor_cost / w.sales.grossSales) *
                    100
                  ).toFixed(2)} />
                  <br />
                  <em style={{ fontSize: "12px" }}>
                    (sch)&nbsp;
                    <NumberFormat
                      thousandSeparator={true}
                      value={Math.round(w.laborSummary.projected_labor_cost)}
                      displayType="text"
                    />{" "}
                    <DisplayPercentage value={(
                      ((w.laborSummary.actual_labor_cost -
                        w.laborSummary.projected_labor_cost) /
                        w.laborSummary.projected_labor_cost) *
                      100
                    ).toFixed(2)} />
                  </em>
                  <br />
                  <br />
                  <Image
                    src={toastIcon}
                    style={{ width: "15px", display: "inline" }}
                    title="Toast Labor"
                  />{" "}
                  <NumberFormat
                    thousandSeparator={true}
                    value={Math.round(w.laborSummary.actual_labor_cost_toast)}
                    displayType="text"
                  />{" "}
                  <DisplayPercentage value={(
                    (w.laborSummary.actual_labor_cost_toast /
                      w.sales.grossSales) *
                    100
                  ).toFixed(2)} />
                  <br />
                  <ShowBudgetPercentageDifferences
                    actual={
                      w.laborSummary.actual_labor_cost_toast /
                      w.sales.grossSales
                    }
                    projected={laborHourlyPercent[2]}
                  />
                </>
              )}
            </Table.Cell>
          );
        })}
      <Table.Cell textAlign="right">
        {data?.periodToDate?.laborSummary.actual_labor_cost > 0 && (
          <>
            <Image
              src={sevenShiftsIcon}
              style={{ width: "15px", display: "inline" }}
              title="7Shifts Labor"
            />{" "}
            <NumberFormat
              thousandSeparator={true}
              value={Math.round(
                data?.periodToDate?.laborSummary.actual_labor_cost
              )}
              displayType="text"
            />{" "}
            <DisplayPercentage value={(
              (data?.periodToDate?.laborSummary.actual_labor_cost /
                data?.periodToDate?.sales.grossSales) *
              100
            ).toFixed(2)} />
            <br />
            <em style={{ fontSize: "12px" }}>
              (sch)&nbsp;
              <NumberFormat
                thousandSeparator={true}
                value={Math.round(
                  data?.periodToDate?.laborSummary.projected_labor_cost
                )}
                displayType="text"
              />{" "}
              <DisplayPercentage value={(
                ((data?.periodToDate?.laborSummary.actual_labor_cost -
                  data?.periodToDate?.laborSummary
                    .projected_labor_cost) /
                  data?.periodToDate?.laborSummary
                    .projected_labor_cost) *
                100
              ).toFixed(2)} />
            </em>
            <br />
            <br />
            <Image
              src={toastIcon}
              style={{ width: "15px", display: "inline" }}
              title="Toast Labor"
            />{" "}
            <NumberFormat
              thousandSeparator={true}
              value={Math.round(
                data?.periodToDate?.laborSummary
                  .actual_labor_cost_toast
              )}
              displayType="text"
            />{" "}
            <DisplayPercentage value={(
              (data?.periodToDate?.laborSummary
                .actual_labor_cost_toast /
                data?.periodToDate?.sales.grossSales) *
              100
            ).toFixed(2)} />
            <br />
            <ShowBudgetPercentageDifferences
              actual={
                data?.periodToDate?.laborSummary
                  .actual_labor_cost_toast /
                data?.periodToDate?.sales.grossSales
              }
              projected={laborHourlyPercent[2]}
            />
          </>
        )}
      </Table.Cell>
    </Table.Row>
  );
}
