import AxiosWrapper from "../utils/AxiosWrapper";

export async function GetEmployeesStatus({ company }) {
  let _company = company.replaceAll('-','').toUpperCase();
  try {
    const url = `${process.env.LTG_NEXT_APP_URL}/api/training/employees-status?company=${_company}`;
    const res = await AxiosWrapper({
      url: url,
      method: "GET",
      isAuthRoute: true
    });
    return res;
  } catch (e) {
    console.error(e);
  }
}

export async function GetEmployeeStatus({ company, externalEmployeeId }) {
  let _company = company.replaceAll('-','').toUpperCase();
  try {
    const url = `${process.env.LTG_NEXT_APP_URL}/api/training/employees-status/${externalEmployeeId}?company=${_company}`;
    const res = await AxiosWrapper({
      url: url,
      method: "GET",
      isAuthRoute: true
    });
    return res;
  } catch (e) {
    console.error(e);
  }
}

export async function UpdateEmployeetrainingSkillStatus({ externalEmployeeId, trainingSkillStatus }) {
  try {
    const url = `${process.env.LTG_NEXT_APP_URL}/api/training/employees-status/${externalEmployeeId}/training-items`;
    const res = await AxiosWrapper({
      url: url,
      method: "POST",
      isAuthRoute: true,
      data: trainingSkillStatus
    });
    return res;
  } catch (e) {
    console.error(e);
  }
}

export async function ImportTrainingSkills() {
  try {
    const url = `${process.env.API_URL}/trigger-scripts/import-google-training-skills`;
    const res = await AxiosWrapper({
      url: url,
      method: "GET",
      isAuthRoute: true
    });
    return res;
  } catch (e) {
    console.error(e);
  }
}

export async function ImportToastEmployees() {
  try {
    const url = `${process.env.API_URL}/trigger-scripts/import-toast-employees`;
    const res = await AxiosWrapper({
      url: url,
      method: "GET",
      isAuthRoute: true
    });
    return res;
  } catch (e) {
    console.error(e);
  }
}


export async function BuildAlgoliaSearchIndex() {
  try {
    const url = `${process.env.API_URL}/trigger-scripts/build-alogolia-search`;
    const res = await AxiosWrapper({
      url: url,
      method: "GET",
      isAuthRoute: true
    });
    return res;
  } catch (e) {
    console.error(e);
  }
}
