import React, { useState, useEffect, useRef } from "react";
import MediaQuery from "react-responsive";
import PropTypes from "prop-types";
import {
  Button,
  Dropdown,
  Grid,
  Table,
  Header,
  Dimmer,
  Loader,
  Icon,
  Checkbox,
  Modal,
  Form,
  Input,
  TextArea,
  Radio,
  Ref,
  TableBody,
  GridColumn,
  Container,
  Menu,
  Sticky,
} from "semantic-ui-react";
import { firebase } from "@firebase/app";
import "@firebase/storage";
import usePageData from "../state-manipulation/hooks/usePageData";
import useUserData from "../state-manipulation/hooks/useUserData";
import { useParams, useNavigate } from "react-router-dom";
import {
  GetTaskListsById,
  PatchTaskList,
  DeleteTaskList,
} from "../api/TaskListAPI";
import { setSelectedPage } from "../state-manipulation/reducers/page-data/types";
import { GetEmployees7Shifts } from "../api/EmployeesAPI";
import ReactToPrint from "react-to-print";
import TaskListPrint from "../components/tasks/TaskListPrint";
import TaskListDescriptionsPrint from "../components/tasks/TaskListDescriptionsPrint";
import { LG_WIDTH, SM_WIDTH, MD_WIDTH } from "../constants";
import Moment from "react-moment";
import "moment-timezone";
import { useWindowWidth } from "@react-hook/window-size";
import ReactMarkdown from "react-markdown";

const isTaskAddtionalFieldsCompleted = (additionalFields) => {
  let incompleteCount = 0;
  additionalFields.forEach((af) => {
    if (af.isRequired && !af.value) {
      incompleteCount++;
    }
  });

  return incompleteCount === 0;
};

export default function TaskList({ className }) {
  const onlyWidth = useWindowWidth();
  const navigate = useNavigate();
  const { company, taskListId } = useParams();
  const [taskList, setTaskList] = useState({});
  const [prevCompany, setPrevCompany] = useState("");
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [selectedTasks, setSelectedTasks] = useState({});
  const [selectedTask, setSelectedTask] = useState(null);
  const [pageData, pageDispatch] = usePageData();
  const [userData] = useUserData();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [helpDescription, setHelpDescription] = useState("");
  const [mode, setMode] = useState("complete");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [sectionChecked, setSectionChecked] = useState({});
  const [allTaskChecked, setAllTaskChecked] = useState(false);
  const [stickyOffset, setStickyOffset] = useState(0);
  const componentRef = useRef();
  const taskListDecriptionsRef = useRef();
  const containerRef = useRef();

  const getTaskList = async () => {
    try {
      setIsLoading(true);
      let res = await GetTaskListsById(company, taskListId);
      setIsLoading(false);
      if (!res.data) {
        navigate(`/${company}/task-lists`, { replace: true});
        return;
      }
      setTaskList(res.data);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  const deleteTaskList = async () => {
    try {
      setIsLoading(true);
      let res = await DeleteTaskList(company, taskListId);
      setIsLoading(false);
      navigate(`/${company}/task-lists`, { replace: true});
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  const saveTaskList = async (_taskList, autoApprove = false, _task = null) => {
    let t = _task;
    if (t && t.additionalFields && t.additionalFields.length > 0) {
      if (!isTaskAddtionalFieldsCompleted(t.additionalFields)) {
        delete t.completedBy;
        delete t.approvedBy;
      } else if (isTaskAddtionalFieldsCompleted(t.additionalFields)) {
        t.completedBy = employees.find((e) => e.id === selectedEmployee);
      }
    }

    try {
      setIsLoading(true);
      let res = await PatchTaskList({
        company: company,
        taskList: _taskList,
        autoApprove: autoApprove,
      });
      setIsLoading(false);
      setTaskList(res.data);
      return res;
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleSelectEmployee = (e, { value }) => {
    if (value !== "unselect") {
      window.localStorage.setItem(`${company}selectedEmployeeId`, value);
    }

    setSelectedEmployee(value);
  };

  const handleTaskCheckChange = async (e, { value, taskindex, checked }) => {
    let _selectedTasks = { ...selectedTasks };
    if (checked) {
      _selectedTasks[taskindex] = checked;
    } else {
      delete _selectedTasks[taskindex];
    }

    if (mode === "complete") {
      let task = taskList.tasks.find((t, tIndex) => tIndex === taskindex);
      if (selectedEmployee === "unselect" || !checked) {
        delete task.completedBy;
        delete task.approvedBy;
      } else {
        task.completedBy = employees.find((e) => e.id === selectedEmployee);
      }

      try {
        let res = await saveTaskList(taskList, true);
        setTaskList(res.data);
      } catch (error) {
        console.error(error);
      }
    }
    setSelectedTasks(_selectedTasks);
  };

  const toggleAllTasks = (_mode, checked) => {
    let _selectedTasks = {};
    let _sectionChecked = { ...sectionChecked };
    taskList.tasks.forEach((t, tIndex) => {
      if (t.type !== "task") {
        _sectionChecked[tIndex] = checked;
        return;
      }

      if (mode === "complete" && t.type === "task") {
        if (!isTaskAddtionalFieldsCompleted(t.additionalFields)) {
          return;
        }
      }

      if (checked) {
        _selectedTasks[tIndex] = checked;
      } else {
        delete _selectedTasks[tIndex];
      }
    });

    setSectionChecked(_sectionChecked);
    setSelectedTasks(_selectedTasks);
  };

  const handleSelectAllTasks = (e, { checked }) => {
    setAllTaskChecked(checked);
    toggleAllTasks(mode, checked);
  };

  const toggleSectionTasks = (_mode, checked, index) => {
    let _selectedTasks = { ...selectedTasks };
    for (let i = index + 1; i < taskList.tasks.length; i++) {
      let t = taskList.tasks[i];
      if (t.type !== "task") {
        break;
      }

      if (mode === "complete" && t.type === "task") {
        if (!isTaskAddtionalFieldsCompleted(t.additionalFields)) {
          continue;
        }
      }

      if (checked) {
        _selectedTasks[i] = checked;
      } else {
        if (_selectedTasks[i]) {
          delete _selectedTasks[i];
        }
      }
    }
    setSelectedTasks(_selectedTasks);
  };

  const handleSelectAllSectionTasks = (e, { checked, index }) => {
    let _sectionChecked = { ...sectionChecked };
    _sectionChecked[index] = checked;
    setSectionChecked(_sectionChecked);
    toggleSectionTasks(mode, checked, index);
  };

  const handleAssignClick = async () => {
    Object.keys(selectedTasks).forEach((key) => {
      if (selectedTasks[key]) {
        let task = taskList.tasks[key];

        if (selectedEmployee === "unselect") {
          delete task.assignedTo;
        } else {
          task.assignedTo = employees.find((e) => e.id === selectedEmployee);
        }
      }
    });

    try {
      let res = await saveTaskList(taskList);
      setTaskList(res.data);
      setSelectedTasks({});
      setSectionChecked({});
      setAllTaskChecked(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCompleteClick = async () => {
    Object.keys(selectedTasks).forEach((key) => {
      if (selectedTasks[key]) {
        let task = taskList.tasks.find((t) => t.name === key);
        if (selectedEmployee === "unselect") {
          delete task.completedBy;
          delete task.approvedBy;
        } else {
          task.completedBy = employees.find((e) => e.id === selectedEmployee);
        }
      }
    });

    try {
      let res = await saveTaskList(taskList, true);
      setTaskList(res.data);
      setSelectedTasks({});
    } catch (error) {
      console.error(error);
    }
  };

  const handleTestList = () => {
    console.log("taskList Check");
    console.log(taskList.tasks);
  };

  const handleHelpClick = (e, { description }) => {
    setHelpDescription(description);
    setIsModalOpen(true);
  };

  const handleModeChange = (e, { value }) => {
    setMode(value);
  };

  const getEmployees = async () => {
    let res = await GetEmployees7Shifts(company);
    setEmployees(res.data);
  };

  const handleDeleteTaskListStart = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteTaskListYes = async () => {
    setIsDeleteModalOpen(false);
    deleteTaskList();
  };

  const handleDeleteTaskListNo = () => {
    setIsDeleteModalOpen(false);
  };

  const getLastCompletedEmployee = () => {
    let _tasks = [...taskList.tasks];
    _tasks.sort((a, b) => {
      let aTaskTime =
        a.completedBy && a.completedBy.date
          ? new Date(a.completedBy.date)
          : null;
      let bTaskTime =
        b.completedBy && b.completedBy.date
          ? new Date(b.completedBy.date)
          : null;
      return bTaskTime - aTaskTime;
    });

    return _tasks[0] ? _tasks[0].completedBy : null;
  };

  useEffect(() => {
    getTaskList();
    getEmployees();
  }, []);

  useEffect(() => {
    if (taskList.tasks) {
      let lastEmployee = getLastCompletedEmployee();
      let lastEmployeeId;
      if (lastEmployee && lastEmployee.id) {
        lastEmployeeId = lastEmployee.id;
      }

      lastEmployeeId =
        Number(window.localStorage.getItem(`${company}selectedEmployeeId`)) ||
        lastEmployeeId;

      if (lastEmployeeId) {
        setSelectedEmployee(lastEmployeeId);
      }
    }
  }, [taskList]);

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: "Task Lists",
      },
    });
  }, []);

  useEffect(() => {
    if (!company) {
      return;
    }
    if (!prevCompany) {
      setPrevCompany(company);
    } else {
      navigate(`/${company}/task-lists`, { replace: true});
    }
  }, [company]);

  useEffect(() => {
    if (onlyWidth < LG_WIDTH) {
      setStickyOffset(45);
    } else {
      setStickyOffset(0);
    }
  }, [onlyWidth]);

  return (
    <div className={"content-container " + className}>
      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      <Header as="h2">Task List: {taskList.name}</Header>
      <Grid>
        <Grid.Column textAlign="right">
          <Button.Group color="blue">
            <ReactToPrint
              trigger={() => <Button primary>Print task list</Button>}
              content={() => componentRef.current}
            />
            <Dropdown
              className="button icon"
              floating
              options={[
                {
                  key: "print-description",
                  text: "Print Descriptions",
                  value: "Print Descriptions",
                  as: () => (
                    <ReactToPrint
                      trigger={() => <Menu.Item>Print Description</Menu.Item>}
                      content={() => taskListDecriptionsRef.current}
                    />
                  ),
                },
              ]}
              trigger={<React.Fragment />}
            />
          </Button.Group>
          <div style={{ display: "none" }}>
            <TaskListPrint ref={componentRef} taskList={taskList} />
          </div>
          <div style={{ display: "none" }}>
            <TaskListDescriptionsPrint
              ref={taskListDecriptionsRef}
              taskList={taskList}
            />
          </div>
        </Grid.Column>
      </Grid>
      {taskList.tasks && (
        <div className="task-status">
          <h3>Task Stats</h3>
          <Table unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Who</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Completed</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Total</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Percent</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <TableBody>
              <Table.Row>
                <Table.Cell>All</Table.Cell>
                <Table.Cell textAlign="right">
                  {taskList.totalTasksComplete}
                </Table.Cell>
                <Table.Cell textAlign="right">{taskList.totalTasks}</Table.Cell>
                <Table.Cell textAlign="right">
                  {taskList.percentComplete && (
                    <>{Math.round(taskList.percentComplete * 100)}%</>
                  )}
                </Table.Cell>
              </Table.Row>
            </TableBody>
          </Table>
          <Table unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Who</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Completed</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  Assigned To and Completed
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Assigned</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Percent</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <TableBody>
              {taskList.taskCompletionByPerson &&
                taskList.taskCompletionByPerson.map((tc, tcIndex) => {
                  return (
                    <Table.Row key={tcIndex}>
                      <Table.Cell>
                        {tc.firstName} {tc.lastName}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {tc.taskCompleteCount}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {tc.taskAssignedAndCompletedCount}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {tc.taskAssignedCount}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {tc.taskAssignedCount && (
                          <>
                            {Math.round(
                              (tc.taskAssignedAndCompletedCount /
                                tc.taskAssignedCount) *
                                100
                            )}
                            %
                          </>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </TableBody>
          </Table>
        </div>
      )}
      <Form.Field className="task-list-mode">
        <label>Mode</label>
        <Radio
          label="Assign"
          name="mode"
          value="assign"
          checked={mode === "assign"}
          onChange={handleModeChange}
        />
        <Radio
          label="Complete"
          name="mode"
          value="complete"
          checked={mode === "complete"}
          onChange={handleModeChange}
        />
      </Form.Field>
      <Sticky
        context={containerRef}
        offset={stickyOffset}
        style={{ position: "sticky", top: `${stickyOffset}px`, zIndex: 99 }}
      >
        <Dropdown
          placeholder="Select one"
          search
          selection
          value={selectedEmployee ? selectedEmployee : ""}
          onChange={handleSelectEmployee}
          options={[
            { key: "unselect", value: "unselect", text: "No one" },
          ].concat(
            employees.map((e) => {
              return {
                key: e.id,
                value: e.id,
                text: `${e.firstName} ${e.lastName}`,
              };
            })
          )}
        />
      </Sticky>
      {mode === "assign" ? (
        <Button
          primary
          className="task-action-button"
          onClick={handleAssignClick}
          disabled={
            selectedTasks && Object.keys(selectedTasks).length > 0
              ? false
              : true
          }
        >
          Assign
        </Button>
      ) : (
        <></>
      )}
      <Ref innerRef={containerRef}>
        <Table>
          <MediaQuery minWidth={MD_WIDTH}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {mode === "assign" && (
                    <>
                      {allTaskChecked ? (
                        <Icon
                          name="check square outline"
                          size="big"
                          onClick={handleSelectAllTasks}
                          checked={false}
                        />
                      ) : (
                        <Icon
                          name="square outline"
                          size="big"
                          onClick={handleSelectAllTasks}
                          checked={true}
                        />
                      )}
                    </>
                  )}
                </Table.HeaderCell>
                <Table.HeaderCell>Task</Table.HeaderCell>
                <Table.HeaderCell>Assigned To</Table.HeaderCell>
                <Table.HeaderCell>Completed By</Table.HeaderCell>
                <Table.HeaderCell>Completed At</Table.HeaderCell>
                <Table.HeaderCell>Approved By</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          </MediaQuery>
          <Table.Body>
            {taskList.tasks &&
              taskList.tasks.map((t, taskIndex) => {
                return (
                  <React.Fragment key={taskIndex}>
                    {t.type === "section" ? (
                      <Table.Row className="task-section">
                        <Table.Cell>
                          {mode === "assign" && (
                            <>
                              {sectionChecked[taskIndex] ? (
                                <Icon
                                  name="check square outline"
                                  size="big"
                                  onClick={handleSelectAllSectionTasks}
                                  checked={false}
                                  index={taskIndex}
                                />
                              ) : (
                                <Icon
                                  name="square outline"
                                  size="big"
                                  onClick={handleSelectAllSectionTasks}
                                  checked={true}
                                  index={taskIndex}
                                />
                              )}
                            </>
                          )}
                        </Table.Cell>
                        <Table.Cell colSpan={5}>{t.name}</Table.Cell>
                      </Table.Row>
                    ) : (
                      <Table.Row
                        className={`${t.completedBy ? "completed" : ""}${
                          t.isHighlighted ? " highlighted" : ""
                        } `}
                      >
                        <Table.Cell collapsing>
                          <MediaQuery minWidth={MD_WIDTH}>
                            <TaskControl
                              mode={mode}
                              task={t}
                              taskIndex={taskIndex}
                              selectedTasks={selectedTasks}
                              handleTaskCheckChange={handleTaskCheckChange}
                              setSelectedTask={setSelectedTask}
                            />
                          </MediaQuery>
                        </Table.Cell>
                        <Table.Cell className="task-name">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <MediaQuery maxWidth={MD_WIDTH}>
                              <TaskControl
                                mode={mode}
                                task={t}
                                taskIndex={taskIndex}
                                selectedTasks={selectedTasks}
                                handleTaskCheckChange={handleTaskCheckChange}
                                setSelectedTask={setSelectedTask}
                              />
                            </MediaQuery>

                            <span
                              className="task-name"
                              style={{ flexGrow: 2 }}
                              onClick={() => {
                                setSelectedTask(t);
                              }}
                            >
                              {t.name}
                            </span>
                            <Icon
                              className="task-help"
                              name="help circle"
                              size="big"
                              description={t.description}
                              disabled={t.description ? false : true}
                              onClick={handleHelpClick}
                            />
                          </div>
                        </Table.Cell>
                        <Table.Cell>
                          {t.assignedTo && (
                            <>
                              <MediaQuery maxWidth={MD_WIDTH}>
                                <span className="mobile-table-header">
                                  Assigned To:{" "}
                                </span>
                              </MediaQuery>
                              {t.assignedTo.firstName} {t.assignedTo.lastName}
                            </>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {t.completedBy && (
                            <>
                              <MediaQuery maxWidth={MD_WIDTH}>
                                <span className="mobile-table-header">
                                  Completed By:
                                </span>
                              </MediaQuery>
                              {t.completedBy.firstName} {t.completedBy.lastName}
                            </>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {t.completedBy && (
                            <>
                              <MediaQuery maxWidth={MD_WIDTH}>
                                <span className="mobile-table-header">
                                  Completed at:
                                </span>
                              </MediaQuery>
                              <Moment format="M/D h:mm a" tz="America/New_York">
                                {t.completedBy.date}
                              </Moment>
                            </>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {t.approvedBy && (
                            <>
                              <MediaQuery maxWidth={MD_WIDTH}>
                                <span className="mobile-table-header">
                                  Approveddd By:
                                </span>
                              </MediaQuery>
                              {t.approvedBy.firstName} {t.approvedBy.lastName}
                            </>
                          )}
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </React.Fragment>
                );
              })}
          </Table.Body>
        </Table>
      </Ref>
      {userData.userInfo.userTags &&
        userData.userInfo.userTags.hasTasksManage === "1" && (
          <Button onClick={handleDeleteTaskListStart}>Delete Task List</Button>
        )}
      <TaskDetails
        company={company}
        task={selectedTask}
        taskList={taskList}
        handleHelpClick={handleHelpClick}
        saveTaskList={saveTaskList}
        handleClose={() => setSelectedTask(null)}
      />
      <DescriptionDisplay
        isModalOpen={isModalOpen}
        description={helpDescription}
        handleModalClose={() => {
          setIsModalOpen(false);
        }}
      />
      <DeleteTaskConfirm
        isModalOpen={isDeleteModalOpen}
        handleDeleteTaskListYes={handleDeleteTaskListYes}
        handleDeleteTaskListNo={handleDeleteTaskListNo}
      />
    </div>
  );
}

TaskList.propTypes = {
  className: PropTypes.string,
};

TaskList.defaultProps = {
  className: "",
};

function TaskControl({
  mode,
  task: t,
  taskIndex,
  selectedTasks,
  handleTaskCheckChange,
  setSelectedTask,
}) {
  return (
    <React.Fragment>
      {mode === "assign" ? (
        <>
          {selectedTasks[taskIndex] ? (
            <Icon
              name="check square outline"
              size="big"
              onClick={handleTaskCheckChange}
              checked={false}
              taskindex={taskIndex}
            />
          ) : (
            <Icon
              name="square outline"
              size="big"
              onClick={handleTaskCheckChange}
              checked={true}
              taskindex={taskIndex}
            />
          )}
        </>
      ) : (mode === "complete") &
        ((t.additionalFields && t.additionalFields.length === 0) ||
          isTaskAddtionalFieldsCompleted(t.additionalFields)) ? (
        <>
          {selectedTasks[taskIndex] ? (
            <Icon
              name="check square outline"
              size="big"
              onClick={handleTaskCheckChange}
              checked={false}
              taskindex={taskIndex}
            />
          ) : (
            <Icon
              name="square outline"
              size="big"
              onClick={handleTaskCheckChange}
              checked={true}
              taskindex={taskIndex}
            />
          )}
        </>
      ) : (
        <Icon
          name="expand"
          size="big"
          onClick={() => {
            setSelectedTask(t);
          }}
        />
      )}
    </React.Fragment>
  );
}

TaskControl.propTypes = {
  mode: PropTypes.string,
  task: PropTypes.object,
  taskIndex: PropTypes.number,
  selectedTasks: PropTypes.object,
  handleTaskCheckChange: PropTypes.func,
  setSelectedTask: PropTypes.func,
};

function TaskDetails({
  company,
  task,
  taskList,
  handleClose: handleCloseParent,
  saveTaskList,
  handleHelpClick,
}) {
  const [additionalNotes, setAdditionalNotes] = useState(
    task ? task.additionalNotes : ""
  );

  const handleNoteChange = (e, { value }) => {
    setAdditionalNotes(value);
  };

  const handleClose = () => {
    handleCloseParent();
  };

  useEffect(() => {
    if (task) {
      task.additionalNotes = additionalNotes;
    }
  }, [additionalNotes]);

  useEffect(() => {
    if (task) {
      setAdditionalNotes(task.additionalNotes);
    } else {
      setAdditionalNotes("");
    }
  }, [task]);

  return (
    <>
      {task && (
        <Modal open={task ? true : false} onClose={handleClose}>
          <Modal.Header>{task.name}</Modal.Header>
          <Modal.Content>
            <Form>
              {task.additionalFields &&
                task.additionalFields.map((af, afIndex) => {
                  return (
                    <div key={afIndex} className="additional-field-container">
                      <div>
                        <label className="additional-field-label">
                          {af.name} {af.isRequired && "*"}
                        </label>
                        <Icon
                          name="help circle"
                          size="big"
                          description={af.description}
                          disabled={af.description ? false : true}
                          onClick={handleHelpClick}
                        />
                      </div>
                      <AdditionalFieldEditor
                        additionalField={af}
                        taskList={taskList}
                        company={company}
                        saveTaskList={saveTaskList}
                      />
                    </div>
                  );
                })}
              <div>
                <label className="additional-field-label">
                  Additional Notes:
                </label>
              </div>
              <TextArea
                label="notes"
                value={additionalNotes}
                onChange={handleNoteChange}
              />
              <Button
                primary
                onClick={async () => {
                  await saveTaskList(taskList, true, task);
                  handleClose();
                }}
                className="additional-fields-button"
              >
                Save
              </Button>
            </Form>
          </Modal.Content>
        </Modal>
      )}
    </>
  );
}

TaskDetails.propTypes = {
  company: PropTypes.string,
  task: PropTypes.object,
  taskList: PropTypes.object,
  handleClose: PropTypes.func,
  saveTaskList: PropTypes.func,
  handleHelpClick: PropTypes.func,
};

function DescriptionDisplay({ description, isModalOpen, handleModalClose }) {
  return (
    <Modal open={isModalOpen} onClose={handleModalClose}>
      <Modal.Header>Task Description</Modal.Header>
      <Modal.Content>
        {description ? (
          <ReactMarkdown>{description}</ReactMarkdown>
        ) : (
          <>No description</>
        )}
      </Modal.Content>
    </Modal>
  );
}

DescriptionDisplay.propTypes = {
  description: PropTypes.string,
  isModalOpen: PropTypes.bool,
  handleModalClose: PropTypes.func,
};

DescriptionDisplay.defaultProps = {
  isModalOpen: false,
};

function NumberField({
  handleInputChange: handleInputChangeParent,
  value: initialValue,
}) {
  const [inputValue, setInputValue] = useState(initialValue);
  const handleInputChange = (e, { value }) => {
    setInputValue(value);
    handleInputChangeParent(value);
  };

  return (
    <Input
      fluid
      placeholder=""
      type="number"
      value={inputValue}
      inputMode="numeric"
      onChange={handleInputChange}
      className="number-field"
    >
      <input inputMode="decimal" />
    </Input>
  );
}

NumberField.propTypes = {
  handleInputChange: PropTypes.func,
  value: PropTypes.string,
};

NumberField.defaultProps = {
  value: "",
};

function SingleTextField({
  handleInputChange: handleInputChangeParent,
  value: initialValue,
}) {
  const [inputValue, setInputValue] = useState(initialValue);
  const handleInputChange = (e, { value }) => {
    setInputValue(value);
    handleInputChangeParent(value);
  };

  return (
    <Input
      value={inputValue}
      onChange={handleInputChange}
      className="single-text-field"
    />
  );
}

SingleTextField.propTypes = {
  handleInputChange: PropTypes.func,
  value: PropTypes.string,
};

SingleTextField.defaultProps = {
  value: "",
};

function MultiLineTextField({
  handleInputChange: handleInputChangeParent,
  value: initialValue,
}) {
  const [inputValue, setInputValue] = useState(initialValue);
  const handleInputChange = (e, { value }) => {
    setInputValue(value);
    handleInputChangeParent(value);
  };

  return (
    <TextArea
      rows={3}
      value={inputValue}
      onChange={handleInputChange}
      className="multiline-text-field"
    />
  );
}

MultiLineTextField.propTypes = {
  handleInputChange: PropTypes.func,
  value: PropTypes.string,
};

MultiLineTextField.defaultProps = {
  value: "",
};

function MultipleChoice({
  handleInputChange: handleInputChangeParent,
  value: initialValue,
  choicesText,
}) {
  const [inputValue, setInputValue] = useState(initialValue);
  const [choices, setChoices] = useState([]);
  const formatChoicesText = (_choicesText) => {
    let _choices = _choicesText.split("\n");
    setChoices(_choices);
  };

  const handleInputChange = (e, { value }) => {
    setInputValue(value);
    handleInputChangeParent(value);
  };

  useEffect(() => {
    if (choicesText) {
      formatChoicesText(choicesText);
    }
  }, [choicesText]);

  return (
    <>
      {choices &&
        choices.map((c, cIndex) => {
          return (
            <Form.Field key={cIndex} className="multiple-choice">
              <Radio
                label={c}
                name="radioGroup"
                value={c}
                checked={inputValue === c}
                onChange={handleInputChange}
              />
            </Form.Field>
          );
        })}
    </>
  );
}

MultipleChoice.propTypes = {
  handleInputChange: PropTypes.func,
  value: PropTypes.string,
  choicesText: PropTypes.string,
};

MultipleChoice.defaultProps = {
  value: "",
};

function PhotoField({
  handleInputChange: handleInputChangeParent,
  value: initialValue,
}) {
  const [inputValue, setInputValue] = useState(initialValue);
  const photoRef = useRef();
  const handleInputChange = async () => {
    let myFile =
      photoRef.current.inputRef.current.files.length > 0
        ? photoRef.current.inputRef.current.files[0]
        : null;
    handleInputChangeParent(myFile);
  };

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  return (
    <>
      {inputValue && (
        <div>
          <a href={inputValue} target="_blank" rel="noopener noreferrer">
            <img src={inputValue} width="50" />
          </a>
        </div>
      )}
      <Input
        type="file"
        capture="camera"
        accept="image/x-png,image/jpeg,image/gif"
        ref={photoRef}
        onChange={handleInputChange}
      ></Input>
    </>
  );
}

PhotoField.propTypes = {
  handleInputChange: PropTypes.func,
  value: PropTypes.string,
};

PhotoField.defaultProps = {
  value: "",
};

function AdditionalFieldEditor({
  additionalField: af,
  taskList,
  company,
  saveTaskList,
}) {
  const [imageUrl, setImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleInputChange = (value) => {
    af.value = value;
  };

  const handlePhotoInputChange = async (myFile) => {
    try {
      const storagePathAndFilename = `task-lists/${company}/${taskList._id.$oid}/${myFile.name}`;
      const ref = firebase.storage().ref(storagePathAndFilename);
      setIsLoading(true);
      await ref.put(myFile);
      const myDownloadUrl = await ref.getDownloadURL();
      setIsLoading(false);
      console.log(myDownloadUrl);
      af.value = myDownloadUrl;
      saveTaskList(taskList);
      setImageUrl(myDownloadUrl);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (af.type.toUpperCase() === "PHOTO") {
      setImageUrl(af.value);
    }
  }, []);

  return (
    <>
      {af.type.toUpperCase() === "NUMBER" ? (
        <NumberField handleInputChange={handleInputChange} value={af.value} />
      ) : af.type.toUpperCase() === "TEXT" &&
        af.display.toUpperCase() === "SINGLE" ? (
        <SingleTextField
          handleInputChange={handleInputChange}
          value={af.value}
        />
      ) : af.type.toUpperCase() === "TEXT" &&
        af.display.toUpperCase() === "MULTI-LINE" ? (
        <MultiLineTextField
          handleInputChange={handleInputChange}
          value={af.value}
        />
      ) : af.type.toUpperCase() === "PHOTO" ? (
        <div>
          <Dimmer active={isLoading}>
            <Loader />
          </Dimmer>
          <PhotoField
            handleInputChange={handlePhotoInputChange}
            value={imageUrl}
          />
        </div>
      ) : af.type.toUpperCase() === "MULTIPLE CHOICE" ? (
        <MultipleChoice
          handleInputChange={handleInputChange}
          value={af.value}
          choicesText={af.choices}
        />
      ) : (
        <>None</>
      )}
    </>
  );
}

AdditionalFieldEditor.propTypes = {
  additionalField: PropTypes.object,
  taskList: PropTypes.object,
  company: PropTypes.string,
  saveTaskList: PropTypes.func,
};

function DeleteTaskConfirm({
  isModalOpen,
  handleDeleteTaskListYes,
  handleDeleteTaskListNo,
}) {
  return (
    <Modal open={isModalOpen} onClose={handleDeleteTaskListNo}>
      <Modal.Header>Delete Task List?</Modal.Header>
      <Modal.Content>
        <Button onClick={handleDeleteTaskListYes}>Yes</Button>
        <Button onClick={handleDeleteTaskListNo}>No</Button>
      </Modal.Content>
    </Modal>
  );
}

DeleteTaskConfirm.propTypes = {
  isModalOpen: PropTypes.bool,
  handleDeleteTaskListYes: PropTypes.func,
  handleDeleteTaskListNo: PropTypes.func,
};

DeleteTaskConfirm.defaultProps = {
  isModalOpen: false,
};
