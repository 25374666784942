import React, { useState, useEffect } from "react";
import {
  Menu,
  Loader,
  Header,
  Label,
  Segment,
  Grid,
  Message,
  Table,
  Tab,
  Dimmer,
  Icon,
  Accordion,
} from "semantic-ui-react";
import PropTypes from "prop-types";

import "./styles/Sales.scss";

import moment from "moment";
import "moment-timezone";
import useUserData from "../../state-manipulation/hooks/useUserData";
import usePageData from "../../state-manipulation/hooks/usePageData";
import { setSelectedPage } from "../../state-manipulation/reducers/page-data/types";
import { LG_WIDTH } from "../../constants";

import DateRangeSelector from "../common/DateRangeSelector";
import useDateRangeData from "../../state-manipulation/hooks/useDateRangeData";
import DateMenu from "../common/DateMenu";
import Moment from "react-moment";
import { clearUser } from "../../state-manipulation/reducers/user-data/types";
import PageHeader from "../common/PageHeader";
import AxiosWrapper, { AxiosAbort } from "../../utils/AxiosWrapper";

export default function Voids({ className }) {
  const [pageData, pageDispatch] = usePageData();
  const [dateRangeData, dateRangeDispatch] = useDateRangeData();

  const [days, setDays] = useState(0);
  const [userData, userDispatch] = useUserData();
  const [isLoading, setIsLoading] = useState(false);
  const [voids, setVoids] = useState([]);
  const [activePanels, setActivePanels] = useState({});
  const [timeOutId, setTimeoutId] = useState("");

  const getSalesVoids = async (company, _dateStart, _dateEnd) => {
    try {
      setIsLoading(true);
      let res = await AxiosWrapper({
        method: "GET",
        url: `${process.env.API_URL}/${company}/voids?startDate=${_dateStart}&endDate=${_dateEnd}`,
        headers: {
          Authorization: `Basic ${userData.token}`,
        },
      }).catch((e) => {
        if (e.response.status === 401) {
          userDispatch({
            type: clearUser,
            payload: {},
          });
        }
      });

      setVoids(res.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleAccoridian1Click = (e, { index }) => {
    let _activePanels = { ...activePanels };
    _activePanels[index] = !_activePanels[index] ? 1 : 0;
    setActivePanels(_activePanels);
  };

  const renderManagerVoid = (voidItem, voidIndex) => {
    return (
      <Grid>
        <Grid.Column mobile={10}>
          <Header as="h4" className="for-table">
            {voidItem.firstName} {voidItem.lastName}{" "}
            <Icon
              name={
                activePanels[voidIndex] === 1 ? "caret down" : "caret right"
              }
            />
          </Header>
        </Grid.Column>
        <Grid.Column mobile={6}>
          <p>
            Total: ${voidItem.total.toFixed(2)} ({voidItem.count})
          </p>
        </Grid.Column>
      </Grid>
    );
  };

  const renderEmployeeVoid = (owner) => {
    if (owner.firstName === "") {
      return;
    }
    return (
      <Header as="h5">
        For {owner.firstName} {owner.lastName}{" "}
        <Label color="grey">
          Total: ${owner.total.toFixed(2)} ({owner.count})
        </Label>
      </Header>
    );
  };

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: "Voids",
      },
    });
  }, []);

  useEffect(() => {
    let _days =
      moment(dateRangeData.dateEnd).diff(moment(dateRangeData.dateStart)) /
      1000 /
      60 /
      60 /
      24;

    if (_days >= 0 && _days <= 7 && userData.selectedOrg) {
      clearTimeout(timeOutId);
      setTimeoutId(
        setTimeout(() => {
          getSalesVoids(
            userData.selectedOrg.name.toLowerCase(),
            dateRangeData.dateStart,
            dateRangeData.dateEnd
          );
        }, 1000)
      );
    }
    setDays(_days);

    return () => {
      clearTimeout(timeOutId);
      AxiosAbort();
    }
  }, [dateRangeData, userData.selectedOrg]);

  return (
    <div className={"content-container " + className}>
      <DateMenu />
      <PageHeader pageName="Voids" orgName={userData.selectedOrg?.name} />
      {days > 7 && (
        <Message warning>Date range must be 7 days or less.</Message>
      )}
      {days < 0 && (
        <Message warning>
          End date must be the same or after start date.
        </Message>
      )}
      <DateRangeSelector />
      {voids && (
        <div>
          <Segment>
            <Dimmer active={isLoading}>
              <Loader />
            </Dimmer>
            <Accordion exclusive={false} className="accordion-level-1">
              {voids &&
                voids.map((voidItem, voidIndex) => {
                  return (
                    <div key={voidIndex}>
                      <Accordion.Title
                        active={activePanels[voidIndex] === 1}
                        index={voidIndex}
                        onClick={handleAccoridian1Click}
                      >
                        {renderManagerVoid(voidItem, voidIndex)}
                      </Accordion.Title>
                      <Accordion.Content
                        active={activePanels[voidIndex] === 1}
                        className="accordion-content-1"
                      >
                        {voidItem.owners &&
                          voidItem.owners.map((owner, ownerIndex) => {
                            let show = {};
                            return (
                              <div key={ownerIndex}>
                                {renderEmployeeVoid(owner)}
                                <Accordion
                                  exclusive={false}
                                  className="accordion-level-2"
                                >
                                  {owner &&
                                    owner.voidTypes.map(
                                      (voidType, voidTypeIndex) => {
                                        return (
                                          <div key={voidTypeIndex}>
                                            <Accordion.Title
                                              active={
                                                activePanels[
                                                  `${voidIndex}-${ownerIndex}-${voidTypeIndex}`
                                                ] === 1
                                              }
                                              index={`${voidIndex}-${ownerIndex}-${voidTypeIndex}`}
                                              onClick={handleAccoridian1Click}
                                            >
                                              {voidType.reason}{" "}
                                              <Label>
                                                ${voidType.total.toFixed(2)} (
                                                {voidType.count})
                                              </Label>
                                              <Icon
                                                name={
                                                  activePanels[
                                                    `${voidIndex}-${ownerIndex}-${voidTypeIndex}`
                                                  ] === 1
                                                    ? "caret down"
                                                    : "caret right"
                                                }
                                              />
                                            </Accordion.Title>
                                            <Accordion.Content
                                              active={
                                                activePanels[
                                                  `${voidIndex}-${ownerIndex}-${voidTypeIndex}`
                                                ] === 1
                                              }
                                            >
                                              <Table celled>
                                                <Table.Header>
                                                  <Table.Row>
                                                    <Table.HeaderCell>
                                                      Ticket Id
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                      Time
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                      Item
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                      Qty
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                      Amount
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                      CC Name
                                                    </Table.HeaderCell>
                                                  </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                  {voidType.voidItems &&
                                                    voidType.voidItems.map(
                                                      (
                                                        voidItem,
                                                        voidItemIndex
                                                      ) => {
                                                        return (
                                                          <Table.Row
                                                            key={voidItemIndex}
                                                          >
                                                            <Table.Cell>
                                                              {
                                                                voidItem.i_ticket_id
                                                              }
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                              <Moment format="M/D h:mm a">
                                                                {
                                                                  voidItem.dt_when
                                                                }
                                                              </Moment>
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                              {
                                                                voidItem.menuItem
                                                              }
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                              {voidItem.qty}
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                              {voidItem.amount.toFixed(
                                                                2
                                                              )}
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                              {
                                                                voidItem.creditCardName
                                                              }
                                                            </Table.Cell>
                                                          </Table.Row>
                                                        );
                                                      }
                                                    )}
                                                </Table.Body>
                                              </Table>
                                            </Accordion.Content>
                                          </div>
                                        );
                                      }
                                    )}
                                </Accordion>
                              </div>
                            );
                          })}
                      </Accordion.Content>
                    </div>
                  );
                })}
            </Accordion>
          </Segment>
        </div>
      )}
    </div>
  );
}

Voids.propTypes = {
  className: PropTypes.string,
};

Voids.defaultProps = {
  className: "",
};
