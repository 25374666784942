import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Form,
  Label,
  Input,
  Grid,
  Table,
  Segment,
  GridColumn,
  TextArea,
  Dropdown,
  Loader,
  Dimmer,
  Button
} from 'semantic-ui-react';
import useUserData from '../../state-manipulation/hooks/useUserData';
import AxiosWrapper from '../../utils/AxiosWrapper';
import { mutliplyEmpty } from '../../utils/MathUtils';
import CashCalculator from './CashCalculator';

export default function CashCountForm({
  formSubmissionCompleteHandler,
  lastCashCount,
  handleDrawerOpen
}) {
  const [userData, userDispatch] = useUserData();
  const [total, setTotal] = useState('');
  const [totalLeftInDrawer, setTotalLeftInDrawer] = useState('');
  const [totalBillsLeftInDrawer, setTotalBillsLeftInDrawer] = useState('');
  const [depositAmount, setDepositAmount] = useState('');
  const [posAmount, setPosAmount] = useState('');
  const [countGoal, setCountGoal] = useState('');
  const [difference, setDifference] = useState('');
  const [countedBy, setCountedBy] = useState('');
  const [notes, setNotes] = useState('');
  const [isCashLeftInDrawerOpen, setIsCashLeftInDrawerOpen] = useState(false);
  const [drawerStatus, setDrawerStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [timeOutId, setTimeoutId] = useState('');
  const [configTimeOutId, setConfigTimeoutId] = useState('');
  const [cashTransactions, setCashTransactions] = useState({});
  const [cashCountConfig, setCashCountConfig] = useState({});
  const [cashDrawers, setCashDrawers] = useState([]);
  const [cashDrawer, setCashDrawer] = useState('');
  const [cashCount, setCashCount] = useState({});
  const [leftInDrawer, setLeftInDrawer] = useState({});
  const [depositCount, setDepositCount] = useState({});
  const [amountToDeposit, setAmountToDeposit] = useState(0);

  const drawerStatusOptions = [
    {
      key: 'open',
      text: 'open',
      value: 'open'
    },
    {
      key: 'close',
      text: 'close',
      value: 'close'
    }
  ];

  const getCashDrawerConfig = async company => {
    try {
      setIsLoading(true);
      let url = `${process.env.API_URL}/${company}/configs/cash-count`;

      let res = await AxiosWrapper({
        method: 'GET',
        url: url,
        headers: {
          Authorization: `Basic ${userData.token}`
        }
      });

      resetForm();
      setCashCountConfig(res.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const getCashTransactions = async (company, _dateStart, _dateEnd) => {
    try {
      setIsLoading(true);
      let url = `${process.env.API_URL}/${company}/cash-transactions`;
      if (_dateStart && _dateEnd) {
        url += `?startDate=${_dateStart}&endDate=${_dateEnd}`;
      }

      let res = await AxiosWrapper({
        method: 'GET',
        url: url,
        headers: {
          Authorization: `Basic ${userData.token}`
        }
      });

      setCashTransactions(res.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const postCashTransaction = async company => {
    try {
      setIsLoading(true);

      let res = await AxiosWrapper({
        method: 'POST',
        url: `${process.env.API_URL}/${company}/cash-counts`,
        headers: {
          Authorization: `Basic ${userData.token}`
        },
        data: {
          cashCount: {
            count100: cashCount.count100,
            count50: cashCount.count50,
            count20: cashCount.count20,
            count10: cashCount.count10,
            count5: cashCount.count5,
            count2: cashCount.count2,
            count1: cashCount.count1,
            countChange50: cashCount.countChange50,
            countChange25: cashCount.countChange25,
            countChange10: cashCount.countChange10,
            countChange5: cashCount.countChange5,
            countChange1: cashCount.countChange1,
            leftInDrawer100: leftInDrawer.count100,
            leftInDrawer50: leftInDrawer.count50,
            leftInDrawer20: leftInDrawer.count20,
            leftInDrawer10: leftInDrawer.count10,
            leftInDrawer5: leftInDrawer.count5,
            leftInDrawer2: leftInDrawer.count2,
            leftInDrawer1: leftInDrawer.count1,
            leftInDrawerChange50: leftInDrawer.countChange50,
            leftInDrawerChange25: leftInDrawer.countChange25,
            leftInDrawerChange10: leftInDrawer.countChange10,
            leftInDrawerChange5: leftInDrawer.countChange5,
            leftInDrawerChange1: leftInDrawer.countChange1,
            total,
            totalLeftInDrawer,
            depositAmount,
            posAmount,
            countGoal,
            difference,
            countedBy,
            notes,
            cashDrawer,
            drawerStatus
          }
        }
      });

      setIsLoading(false);
      resetForm();
      formSubmissionCompleteHandler();
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const resetForm = () => {
    setCashCount({});
    setLeftInDrawer({})
    setCountGoal('');
    setDifference('');
    setNotes('');
    setIsCashLeftInDrawerOpen(false);
    setDrawerStatus('');
    setCashDrawer('');
    setDepositCount({});
  };

  const handleCashCountChange = (count) => {
    setCashCount({...count});
    setLeftInDrawer({...count});
    setTotal(count.total);
    if (count.totalBills > cashCountConfig.DRAWER_AMOUNT) {
      setAmountToDeposit(subtractMoney(count.totalBills, cashCountConfig.DRAWER_AMOUNT));
    } else {
      setAmountToDeposit(0);
    }
    calculateLeftInDrawer(count, depositCount);

  }

  const handleLeftInDrawerChange = (count) => {
    setLeftInDrawer({...count});
    setTotalLeftInDrawer(count.total);
    // setTotal(count.total);
  }

  const calculateLeftInDrawer = (cashCountSource, depositCountSource) => {
    let _leftInDrawer = {...cashCountSource};
    Object.keys(_leftInDrawer).forEach(k => {
      _leftInDrawer[k] = _leftInDrawer[k] - (depositCountSource[k] || 0);
    });

    setLeftInDrawer(_leftInDrawer);
  }

  const handleDepositCountChange = (count) => {
    setDepositCount({...count});
    setDepositAmount(count.total);
    calculateLeftInDrawer(cashCount, count);
  }

  const handleCashDrawerChange = (e, { value }) => {
    setCashDrawer(value);
  };

  const handleCashLeftInDrawerClick = () => {
    if (isCashLeftInDrawerOpen) {
      setIsCashLeftInDrawerOpen(false);
    } else {
      setIsCashLeftInDrawerOpen(true);
    }
  };

  const handleDrawerStatusChagne = (e, { value }) => {
    setDrawerStatus(value);
  };

  const handleNotesChange = (e, { value }) => {
    setNotes(value);
  };

  const handleFormSubmitClick = () => {
    postCashTransaction(userData.selectedOrg.name.toLowerCase());
  };

  const subtractMoney = (number1, number2) => {
    number1 = Number(number1);
    number2 = Number(number2);
    let res = (number1 * 100 - number2 * 100) / 100;
    return res;
  };

  const roundDecimal = (number, decimalPlaces = 2) => {
    return parseFloat(number.toFixed(decimalPlaces));
  };

  useEffect(() => {
    if (userData.selectedOrg) {
      clearTimeout(configTimeOutId);
      setConfigTimeoutId(
        setTimeout(() => {
          getCashDrawerConfig(userData.selectedOrg.name.toLowerCase());
        }, 1000)
      );
    }
  }, [userData.selectedOrg]);

  useEffect(() => {
    if (!cashCount.total) {
      return;
    }
    let difference = subtractMoney(cashCount.total, countGoal);
    setDifference(difference);
  }, [
    cashCount.total,
    countGoal
  ]);


  useEffect(() => {
    if (drawerStatus === 'open') {
      handleDrawerOpen(cashDrawer);
    } else if (drawerStatus === 'close') {
      if (cashCountConfig.POS.toUpperCase() === 'DINERWARE') {
        clearTimeout(timeOutId);
        setTimeoutId(
          setTimeout(() => {
            getCashTransactions(userData.selectedOrg.name.toLowerCase());
          }, 1000)
        );
      }
    } else {
      return;
    }
  }, [drawerStatus, cashDrawer]);

  useEffect(() => {
    if (drawerStatus === 'close' && cashTransactions.summary) {
      setPosAmount(cashTransactions.summary.deposit);
      setCountGoal(cashTransactions.summary.deposit);
    }
  }, [cashTransactions]);

  useEffect(() => {
    if (userData.employeeByPin) {
      setCountedBy(
        `${userData.employeeByPin.firstName} ${userData.employeeByPin.lastName}`
      );
    } else if (userData.userInfo && userData.userInfo.firstName) {
      setCountedBy(
        `${userData.userInfo.firstName} ${userData.userInfo.lastName}`
      );
    }
  }, [userData.userInfo.firstName, userData.employeeByPin]);

  useEffect(() => {
    if (!cashCountConfig.CASH_DRAWERS) {
      return;
    }
    let _cashDrawers = cashCountConfig.CASH_DRAWERS.map(d => {
      return {
        key: d,
        text: d,
        value: d
      };
    });

    if (cashCountConfig.CASH_DRAWERS.length === 1) {
      setCashDrawer(cashCountConfig.CASH_DRAWERS[0]);
    }

    setCashDrawers(_cashDrawers);
  }, [cashCountConfig]);

  useEffect(() => {
    setCountGoal(
      lastCashCount.totalLeftInDrawer ? lastCashCount.totalLeftInDrawer : 0
    );
  }, [lastCashCount]);

  return (
    <>
      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      <Table unstackable>
        <Table.Body>
          <Table.Row>
            <Table.Cell collapsing>Drawer</Table.Cell>
            <Table.Cell>
              <Dropdown
                placeholder="Select One"
                options={cashDrawers}
                value={cashDrawer}
                onChange={handleCashDrawerChange}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell collapsing>Drawer Status</Table.Cell>
            <Table.Cell>
              <Dropdown
                placeholder="Select One"
                options={drawerStatusOptions}
                value={drawerStatus}
                onChange={handleDrawerStatusChagne}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell collapsing>Count Goal:</Table.Cell>
            <Table.Cell textAlign="right">
              <Input
                value={countGoal}
                type="number"
                onChange={(e, { value }) => {
                  setCountGoal(value);
                }}
              >
                <input inputMode="decimal" style={{ textAlign: 'right' }} />
              </Input>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell collapsing>Total:</Table.Cell>
            <Table.Cell textAlign="right">
              {total && total.toFixed(2)}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell collapsing>Difference:</Table.Cell>
            <Table.Cell
              textAlign="right"
              negative={difference < -0.01 ? true : false}
            >
              {difference && difference.toFixed(2)}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Grid stackable columns={2}>
        {/* Cash Count */}
        <Grid.Column width={16}>
          <Segment>
            <CashCalculator cashCount={cashCount} onChange={handleCashCountChange} />
          </Segment>
        </Grid.Column>
        {/* Deposit */}
        <Grid.Column width={16} style={{ display: drawerStatus !== 'close' ? 'none' : 'block' }}>
          <Segment.Group>
            <Segment
              style={{ cursor: 'pointer' }}
            >
              <strong>Deposit</strong>
            </Segment>
            <Segment>
              <p>Deposit should be ${amountToDeposit || 0}. Please enter the bills for the deposit.</p>
              <Table unstackable>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell collapsing>Deposit</Table.Cell>
                    <Table.Cell>
                      ${depositAmount && depositAmount.toFixed(2)}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <CashCalculator cashCount={depositCount} onChange={handleDepositCountChange} />
            </Segment>
          </Segment.Group>
        </Grid.Column>
        {/* Cash left in Drawer */}
        <Grid.Column width={16}>
          <Segment.Group>
            <Segment
              onClick={handleCashLeftInDrawerClick}
              style={{ cursor: 'pointer' }}
            >
              <strong>Cash left in drawer</strong>
            </Segment>
            <Segment style={{ display: !isCashLeftInDrawerOpen ? 'none' : 'block' }}>
              <Table unstackable>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell collapsing>Total</Table.Cell>
                    <Table.Cell>
                      ${totalLeftInDrawer && totalLeftInDrawer.toFixed(2)}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <CashCalculator cashCount={leftInDrawer} readOnly={true} onChange={handleLeftInDrawerChange} />
            </Segment>
          </Segment.Group>
        </Grid.Column>
        <Grid.Column width={16}>
          <Segment>
            <p>
              <strong>Counted By:</strong> {countedBy}
            </p>
            <Form>
              <Form.TextArea
                label="Notes"
                value={notes}
                onChange={handleNotesChange}
              ></Form.TextArea>
              <Button
                primary
                onClick={handleFormSubmitClick}
                disabled={!drawerStatus ? true : false}
              >
                Submit
              </Button>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
}

CashCountForm.propTypes = {
  formSubmissionCompleteHandler: PropTypes.func,
  handleDrawerOpen: PropTypes.func,
  lastCashCount: PropTypes.object
};

CashCountForm.defaultProps = {
  formSubmissionCompleteHandler: () => {},
  handleDrawerOpen: () => {},
  lastCashCount: {}
};