import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from 'recharts';
import useUserData from '../../state-manipulation/hooks/useUserData';
import usePageData from '../../state-manipulation/hooks/usePageData';
import { setSelectedPage } from '../../state-manipulation/reducers/page-data/types';
import {
  Header,
  Segment,
  Dimmer,
  Loader,
  Statistic,
  Menu,
  Grid,
} from 'semantic-ui-react';
import { LG_WIDTH } from '../../constants';
import moment from 'moment';
import { DateMenuForm } from '../common/DateMenu';
import { GetStockPots } from '../../api/StockPotsAPI';
import useDateRangeData from '../../state-manipulation/hooks/useDateRangeData';
import { useParams } from 'react-router-dom';
import { DateTimeRangeSelectorForm } from '../common/DateTimeRangeSelector';

export default function StockPotsHistorical() {
  const { company } = useParams();
  const [userData, userDispatch] = useUserData();
  const [pageData, pageDispatch] = usePageData();
  const [isLoading, setIsLoading] = useState(false);
  const [stockPots, setStockPots] = useState([]);
  const [eventSources, setEventSources] = useState([]);
  const currentHour = moment().tz('America/New_York').hours();
  const defaultStartDate =
    currentHour >= 0 && currentHour < 4
      ? moment().subtract(1, 'days').startOf('day')
      : moment();
  const defaultEndDate =
    currentHour >= 0 && currentHour < 4
      ? moment().subtract(1, 'days')
      : moment();
  const [dateStart, setDateStart] = useState(
    moment(defaultStartDate).startOf('day').format('MM/DD/YY H:mm')
  );
  const [dateEnd, setDateEnd] = useState(
    defaultEndDate.format('MM/DD/YY H:mm')
  );

  const getStockPots = async () => {
    try {
      setIsLoading(true);
      let res = await GetStockPots({
        startDate: dateStart,
        endDate: dateEnd,
        company: company,
      });

      setStockPots(res.data.devices);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const formatDate = (date) => {
    return moment(date).format('h:mm a');
  };

  const formatTemperature = (temperature) => {
    return formatTemperature.toFixed(2);
  };

  // DATE FUNCTIONS
  const handleDateStartChange = (e, { value }) => {
    console.log('handleDateStartChange -> value', value);
    setDateStart(value);
  };

  const handleDateEndChange = (e, { value }) => {
    setDateEnd(value);
  };

  const handleCaretLeftClick = () => {
    setDateStart(
      moment(dateStart)
        .subtract(1, 'days')
        .startOf('day')
        .format('MM/DD/YY H:mm')
    );
    setDateEnd(moment(dateEnd).subtract(1, 'days').format('MM/DD/YY H:mm'));
  };

  const handleCaretRightClick = () => {
    setDateStart(
      moment(dateStart).add(1, 'days').startOf('day').format('MM/DD/YY H:mm')
    );
    setDateEnd(moment(dateEnd).add(1, 'days').format('MM/DD/YY H:mm'));
  };

  const handleFastBackwardClick = () => {
    setDateStart(
      moment(dateStart)
        .subtract(7, 'days')
        .startOf('day')
        .format('MM/DD/YY H:mm')
    );
    setDateEnd(moment(dateEnd).subtract(7, 'days').format('MM/DD/YY H:mm'));
  };

  const handleFastForwardClick = () => {
    setDateStart(
      moment(dateStart).add(7, 'days').startOf('day').format('MM/DD/YY H:mm')
    );
    setDateEnd(moment(dateEnd).add(7, 'days').format('MM/DD/YY H:mm'));
  };

  // START: Functions for DateMenuForm

  const handleTodayClick = () => {
    setDateStart(defaultStartDate.startOf('day').format('MM/DD/YY H:mm'));
    setDateEnd(defaultEndDate.format('MM/DD/YY H:mm'));
  };

  const handleYesterdayClick = () => {
    setDateStart(
      defaultStartDate
        .subtract(1, 'days')
        .startOf('day')
        .format('MM/DD/YY H:mm')
    );
    setDateEnd(
      defaultEndDate.subtract(1, 'days').endOf('day').format('MM/DD/YY H:mm')
    );
  };

  const handle52WeeksAgoClick = () => {
    setDateStart(
      defaultStartDate
        .subtract(52, 'weeks')
        .startOf('day')
        .format('MM/DD/YY H:mm')
    );
    setDateEnd(defaultEndDate.subtract(52, 'weeks').format('MM/DD/YY H:mm'));
  };

  const handleWeekToDateClick = () => {
    let _startDate =
      defaultStartDate.format('ddd').toUpperCase() === 'SUN'
        ? defaultStartDate.subtract(6, 'days').startOf('day').format('MM/DD/YY')
        : defaultStartDate
            .startOf('week')
            .add(1, 'days')
            .startOf('day')
            .format('MM/DD/YY');
    setDateStart(_startDate);
    setDateEnd(defaultEndDate.format('MM/DD/YY H:mm'));
  };

  const handleLastWeekClick = () => {
    setDateStart(
      defaultStartDate
        .startOf('week')
        .subtract(6, 'days')
        .startOf('day')
        .format('MM/DD/YY H:mm')
    );
    setDateEnd(
      defaultEndDate.startOf('week').endOf('day').format('MM/DD/YY H:mm')
    );
  };

  const handleLast7DaysClick = () => {
    setDateStart(
      defaultStartDate
        .subtract(7, 'days')
        .startOf('day')
        .format('MM/DD/YY H:mm')
    );
    setDateEnd(defaultEndDate.format('MM/DD/YY H:mm'));
  };

  const handleLastMonthClick = () => {
    setDateStart(
      defaultStartDate
        .startOf('month')
        .subtract(1, 'month')
        .startOf('day')
        .format('MM/DD/YY H:mm')
    );
    setDateEnd(
      defaultEndDate
        .endOf('month')
        .subtract(1, 'month')
        .endOf('day')
        .format('MM/DD/YY H:mm')
    );
  };

  const handleLast30DaysClick = () => {
    setDateStart(
      defaultStartDate
        .subtract(30, 'days')
        .startOf('day')
        .format('MM/DD/YY H:mm')
    );
    setDateEnd(defaultEndDate.format('MM/DD/YY H:mm'));
  };

  const handleMonthToDateClick = () => {
    setDateStart(
      defaultStartDate.startOf('month').startOf('day').format('MM/DD/YY H:mm')
    );
    setDateEnd(defaultEndDate.format('MM/DD/YY H:mm'));
  };

  // END: Functions for DateMenuForm

  useEffect(() => {
    if (company) {
      getStockPots();
    }
  }, [company, dateStart, dateEnd]);

  useEffect(() => {
    return () => {
      eventSources.forEach((es) => {
        es.close();
      });
    };
  }, [eventSources]);

  return (
    <>
      <DateMenuForm
        handleTodayClick={handleTodayClick}
        handleYesterdayClick={handleYesterdayClick}
        handle52WeeksAgoClick={handle52WeeksAgoClick}
        handleWeekToDateClick={handleWeekToDateClick}
        handleLastWeekClick={handleLastWeekClick}
        handleLast7DaysClick={handleLast7DaysClick}
        handleMonthToDateClick={handleMonthToDateClick}
        handleLastMonthClick={handleLastMonthClick}
        handleLast30DaysClick={handleLast30DaysClick}
      />
      <DateTimeRangeSelectorForm
        dateRangeData={{ dateStart: dateStart, dateEnd: dateEnd }}
        handleFastBackwardClick={handleFastBackwardClick}
        handleFastForwardClick={handleFastForwardClick}
        handleCaretLeftClick={handleCaretLeftClick}
        handleDateStartChange={handleDateStartChange}
        handleDateEndChange={handleDateEndChange}
        handleCaretRightClick={handleCaretRightClick}
      />
      <Segment>
        {stockPots &&
          stockPots.map((stockPot, stockPotIndex) => {
            let data = stockPot.states.map((s) => {
              return {
                ...s,
                temperatureF: s.value,
              };
            });

            return (
              <div key={stockPotIndex}>
                <Header as="h2">{stockPot.name}</Header>
                <div style={{ textAlign: 'center' }}>
                  <Grid columns={3}>
                    <Grid.Row>
                      <Grid.Column
                        mobile={16}
                        computer={5}
                        verticalAlign="middle"
                      >
                        <Statistic
                          label="Min"
                          size="tiny"
                          value={
                            stockPot.min ? `${stockPot.min.toFixed(2)}˚F` : '--'
                          }
                        />
                      </Grid.Column>
                      <Grid.Column
                        mobile={16}
                        computer={6}
                        verticalAlign="middle"
                      >
                        <Statistic
                          label="Average"
                          value={
                            stockPot.averageTemperatureF
                              ? `${stockPot.averageTemperatureF.toFixed(2)}˚F`
                              : '- -'
                          }
                        />
                      </Grid.Column>
                      <Grid.Column
                        mobile={16}
                        computer={5}
                        verticalAlign="middle"
                      >
                        <Statistic
                          label="Max"
                          size="tiny"
                          value={
                            stockPot.max ? `${stockPot.max.toFixed(2)}˚F` : '--'
                          }
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
                <ResponsiveContainer width="100%" height={200}>
                  <LineChart
                    data={data}
                    margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                  >
                    <XAxis
                      dataKey="time"
                      tickFormatter={formatDate}
                      minTickGap={20}
                    />
                    <YAxis dataKey="temperatureF" />
                    <Tooltip
                      formatter={(val) => `${val.toFixed(2)}˚F`}
                      labelFormatter={(val) =>
                        moment(val).format('ddd MM/DD h:mm a')
                      }
                    />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Line
                      type="monotone"
                      dataKey="temperatureF"
                      stroke="#8884d8"
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            );
          })}
      </Segment>
    </>
  );
}
