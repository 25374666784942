import React from "react";
import { Link, useParams } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";
import usePageData from "../../state-manipulation/hooks/usePageData";
import useUserData from "../../state-manipulation/hooks/useUserData";
import IsAuthorized from "../common/IsAuthorized";

export default function TasksMenu({ onClick }) {
  let { company } = useParams();
  const [pageData] = usePageData();
  const [userData, userDispatch] = useUserData();

  if (!company) {
    company = userData.orgs[0].name.toLowerCase();
  }

  return (
    <IsAuthorized permission="hasTasks">
      <Menu.Item>
        <Menu.Header>Tasks</Menu.Header>
        <Menu.Menu>
          <IsAuthorized permission="hasTasksTaskLists">
            <Menu.Item
              as={Link}
              to={"/" + company + "/task-lists"}
              onClick={onClick}
              active={
                pageData.selectedPage.toUpperCase() === "TASK LISTS"
                  ? true
                  : false
              }
            >
              <Icon name="gamepad" />
              Daily Task Lists
            </Menu.Item>
          </IsAuthorized>
          <IsAuthorized permission="hasTasksManage">
            <Menu.Item
              as={Link}
              to={"/" + company + "/admin/task-list-templates"}
              onClick={onClick}
              active={
                pageData.selectedPage.toUpperCase() === "MANAGE TASKS"
                  ? true
                  : false
              }
            >
              <Icon name="gamepad" />
              Task List Templates
            </Menu.Item>
          </IsAuthorized>
          <IsAuthorized permission="hasTasksTrelloTools">
            <Menu.Item
              as={Link}
              to={"/" + company + "/trello-tools"}
              onClick={onClick}
              active={
                pageData.selectedPage.toUpperCase() === "TRELLO"
                  ? true
                  : false
              }
            >
              <Icon name="trello" />
              Trello
            </Menu.Item>
          </IsAuthorized>
        </Menu.Menu>
      </Menu.Item>
    </IsAuthorized>
  );
}
