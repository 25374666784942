import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Dimmer, Loader, Header, Button, Message } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { PostUser } from '../api/UserAPI';

export default function UserForm({ className }) {
  const navigate = useNavigate();
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ isLoading, setIsLoading ] = useState(false);
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });

  const handleFirstNameChange = (e, { value }) => {
    let _user = { ...user };
    _user.firstName = value;
    setUser(_user);
  };

  const handleLastNameChange = (e, { value }) => {
    let _user = { ...user };
    _user.lastName = value;
    setUser(_user);
  };

  const handleEmailChange = (e, { value }) => {
    let _user = { ...user };
    _user.email = value;
    setUser(_user);
  };

  const handlePasswordChange = (e, { value }) => {
    let _user = { ...user };
    _user.password = value;
    setUser(_user);
  };

  const handleFormSubmit = async () => {
    setErrorMessage('');
    setIsLoading(true);
    let res = await PostUser(user);
    console.log("handleFormSubmit -> res", res)
    setIsLoading(false);
    if (!res.data.error) {
      navigate('/all/admin/manage-users', { replace: true });
    } else {
      setErrorMessage(res.data.error.message);
    }
  };

  return (
    <div className={'content-container ' + className}>
      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      <Header as="h1">Add User</Header>
      {errorMessage && (
        <Message negative><p>{errorMessage}</p></Message>
      )}
      <Form onSubmit={handleFormSubmit}>
        <Form.Field>
          <label>First Name</label>
          <Form.Input
            placeholder="First Name"
            value={user.firstName}
            onChange={handleFirstNameChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Last Name</label>
          <Form.Input
            placeholder="Last Name"
            value={user.lastName}
            onChange={handleLastNameChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Email</label>
          <Form.Input
            placeholder="user@langthanggroup.com"
            value={user.email}
            onChange={handleEmailChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Password</label>
          <Form.Input
            placeholder="password"
            value={user.password}
            onChange={handlePasswordChange}
          />
        </Form.Field>
        <Button primary type="submit">Save</Button>
      </Form>
    </div>
  );
}

UserForm.propTypes = {
  className: PropTypes.string,
};

UserForm.defaultProps = {
  className: '',
};
