import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  Form,
  Icon,
  Checkbox,
  Table,
  Grid,
  Button,
  Header,
  Modal,
  TextArea,
  Ref
} from 'semantic-ui-react';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import taskSchema from '../../schemas/task-list.json';

const taskInitialData = {
  name: '',
  description: '',
  additionalFields: []
};

const defaultAdditionalField = {
  name: '',
  description: '',
  type: '',
  isRequired: false
};

const daysOfWeek = taskSchema.properties.daysOfWeek.items.enum;

export default function TaskForm({
  task: originalTask,
  isModalOpen,
  handleModalClose: handleModalCloseParent,
  handleAddTask
}) {
  const [task, setTask] = useState(taskInitialData);
  const [daysSelected, setDaysSelected] = useState([]);
  const [isAddorEditProperty, setIsAddorEditProperty] = useState(false);

  const [currentAdditionalField, setCurrentAddtionalField] = useState(
    defaultAdditionalField
  );

  const [isFocussed, setIsFocussed] = useState(false);

  const handleModalClose = e => {
    setIsFocussed(false);
    setDaysSelected([]);
    handleModalCloseParent(e);
  };

  const handleTaskNameChange = (e, { value }) => {
    setTask({ ...task, name: value });
  };

  const handleHighlightChange = (e, { checked }) => {
    setTask({ ...task, isHighlighted: checked });
  };

  const handleTaskDescriptionChange = (e, { value }) => {
    setTask({ ...task, description: value });
  };

  const handleAdd = e => {
    e.preventDefault();
    task.taskDays = [...daysSelected];
    handleAddTask(task);
    setDaysSelected([]);
    handleModalClose();
    setIsAddorEditProperty(false);
    setCurrentAddtionalField(defaultAdditionalField);
  };

  const handleNameChange = (e, { value }) => {
    setCurrentAddtionalField({ ...currentAdditionalField, name: value });
  };

  const handleTypeChange = (e, { value }) => {
    let _currentAdditionalField = { ...currentAdditionalField };
    setCurrentAddtionalField({
      name: _currentAdditionalField.name,
      isRequired: _currentAdditionalField.isRequired,
      type: value
    });
  };

  const handleIsRequiredChange = (e, { checked }) => {
    setCurrentAddtionalField({
      ...currentAdditionalField,
      isRequired: checked
    });
  };

  const handleAdditionalFieldDescriptionChange = (e, { value }) => {
    setCurrentAddtionalField({
      ...currentAdditionalField,
      description: value
    });
  };

  const handleAdditionalFieldPropChange = (e, { name, value, type }) => {
    if (type === 'number') {
      value = Number(value);
    }
    setCurrentAddtionalField({ ...currentAdditionalField, [name]: value });
  };

  const handleSubmitAdditionalField = e => {
    console.log('handleSubmitAdditionalField');
    e.preventDefault();
    let _task = { ...task };
    if (typeof currentAdditionalField.index === 'undefined') {
      _task.additionalFields.push({ ...currentAdditionalField });
    } else {
      _task.additionalFields[currentAdditionalField.index] = {
        ...currentAdditionalField
      };
    }

    setTask(_task);
    setIsAddorEditProperty(false);
    setCurrentAddtionalField(defaultAdditionalField);
  };

  const handleEditAdditionalField = (e, { index }) => {
    let _task = { ...task };
    _task.additionalFields[index].index = index;
    setIsAddorEditProperty(true);
    setCurrentAddtionalField(_task.additionalFields[index]);
  };

  const handleDeleteAdditionalField = (e, { index }) => {
    let _task = { ...task };
    _task.additionalFields.splice(index, 1);
    setTask(_task);
    setIsAddorEditProperty(false);
    setCurrentAddtionalField(defaultAdditionalField);
  };

  const handleAddPropertyStart = e => {
    e.preventDefault();
    console.log('handleAddPropertyStart');
    setIsAddorEditProperty(true);
  };

  const handleAddPropertyStartKeyboard = () => {
    console.log('handleAddPropertyStartKeyboard');
    setIsAddorEditProperty(true);
    setTimeout(() => {
      document.getElementById('addtional-field-name').focus();
    }, 100);
  };

  const handleCancel = e => {
    e.preventDefault();
    setIsAddorEditProperty(false);
  };

  const handleNameRef = node => {
    if (!isFocussed) {
      if (node) {
        setTimeout(() => {
          node.getElementsByTagName('input')[0].focus();
        }, 200);
      }
      setIsFocussed(true);
    }
  };

  const handleDaysSelectedChange = (e, { value }) => {
    let _daysSelected = [...daysSelected];
    let index = _daysSelected.indexOf(value);
    if (index === -1) {
      _daysSelected.push(value);
    } else {
      _daysSelected.splice(index, 1);
    }
    setDaysSelected(_daysSelected);
  };

  useEffect(() => {
    if (currentAdditionalField.type === 'Text') {
      let af = taskSchema.properties.tasks.items.properties.additionalFields.items.oneOf.find(
        af => {
          return af.properties.type.enum[0] === 'Text';
        }
      );
      setCurrentAddtionalField({
        ...currentAdditionalField,
        display: af.properties.display.enum[0]
      });
    }
  }, [currentAdditionalField.type]);

  useEffect(() => {
    if (originalTask.taskDays) {
      setDaysSelected(originalTask.taskDays);
    }
    setTask({ ...originalTask });
  }, [originalTask]);

  useEffect(() => {
    if (isModalOpen) {
      // nameRef.current.focus();
    }
  }, [isModalOpen]);

  return (
    <Modal open={isModalOpen} onClose={handleModalClose}>
      <KeyboardEventHandler
        handleKeys={['n']}
        onKeyEvent={handleAddPropertyStartKeyboard}
      />
      <Modal.Header>
        <Grid columns={2}>
          <Grid.Column>Add Task</Grid.Column>
          <Grid.Column textAlign="right">
            <Button primary onClick={handleAdd}>
              Save
            </Button>
          </Grid.Column>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleAdd} className="task-form">
          <Form.Field>
            <label>Task Name</label>
            <Ref innerRef={handleNameRef}>
              <Form.Input
                value={task.name}
                onChange={handleTaskNameChange}
              ></Form.Input>
            </Ref>
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Highlight"
              checked={task.isHighlighted}
              onChange={handleHighlightChange}
            />
          </Form.Field>
          <p>
            <strong>Selected Days</strong>
            <br />
            <em>
              (if no days are selected, task will be applied to every day)
            </em>
          </p>
          <Form.Group inline>
            {daysOfWeek.map(d => {
              return (
                <Form.Field key={d}>
                  <Checkbox
                    label={d}
                    value={d}
                    checked={daysSelected.indexOf(d) > -1}
                    onChange={handleDaysSelectedChange}
                  ></Checkbox>
                </Form.Field>
              );
            })}
          </Form.Group>
          <Form.Field>
            <label>Description</label>
            <TextArea
              value={task.description}
              onChange={handleTaskDescriptionChange}
            />
          </Form.Field>
        </Form>
        <Grid columns={2} verticalAlign="middle">
          <Grid.Column>
            <Header as="h3">Additional Fields</Header>
          </Grid.Column>
          <Grid.Column textAlign="right">
            {!isAddorEditProperty && (
              <Button onClick={handleAddPropertyStart} secondary>
                Add Property
              </Button>
            )}
          </Grid.Column>
        </Grid>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Required</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {task.additionalFields.map((af, afIndex) => {
              return (
                <Table.Row
                  key={afIndex}
                  className={
                    currentAdditionalField.index === afIndex ? 'selected' : ''
                  }
                >
                  <Table.Cell>{af.name}</Table.Cell>
                  <Table.Cell>{af.type}</Table.Cell>
                  <Table.Cell>{af.isRequired ? 'Y' : 'N'}</Table.Cell>
                  <Table.Cell>
                    <Icon
                      name="edit"
                      index={afIndex}
                      onClick={handleEditAdditionalField}
                    />
                    <Icon
                      name="delete"
                      index={afIndex}
                      onClick={handleDeleteAdditionalField}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>

        {isAddorEditProperty && (
          <Form
            onSubmit={handleSubmitAdditionalField}
            className="additional-properties"
          >
            <Grid
              columns={3}
              verticalAlign="bottom"
              className="addtional-property-type"
            >
              <Grid.Column>
                <Form.Field>
                  <label>Field Name</label>
                  <Form.Input
                    id="addtional-field-name"
                    value={currentAdditionalField.name}
                    onChange={handleNameChange}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field>
                  <label>Type</label>
                  <Dropdown
                    options={taskSchema.properties.tasks.items.properties.additionalFields.items.oneOf.map(
                      af => {
                        return {
                          text: af.properties.type.enum[0],
                          value: af.properties.type.enum[0]
                        };
                      }
                    )}
                    value={currentAdditionalField.type}
                    onChange={handleTypeChange}
                    placeholder="Type"
                    selection
                  ></Dropdown>
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Group inline>
                  <Form.Field>
                    <Checkbox
                      label="is required?"
                      checked={currentAdditionalField.isRequired ? true : false}
                      onChange={handleIsRequiredChange}
                    />
                  </Form.Field>
                </Form.Group>
              </Grid.Column>
            </Grid>
            <Form.Field>
              <label>Description</label>
              <TextArea
                value={currentAdditionalField.description}
                onChange={handleAdditionalFieldDescriptionChange}
              />
            </Form.Field>

            {taskSchema.properties.tasks.items.properties.additionalFields.items.oneOf.map(
              (af, afIndex) => {
                return (
                  <React.Fragment key={afIndex}>
                    {af.properties &&
                      Object.keys(af.properties).map(key => {
                        if (['name', 'type'].indexOf(key) > -1) {
                          return;
                        }
                        return (
                          <React.Fragment key={key}>
                            {currentAdditionalField.type ===
                              af.properties.type.enum[0] && (
                              <Form.Field key={key}>
                                <label>{key}</label>
                                {af.properties[key].type === 'number' ? (
                                  <Form.Input
                                    type="number"
                                    name={key}
                                    value={
                                      currentAdditionalField[key]
                                        ? currentAdditionalField[key]
                                        : ''
                                    }
                                    onChange={handleAdditionalFieldPropChange}
                                  />
                                ) : af.properties[key].type === 'string' &&
                                  af.properties[key].enum ? (
                                  <Dropdown
                                    selection
                                    options={af.properties[key].enum.map(o => {
                                      return {
                                        text: o,
                                        value: o
                                      };
                                    })}
                                    name={key}
                                    value={currentAdditionalField[key]}
                                    onChange={handleAdditionalFieldPropChange}
                                    placeholder="Select One"
                                  ></Dropdown>
                                ) : af.properties[key].type === 'array' ? (
                                  <TextArea
                                    name={key}
                                    value={
                                      currentAdditionalField[key]
                                        ? currentAdditionalField[key]
                                        : ''
                                    }
                                    onChange={handleAdditionalFieldPropChange}
                                  />
                                ) : (
                                  <></>
                                )}
                              </Form.Field>
                            )}
                          </React.Fragment>
                        );
                      })}
                  </React.Fragment>
                );
              }
            )}
            <Button
              className="save-property"
              type="submit"
              secondary
              disabled={
                !currentAdditionalField.name || !currentAdditionalField.type
                  ? true
                  : false
              }
            >
              {typeof currentAdditionalField.index === 'undefined' ? (
                <>Add</>
              ) : (
                <>Update</>
              )}
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </Form>
        )}
      </Modal.Content>
    </Modal>
  );
}

TaskForm.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleAddTask: PropTypes.func.isRequired,
  task: PropTypes.object
};
