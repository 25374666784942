import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Checkbox, Container, Header, Input, Loader, Segment, Table, TableBody, TableCell, TableRow } from "semantic-ui-react";
import usePageData from "../state-manipulation/hooks/usePageData";
import { setSelectedPage } from "../state-manipulation/reducers/page-data/types";
import AxiosWrapper from "../utils/AxiosWrapper";
import ContentList from "../components/ContentList";
import IsAuthorized from "../components/common/IsAuthorized";
import algoliasearch from 'algoliasearch/lite';
import { HitsPerPage, InstantSearch, useHits, useSearchBox } from 'react-instantsearch';
import AccessResource from "../components/common/AccessResource";
import { BuildAlgoliaSearchIndex } from "../api/TrainingAPI";

const searchClient = algoliasearch(process.env.ALGOLIA_APPLICATION_ID, process.env.ALGOLIA_API_KEY);

export default function TeamSiteSOPs({ selectedPage, pageName }) {
  const { company } = useParams();
  const [ SOPs, setSOPs] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const contactsRef = useRef();
  const [isPLTChecked, setIsPLTChecked] = useState(false);
  const [isHapaChecked, setIsHapaChecked] = useState(false);
  const [isHiMarkChecked, setIsHiMarkChecked] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [, pageDispatch] = usePageData();

  const searchRef = useRef();

  const handleBuildSOPSearch = async () => {
    let res = await BuildAlgoliaSearchIndex();
  }

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: selectedPage,
      },
    });
  }, []);


  return (
    <div className={"content-container"}>
      <Header as="h1">{pageName}</Header>

      <InstantSearch searchClient={searchClient} indexName="ltg_docs">
        <CustomSearchBox searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        <HitsPerPage
          items={[
            { label: '100 hits per page', value: 100, default: true },
            { label: '200 hits per page', value: 200 }
          ]}
          style={{ display: 'none' }}
        />
        {searchQuery && (
          <Checkbox style={{ marginLeft: "10px" }} label="Show Search Details" checked={showDetails} onChange={() => {
            setShowDetails(!showDetails);
          }} />
        )}
        <CustomHits company={company} showDetails={showDetails} />
      </InstantSearch>
      <IsAuthorized permission="hasTeamSiteEditGoogleDocs">
        <Segment>
          <Button as="a"
           href={`https://docs.google.com/spreadsheets/d/1rAwlWd82bNjyJYn11JNv3T-NLPVodoQZmClHQ6xBJ0U/edit#gid=292812001`}
           target="_blank">Edit Doc</Button>
          <Button onClick={handleBuildSOPSearch}>Build SOP Search Index</Button>
        </Segment>
      </IsAuthorized>
    </div>
  );
}

function Hit({ hit }) {
  return (
    <Table.Row>
      <Table.Cell>{hit.name}</Table.Cell>
    </Table.Row>
  );
}

function CustomHits(props) {
  const { company, showDetails } = props;
  const { hits, results, sendEvent } = useHits(props);

  const uniqueDocs = hits.filter((hit, index, array) => {
    const foundIndex = array.findIndex(a => {
      return a.slug === hit.slug;
    });
    return foundIndex === index;
  })

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            Name
          </Table.HeaderCell>
          <Table.HeaderCell>
            Category
          </Table.HeaderCell>
          <Table.HeaderCell>
            Google Drive
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {uniqueDocs && uniqueDocs.map((r, rIndex) => {
          return (
            <AccessResource key={rIndex} accessCompany={r.company} access={r.access} company={company}>
              <Table.Row>
                <Table.Cell>
                  <RenderLinkType url={`./${r.slug}`} target={r.target} name={r.name} />
                  {showDetails && (
                    <DisplayMatchLevel hit={r} property="body" />
                  )}
                </Table.Cell>
                <Table.Cell>
                  {r.category}
                </Table.Cell>
                <Table.Cell>
                <a
                    href={`https://docs.google.com/document/d/${r.googleDocId}`}
                    target="_blank"
                  >View in Drive</a>
                </Table.Cell>
              </Table.Row>
            </AccessResource>
          )
        })}
      </Table.Body>
    </Table>

  );
}

function RenderLinkType({ url, target, name }) {
  if (!target) {
    return <Link to={url}>{name}</Link>
  }
  return <a href={url} target={target}>{name}</a>
}

function DisplayMatchLevel({ hit, property }) {
  if (hit?._highlightResult[property].matchLevel === "full") {
    return <div dangerouslySetInnerHTML={{__html: hit._highlightResult[property].value}} />
  }

  return <></>
}

function CustomSearchBox(props) {
  const {searchQuery, setSearchQuery} = props;
  const { query, refine, clear, isSearchStalled } = useSearchBox(props);
  const inputRef = useRef(null);

  useEffect(() => {
    setQuery(searchQuery);
  }, [searchQuery])

  function setQuery(newQuery) {
    refine(newQuery);
  }
  return <Input
    placeholder="Search"
    value={searchQuery}
    onChange={(e, { value }) => {
      setSearchQuery(value);
    }}
    icon="search"
  />;
}

