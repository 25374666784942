import AxiosWrapper from '../utils/AxiosWrapper';

export async function PostTaskListTemplate({ company, taskListTemplate }) {
  try {
    let url = `${process.env.API_URL}/${company}/task-list-templates`;
    let res = await AxiosWrapper({
      method: 'POST',
      url: url,
      data: taskListTemplate,
      isAuthRoute: true
    });
    return res;
  } catch (e) {
    console.error(e);
  }
}

export async function PatchTaskListTemplate({ company, id, action, data }) {
  try {
    let url = `${process.env.API_URL}/${company}/task-list-templates/${id}`;
    let res = await AxiosWrapper({
      method: 'PATCH',
      url: url,
      data: {
        action: action,
        data: data
      },
      isAuthRoute: true
    });
    return res;
  } catch (e) {
    console.error(e);
  }
}

export async function GetTaskListTemplates({ company }) {
  try {
    let url = `${process.env.API_URL}/${company}/task-list-templates`;

    let res = await AxiosWrapper({
      method: 'GET',
      url: url,
      isAuthRoute: true
    });
    return res;
  } catch (e) {
    console.error(e);
  }
}

export async function GetTaskListTemplateMerged({ company, day }) {
  try {
    let url = `${process.env.API_URL}/${company}/task-list-templates/merged`;
    let options = [];

    if (day) {
      options.push(`day=${day}`);
    }

    if (options.length > 0) {
      url += `?${options.join('&')}`;
    }

    let res = await AxiosWrapper({
      method: 'GET',
      url: url,
      isAuthRoute: true
    });
    return res;
  } catch (e) {
    console.error(e);
  }
}
