import React, { useState, useEffect, ReactDOM } from 'react';
import {
  Menu,
  Loader,
  Header,
  Segment,
  Grid,
  Message,
  Table,
  Tab,
  Dimmer,
  Icon,
  Accordion,
  List,
  Checkbox,
  Dropdown,
  Button,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';

import moment from 'moment';
import 'moment-timezone';

import useUserData from '../../state-manipulation/hooks/useUserData';
import usePageData from '../../state-manipulation/hooks/usePageData';
import { setSelectedPage } from '../../state-manipulation/reducers/page-data/types';
import { LG_WIDTH } from '../../constants';
import { async } from 'q';
import { setUserInfo } from '../../state-manipulation/reducers/user-data/types';
import { Link } from 'react-router-dom';
import AxiosWrapper from '../../utils/AxiosWrapper';

export default function ManageUsers({ className }) {
  const [pageData, pageDispatch] = usePageData();

  const [userData, userDispatch] = useUserData();
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [permissionTypes, setPermissionTypes] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [activePanels, setActivePanels] = useState({});

  const getUsers = async () => {
    try {
      setIsLoading(true);
      let res = await AxiosWrapper({
        method: 'GET',
        url: `${process.env.API_URL}/users`,
        headers: {
          Authorization: `Basic ${userData.token}`,
        },
      });

      setUsers(res.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const getPermissionTypes = async () => {
    try {
      setIsLoading(true);
      let res = await AxiosWrapper({
        method: 'GET',
        url: `${process.env.API_URL}/permission-types`,
        headers: {
          Authorization: `Basic ${userData.token}`,
        },
      });

      // setPermissionTypes(res.data);
      setCompanies(res.data.properties.company.enum);
      let permissionProperties = res.data.properties.permissions.properties;
      let _permissionTypes = Object.keys(permissionProperties).map((key) => {
        return { ...permissionProperties[key], key: key };
      });

      setPermissionTypes(_permissionTypes);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const saveUserPermissions = async (e, { index, userid }) => {
    let user = users.find((u) => {
      return u.id === userid;
    });

    if (!user) {
      return;
    }

    try {
      setIsLoading(true);
      let res = await AxiosWrapper({
        method: 'PATCH',
        url: `${process.env.API_URL}/users/${userid}`,
        headers: {
          Authorization: `Basic ${userData.token}`,
        },
        data: user,
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleUserCompanyChange = (
    e,
    { label, companyid, userid, checked }
  ) => {
    let _users = JSON.parse(JSON.stringify(users));
    let user = _users.find((u) => {
      return u.id === userid;
    });

    if (checked === true) {
      user.experienceGroups.push({
        name: label,
        id: companyid,
      });
    } else {
      user.experienceGroups = user.experienceGroups.filter((eg) => {
        return eg.id !== companyid;
      });
    }

    setUsers(_users);
  };

  const handlePermissionTypeCheckChange = (
    e,
    { permissiontype, userid, checked, issection }
  ) => {
    let _users = JSON.parse(JSON.stringify(users));
    let user = _users.find((u) => {
      return u.id === userid;
    });

    user.userTags[permissiontype] = checked ? '1' : '0';

    if (issection) {
      Object.keys(user.userTags).forEach((key) => {
        if (key.search(permissiontype) > -1) {
          user.userTags[key] = checked ? '1' : '0';
        }
      });
    }

    setUsers(_users);
  };

  const handlePermissionTypeDropDownChange = (
    e,
    { permissiontype, userid, value }
  ) => {
    let _users = JSON.parse(JSON.stringify(users));
    let user = _users.find((u) => {
      return u.id === userid;
    });

    user.userTags[permissiontype] = value;
    setUsers(_users);
  };

  const handleAccoridian1Click = (e, { index }) => {
    let _activePanels = { ...activePanels };
    _activePanels[index] = !_activePanels[index] ? 1 : 0;
    setActivePanels(_activePanels);
  };

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: 'Manage Users',
      },
    });
  }, []);

  useEffect(() => {
    getUsers();
    getPermissionTypes();
  }, []);

  useEffect(() => {
    if (users.length === 0 || permissionTypes.length === 0) {
      return;
    }

    let _users = JSON.parse(JSON.stringify(users));

    _users.forEach((user) => {
      permissionTypes.forEach((pt) => {
        if (!user.userTags[pt.key]) {
          user.userTags[pt.key] = '0';
        }
      });
    });

    setUsers(_users);
  }, [permissionTypes]);

  return (
    <div className={'content-container ' + className}>
      <Header as="h1">Manage Users</Header>
      <Grid columns="1" style={{marginBottom: "10px"}}>
        <Grid.Row>
          <Grid.Column textAlign="right">
            <Link to={`/all/admin/manage-users/new`}>
              <Button primary>Add</Button>
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {users && (
        <div>
          <Segment>
            <Dimmer active={isLoading}>
              <Loader />
            </Dimmer>
            <Accordion exclusive={false}>
              {users &&
                users.map((user, userIndex) => {
                  return (
                    <div key={userIndex}>
                      <Accordion.Title
                        active={activePanels[userIndex] === 1}
                        index={userIndex}
                        onClick={handleAccoridian1Click}
                      >
                        <Header as="h4" className="for-table">
                          {user.firstName} {user.lastName}
                          <Icon
                            name={
                              activePanels[userIndex] === 1
                                ? 'caret down'
                                : 'caret right'
                            }
                          />
                        </Header>
                        <p>{user.email}</p>
                      </Accordion.Title>
                      <Accordion.Content active={activePanels[userIndex] === 1}>
                        <List>
                          {companies &&
                            companies.map((company, companyIndex) => {
                              let foundCompany = user.experienceGroups.find(
                                (eg) => {
                                  return (
                                    eg.name.toUpperCase() ===
                                    company.name.toUpperCase()
                                  );
                                }
                              );

                              return (
                                <Checkbox
                                  label={company.name}
                                  companyid={company.id}
                                  checked={foundCompany ? true : false}
                                  key={companyIndex}
                                  userid={user.id}
                                  onChange={handleUserCompanyChange}
                                />
                              );
                            })}
                        </List>
                        <Table>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>Type</Table.HeaderCell>
                              <Table.HeaderCell />
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {permissionTypes &&
                              permissionTypes.map(
                                (permissionType, permissionTypeIndex) => {
                                  return (
                                    <Table.Row
                                      key={permissionTypeIndex}
                                      className={
                                        permissionType.isSection
                                          ? 'permission-section'
                                          : ''
                                      }
                                    >
                                      <Table.Cell>
                                        {permissionType.label}
                                      </Table.Cell>
                                      <Table.Cell>
                                        {permissionType.enum && (
                                          <Dropdown
                                            placeholder="Select"
                                            value={
                                              user.userTags[permissionType.key]
                                            }
                                            permissiontype={permissionType.key}
                                            userid={user.id}
                                            onChange={
                                              handlePermissionTypeDropDownChange
                                            }
                                            options={permissionType.enum.map(
                                              (i) => {
                                                return {
                                                  key: i,
                                                  text: i,
                                                  value: i,
                                                };
                                              }
                                            )}
                                          />
                                        )}
                                        {permissionType.type === 'boolean' && (
                                          <Checkbox
                                            permissiontype={permissionType.key}
                                            userid={user.id}
                                            issection={
                                              permissionType.isSection
                                                ? 'true'
                                                : undefined
                                            }
                                            checked={
                                              user.userTags[
                                                permissionType.key
                                              ] === '1'
                                                ? true
                                                : false
                                            }
                                            onChange={
                                              handlePermissionTypeCheckChange
                                            }
                                          />
                                        )}
                                      </Table.Cell>
                                    </Table.Row>
                                  );
                                }
                              )}
                          </Table.Body>
                        </Table>
                        <Button
                          primary
                          index={userIndex}
                          onClick={saveUserPermissions}
                          userid={user.id}
                        >
                          Save
                        </Button>
                      </Accordion.Content>
                    </div>
                  );
                })}
            </Accordion>
          </Segment>
        </div>
      )}
    </div>
  );
}

ManageUsers.propTypes = {
  className: PropTypes.string,
};

ManageUsers.defaultProps = {
  className: '',
};
