import React from "react";
import IsAuthorized from "./IsAuthorized";

export default function AccessResource({ accessCompany, access, company, children }) {
  if (access && accessCompany && accessCompany === company) {
    return <IsAuthorized permission={access}>{children}</IsAuthorized>;
  } else if (access && !accessCompany) {
    return <IsAuthorized permission={access}>{children}</IsAuthorized>;
  } else if ((accessCompany.length > 1)  && !accessCompany.split(',').includes(company.toUpperCase())) {
    return <></>;
  }
  return children;
}