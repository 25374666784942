import moment from "moment";
import React, { createContext } from "react";
import { useState } from "react";

export const DateRangeContext = createContext({});

export const DateRangeProvider = ({ children }) => {

  const currentHour = moment().tz("America/New_York").hours();
  const defaultStartDate =
    currentHour >= 0 && currentHour < 4
      ? moment().subtract(1, "days")
      : moment();
  const defaultEndDate =
    currentHour >= 0 && currentHour < 4
      ? moment().subtract(1, "days")
      : moment();
  const [dateStart, setDateStart] = useState(
    moment(defaultStartDate).add(-5, "days").format("MM/DD/YY")
  );
  const [dateEnd, setDateEnd] = useState(defaultEndDate.format("MM/DD/YY"));

  const [days, setDays] = useState(0);
  return (
    <DateRangeContext.Provider value={{ dateStart, setDateStart, dateEnd, setDateEnd, days, setDays }}>
      {children}
    </DateRangeContext.Provider>
  );
}
