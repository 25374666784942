import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import useUserData from "../../state-manipulation/hooks/useUserData";
import { useNavigate } from "react-router-dom";

export default function IsAuthorized({ permission, redirect, children, notAuthorizedContent }) {

  const [userData] = useUserData();
  const navigate = useNavigate();

  if (userData?.userInfo?.userTags[permission] !== "1") {

    if (redirect) {
      navigate(`/${userData.orgs[0].name.toLowerCase()}/${
        userData.userInfo.userTags.homeScreen
      }`, { replace: true });
    } else if (notAuthorizedContent) {
      return notAuthorizedContent;
    }

    return <></>;
  }

  return children;
}

IsAuthorized.propTypes = {
  permission: PropTypes.string,
  redirect: PropTypes.bool
};

IsAuthorized.defaultProps = {
  redirect: false
}