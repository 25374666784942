import React, { useState, useEffect, useContext } from "react";
import MediaQuery from "react-responsive";

import {
  BrowserRouter as Router,
  Route,
  Link,
  Navigate,
  Routes,
  Outlet,
} from "react-router-dom";
import {
  Container,
  Header,
  Sidebar,
  Icon,
  Label,
  Menu,
  Dropdown,
  Grid,
} from "semantic-ui-react";
import PropTypes from "prop-types";

import useUserData from "../state-manipulation/hooks/useUserData";
import {
  clearUser,
  selectCurrentOrgByName,
  selectCurrentOrg,
  setUserInfo,
  setIsAdmin,
  setIsSuperAdmin,
  setUserOrgs,
  setEmployeeByPin,
  setUserScreenLocked,
} from "../state-manipulation/reducers/user-data/types";
import { LG_WIDTH } from "../constants";

import "./style/StandardLayout.scss";
import SalesOverview from "../components/Sales/SalesOverview";
import usePageData from "../state-manipulation/hooks/usePageData";
import ProductMix from "../components/Sales/ProductMix";
import DateRangeDataProvider from "../state-manipulation/providers/DateRangeDataProvider";
import Discounts from "../components/Sales/Discounts";
import ManageDraftList from "../components/drafts/ManageDraftList";
import ManageVendors from "../components/checks/ManageVendors";
import ManagePayments from "../components/checks/ManagePayments";
import ViewLabor from "../components/labor/ViewLabor";
import PricingCalculator from "../components/pricing-calculator/PricingCalculator";
import SevenShiftsSync from "../components/employees/SevenShiftsSync";
import ManageUsers from "../components/super-admin/ManageUsers";
import CashTransactions from "../components/cash/CashTransactions";
import isAnyBoolTrue from "../utils/isAnyBoolTrue";
import StockPots from "../pages/StockPots";
import PrintChecks from "../components/checks/PrintChecks";
import AddPayment from "../components/checks/AddPayment";
import ScreenLockLogin from "../components/forms/ScreenLockLogin";
import Voids from "../components/Sales/Voids";
import DailyTasks from "../components/tasks/DailyTasks";
import CashCount from "../components/cash/CashCount";
import TaskListTemplates from "../pages/TaskListTemplates";
import TaskListForm from "../components/tasks/TaskListForm";
import TaskListTemplate from "../pages/TaskListTemplate";
import TaskLists from "../pages/TaskLists";
import TaskList from "../pages/TaskList";
import TaskListTemplateMerged from "../pages/TaskListTemplateMerged";
import SalesStats from "../components/Sales/SalesStats";
import { version } from "../../package.json";
import ChangeLog from "../pages/ChangeLog";
import UserForm from "../pages/UserForm";
import Refrigerators from "../pages/Refrigerators";
import Alerts from "../pages/Alerts";
import { GetAlerts } from "../api/AlertAPI";
import SalesAndDiscountsMenu from "../components/menus/SalesAndDiscountsMenu";
import TasksMenu from "../components/menus/TasksMenu";
import BarToolsMenu from "../components/menus/BarToolsMenu";
import EmployeesMenu from "../components/menus/EmployeesMenu";
import CashAndPaymentsMenu from "../components/menus/CashAndPaymentsMenu";
import IoTMenu from "../components/menus/IoTMenu";
import IsAuthorized from "../components/common/IsAuthorized";
import AxiosWrapper from "../utils/AxiosWrapper";
import { useNavigate } from "react-router-dom";
import TeamSiteMenu from "../components/menus/TeamSiteMenu";
import AdminMenu from "../components/menus/AdminMenu";
import { useRef } from "react";
import TrainingMenu from "../components/menus/TrainingMenu";
import PrepMenu from "../components/menus/PrepMenu";

export default function StandardLayout({
  showHamburgerMenu,
  animationType,
  alwaysVisible,
  neverDimmed,
}) {
  const [pageData] = usePageData();
  const [visible, setVisible] = useState(false);
  const [company, setCompany] = useState("");
  const [userData, userDispatch] = useUserData();
  const [orgDropdownOptions, setOrgDropdownOptions] = useState([]);
  const [redirectLocation, setRedirectLocation] = useState("");
  const [isScreenLocked, setIsScreenLocked] = useState(true);
  const [timeOutId, setTimeoutId] = useState("");
  const [alertCount, setAlertCount] = useState(0);
  const navigate = useNavigate();
  const alertIntervalId = useRef();

  const updateUserAccess = async () => {
    try {
      let res = await AxiosWrapper({
        method: "GET",
        url: `${process.env.API_URL}/me`,
        headers: {
          Authorization: `Basic ${userData.token}`,
        },
      }).catch((e) => {
        if (e.response.status === 401) {
          userDispatch({
            type: clearUser,
            payload: {},
          });
        }
      });

      const { email, firstName, lastName, avatarUrl, userTags } = res.data.user;
      userDispatch({
        type: setUserInfo,
        payload: {
          userInfo: { email, firstName, lastName, avatarUrl, userTags },
        },
      });

      const { isAdmin = false } = res.data.user.userTags;
      userDispatch({
        type: setIsAdmin,
        payload: {
          isAdmin: isAnyBoolTrue(isAdmin),
        },
      });

      const { isSuperAdmin = false } = res.data.user.userTags;
      userDispatch({
        type: setIsSuperAdmin,
        payload: {
          isSuperAdmin: isAnyBoolTrue(isSuperAdmin),
        },
      });

      const { experienceGroups } = res.data.user;
      userDispatch({
        type: setUserOrgs,
        payload: {
          orgs: experienceGroups,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleShowClick = () => {
    setVisible(true);
  };

  const handleHideClick = () => {
    setVisible(false);
  };

  const handleToggleSlider = () => {
    setVisible(visible ? false : true);
  };

  const handleLogOut = () => {
    userDispatch({
      type: clearUser,
      payload: {},
    });
  };

  const handleOrgDropdownChange = (e, { value }) => {
    // TODO: see if there's a react router way to get current route
    const urlParts = window.location.pathname.split("/");

    urlParts.shift();
    urlParts.shift();
    const selectedOrgName = value;
    urlParts[0] = selectedOrgName;

    if (selectedOrgName) {
      userDispatch({
        type: selectCurrentOrgByName,
        payload: {
          selectedOrgName: selectedOrgName,
        },
      });
    }

    navigate(`/${urlParts.join("/")}`, { replace: true });
    handleToggleSlider();
  };

  const handleTopMenuClick = () => {
    if (visible) {
      handleToggleSlider();
    }
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleEmployeeByPinLogin = (emp) => {
    userDispatch({
      type: setEmployeeByPin,
      payload: {
        employeeByPin: emp,
      },
    });

    userDispatch({
      type: setUserScreenLocked,
      payload: {
        isScreenLocked: false,
      },
    });

    // clearTimeout(timeOutId);
    // setTimeoutId(setTimeout(()=>{
    //     setIsScreenLocked(true);
    //   }, 1000 * 60 * 3));
  };

  const handleLockScreen = () => {
    userDispatch({
      type: setUserScreenLocked,
      payload: {
        isScreenLocked: true,
      },
    });
  };

  // set user selected org based on URL
  useEffect(() => {
    const urlParts = window.location.pathname.split("/");
    const selectedOrgName = decodeURI(urlParts[2]);

    if (selectedOrgName) {
      userDispatch({
        type: selectCurrentOrgByName,
        payload: {
          selectedOrgName: selectedOrgName,
        },
      });
    } else {
      userDispatch({
        type: selectCurrentOrg,
        payload: {
          selectedOrg: userData.orgs[0],
        },
      });
    }
  }, []);

  // set page data
  useEffect(() => {
    updateUserAccess();
  }, []);

  useEffect(() => {

    if (
      !userData.token ||
      !userData.userInfo ||
      !userData.userInfo.userTags ||
      !userData.userInfo.userTags.homeScreen
    ) {
      userDispatch({
        type: clearUser,
        payload: {},
      });
    }
  }, [userData]);

  useEffect(() => {
    if (
      !userData.selectedOrg ||
      userData.selectedOrg.name.trim().length === 0
    ) {
      return;
    }
    setCompany(userData.selectedOrg.name.toLowerCase());
  }, [userData.selectedOrg]);

  useEffect(() => {
    setOrgDropdownOptions(
      userData.orgs.map((o) => {
        return {
          text: o.name,
          value: o.name.toLowerCase(),
        };
      })
    );
  }, [userData.orgs]);

  useEffect(() => {
    setIsScreenLocked(userData.isScreenLocked);
  }, [userData.isScreenLocked]);

  useEffect(() => {
    if (!userData.isScreenLocked) {
      if (
        userData.userInfo &&
        userData.userInfo.userTags &&
        isAnyBoolTrue(userData.userInfo.userTags.isSharedAccount)
      ) {
        let duration = 1000 * 60 * 3;
        switch (pageData.selectedPage.toUpperCase()) {
          case "CASH COUNT":
            duration = 1000 * 60 * 15;
            break;
          case "DRAFT LIST":
            duration = 1000 * 60 * 60;
            break;
        }
        clearTimeout(timeOutId);
        setTimeoutId(
          setTimeout(() => {
            userDispatch({
              type: setUserScreenLocked,
              payload: {
                isScreenLocked: true,
              },
            });
          }, duration)
        );
      }
    }
  }, [pageData.selectedPage]);

  // redirect if /app is hit
  useEffect(() => {
    if (window.location.pathname === "/app" && company) {
      setRedirectLocation(`/${company}/home`);
    }
  }, [company]);

  useEffect(() => {
    if (
      userData.userInfo &&
      userData.userInfo.userTags &&
      !isAnyBoolTrue(userData.userInfo.userTags.isSharedAccount)
    ) {
      userDispatch({
        type: setUserScreenLocked,
        payload: {
          isScreenLocked: false,
        },
      });
    }
  }, [userData.userInfo.userTags.isSharedAccount]);

  const getAlerts = async () => {
    let res = await GetAlerts({ company: company });
    setAlertCount(res.data.totalCount);
  };

  useEffect(() => {
    if (company) {
      getAlerts();
      alertIntervalId.current = setInterval(() => {
        getAlerts();
      }, 30000);
    }
    return () => clearInterval(alertIntervalId.current);
  }, [company]);

  return (
    <>
      {!isScreenLocked && (
        <Sidebar.Pushable
          className="menu-sidebar"
          style={{ transform: "none" }}
        >
          <Sidebar
            as={Menu}
            animation={animationType}
            vertical
            visible={alwaysVisible ? true : visible}
            className="main-menu"
          >
            <Container style={{ marginTop: "20px" }}>
              {userData.employeeByPin && userData.employeeByPin.firstName ? (
                <Label as="h2" color="teal">
                  {userData.employeeByPin.firstName}{" "}
                  {userData.employeeByPin.lastName}
                </Label>
              ) : (
                <>
                  <Header as="h3">
                    {userData.userInfo.firstName} {userData.userInfo.lastName}
                  </Header>
                  <small>{userData.userInfo.email}</small>
                </>
              )}
            </Container>
            <Menu.Item></Menu.Item>
            <Menu.Item>
              <Dropdown
                fluid
                selection
                onChange={handleOrgDropdownChange}
                value={
                  userData.selectedOrg &&
                  userData.selectedOrg.name.toLowerCase()
                }
                options={orgDropdownOptions}
              ></Dropdown>
            </Menu.Item>
            <Menu.Item
              as={Link}
              to={"/" + company + "/home"}
              onClick={handleToggleSlider}
            >
              <Icon name="home" />
              Home
            </Menu.Item>
            {userData.userInfo.userTags &&
              userData.userInfo.userTags.hasAlerts === "1" && (
                <Menu.Item
                  as={Link}
                  to={"/" + company + "/alerts"}
                  onClick={handleToggleSlider}
                  active={
                    pageData.selectedPage.toUpperCase() === "ALERTS"
                      ? true
                      : false
                  }
                >
                  <Icon name="alarm" />
                  Alerts
                  {alertCount > 0 && <Label color="red">{alertCount}</Label>}
                </Menu.Item>
              )}
            <TeamSiteMenu onClick={handleToggleSlider} />
            <SalesAndDiscountsMenu onClick={handleToggleSlider} />
            <TasksMenu onClick={handleToggleSlider} />
            <PrepMenu onClick={handleToggleSlider} />
            <BarToolsMenu onClick={handleToggleSlider} />
            <EmployeesMenu onClick={handleToggleSlider} />
            <TrainingMenu onClick={handleToggleSlider} />
            <CashAndPaymentsMenu onClick={handleToggleSlider} />
            <IoTMenu onClick={handleToggleSlider} />
            <AdminMenu  onClick={handleToggleSlider} />
            <IsAuthorized permission="hasManageUsers">
              <Menu.Item
                as={Link}
                to={"/all/admin/manage-users"}
                onClick={handleToggleSlider}
                active={
                  pageData.selectedPage.toUpperCase() === "MANAGE USERS"
                    ? true
                    : false
                }
              >
                <Icon name="users" />
                Manage Users
              </Menu.Item>
            </IsAuthorized>
            <Menu.Item as="a" onClick={handleRefresh}>
              <Icon name="refresh" />
              Refresh App
            </Menu.Item>
            {((userData.userInfo.userTags &&
              !isAnyBoolTrue(userData.userInfo.userTags.isSharedAccount)) ||
              (userData.employeeByPin &&
                userData.employeeByPin.canLogOutOfSharedAccount === "1")) && (
              <Menu.Item as="a" onClick={handleLogOut}>
                <Icon name="log out" />
                Log out
              </Menu.Item>
            )}
            {userData.userInfo.userTags &&
              isAnyBoolTrue(userData.userInfo.userTags.isSharedAccount) && (
                <Menu.Item as="a" onClick={handleLockScreen}>
                  <Icon name="lock" />
                  Lock Screen
                </Menu.Item>
              )}
            <Menu.Item
              className="app-info"
              as={Link}
              to="/all/change-log"
              onClick={handleToggleSlider}
            >
              Version {version}
            </Menu.Item>
            <Menu.Item
              className="app-info"
              as="a"
              href={process.env.APP_URL}
              target="_blank"
            >
              {process.env.APP_URL}
            </Menu.Item>
          </Sidebar>
          <Grid
            celled
            className="top-menu"
            onClick={handleTopMenuClick}
            style={{
              position: "sticky",
              top: 0,
              zIndex: 101,
              backgroundColor: "white",
            }}
          >
            <Grid.Row>
              <Grid.Column>
                <MediaQuery maxWidth={LG_WIDTH}>
                  <Icon
                    name="bars"
                    className="hamburger-menu"
                    onClick={handleToggleSlider}
                    size="large"
                  />
                </MediaQuery>
                {pageData.selectedPage}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Sidebar.Pusher
            dimmed={neverDimmed ? false : visible}
            onClick={handleHideClick}
            className={`main-content-container ${pageData.selectedPage
              .split(" ")
              .join("-")
              .toLowerCase()}`}
          >
            <Outlet />
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      )}
      {isScreenLocked && (
        <ScreenLockLogin successFunction={handleEmployeeByPinLogin} />
      )}
    </>
  );
}

StandardLayout.propTypes = {
  showHamburgerMenu: PropTypes.bool,
  animationType: PropTypes.string,
  alwaysVisible: PropTypes.bool,
  neverDimmed: PropTypes.bool,
  isMobile: PropTypes.bool,
};

StandardLayout.defaultProps = {
  showHamburgerMenu: true,
  animationType: "overlay",
  alwaysVisible: false,
  neverDimmed: false,
  isMobile: false,
};
