import React from "react";
import { Grid } from "semantic-ui-react";
import { RadialChart, DiscreteColorLegend } from "react-vis/dist";
import PropTypes from "prop-types";

export default function PieChart({ data, width, height, legendWidth, legendHeight }) {
  return (
    <Grid columns="2">
      <Grid.Column textAlign="right">
        <RadialChart data={data} width={width} height={height} className="pie-chart" colorType='literal' />
      </Grid.Column>
      <Grid.Column verticalAlign="middle" textAlign="left" className="legend-column">
        <DiscreteColorLegend height={legendHeight} width={legendWidth} items={data} />
      </Grid.Column>
    </Grid>
  );
}

PieChart.propTypes = {
  data: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
  legendWidth: PropTypes.number,
  legendHeight: PropTypes.number
};

PieChart.defaultProps = {
  width: 150,
  height: 150,
  legendWidth: 200,
  legendHeight: 100
};
