export const SM_WIDTH = 576;
export const MD_WIDTH = 768;
export const LG_WIDTH = 992;
export const XL_WIDTH = 1200;
export const MARGIN_SM = "10px";
export const MARGIN_MD = "20px";
export const MARGIN_LG ="40px";
export const RED_RIBBON = "#e40c2b";
export const STEEL_GRAY = "#1d1d2c";
export const MERINO = "#f7f4e9";
export const PELOROUS = "#3cbcc3";
export const TULIP_TREE = "#eba63f";
export const PEACH_ORANGE = "#FED394";
export const GOBLIN = "#438945";
export const PASTEL_GREEN = "#90EE90";
export const GALLERY_GRAY = "#ededed";
export const ALTO_GRAY = "#e0e0e0";
export const ALTO_GRAY_DARKER = "#e0e0e0";
export const ALABASTER = "#f9f9f9";