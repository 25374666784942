import React, { useState, useEffect } from "react";
import {
  Menu,
  Loader,
  Header,
  Segment,
  Grid,
  Message,
  Table,
  Tab,
  Dimmer,
  Accordion,
  Icon,
} from "semantic-ui-react";
import PropTypes from "prop-types";

import "./styles/Sales.scss";

import moment from "moment";
import useUserData from "../../state-manipulation/hooks/useUserData";
import usePageData from "../../state-manipulation/hooks/usePageData";
import { setSelectedPage } from "../../state-manipulation/reducers/page-data/types";
import { LG_WIDTH } from "../../constants";
import NumberFormat from "react-number-format";
import PieChart from "../charts/PieChart";

import DateRangeSelector from "../common/DateRangeSelector";
import useDateRangeData from "../../state-manipulation/hooks/useDateRangeData";
import DateMenu from "../common/DateMenu";
import { clearUser } from "../../state-manipulation/reducers/user-data/types";
import PageHeader from "../common/PageHeader";
import AxiosWrapper, { AxiosAbort } from "../../utils/AxiosWrapper";

export default function ProductMix({ className }) {
  const [pageData, pageDispatch] = usePageData();
  const [dateRangeData, dateRangeDispatch] = useDateRangeData();

  const [days, setDays] = useState(0);
  const [userData, userDispatch] = useUserData();
  const [isLoading, setIsLoading] = useState(false);
  const [productMix, setProductMix] = useState([]);
  const [salesByRevenueCenter, setSalesByRevenueCenter] = useState([]);
  const [salesByDayPart, setSalesByDayPart] = useState([]);
  const [revenueClassBreakdown, setRevenueClassBreakdown] = useState([]);
  const [activePanels, setActivePanels] = useState({});
  const [timeOutId, setTimeoutId] = useState("");

  const handleAccoridianClick = (e, { index }) => {
    let _activePanels = { ...activePanels };
    _activePanels[index] = !_activePanels[index] ? 1 : 0;
    setActivePanels(_activePanels);
  };

  const getProductMix = async (company, _dateStart, _dateEnd) => {
    try {
      setIsLoading(true);
      let res = await AxiosWrapper({
        method: "GET",
        url: `${process.env.API_URL}/${company}/product-mix?startDate=${_dateStart}&endDate=${_dateEnd}`,
        headers: {
          Authorization: `Basic ${userData.token}`,
        },
      }).catch((e) => {
        if (e.response.status === 401) {
          userDispatch({
            type: clearUser,
            payload: {},
          });
        }
      });

      let _productMix = res.data.data.productMix.sort((a, b) => {
        return a.revenueClass - b.revenueClass;
      });

      setProductMix(_productMix);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: "Product Mix",
      },
    });
  }, []);

  useEffect(() => {}, []);

  useEffect(() => {
    let _days =
      moment(dateRangeData.dateEnd).diff(moment(dateRangeData.dateStart)) / 1000 / 60 / 60 / 24;

    if (_days >= 0 && _days <= 60 && userData.selectedOrg) {
      clearTimeout(timeOutId);
      setTimeoutId(
        setTimeout(() => {
          getProductMix(
            userData.selectedOrg.name.toLowerCase(),
            dateRangeData.dateStart,
            dateRangeData.dateEnd
          );
        }, 1000)
      );
    }
    setDays(_days);
    return () => {
      clearTimeout(timeOutId);
      AxiosAbort();
    }
  }, [dateRangeData, userData.selectedOrg]);

  return (
    <div className={"content-container " + className}>
      <DateMenu />
      <PageHeader pageName="Product Mix" orgName={userData.selectedOrg?.name} />
      {days > 60 && <Message warning>Date range must be 60 days or less.</Message>}
      {days < 0 && <Message warning>End date must be the same or after start date.</Message>}
      <DateRangeSelector />
      {productMix && (
        <div>
          <Segment>
            <Dimmer active={isLoading}>
              <Loader />
            </Dimmer>

            {productMix &&
              productMix.map((pm, index) => {
                return (
                  <div key={index}>
                    <Header as="h3" className="for-table">
                      {pm.revenueClass}
                    </Header>
                    {pm.itemGroups &&
                      pm.itemGroups.map((itemGroup, index2) => {
                        let total = itemGroup.items.reduce((a, b) => a + b.qty, 0);
                        return (
                          <div key={index2}>
                            <Accordion exclusive={false}>
                              <Accordion.Title
                                active={activePanels[`${index}-${index2}`] === 1}
                                index={`${index}-${index2}`}
                                onClick={handleAccoridianClick}
                              >
                                <Grid>
                                  <Grid.Column mobile={10}>
                                    <Header as="h4" className="for-table">
                                      {itemGroup.itemGroupName}
                                      <Icon
                                        name={
                                          activePanels[`${index}-${index2}`] === 1
                                            ? "caret down"
                                            : "caret right"
                                        }
                                      />
                                    </Header>
                                  </Grid.Column>
                                  <Grid.Column mobile={6}>
                                    <p>Total: {total}</p>
                                  </Grid.Column>
                                </Grid>
                              </Accordion.Title>
                              <Accordion.Content active={activePanels[`${index}-${index2}`] === 1}>
                                <Table celled unstackable className="item-group">
                                  <Table.Header>
                                    <Table.Row>
                                      <Table.HeaderCell>Item</Table.HeaderCell>
                                      <Table.HeaderCell className="qty" textAlign="center">
                                        Qty
                                      </Table.HeaderCell>
                                    </Table.Row>
                                  </Table.Header>
                                  <Table.Body>
                                    {itemGroup.items &&
                                      itemGroup.items.map((item, itemIndex) => {
                                        return (
                                          <Table.Row key={itemIndex}>
                                            <Table.Cell>{item.name}</Table.Cell>
                                            <Table.Cell textAlign="center">{item.qty}</Table.Cell>
                                          </Table.Row>
                                        );
                                      })}
                                  </Table.Body>
                                </Table>
                              </Accordion.Content>
                            </Accordion>
                          </div>
                        );
                      })}
                  </div>
                );
              })}
          </Segment>
        </div>
      )}
    </div>
  );
}

ProductMix.propTypes = {
  className: PropTypes.string,
};

ProductMix.defaultProps = {
  className: "",
};
