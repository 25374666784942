import React from "react";
import { Link, useParams } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";
import usePageData from "../../state-manipulation/hooks/usePageData";
import useUserData from "../../state-manipulation/hooks/useUserData";
import IsAuthorized from "../common/IsAuthorized";

export default function TrainingMenu({ onClick }) {
  let { company } = useParams();
  const [pageData] = usePageData();
  const [userData, userDispatch] = useUserData();

  if (!company) {
    company = userData.orgs[0].name.toLowerCase();
  }

  return (
    <IsAuthorized permission="hasTraining">
      <Menu.Item>
        <Menu.Header>Training</Menu.Header>
        <Menu.Menu>
          <Menu.Item
            as={Link}
            to={"/" + company + "/training/me"}
            onClick={onClick}
            active={
              pageData.selectedPage.toUpperCase() === "MY TRAINING"
                ? true
                : false
            }
          >
            <Icon name="gamepad" />
            My Training Status
          </Menu.Item>
          <IsAuthorized permission="hasTrainingAdmin">
          <Menu.Item
            as={Link}
            to={"/" + company + "/training/admin"}
            onClick={onClick}
            active={
              pageData.selectedPage.toUpperCase() === "TRAINING ADMIN"
                ? true
                : false
            }
          >
            <Icon name="gamepad" />
            Admin
          </Menu.Item>
          </IsAuthorized>
        </Menu.Menu>
      </Menu.Item>
    </IsAuthorized>
  );
}
