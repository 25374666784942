import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import usePageData from '../../state-manipulation/hooks/usePageData';
import { setSelectedPage } from '../../state-manipulation/reducers/page-data/types';
import {
  Header,
  Segment,
  Dimmer,
  Loader,
  Form,
  Button,
  Progress,
  Input
} from 'semantic-ui-react';
import { LG_WIDTH } from '../../constants';
import useUserData from '../../state-manipulation/hooks/useUserData';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import PageHeader from '../common/PageHeader';
import AxiosWrapper from '../../utils/AxiosWrapper';

export default function AddPayment({ className }) {
  const [pageData, pageDispatch] = usePageData();
  const [userData, userDispatch] = useUserData();
  const [isLoading, setIsLoading] = useState(false);
  const [checkDate, setCheckDate] = useState(moment().format('MM/DD/YY'));
  const [vendorsList, setVendorsList] = useState([]);
  const [purchaseAccounts, setPurchaseAccounts] = useState([]);
  const [purchaseAccountCode, setPurchaseAccountCode] = useState('');
  const [purchaseAccountName, setPurchaseAccountName] = useState('');
  const [vendorId, setVendorId] = useState('');
  const [vendorName, setVendorName] = useState('');
  const [amount, setAmount] = useState('');
  const [isAddPaymentDisabled, setIsAddPaymentDisabled] = useState(true);
  const [checkNumber, setCheckNumber] = useState('');
  const [memo, setMemo] = useState('');
  const [printerInfo, setPrinterInfo] = useState({});
  const [printStatusMessage, setPrintStatusMessage] = useState('');
  const [isPrintError, setIsPrintError] = useState(false);
  const [statusPercentage, setStatusPercentage] = useState(0);
  const [dontResetVendor, setDontResetVendor] = useState(false);
  const [isACH, setIsACH] = useState(false);

  const getVendors = async company => {
    try {
      setIsLoading(true);
      let res = await AxiosWrapper({
        method: 'GET',
        url: `${process.env.API_URL}/${company}/vendors`,
        headers: {
          Authorization: `Basic ${userData.token}`
        }
      });

      let vendors = res.data.map(v => {
        return {
          text: v.Name,
          key: v.ContactID,
          value: v.ContactID,
          name: v.Name,
          purchasesdefaultaccountcode: v.PurchasesDefaultAccountCode,
          contactid: v.ContactID
        };
      });
      setVendorsList(vendors);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const getPurchaseAccounts = async company => {
    try {
      setIsLoading(true);
      let res = await AxiosWrapper({
        method: 'GET',
        url: `${process.env.API_URL}/${company}/purchase-accounts`,
        headers: {
          Authorization: `Basic ${userData.token}`
        }
      });

      let purchaseAccountCodes = res.data.map(v => {
        return {
          text: v.Name,
          key: v.Code,
          value: v.Code,
          name: v.Name,
          code: v.Code
        };
      });
      setPurchaseAccounts(purchaseAccountCodes);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleCheckDateChange = (e, { value }) => {
    setCheckDate(value);
  };

  const handleAmountChange = (e, { value }) => {
    setAmount(value);
  };

  const handleCheckNumberChange = (e, { value }) => {
    setCheckNumber(value);
  };

  const handleVendorChange = (e, { value }) => {
    setVendorId(value);
  };

  const handleVendorAddition = (e, { value }) => {
    setVendorsList([
      { text: value, name: value, value: -1, contactid: -1 },
      ...vendorsList
    ]);
    setVendorId(-1);
  };

  const handlePurchaseAccountsChange = (e, { value }) => {
    setPurchaseAccountCode(value);
  };

  const handleMemoChange = (e, { value }) => {
    setMemo(value);
  };

  const validateForm = () => {
    if (amount <= 0) {
      setIsAddPaymentDisabled(true);
      return;
    }
    if (checkNumber <= 0 && !isACH) {
      setIsAddPaymentDisabled(true);
      return;
    }
    if (!checkDate) {
      setIsAddPaymentDisabled(true);
      return;
    }
    if (!vendorId) {
      setIsAddPaymentDisabled(true);
      return;
    }
    if (!purchaseAccountCode) {
      setIsAddPaymentDisabled(true);
      return;
    }
    if (!memo) {
      setIsAddPaymentDisabled(true);
      return;
    }
    setIsAddPaymentDisabled(false);
  };

  const addPayment = async () => {
    try {
      let data = {
        checkDate: checkDate,
        checkNumber: checkNumber,
        isACH: isACH,
        amount: amount,
        vendorId: vendorId,
        vendorName: vendorName,
        purchaseAccountCode: purchaseAccountCode,
        purchaseAccount: purchaseAccountName,
        memo: memo
      };

      setStatusPercentage(50);
      setIsAddPaymentDisabled(true);
      setPrintStatusMessage('Adding...');
      let res = await AxiosWrapper({
        method: 'POST',
        url: `${
          process.env.API_URL
        }/${userData.selectedOrg.name.toLowerCase()}/checks`,
        headers: {
          Authorization: `Basic ${userData.token}`
        },
        data: data
      });

      setIsAddPaymentDisabled(false);
      setIsPrintError(false);
      setStatusPercentage(100);
      resetCheckFields();
      setPrintStatusMessage('Success');
      setTimeout(resetStatusIndicators, 2000);
    } catch (error) {
      setIsAddPaymentDisabled(false);
      setIsPrintError(true);
      setStatusPercentage(100);
      setPrintStatusMessage('Error');
      console.error(error);
    }
  };

  const resetCheckFields = () => {
    setAmount('');
    if (!dontResetVendor) {
      setVendorId('');
      setIsACH(false);
      setPurchaseAccountCode('');
    }
    setCheckNumber('');
    setMemo('');
  };

  const resetStatusIndicators = () => {
    setStatusPercentage(0);
    setIsPrintError(false);
    setPrintStatusMessage('');
  };

  const handleDontResetVendorChange = (e, { checked }) => {
    setDontResetVendor(checked);
  };

  const handleIsACHChange = (e, { checked }) => {
    setIsACH(checked);
  };

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: 'Add Payments'
      }
    });
  }, []);

  useEffect(() => {
    if (userData.selectedOrg) {
      getVendors(userData.selectedOrg.name.toLowerCase());
      getPurchaseAccounts(userData.selectedOrg.name.toLowerCase());
    }
  }, [userData.selectedOrg]);

  useEffect(() => {
    validateForm();
  }, [
    checkDate,
    checkNumber,
    amount,
    vendorId,
    purchaseAccountCode,
    memo,
    isACH
  ]);

  useEffect(() => {
    let _vendor = vendorsList.find(v => {
      return v.contactid === vendorId;
    });

    if (_vendor) {
      setVendorName(_vendor.name);
      setPurchaseAccountCode(_vendor.purchasesdefaultaccountcode);
    }
  }, [vendorId]);

  useEffect(() => {
    let _purchaseAccount = purchaseAccounts.find(p => {
      return p.code === purchaseAccountCode;
    });

    if (_purchaseAccount) {
      setPurchaseAccountName(_purchaseAccount.name);
    }
  }, [purchaseAccountCode]);

  return (
    <div className={'content-container ' + className}>
      <PageHeader pageName="Add Payments" orgName={userData.selectedOrg?.name} />
      <Segment>
        <Dimmer active={isLoading}>
          <Loader />
        </Dimmer>
        <p>Use this page to add in hand written checks and bank transfers (ACH).</p>
        <Progress percent={statusPercentage} autoSuccess error={isPrintError}>
          {printStatusMessage}
        </Progress>
        <Form>
          <Form.Group widths="equal">
            <DateInput
              closable
              label="Date*"
              placeholder="start"
              dateFormat="MM/DD/YY"
              className="date-picker"
              value={checkDate}
              onChange={handleCheckDateChange}
              hideMobileKeyboard={true}
            />
            <Form.Input
              fluid
              label="Check Number*"
              placeholder=""
              disabled={isACH}
              type="number"
              value={checkNumber}
              inputMode="numeric"
              pattern="[0-9]*"
              onChange={handleCheckNumberChange}
            />
            <Form.Field>
              <label>Amount*</label>
              <Input
                fluid
                placeholder=""
                type="number"
                value={amount}
                inputMode="numeric"
                onChange={handleAmountChange}
              >
                <input inputMode="decimal" />
              </Input>
            </Form.Field>
          </Form.Group>
          <Form.Dropdown
            placeholder="Select Vendor"
            fluid
            search
            allowAdditions
            label="Vendor*"
            selection
            value={vendorId}
            options={vendorsList}
            onAddItem={handleVendorAddition}
            onChange={handleVendorChange}
          />
          <Form.Dropdown
            placeholder="Select one"
            fluid
            search
            label="Purchase Account*"
            selection
            options={purchaseAccounts}
            value={purchaseAccountCode}
            onChange={handlePurchaseAccountsChange}
          />
          <Form.Input
            fluid
            label="Memo (Invoice #)*"
            type="text"
            placeholder="Please enter invoice number. If there is none, enter in date."
            value={memo}
            onChange={handleMemoChange}
          />
          <Form.Group inline>
            <Form.Checkbox
              checked={isACH}
              label="Is bank transfer"
              onChange={handleIsACHChange}
            />
            <Form.Checkbox
              checked={dontResetVendor}
              label="Don't Reset Vendor"
              onChange={handleDontResetVendorChange}
            />
          </Form.Group>
          <Button primary disabled={isAddPaymentDisabled} onClick={addPayment}>
            Add Payment
          </Button>
          <p>* = required</p>
        </Form>
      </Segment>
    </div>
  );
}

AddPayment.propTypes = {
  className: PropTypes.string
};

AddPayment.defaultProps = {
  className: ''
};
