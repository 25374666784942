import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Header,
  Icon,
  Image,
  Message,
  Form,
  Segment,
  Button,
  Input
} from 'semantic-ui-react';
import ltgLogo from '../../images/ltg-logo.svg';
import useUserData from '../../state-manipulation/hooks/useUserData';
import AxiosWrapper from '../../utils/AxiosWrapper';

export default function ScreenLockLogin({ successFunction }) {
  const [isInvalidLogin, setIsInvalidLogin] = useState(false);
  const [pin, setPin] = useState('');
  const [userData, userDispatch] = useUserData();

  const handlePinChange = (e, { value }) => {
    setPin(value);
  };

  const getUserByPin = async company => {
    try {
      let res = await AxiosWrapper({
        method: 'GET',
        url: `${process.env.API_URL}/${company}/employees?pin=${pin}`,
        headers: {
          Authorization: `Basic ${userData.token}`
        }
      });

      if (res.data.employee) {
        setIsInvalidLogin(false);
        successFunction(res.data.employee);
      } else {
        setIsInvalidLogin(true);
      }
    } catch (err) {
      console.error(err);
      setIsInvalidLogin(true);
    }
  };

  const handleLogin = () => {
    getUserByPin(userData.selectedOrg.name.toLowerCase());
  };

  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal" textAlign="center">
          <Image src={ltgLogo} /> Log-in to your account
        </Header>
        {isInvalidLogin && (
          <Message warning>
            <Message.Header>Invalid pin</Message.Header>
            <p>Check with your manager if you continue having issues.</p>
          </Message>
        )}
        <Form size="large">
          <Segment stacked>
            <Form.Field>
              <Input
                fluid
                iconPosition="left"
                placeholder="Employee Pin"
                value={pin}
                onChange={handlePinChange}
              >
                <Icon name="lock" />
                <input type="password" inputMode="decimal" />
              </Input>
            </Form.Field>
            <Button color="teal" fluid size="large" onClick={handleLogin}>
              Login
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
}

ScreenLockLogin.propTypes = {
  successFunction: PropTypes.func
};
