import React from "react";
import ReactMarkdown from "react-markdown";
import ChangeLogSource from "../../change-log.md";
import { Segment } from "semantic-ui-react";

export default function ChangeLog() {
  return (
    <div className={"content-container"}>
      <Segment dangerouslySetInnerHTML={{ __html: ChangeLogSource }} />
    </div>
  );
}
