import React, { useState, useEffect } from "react";
import { Menu, Loader, Header, Segment, Grid, Message, Table, Tab, Dimmer } from "semantic-ui-react";
import PropTypes from "prop-types";
import { RadialChart, DiscreteColorLegend } from "react-vis";

import "./styles/Sales.scss";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";
import useUserData from "../../state-manipulation/hooks/useUserData";
import usePageData from "../../state-manipulation/hooks/usePageData";
import { setSelectedPage } from "../../state-manipulation/reducers/page-data/types";
import { LG_WIDTH } from "../../constants";
import NumberFormat from "react-number-format";
import PieChart from "../charts/PieChart";

import DateRangeSelector from "../common/DateRangeSelector";
import useDateRangeData from "../../state-manipulation/hooks/useDateRangeData";
import DateMenu from "../common/DateMenu";
import { clearUser } from "../../state-manipulation/reducers/user-data/types";
import PageHeader from "../common/PageHeader";
import AxiosWrapper, { AxiosAbort } from "../../utils/AxiosWrapper";

export default function SalesOverview({ className }) {
  const [ pageData, pageDispatch ] = usePageData();
  const [ dateRangeData, dateRangeDispatch ] = useDateRangeData();

  const [ days, setDays ] = useState(0);
  const [ userData, userDispatch ] = useUserData();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ salesData, setSalesData ] = useState({});
  const [ salesByRevenueCenter, setSalesByRevenueCenter ] = useState([]);
  const [ salesByDayPart, setSalesByDayPart ] = useState([]);
  const [ revenueClassBreakdown, setRevenueClassBreakdown ] = useState([]);
  const [ discounts, setDiscounts ] = useState([]);
  const [ voids, setVoids ] = useState([]);
  const [ timeOutId, setTimeoutId ] = useState('');

  const getSalesOverview = async (company, _dateStart, _dateEnd) => {
    try {
      setIsLoading(true);
      let res = await AxiosWrapper({
        method: 'GET',
        url: `${process.env.API_URL}/${company}/sales?startDate=${_dateStart}&endDate=${_dateEnd}`,
        headers: {
          'Authorization': `Basic ${userData.token}`
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          userDispatch({
            type: clearUser,
            payload: {}
          });
        }
      });

      let _salesByRevenuCenter = res.data.data.salesByRevenueCenter;
      let counter = 0;
      let colors = ["#93E1D8", "#336699", "#86BBD8","#2F4858","#B5D1CB","#79B9B1","#2A547E","#6E9AB1","#273B49"];
      _salesByRevenuCenter = _salesByRevenuCenter.map(s => {
        counter++;
        return {
          angle: s.total,
          label: s.revenueCenter,
          title: `${s.revenueCenter}: $${s.total.toFixed(2)} (${Math.round(s.total/res.data.data.closed.total*100)}%)`,
          strokeWidth: 10,
          color: colors[counter-1],
          className: colors[counter-1]
        };
      });

      _salesByRevenuCenter = _salesByRevenuCenter.sort((a,b) => {
        return b.angle - a.angle;
      });

      setSalesByRevenueCenter(_salesByRevenuCenter);

      setSalesData(res.data.data);

      let _salesByDayPart = res.data.data.salesByDayPart;
      counter = 0;
      _salesByDayPart = _salesByDayPart.map(s => {
        counter++;
        return {
          angle: s.amount,
          label: s.name,
          title: `${s.name}: $${s.amount.toFixed(2)} (${Math.round(s.amount/res.data.data.closed.total*100)}%)`,
          strokeWidth: 10,
          color: colors[counter-1],
          className: colors[counter-1]
        };
      });

      _salesByDayPart = _salesByDayPart.sort((a,b) => {
        return b.angle - a.angle;
      });

      setSalesByDayPart(_salesByDayPart);

      let _revenueClassBreakdown = res.data.data.closed.data;
      counter = 0;
      _revenueClassBreakdown = _revenueClassBreakdown.map(s => {
        counter++;
        return {
          angle: s.amount,
          label: s.name,
          title: `${s.name}: $${s.amount.toFixed(2)} (${Math.round(s.amount/res.data.data.closed.total*100)}%)`,
          strokeWidth: 10,
          color: colors[counter-1],
          className: colors[counter-1]
        };
      });

      _revenueClassBreakdown = _revenueClassBreakdown.sort((a,b) => {
        return b.angle - a.angle;
      });

      setRevenueClassBreakdown(_revenueClassBreakdown);
      setDiscounts(res.data.data.discounts);
      setVoids(res.data.data.voids);
      setIsLoading(false);

    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }


  };

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: 'Sales Overview'
      }
    });
  }, []);

  useEffect(() => {
    let _days = moment(dateRangeData.dateEnd).diff(moment(dateRangeData.dateStart))/1000/60/60/24;

    if (_days >= 0 && _days <= 100 && userData.selectedOrg) {
      clearTimeout(timeOutId);
      setTimeoutId(setTimeout(()=>{
          getSalesOverview(userData.selectedOrg.name.toLowerCase(), dateRangeData.dateStart, dateRangeData.dateEnd);
        }, 1000));

    }
    setDays( _days );

    return () => {
      clearTimeout(timeOutId);
      AxiosAbort();
    }
  }, [ dateRangeData, userData.selectedOrg ]);

  useEffect(() => {

  }, [salesData]);

  return (
    <div className={"content-container " + className}>
      <DateMenu />
      <PageHeader pageName="Sales Overview" orgName={userData.selectedOrg?.name} />
      {(days > 100) && (
        <Message warning>Date range must be 60 days or less.</Message>
      )}
      {(days < 0) && (
        <Message warning>End date must be the same or after start date.</Message>
      )}
      <DateRangeSelector />
      {salesData && (
      <div>
        <Segment padded={false}
          className="no-padding">
          <Dimmer active={isLoading}>
            <Loader />
          </Dimmer>
          <Table celled unstackable>
            <Table.Body>
            {dateRangeData.isLastDayToday && (
              <Table.Row>
                <Table.Cell>
                  Current Total
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {salesData.currentTotal && <NumberFormat thousandSeparator={true} value={salesData.currentTotal} displayType="text"></NumberFormat>}
                </Table.Cell>
              </Table.Row>
              )}
              <Table.Row>
                <Table.Cell>
                  Closed Total
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {salesData.closed && <NumberFormat thousandSeparator={true} value={salesData.closed.total} displayType="text"></NumberFormat>}
                </Table.Cell>
              </Table.Row>
              {dateRangeData.isLastDayToday && (
              <Table.Row>
                <Table.Cell>
                  Open Total
                </Table.Cell>
                <Table.Cell textAlign="right">
                {salesData.openTickets && <NumberFormat thousandSeparator={true} value={salesData.openTickets.total} displayType="text"></NumberFormat>}
                </Table.Cell>
              </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Segment>
        <Segment>
          <Dimmer active={isLoading}>
            <Loader />
          </Dimmer>
          <Header as="h2">Sales By Revenue Center</Header>
          <PieChart data={salesByRevenueCenter} />
        </Segment>
        <Segment>
          <Dimmer active={isLoading}>
            <Loader />
          </Dimmer>
          <Header as="h2">Day Part Sales</Header>
          <PieChart data={salesByDayPart} />
        </Segment>
        <Segment>
          <Dimmer active={isLoading}>
            <Loader />
          </Dimmer>
          <Header as="h2">Revenue Class Breakdown</Header>
          <PieChart data={revenueClassBreakdown} height={300} legendHeight={200} />
        </Segment>
        <Segment>
          <Dimmer active={isLoading}>
            <Loader />
          </Dimmer>
          <Header as="h2">Discounts Total: $<NumberFormat thousandSeparator displayType="text" value={discounts.reduce((a,b) => a + b.amount, 0).toFixed(2)} /></Header>
          <Table celled unstackable
            className="item-group">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell
                  className="qty"
                  textAlign="center">Qty</Table.HeaderCell>
                <Table.HeaderCell>Amount</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
            {discounts && discounts.map((discount, itemIndex) => {
              return (
                <Table.Row key={itemIndex}>
                  <Table.Cell>{discount.type}</Table.Cell>
                  <Table.Cell textAlign="center">{discount.count}</Table.Cell>
                  <Table.Cell textAlign="right">{discount.amount}</Table.Cell>
                </Table.Row>
              );
            })}
            </Table.Body>
          </Table>
        </Segment>
        <Segment>
          <Dimmer active={isLoading}>
            <Loader />
          </Dimmer>
          <Header as="h2">Voids Total: $<NumberFormat thousandSeparator displayType="text" value={voids.reduce((a,b) => a + b.amount, 0).toFixed(2)} /></Header>
          <Table celled unstackable
            className="item-group">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Reason</Table.HeaderCell>
                <Table.HeaderCell
                  className="qty"
                  textAlign="center">Qty</Table.HeaderCell>
                <Table.HeaderCell>Amount</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
            {voids && voids.map((voids, itemIndex) => {
              return (
                <Table.Row key={itemIndex}>
                  <Table.Cell>{voids.reason}</Table.Cell>
                  <Table.Cell textAlign="center">{voids.count}</Table.Cell>
                  <Table.Cell textAlign="right">{voids.amount}</Table.Cell>
                </Table.Row>
              );
            })}
            </Table.Body>
          </Table>
        </Segment>
      </div>
      )}

    </div>
  );
}

SalesOverview.propTypes = {
  className: PropTypes.string
};

SalesOverview.defaultProps = {
  className: ''
};