import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Redirect,
  useParams,
  Outlet,
} from "react-router-dom";

import useUserData from "../state-manipulation/hooks/useUserData";
import usePageData from "../state-manipulation/hooks/usePageData";
import { setSelectedPage } from "../state-manipulation/reducers/page-data/types";
import {
  Header,
  Segment,
  Dimmer,
  Loader,
  Statistic,
  Menu,
} from "semantic-ui-react";
import { LG_WIDTH } from "../constants";
import moment from "moment";
import StockPotsLive from "../components/iot/StockPotsLive";
import StockPotsHistorical from "../components/iot/StockPotsHistorical";
import DateRangeDataProvider from "../state-manipulation/providers/DateRangeDataProvider";
import PageHeader from "../components/common/PageHeader";

export default function StockPots({ className }) {
  const { company } = useParams();
  const [userData, userDispatch] = useUserData();
  const [pageData, pageDispatch] = usePageData();
  const [isLoading, setIsLoading] = useState(false);
  const [stockPots, setStockPots] = useState([]);
  const [eventSources, setEventSources] = useState([]);
  const [token, setToken] = useState("");

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: "Stock Pots",
      },
    });
  }, []);

  return (
    <div className={"content-container " + className}>
      <PageHeader pageName="Stock Pots" orgName={userData.selectedOrg?.name} />
      <Menu>
        <Menu.Item as={Link} to={`/${company}/stock-pots/live`}>
          Live View
        </Menu.Item>
        <Menu.Item as={Link} to={`/${company}/stock-pots/historical`}>
          Historical
        </Menu.Item>
      </Menu>

      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      <Outlet />
    </div>
  );
}

StockPots.propTypes = {
  className: PropTypes.string,
};

StockPots.defaultProps = {
  className: "",
};
