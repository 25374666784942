import AxiosWrapper from '../utils/AxiosWrapper';

export async function GetEmployees(company, { role } = {}) {
  try {
    let url = `${process.env.API_URL}/${company}/employees?format=common`;
    let filters = [];

    if (role) {
      filters.push(`role=${role}`);
    }

    if (filters.length > 0) {
      url += `?${filters.join('&')}`;
    }

    let res = await AxiosWrapper({
      method: 'GET',
      url: url,
      isAuthRoute: true,
    });
    return res;
  } catch (e) {
    console.error(e);
  }
}

export async function GetEmployees7Shifts({ company, role }) {
  try {
    let url = `${process.env.API_URL}/${company}/employees/7shifts`;
    let filters = [];

    if (role) {
      filters.push(`role=${role}`);
    }

    if (filters.length > 0) {
      url += `?${filters.join('&')}`;
    }

    let res = await AxiosWrapper({
      method: 'GET',
      url: url,
      isAuthRoute: true,
    });
    return res;
  } catch (e) {
    console.error(e);
  }
}
