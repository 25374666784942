import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Loader, Dimmer, Message, Table, Button, Icon, Modal, Grid, Input, Container, Checkbox } from "semantic-ui-react";
import moment from "moment";
import useUserData from "../../state-manipulation/hooks/useUserData";
import usePageData from "../../state-manipulation/hooks/usePageData";
import { setSelectedPage } from "../../state-manipulation/reducers/page-data/types";
import { clearUser } from "../../state-manipulation/reducers/user-data/types";
import { DateMenuForm } from "../common/DateMenu";
import { DateRangeSelectorForm } from "../common/DateRangeSelector";
import Moment from "react-moment";
import "moment-timezone";
import CashCountForm from "./CashCountForm";
import isAnyBoolTrue from "../../utils/isAnyBoolTrue";
import PageHeader from "../common/PageHeader";
import AxiosWrapper from "../../utils/AxiosWrapper";
import IsAuthorized from "../common/IsAuthorized";
import { mutliplyEmpty } from "../../utils/MathUtils";
import CashCalculator from "./CashCalculator";

function CashCounts({ cashCounts, handleViewClick }) {
  return (
    <Table unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>Time</Table.HeaderCell>
          <Table.HeaderCell>Count Goal</Table.HeaderCell>
          <Table.HeaderCell>Counted</Table.HeaderCell>
          <Table.HeaderCell>Over/Short</Table.HeaderCell>
          <Table.HeaderCell>Left in Drawer</Table.HeaderCell>
          <Table.HeaderCell>Drawer</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell colSpan="2" textAlign="center">Deposit</Table.HeaderCell>
          <Table.HeaderCell>Counted By</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        { cashCounts && cashCounts.map((cashCount, cashCountIndex) => {
          return (
          <Table.Row key={cashCountIndex}>
            <Table.Cell><Moment format="ddd M/DD">{cashCount.timestamp}</Moment></Table.Cell>
            <Table.Cell><Moment format="h:mm a">{cashCount.timestamp}</Moment></Table.Cell>
            <Table.Cell textAlign="right">{cashCount.countGoal && cashCount.countGoal?.toFixed(2)}{(!cashCount.countGoal && cashCount.posAmount) && cashCount.posAmount.toFixed(2)}</Table.Cell>
            <Table.Cell textAlign="right">{cashCount.total?.toFixed(2)}</Table.Cell>
            <Table.Cell textAlign="right" negative={cashCount.difference < -2 ? true : false}>{cashCount.difference?.toFixed(2)}</Table.Cell>
            <Table.Cell textAlign="right">{cashCount.totalLeftInDrawer?.toFixed(2)}</Table.Cell>
            <Table.Cell>{cashCount.cashDrawer}</Table.Cell>
            <Table.Cell>{cashCount.drawerStatus}</Table.Cell>
            <Table.Cell textAlign="right">{cashCount.depositAmount.toFixed(2)}</Table.Cell>
            <DepositVerifyStatus depositAmount={cashCount.depositAmount} depositVerifyCount={cashCount.depositVerifyCount} bankDepositCount={cashCount.bankDepositCount} />
            <Table.Cell>{cashCount.countedBy}
            {cashCount.depositVerifiedBy && (
              <> / {cashCount.depositVerifiedBy}</>
            )}
            </Table.Cell>
            <Table.Cell><Button cashcount={cashCount} onClick={handleViewClick}>View</Button>
            {cashCount.notes &&
              <Icon name="sticky note outline" cashcount={cashCount} onClick={handleViewClick} />
            }
            </Table.Cell>
          </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}

CashCounts.propTypes = {
  cashCounts: PropTypes.array,
  handleViewClick: PropTypes.func.isRequired
};

CashCounts.defaultProps = {
  cashCounts: []
};

function DepositVerifyStatus ({ depositVerifyCount, depositAmount, bankDepositCount }) {
  if (depositAmount > 0) {
    if (typeof depositVerifyCount !== 'undefined') {
      if (depositAmount === depositVerifyCount && depositVerifyCount === bankDepositCount) {
        return <Table.Cell positive><Icon color="green" name="check" /><Icon color="green" name="check" /></Table.Cell>
      } else if (depositAmount === depositVerifyCount) {
        return <Table.Cell positive><Icon color="green" name="check" /></Table.Cell>
      } else {
        return <Table.Cell negative>{depositVerifyCount - depositAmount > 0 ? '+' : ''}{depositVerifyCount - depositAmount}</Table.Cell>
      }
    } else {
      return <Table.Cell><Icon name="circle outline" /></Table.Cell>
    }
  }

  return (
    <Table.Cell></Table.Cell>
  )
}

export default function CashCount({ className }) {
  const [ userData, userDispatch ] = useUserData();
  const [ cashCounts, setCashCounts ] = useState([]);
  const [ pageData, pageDispatch ] = usePageData();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ days, setDays ] = useState(0);
  const [showOnlyClose, setShowOnlyClose] = useState(false);
  const [ cashCount, setCashCount ] = useState({
    count100: '',
    count50: '',
    count20: '',
    count10: '',
    count5: '',
    count2: '',
    count1: '',
    countChange50: '',
    countChange25: '',
    countChange10: '',
    countChange5: '',
    countChange1: '',
    countedBy: ''
  });

  const [ totalBillsLeftInDrawer, setTotalBillsLeftInDrawer] = useState(0);
  const currentHour = moment().tz("America/New_York").hours();
  const defaultStartDate = (currentHour >= 0 && currentHour  < 4) ? moment().subtract(1, "days") : moment();
  const defaultEndDate = (currentHour >= 0 && currentHour  < 4)  ? moment().subtract(1, "days") : moment();
  const [ dateStart, setDateStart ] = useState(moment(defaultStartDate).add(-5,'days').format('MM/DD/YY'));
  const [ dateEnd, setDateEnd ] = useState(defaultEndDate.format('MM/DD/YY'));
  const topOfPageRef = useRef();
  const [ timeOutId, setTimeoutId ] = useState('');
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const [ lastCashCount, setLastCashCount ] = useState({});
  const [ isCaclulatorModalOpen, setIsCaclulatorModalOpen] = useState(false);
  const [ depositCalculatorCount, setDepositCalculatorCount ] = useState({});
  const [ cashCountsFiltered, setCashCountsFiltered ] = useState([]);

  const getCashCounts = async (company, _dateStart, _dateEnd) => {
    try {
      setIsLoading(true);
      let res = await AxiosWrapper({
        method: 'GET',
        url: `${process.env.API_URL}/${company}/cash-counts?startDate=${_dateStart}&endDate=${_dateEnd}`,
        headers: {
          'Authorization': `Basic ${userData.token}`
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          userDispatch({
            type: clearUser,
            payload: {}
          });
        }
      });

      setCashCounts(res.data);
      setIsLoading(false);

    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleChangeDepositVerifyCount = (e, { value }) => {
    setCashCount({...cashCount, depositVerifyCount: value});
  }

  const handleChangeBankDepositCount = (e, { value }) => {
    setCashCount({...cashCount, bankDepositCount: value});
  }

  const handleVerifyCashCount = async (e, data) => {
    let depositVerifiedBy;
    if (userData.employeeByPin) {
      depositVerifiedBy = `${userData.employeeByPin.firstName} ${userData.employeeByPin.lastName}`;
    } else if (userData.userInfo && userData.userInfo.firstName) {
      depositVerifiedBy = `${userData.userInfo.firstName} ${userData.userInfo.lastName}`;
    }
    try {

      const id = cashCount._id['$oid'];
      let company = userData.selectedOrg.name.toLowerCase();
      let url =  `${process.env.API_URL}/${company}/cash-counts/${id}`;
      setIsLoading(true);
      let res = await AxiosWrapper({
        method: 'PATCH',
        url: url,
        isAuthRoute: true,
        data: {
          depositVerifyCount: cashCount.depositVerifyCount,
          depositVerifiedBy: depositVerifiedBy
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          userDispatch({
            type: clearUser,
            payload: {}
          });
        }
      });
      setIsLoading(true);
      setIsModalOpen(false);
      getCashCounts(company, dateStart, dateEnd);
    } catch (error) {
      setIsLoading(false);
      setIsModalOpen(false);
      console.error(error);
    }
  }

  const handleBankDepositCount = async (e, data) => {
    let bankDepositCountVerfiedBy;
    if (userData.employeeByPin) {
      bankDepositCountVerfiedBy = `${userData.employeeByPin.firstName} ${userData.employeeByPin.lastName}`;
    } else if (userData.userInfo && userData.userInfo.firstName) {
      bankDepositCountVerfiedBy = `${userData.userInfo.firstName} ${userData.userInfo.lastName}`;
    }
    try {

      const id = cashCount._id['$oid'];
      let company = userData.selectedOrg.name.toLowerCase();
      let url =  `${process.env.API_URL}/${company}/cash-counts/${id}`;
      setIsLoading(true);
      let res = await AxiosWrapper({
        method: 'PATCH',
        url: url,
        isAuthRoute: true,
        data: {
          bankDepositCount: cashCount.bankDepositCount,
          bankDepositCountVerfiedBy: bankDepositCountVerfiedBy
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          userDispatch({
            type: clearUser,
            payload: {}
          });
        }
      });
      setIsLoading(true);
      setIsModalOpen(false);
      getCashCounts(company, dateStart, dateEnd);
    } catch (error) {
      setIsLoading(false);
      setIsModalOpen(false);
      console.error(error);
    }
  }

  const deleteCashCount = async (company) => {
    try {
      setIsLoading(true);
      let res = await AxiosWrapper({
        method: 'DELETE',
        url: `${process.env.API_URL}/${company}/cash-counts/${cashCount._id['$oid']}`,
        headers: {
          'Authorization': `Basic ${userData.token}`
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          userDispatch({
            type: clearUser,
            payload: {}
          });
        }
      });

      setIsModalOpen(false);
      getCashCountsTimeout();

    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleViewClick = (e, { cashcount }) => {
    setCashCount(cashcount);
    let {leftInDrawer100,
      leftInDrawer50,
      leftInDrawer20,
      leftInDrawer10,
      leftInDrawer5,
      leftInDrawer2,
      leftInDrawer1 } = cashcount;
    let _total =
      mutliplyEmpty(leftInDrawer100, 100) * 100 +
      mutliplyEmpty(leftInDrawer50, 50) * 100 +
      mutliplyEmpty(leftInDrawer20, 20) * 100 +
      mutliplyEmpty(leftInDrawer10, 10) * 100 +
      mutliplyEmpty(leftInDrawer5, 5) * 100 +
      mutliplyEmpty(leftInDrawer2, 2) * 100 +
      mutliplyEmpty(leftInDrawer1, 1) * 100 ;
    setTotalBillsLeftInDrawer(_total / 100);
    setIsModalOpen(true);
  };

  const handleDateStartChange = (e, { value } ) => {
    setDateStart(value);
  };

  const handleDateEndChange = (e, { value } ) => {
    setDateEnd(value);
  };

  const handleCaretLeftClick = () => {
    setDateStart(moment(dateStart).subtract(1,'days').format('MM/DD/YY'));
    setDateEnd(moment(dateEnd).subtract(1,'days').format('MM/DD/YY'));
  };

  const handleCaretRightClick = () => {
    setDateStart(moment(dateStart).add(1,'days').format('MM/DD/YY'));
    setDateEnd(moment(dateEnd).add(1,'days').format('MM/DD/YY'));
  };

  const handleFastBackwardClick = () => {
    setDateStart(moment(dateStart).subtract(7,'days').format('MM/DD/YY'));
    setDateEnd(moment(dateEnd).subtract(7,'days').format('MM/DD/YY'));
  };

  const handleFastForwardClick = () => {
    setDateStart(moment(dateStart).add(7,'days').format('MM/DD/YY'));
    setDateEnd(moment(dateEnd).add(7,'days').format('MM/DD/YY'));
  };

  // START: Functions for DateMenuForm

  const handleTodayClick = () => {
    setDateStart(defaultStartDate.format('MM/DD/YY'));
    setDateEnd(defaultEndDate.format('MM/DD/YY'));
  };

  const handleYesterdayClick = () => {
    setDateStart(defaultStartDate.subtract(1, 'days').format('MM/DD/YY'));
    setDateEnd(defaultEndDate.subtract(1, 'days').format('MM/DD/YY'));
  };

  const handle52WeeksAgoClick = () => {
    setDateStart(defaultStartDate.subtract(52, 'weeks').format('MM/DD/YY'));
    setDateEnd(defaultEndDate.subtract(52, 'weeks').format('MM/DD/YY'));
  };

  const handleWeekToDateClick = () => {
    let _startDate = (defaultStartDate.format('ddd').toUpperCase() === 'SUN') ? defaultStartDate.subtract(6, 'days').format('MM/DD/YY') : defaultStartDate.startOf('week').add(1, 'days').format('MM/DD/YY');
    setDateStart(_startDate);
    setDateEnd(defaultEndDate.format('MM/DD/YY'));
  };

  const handleLastWeekClick = () => {
    setDateStart(defaultStartDate.startOf('week').subtract(6, 'days').format('MM/DD/YY'));
    setDateEnd(defaultEndDate.startOf('week').format('MM/DD/YY'));
  };

  const handleLast7DaysClick = () => {
    setDateStart(defaultStartDate.subtract(7, 'days').format('MM/DD/YY'));
    setDateEnd(defaultEndDate.format('MM/DD/YY'));
  };

  const handleLastMonthClick = () => {
    setDateStart(defaultStartDate.startOf('month').subtract(1, 'month').format('MM/DD/YY'));
    setDateEnd(defaultEndDate.endOf('month').subtract(1, 'month').format('MM/DD/YY'));
  };

  const handleLast30DaysClick = () => {
    setDateStart(defaultStartDate.subtract(30, 'days').format('MM/DD/YY'));
    setDateEnd(defaultEndDate.format('MM/DD/YY'));
  };

  const handleMonthToDateClick = () => {
    setDateStart(defaultStartDate.startOf('month').format('MM/DD/YY'));
    setDateEnd(defaultEndDate.format('MM/DD/YY'));
  };

  // END: Functions for DateMenuForm
  const handleDrawerOpen = (cashDrawer) => {
    let _lastCashCount = cashCounts.find((c) => {
      return ((c.cashDrawer === cashDrawer) && (c.drawerStatus === 'close'));
    });
    setLastCashCount(_lastCashCount);
  };

  const getCashCountsTimeout = () => {
    clearTimeout(timeOutId);
    setTimeoutId(setTimeout(()=>{
      getCashCounts(userData.selectedOrg.name.toLowerCase(), dateStart, dateEnd);
    }, 1000));
  };

  const cashCountFormSubmissionHandler = ()=> {
    getCashCountsTimeout();
  };

  const handleDeleteCashCount = () => {
    clearTimeout(timeOutId);
    setTimeoutId(setTimeout(()=>{
      deleteCashCount(userData.selectedOrg.name.toLowerCase());
    }, 1000));
  };

  const handleClickDepositCalculator = () => {
    setIsCaclulatorModalOpen(true);
  }

  const handleCalculatorChange = (count) => {
    setDepositCalculatorCount(count);
    setCashCount({ ...cashCount, depositVerifyCount: count.total });
  };

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: 'Cash Count'
      }
    });

  }, []);

  useEffect(() => {
    let _cashCounts = [...cashCounts];
    if (showOnlyClose) {
      _cashCounts = _cashCounts.filter(c => {
        return c.drawerStatus === 'close';
      });
    }

    setCashCountsFiltered(_cashCounts);

  }, [showOnlyClose, cashCounts]);

  useEffect(() => {
    let _days = moment(dateEnd).diff(moment(dateStart))/1000/60/60/24;

    if (_days >= 0 && _days <= 100 && userData.selectedOrg) {
      getCashCountsTimeout();
    }
    setDays( _days );
  }, [dateStart, dateEnd, userData.selectedOrg]);

  return (
    <div className={"content-container " + className} ref={topOfPageRef}>
      <PageHeader pageName="Cash Count" orgName={userData.selectedOrg?.name} />
      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      <CashCountForm
        formSubmissionCompleteHandler={cashCountFormSubmissionHandler}
        lastCashCount={lastCashCount}
        handleDrawerOpen={handleDrawerOpen}
      />
      <DateMenuForm
        handleTodayClick={handleTodayClick}
        handleYesterdayClick={handleYesterdayClick}
        handle52WeeksAgoClick={handle52WeeksAgoClick}
        handleWeekToDateClick={handleWeekToDateClick}
        handleLastWeekClick={handleLastWeekClick}
        handleLast7DaysClick={handleLast7DaysClick}
        handleMonthToDateClick={handleMonthToDateClick}
        handleLastMonthClick={handleLastMonthClick}
        handleLast30DaysClick={handleLast30DaysClick}
      />
      {days > 100 && (
        <Message warning>Date range must be 60 days or less.</Message>
      )}
      {days < 0 && (
        <Message warning>
          End date must be the same or after start date.
        </Message>
      )}
      <DateRangeSelectorForm
        dateRangeData={{ dateStart: dateStart, dateEnd: dateEnd }}
        handleFastBackwardClick={handleFastBackwardClick}
        handleFastForwardClick={handleFastForwardClick}
        handleCaretLeftClick={handleCaretLeftClick}
        handleDateStartChange={handleDateStartChange}
        handleDateEndChange={handleDateEndChange}
        handleCaretRightClick={handleCaretRightClick}
      />
      <Checkbox label="Show Only Closes" checked={showOnlyClose} onChange={() => {
        setShowOnlyClose(!showOnlyClose);
      }} />
      <CashCounts cashCounts={cashCountsFiltered} handleViewClick={handleViewClick} />
      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <Modal.Header>
          <Grid stackable={false} columns={2}>
            <Grid.Column width={15}>
              Cash Count for{" "}
              <Moment format="ddd M/DD h:mm A">{cashCount.timestamp}</Moment>
            </Grid.Column>
            <Grid.Column width={1} textAlign="right">
              <Icon
                name="close"
                className="mouse-hand"
                onClick={() => {
                  setIsModalOpen(false);
                }}
              />
            </Grid.Column>
          </Grid>
        </Modal.Header>
        <Modal.Content>
          {cashCount.notes && (
            <Message>
              <Message.Header>Notes</Message.Header>
              <p>{cashCount.notes}</p>
            </Message>
          )}
          <Modal open={isCaclulatorModalOpen}>
            <Modal.Header>
              <Grid stackable={false} columns={2}>
                <Grid.Column width={15}>Calculator</Grid.Column>
                <Grid.Column width={1} textAlign="right">
                  <Icon
                    name="close"
                    className="mouse-hand"
                    onClick={() => {
                      setIsCaclulatorModalOpen(false);
                    }}
                  />
                </Grid.Column>
              </Grid>
            </Modal.Header>
            <Modal.Content>
              <CashCalculator
                cashCount={depositCalculatorCount}
                onChange={handleCalculatorChange}
              />
              <Container textAlign="center" style={{ padding: "10px" }}>
                <Button
                  textAlign="right"
                  onClick={() => {
                    setIsCaclulatorModalOpen(false);
                    setDepositCalculatorCount({});
                  }}
                >
                  Update
                </Button>
              </Container>
            </Modal.Content>
          </Modal>
          <p>
            Counted by: <strong>{cashCount.countedBy}</strong>
          </p>
          <Table size="small" unstackable>
            <Table.Body>
              {cashCount.cashDrawer && (
                <Table.Row>
                  <Table.Cell collapsing>Drawer:</Table.Cell>
                  <Table.Cell textAlign="right">
                    {cashCount.cashDrawer}
                  </Table.Cell>
                </Table.Row>
              )}
              {cashCount.drawerStatus && (
                <Table.Row>
                  <Table.Cell collapsing>Drawer Status:</Table.Cell>
                  <Table.Cell textAlign="right">
                    {cashCount.drawerStatus}
                  </Table.Cell>
                </Table.Row>
              )}
              <Table.Row>
                <Table.Cell collapsing>POS Amount:</Table.Cell>
                <Table.Cell textAlign="right">
                  {cashCount.posAmount && cashCount.posAmount.toFixed(2)}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>Count Goal:</Table.Cell>
                <Table.Cell textAlign="right">
                  {cashCount.countGoal && cashCount.countGoal.toFixed(2)}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>Total:</Table.Cell>
                <Table.Cell textAlign="right">
                  {cashCount.total && cashCount.total.toFixed(2)}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>Difference:</Table.Cell>
                <Table.Cell
                  textAlign="right"
                  negative={cashCount.difference < -2 ? true : false}
                >
                  {cashCount.difference && cashCount.difference.toFixed(2)}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Table size="small" unstackable columns={4}>
            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing active>
                  $100
                </Table.Cell>
                <Table.Cell collapsing>{cashCount.count100}</Table.Cell>
                <Table.Cell collapsing active>
                  .50
                </Table.Cell>
                <Table.Cell collapsing>{cashCount.countChange50}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing active>
                  $50
                </Table.Cell>
                <Table.Cell collapsing>{cashCount.count50}</Table.Cell>
                <Table.Cell collapsing active>
                  .25
                </Table.Cell>
                <Table.Cell collapsing>{cashCount.countChange25}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing active>
                  $20
                </Table.Cell>
                <Table.Cell collapsing>{cashCount.count20}</Table.Cell>
                <Table.Cell collapsing active>
                  .10
                </Table.Cell>
                <Table.Cell collapsing>{cashCount.countChange10}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing active>
                  $10
                </Table.Cell>
                <Table.Cell collapsing>{cashCount.count10}</Table.Cell>
                <Table.Cell collapsing active>
                  .05
                </Table.Cell>
                <Table.Cell collapsing>{cashCount.countChange5}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing active>
                  $5
                </Table.Cell>
                <Table.Cell collapsing>{cashCount.count5}</Table.Cell>
                <Table.Cell collapsing active>
                  .01
                </Table.Cell>
                <Table.Cell collapsing>{cashCount.countChange1}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing active>
                  $1
                </Table.Cell>
                <Table.Cell collapsing>{cashCount.count1}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <p>Cash Left in Drawer</p>
          <Table size="small" unstackable>
            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing>Total:</Table.Cell>
                <Table.Cell textAlign="right">
                  {cashCount.totalLeftInDrawer &&
                    cashCount.totalLeftInDrawer.toFixed(2)}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>Total Bills Only:</Table.Cell>
                <Table.Cell textAlign="right">
                  {totalBillsLeftInDrawer && totalBillsLeftInDrawer.toFixed(2)}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>Deposit:</Table.Cell>
                <Table.Cell textAlign="right">
                  {cashCount.depositAmount &&
                    cashCount.depositAmount.toFixed(2)}
                </Table.Cell>
              </Table.Row>
              <IsAuthorized permission="hasCashPaymentsCashCountVerifyCount">
                <Table.Row>
                  <Table.Cell>Deposit Verify</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Input
                      value={cashCount.depositVerifyCount || ""}
                      onChange={handleChangeDepositVerifyCount}
                      type="number"
                      labelPosition="left"
                      size="mini"
                    >
                      <input inputMode="numeric" pattern="[0-9]*" />
                    </Input>

                    <Button
                      onClick={handleVerifyCashCount}
                      primary
                      icon="save"
                    ></Button>
                    <Button
                      onClick={handleClickDepositCalculator}
                      icon="calculator"
                    ></Button>
                  </Table.Cell>
                </Table.Row>
              </IsAuthorized>
              <IsAuthorized permission="hasCashPaymentsCashCountVerifyBankDeposit">
                <Table.Row>
                  <Table.Cell>Bank Verify</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Input
                      value={cashCount.bankDepositCount || ""}
                      onChange={handleChangeBankDepositCount}
                      type="number"
                      labelPosition="left"
                      size="mini"
                    >
                      <input inputMode="numeric" pattern="[0-9]*" />
                    </Input>

                    <Button
                      onClick={handleBankDepositCount}
                      icon="save"
                      primary
                    ></Button>
                  </Table.Cell>
                </Table.Row>
              </IsAuthorized>
            </Table.Body>
          </Table>
          <Table size="small" unstackable columns={4}>
            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing active>
                  $100
                </Table.Cell>
                <Table.Cell collapsing>{cashCount.leftInDrawer100}</Table.Cell>
                <Table.Cell collapsing active>
                  .50
                </Table.Cell>
                <Table.Cell collapsing>
                  {cashCount.leftInDrawerChange50}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing active>
                  $50
                </Table.Cell>
                <Table.Cell collapsing>{cashCount.leftInDrawer50}</Table.Cell>
                <Table.Cell collapsing active>
                  .25
                </Table.Cell>
                <Table.Cell collapsing>
                  {cashCount.leftInDrawerChange25}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing active>
                  $20
                </Table.Cell>
                <Table.Cell collapsing>{cashCount.leftInDrawer20}</Table.Cell>
                <Table.Cell collapsing active>
                  .10
                </Table.Cell>
                <Table.Cell collapsing>
                  {cashCount.leftInDrawerChange10}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing active>
                  $10
                </Table.Cell>
                <Table.Cell collapsing>{cashCount.leftInDrawer10}</Table.Cell>
                <Table.Cell collapsing active>
                  .05
                </Table.Cell>
                <Table.Cell collapsing>
                  {cashCount.leftInDrawerChange5}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing active>
                  $5
                </Table.Cell>
                <Table.Cell collapsing>{cashCount.leftInDrawer5}</Table.Cell>
                <Table.Cell collapsing active>
                  .01
                </Table.Cell>
                <Table.Cell collapsing>
                  {cashCount.leftInDrawerChange1}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing active>
                  $1
                </Table.Cell>
                <Table.Cell collapsing>{cashCount.leftInDrawer1}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          {userData.userInfo &&
            userData.userInfo.userTags &&
            isAnyBoolTrue(
              userData.userInfo.userTags.hasCashPaymentsCashCountDelete
            ) && <Button onClick={handleDeleteCashCount}>Delete</Button>}
        </Modal.Content>
      </Modal>
    </div>
  );
}

CashCount.propTypes = {
  className: PropTypes.string
};
