import React, { useContext, useEffect, useState } from "react";
import DisplayTrainingSkillDetail from "./DisplaytrainingSkillDetail";
import { Accordion, Icon } from "semantic-ui-react";
import DisplayTrainingStatusLabel from "./DisplayTrainingStatusLabel";
import { ALTO_GRAY } from "../../constants";
import { EmployeesTrainingStatusContext } from "../../context/EmployeesTrainingStatusContext";

export default function DisplayTrainingSkillsGroup({ trainingStatus, trainingSkills, name, employee, expandAll }) {
  const [ isOpen, setIsOpen ] = useState(false);
  const [employeesTrainingStatus] = useContext(EmployeesTrainingStatusContext);
  function handleClick () {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    if (employeesTrainingStatus.isSearching === true) {
      setIsOpen(true);
    }
  }, [employeesTrainingStatus.isSearching]);

  return (
    <Accordion.Accordion style={{marginTop:0}}>
      <Accordion.Title onClick={handleClick} active={isOpen}  style={{marginTop: 0 }}>
        <Icon name="dropdown" />

        {name} <DisplayTrainingStatusLabel trainingSkillsCount={trainingSkills.length} trainingStatus={trainingStatus} />
      </Accordion.Title>
      <Accordion.Content active={isOpen} style={{backgroundColor: ALTO_GRAY}}>
        <Accordion.Accordion>
          {trainingSkills && trainingSkills.map((ts, tsIndex) => {
            return (
              <DisplayTrainingSkillDetail key={tsIndex} trainingSkill={ts} externalEmployeeId={employee.externalEmployeeId} />
            )
          })}
        </Accordion.Accordion>
      </Accordion.Content>
    </Accordion.Accordion>
  )
}
