import AxiosWrapper from '../utils/AxiosWrapper';

export async function GetLabor({startDate, endDate, company}) {
  try {
    let url = `${process.env.API_URL}/${company}/labor`;

    let options = [];

    if (startDate) {
      options.push(`startDate=${startDate}`);
    }

    if (endDate) {
      options.push(`endDate=${endDate}`);
    }

    if (options.length > 0) {
      url +=  `?${options.join('&')}`;
    }

    let res = await AxiosWrapper({
      method: 'GET',
      url: url,
      isAuthRoute: true,
    });
    return res;
  } catch (e) {
    console.error(e);
  }
}

export async function GetShifts({startDate, endDate, company}) {
  try {
    let url = `${process.env.API_URL}/${company}/shifts`;

    let options = ['state=timeSheet'];

    if (startDate) {
      options.push(`startDate=${startDate}`);
    }

    if (endDate) {
      options.push(`endDate=${endDate}`);
    }

    if (options.length > 0) {
      url +=  `?${options.join('&')}`;
    }

    let res = await AxiosWrapper({
      method: 'GET',
      url: url,
      isAuthRoute: true,
    });
    return res;
  } catch (e) {
    console.error(e);
  }
}
