import AxiosWrapper from '../utils/AxiosWrapper';

export async function GetVendors({company}) {
  try {
    let url = `${process.env.API_URL}/${company}/vendors`;

    let res = await AxiosWrapper({
      method: 'GET',
      url: url,
      isAuthRoute: true,
    });

    return res;
  } catch (e) {
    console.error(e);
  }
}

export async function DeleteVendor({company, id}) {
  try {
    let url = `${process.env.API_URL}/${company}/vendors/${id}`;

    let res = await AxiosWrapper({
      method: 'DELETE',
      url: url,
      isAuthRoute: true,
    });

    return res;
  } catch (e) {
    console.error(e);
  }
}

export async function SetVendorACH({company, id, isACH}) {
  try {
    let url = `${process.env.API_URL}/${company}/vendors/${id}`;

    let res = await AxiosWrapper({
      method: 'PATCH',
      url: url,
      isAuthRoute: true,
      data: {
        isACH: isACH
      }
    });

    return res;
  } catch (e) {
    console.error(e);
  }
}
