import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dimmer,
  Header,
  Loader,
  Table,
  Tab,
  Grid,
  Button,
  Segment,
  Icon,
  Dropdown,
} from 'semantic-ui-react';
import usePageData from '../state-manipulation/hooks/usePageData';
import { setSelectedPage } from '../state-manipulation/reducers/page-data/types';
import { Link, useParams } from 'react-router-dom';
import useUserData from '../state-manipulation/hooks/useUserData';
import {
  PostTaskListTemplate,
  GetTaskListTemplates,
} from '../api/TaskListTemplateAPI';
import PageHeader from '../components/common/PageHeader';

export default function TaskListTemplates({ className }) {
  const [pageData, pageDispatch] = usePageData();
  const [userData] = useUserData();
  const [taskTemplates, setTaskTemplates] = useState([]);
  const { company } = useParams();
  const [timeOutId, setTimeoutId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const daysOfWeek = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  const [tableSort, setTableSort] = useState({
    column: 'name',
    direction: 'ascending',
  });

  const getTaskListTemplates = async () => {
    try {
      setIsLoading(true);
      let res = await GetTaskListTemplates({ company: company });

      setTaskTemplates(res.data);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSort = (clickedColumn) => () => {
    let { column, direction } = { ...tableSort };
    let _taskTemplates = [...taskTemplates];

    if (column !== clickedColumn) {
      direction = 'ascending';
    } else {
      direction = direction === 'ascending' ? 'descending' : 'ascending';
    }

    if (direction === 'ascending') {
      _taskTemplates.sort((a, b) => {
        let aVal = a[clickedColumn];
        let bVal = b[clickedColumn];

        if (clickedColumn === 'assigneeRoles') {
          aVal = aVal.join(',');
          bVal = bVal.join(',');
        } else if (clickedColumn === 'tasks') {
          aVal = aVal.length;
          bVal = bVal.length;
        } else if (typeof aVal === 'string') {
          aVal = aVal.toUpperCase(); // ignore upper and lowercase
          bVal = bVal.toUpperCase(); // ignore upper and lowercase
        }

        if (aVal < bVal) {
          return -1;
        }
        if (aVal > bVal) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    } else {
      _taskTemplates.sort((a, b) => {
        let aVal = a[clickedColumn];
        let bVal = b[clickedColumn];

        if (typeof aVal === 'string') {
          aVal = aVal.toUpperCase(); // ignore upper and lowercase
          bVal = bVal.toUpperCase(); // ignore upper and lowercase
        }

        if (aVal > bVal) {
          return -1;
        }
        if (aVal < bVal) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    }

    setTableSort({
      column: clickedColumn,
      direction: direction,
    });

    setTaskTemplates(_taskTemplates);
  };

  const handleClone = async (e, { tasklisttemplate }) => {
    let _taskListTemplate = JSON.parse(JSON.stringify(tasklisttemplate));
    delete _taskListTemplate.id;
    delete _taskListTemplate._id;
    _taskListTemplate.name += ' (Cloned)';
    try {
      let res = await PostTaskListTemplate({
        company: company,
        taskListTemplate: _taskListTemplate,
      });

      if (res) {
        getTaskListTemplates();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleCloneToCompany = async (e, { tasklisttemplate, text }) => {
    let _taskListTemplate = JSON.parse(JSON.stringify(tasklisttemplate));
    delete _taskListTemplate.id;
    delete _taskListTemplate._id;
    _taskListTemplate.name += ' (Cloned)';
    try {
      let res = await PostTaskListTemplate({
        company: text.toLowerCase(),
        taskListTemplate: _taskListTemplate,
      });

      if (res) {
        getTaskListTemplates();
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: 'Manage Tasks',
      },
    });
  }, []);

  useEffect(() => {
    clearTimeout(timeOutId);
    setTimeoutId(
      setTimeout(() => {
        getTaskListTemplates();
      }, 1000)
    );
  }, [company]);

  return (
    <div className={'content-container ' + className}>
      <Dimmer active={isLoading}>
        <Loader>Loading</Loader>
      </Dimmer>
      <PageHeader pageName="Task List Templates" orgName={userData.selectedOrg?.name} />
      <p>These templates will be used to create daily task lists.</p>
      <Grid columns="1">
        <Grid.Row>
          <Grid.Column textAlign="right">
            <Link to={`/${company}/admin/task-list-templates/new`}>
              <Button primary>Add</Button>
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {taskTemplates.length === 0 ? (
        <Segment style={{ height: '100px' }}>No Lists</Segment>
      ) : (
        <Table unstackable sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={
                  tableSort.column === 'name' ? tableSort.direction : null
                }
                onClick={handleSort('name')}
              >
                Name
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  tableSort.column === 'assigneeRoles'
                    ? tableSort.direction
                    : null
                }
                onClick={handleSort('assigneeRoles')}
              >
                Assignee Roles
              </Table.HeaderCell>
              <Table.HeaderCell
                textAlign="right"
                sorted={
                  tableSort.column === 'tasks' ? tableSort.direction : null
                }
                onClick={handleSort('tasks')}
              >
                Tasks
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  tableSort.column === 'startTime' ? tableSort.direction : null
                }
                onClick={handleSort('startTime')}
              >
                Start Time
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  tableSort.column === 'endTime' ? tableSort.direction : null
                }
                onClick={handleSort('endTime')}
              >
                End Time
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  tableSort.column === 'frequency' ? tableSort.direction : null
                }
                onClick={handleSort('frequency')}
              >
                Frequency
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  tableSort.column === 'createdBy' ? tableSort.direction : null
                }
                onClick={handleSort('createdBy')}
              >
                Created By
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  tableSort.column === 'autogenerate'
                    ? tableSort.direction
                    : null
                }
                onClick={handleSort('autogenerate')}
              >
                Autogenerate
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {taskTemplates &&
              taskTemplates.map((tt, ttIndex) => {
                return (
                  <Table.Row key={ttIndex}>
                    <Table.Cell>
                      <Link
                        to={`/${company}/admin/task-list-templates/${tt._id.$oid}`}
                      >
                        {tt.name}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>{tt.assigneeRoles.join('/')}</Table.Cell>
                    <Table.Cell textAlign="right">{tt.totalTasks}</Table.Cell>
                    <Table.Cell>{tt.startTime}</Table.Cell>
                    <Table.Cell>{tt.endTime}</Table.Cell>
                    <Table.Cell>{tt.frequency}</Table.Cell>
                    <Table.Cell>{tt.createdBy}</Table.Cell>
                    <Table.Cell textAlign="center">
                      {tt.autogenerate ? (
                        <Icon name="check" color="green" />
                      ) : (
                        ''
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {userData.orgs.length > 1 ? (
                        <Dropdown
                          inline
                          trigger={<Icon
                            link={true}
                            name="clone outline"
                          />}
                          direction="left"
                          options={userData.orgs.map(o => {
                            return {
                              key: o.id,
                              text: o.name,
                              value: o.id,
                              content: o.name,
                              tasklisttemplate:tt,
                              onClick: handleCloneToCompany
                            }
                          })}
                        />
                      ) : (
                        <Icon
                          link={true}
                          name="clone outline"
                          tasklisttemplate={tt}
                          onClick={handleClone}
                        />
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
      )}
      {/* <h3>View Merged Task Lists</h3>
      <Table unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Type</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {daysOfWeek.map((d, dIndex) => {
            return (
              <Table.Row key={dIndex}>
                <Table.Cell>
                  <Link
                    to={`/${company}/admin/task-list-templates/merged?day=${d}`}
                  >
                    {d}
                  </Link>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table> */}
    </div>
  );
}

TaskListTemplates.propTypes = {
  className: PropTypes.string,
};

TaskListTemplates.defaultProps = {
  className: '',
};
