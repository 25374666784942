import React, { useState, useEffect } from "react";
import { Menu, Loader, Header, Segment, Grid, Message, Table, Tab, Responsive, Dimmer, Icon, Accordion, Label } from "semantic-ui-react";
import PropTypes from "prop-types";

import "./styles/Sales.scss";

import moment from "moment";
import "moment-timezone";
import useUserData from "../../state-manipulation/hooks/useUserData";
import usePageData from "../../state-manipulation/hooks/usePageData";
import { setSelectedPage } from "../../state-manipulation/reducers/page-data/types";
import { LG_WIDTH } from "../../constants";

import DateRangeSelector from "../common/DateRangeSelector";
import useDateRangeData from "../../state-manipulation/hooks/useDateRangeData";
import DateMenu from "../common/DateMenu";
import Moment from "react-moment";
import { clearUser } from "../../state-manipulation/reducers/user-data/types";
import PageHeader from "../common/PageHeader";
import AxiosWrapper, { AxiosAbort } from "../../utils/AxiosWrapper";

export default function Discounts({ className }) {
  const [ pageData, pageDispatch ] = usePageData();
  const [ dateRangeData, dateRangeDispatch ] = useDateRangeData();

  const [ days, setDays ] = useState(0);
  const [ userData, userDispatch ] = useUserData();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ discounts, setDiscounts ] = useState([]);
  const [ activePanels, setActivePanels ] = useState({});
  const [ timeOutId, setTimeoutId ] = useState('');

  const getDiscounts = async (company, _dateStart, _dateEnd) => {
    try {
      setIsLoading(true);
      let res = await AxiosWrapper({
        method: 'GET',
        url: `${process.env.API_URL}/${company}/discounts?startDate=${_dateStart}&endDate=${_dateEnd}`,
        headers: {
          'Authorization': `Basic ${userData.token}`
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          userDispatch({
            type: clearUser,
            payload: {}
          });
        }
      });

      setDiscounts(res.data.data);
      setIsLoading(false);

    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleAccoridian1Click = (e, { index }) => {
    let _activePanels = { ...activePanels };
    _activePanels[ index ] = (!_activePanels[ index ]) ? 1 : 0;
    setActivePanels(_activePanels);
  };

  const renderDiscountHeader = (discount, discountIndex) => {
    return (
      <Grid>
        <Grid.Column mobile={10}>
          <Header as="h4" className="for-table">
            {discount.discountType}
            <Icon
              name={
                activePanels[discountIndex] === 1 ? 'caret down' : 'caret right'
              }
            />
          </Header>
        </Grid.Column>
        <Grid.Column mobile={6}>
          <p>
            Total: ${discount.total.toFixed(2)} ({discount.count})
          </p>
        </Grid.Column>
      </Grid>
    );
  };

  const renderManagerDiscount = (manager) => {
    return (
      <Header as="h5" className="manager">
        {manager.firstName} {manager.lastName}{' '}
        <Label color="grey">
          Total: ${manager.total.toFixed(2)} ({manager.count})
        </Label>
      </Header>
    );
  };

  const renderDiscountPerson = (owner, children) => {
    if (owner.firstName !== '') {
      return (
        <>
          For {owner.firstName} {owner.lastName}{' '}
          <Label>
            ${owner.total.toFixed(2)} ({owner.count})
          </Label>
          {children}
        </>
      );
    }

    if (owner.firstName === '') {
      return (
        <p>
          View details
          {children}
        </p>
      );
    }
  };

  const renderOwnerDiscountDetails = (owner) => {
    return (
      <>
        {owner.tickets &&
          owner.tickets.map((ticket, ticketIndex) => {
            return (
              <React.Fragment key={ticketIndex}>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Ticket Id</Table.HeaderCell>
                      <Table.HeaderCell>Time</Table.HeaderCell>
                      <Table.HeaderCell>Discount Value</Table.HeaderCell>
                      <Table.HeaderCell>Reason</Table.HeaderCell>
                      <Table.HeaderCell>CC Name</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>{ticket.i_ticket_id}</Table.Cell>
                      <Table.Cell>
                        <Moment format="M/D h:mm a">{ticket.dt_when}</Moment>
                      </Table.Cell>
                      <Table.Cell>{ticket.discountValue.toFixed(2)}</Table.Cell>
                      <Table.Cell>{ticket.reason}</Table.Cell>
                      <Table.Cell>{ticket.creditCardName}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                {ticket.items && (
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                      <Table.HeaderCell>Item</Table.HeaderCell>
                      <Table.HeaderCell>Amount</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {ticket.items && ticket.items.map((item, itemIndex) => {
                      return (
                        <Table.Row key={itemIndex}>
                          <Table.Cell>{item.s_menu_item}</Table.Cell>
                          <Table.Cell>{item.discountValue.toFixed(2)}</Table.Cell>
                        </Table.Row>
                      )
                    })}
                    </Table.Body>
                  </Table>
                )}
              </React.Fragment>
            );
          })}
      </>
    );
  };

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: 'Discounts'
      }
    });
    
  }, []);

  useEffect(() => {
    let _days = moment(dateRangeData.dateEnd).diff(moment(dateRangeData.dateStart))/1000/60/60/24;

    if (_days >= 0 && _days <= 7 && userData.selectedOrg) {
      clearTimeout(timeOutId);
      setTimeoutId(setTimeout(()=>{
        getDiscounts(userData.selectedOrg.name.toLowerCase(), dateRangeData.dateStart, dateRangeData.dateEnd);
      }, 1000));
    }
    setDays( _days );

    return () => {
      clearTimeout(timeOutId);
      AxiosAbort();
    }
  }, [ dateRangeData, userData.selectedOrg ]);


  return (
    <div className={"content-container " + className}>
      <DateMenu />
      <PageHeader pageName="Discounts" orgName={userData.selectedOrg?.name} />
      {(days > 7) && (
        <Message warning>Date range must be 7 days or less.</Message>
      )}
      {(days < 0) && (
        <Message warning>End date must be the same or after start date.</Message>
      )}
      <DateRangeSelector />
      {discounts && (
      <div>
        <Segment>
          <Dimmer active={isLoading}>
            <Loader />
          </Dimmer>
          <Accordion exclusive={false} className="accordion-level-1">
          {discounts && discounts.map((discount, discountIndex) => {
            return (
              <div key={discountIndex}>
                <Accordion.Title active={activePanels[discountIndex] === 1} index={discountIndex} onClick={handleAccoridian1Click}>
                  {renderDiscountHeader(discount, discountIndex)}
                </Accordion.Title>
                <Accordion.Content active={activePanels[discountIndex] === 1} className="accordion-content-1">
                  {discount.managers && discount.managers.map((manager, managerIndex) => {
                    let show = {};
                    return (
                      <div key={managerIndex} className="managers-container">
                        {renderManagerDiscount(manager)}
                        <Accordion exclusive={false} className="accordion-level-2">
                        {manager.owners && manager.owners.map((owner, ownerIndex) => {
                          return (
                            <div key={ownerIndex}>
                              <Accordion.Title active={activePanels[`${discountIndex}-${managerIndex}-${ownerIndex}`] === 1} index={`${discountIndex}-${managerIndex}-${ownerIndex}`} onClick={handleAccoridian1Click}>
                                {renderDiscountPerson(owner, <Icon name={activePanels[`${discountIndex}-${managerIndex}-${ownerIndex}`] === 1 ? "caret down" : "caret right"} />)}
                              </Accordion.Title>
                              <Accordion.Content active={activePanels[`${discountIndex}-${managerIndex}-${ownerIndex}`] === 1}>
                                {renderOwnerDiscountDetails(owner)}
                              </Accordion.Content>
                            </div>
                          );
                        })}
                        </Accordion>
                      </div>
                    );
                  })}
                </Accordion.Content>
              </div>
            );
          })}
          </Accordion>
        </Segment>
      </div>
      )}

    </div>
  );
}

Discounts.propTypes = {
  className: PropTypes.string
};

Discounts.defaultProps = {
  className: ''
};