import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TaskListForm from '../components/tasks/TaskListForm';
import { useParams } from 'react-router-dom';
import useUserData from '../state-manipulation/hooks/useUserData';
import { Dimmer, Loader } from 'semantic-ui-react';
import usePageData from '../state-manipulation/hooks/usePageData';
import { setSelectedPage } from '../state-manipulation/reducers/page-data/types';
import AxiosWrapper from '../utils/AxiosWrapper';

export default function TaskListTemplate({ className }) {
  const { company, taskListTemplateId } = useParams();
  const [pageData, pageDispatch] = usePageData();
  const [userData] = useUserData();
  const [taskListTemplate, setTaskListTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getTaskListTemplate = async _taskListTemplateId => {
    try {
      setIsLoading(true);
      let res = await AxiosWrapper({
        method: 'GET',
        url: `${process.env.API_URL}/${company}/task-list-templates/${_taskListTemplateId}`,
        headers: {
          Authorization: `Basic ${userData.token}`
        }
      });

      setTaskListTemplate(res.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  useEffect(() => {
    if (taskListTemplateId) {
      getTaskListTemplate(taskListTemplateId);
    }
  }, [taskListTemplateId]);

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: 'Manage Tasks'
      }
    });
  }, []);
  return (
    <div className={'content-container ' + className}>
      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      <TaskListForm taskListTemplate={taskListTemplate} />
    </div>
  );
}

TaskListTemplate.propTypes = {
  className: PropTypes.string
};

TaskListTemplate.defaultProps = {
  className: ''
};
