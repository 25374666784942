import { setDateStart, setDateEnd, clearDates } from "./types";
import moment from "moment";
import "moment-timezone";

export default function dateRangeDataReducer(state, { type, payload }) {
  const currentHour = moment().tz("America/New_York").hours();
  const defaultEndDate = (currentHour >= 0 && currentHour  < 4)  ? moment().subtract(1, "days") : moment();

  switch (type) {
    case setDateStart:
      return { ...state, dateStart: payload.dateStart };
    case setDateEnd:
      return { ...state,
        dateEnd: payload.dateEnd,
        isLastDayToday: (defaultEndDate.format('MM/DD/YY') === payload.dateEnd) ? true : false
      };
    case clearDates:
      return { ...state, dateStart: null, dateEnd: null };
    default:
      throw new Error(`Unknown action ${type} in dateRangeDataReducer`);
  }
}