import React from "react";
import { Link, useParams } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";
import usePageData from "../../state-manipulation/hooks/usePageData";
import useUserData from "../../state-manipulation/hooks/useUserData";
import IsAuthorized from "../common/IsAuthorized";

export default function AdminMenu({ onClick }) {
  let { company } = useParams();
  const [pageData] = usePageData();
  const [userData, userDispatch] = useUserData();

  if (!company) {
    company = userData.orgs[0].name.toLowerCase();
  }

  return (
    <IsAuthorized permission="hasAdmin">
      <Menu.Item>
        <Menu.Header>Admin</Menu.Header>
        <Menu.Menu>
          <IsAuthorized permission="hasAdminPayroll">
          <Menu.Item
            as={Link}
            to={"/" + company + "/admin/payroll-export"}
            onClick={onClick}
            active={
              pageData.selectedPage.toUpperCase() === "PAYROLL EXPORT"
                ? true
                : false
            }
          >
            <Icon name="gamepad" />
            Payroll Export
          </Menu.Item>
          </IsAuthorized>
          <IsAuthorized permission="hasAdminQRCodeGenerator">
          <Menu.Item
            as={Link}
            to={"/" + company + "/admin/qr-code-generator"}
            onClick={onClick}
            active={
              pageData.selectedPage.toUpperCase() === "QR CODE GENERATOR"
                ? true
                : false
            }
          >
            <Icon name="gamepad" />
            QR Code Generator
          </Menu.Item>
          </IsAuthorized>
        </Menu.Menu>
      </Menu.Item>
    </IsAuthorized>
  );
}
