import React from "react";
import { Label } from "semantic-ui-react";

export default function DisplayTrainingStatusLabel({ trainingSkillsCount, trainingStatus }) {

  if (trainingStatus.needRecertification > 0) {
    return <>
      <Label color="yellow">{trainingStatus.completed} of {trainingSkillsCount} complete</Label>
      <Label color="orange">{trainingStatus.needRecertification} to recertify</Label>
    </>
  }

  if (trainingStatus.completed === trainingSkillsCount) {
    return <Label color="green">{trainingStatus.completed} of {trainingSkillsCount} complete</Label>;
  }

  if (trainingStatus.completed > 0 || trainingStatus.started > 0) {
    return <Label color="yellow">{trainingStatus.completed} of {trainingSkillsCount} complete</Label>;
  }

  return <Label>{trainingSkillsCount} skills to learn</Label>;
}
