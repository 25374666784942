import React from "react";
import PropTypes from "prop-types";
import { Table, Tab } from "semantic-ui-react";
import "./styles/DraftListPrint.scss";

export default class DraftListPrint extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Tap</Table.HeaderCell>
              <Table.HeaderCell>Style</Table.HeaderCell>
              <Table.HeaderCell>Brewery</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Location</Table.HeaderCell>
              <Table.HeaderCell>ABV%</Table.HeaderCell>
              <Table.HeaderCell>IBU</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.draftList &&
              this.props.draftList.map((draft, draftIndex) => {
                return (
                  <Table.Row key={draftIndex}>
                    <Table.Cell textAlign="center">{draftIndex + 1}</Table.Cell>
                    <Table.Cell>{draft.style}</Table.Cell>
                    <Table.Cell>{draft.brewery}</Table.Cell>
                    <Table.Cell>{draft.name}</Table.Cell>
                    <Table.Cell>{draft.location}</Table.Cell>
                    <Table.Cell>{draft.abv}</Table.Cell>
                    <Table.Cell>{draft.ibu}</Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
      </>
    );
  }
}

DraftListPrint.propTypes = {
  draftList: PropTypes.array,
};
