import React, { useContext, useState } from "react";
import { Accordion, Button, Checkbox, Container, Icon, Label, List, Transition } from "semantic-ui-react";
import DebounceInput from "../forms/DebounceInput";
import { UpdateEmployeetrainingSkillStatus } from "../../api/TrainingAPI";
import { EmployeesTrainingStatusContext, actions } from "../../context/EmployeesTrainingStatusContext";
import DisplaySingleTrainingStatusLabel from "./DisplaySingleTrainingStatusLabel";
import moment from "moment";
import IsAuthorized from "../common/IsAuthorized";
import { MARGIN_SM } from "../../constants";

export default function DisplayTrainingSkillDetail({ trainingSkill, company, externalEmployeeId }) {
  const [ isOpen, setIsOpen ] = useState(false);
  const [ isSaving, setIsSaving ] = useState(false);
  const [ isDirty, setIsDirty ] = useState(false);
  const [employeesTrainingStatus, employeeTrainingStatusDispatch] = useContext(EmployeesTrainingStatusContext);

  const handleChangeTrainingChange = (e, { checked, value, proptochange, trainingskillid: trainingSkillId }) => {
    setIsDirty(true);
    if (typeof checked === 'boolean') {
      value = checked;
    }

    // find training item in employee
    let _trainingSkill = employeesTrainingStatus.employees[0].employeeTrainingStatus.find(eTS => eTS.trainingSkillId === trainingSkillId);
    if (!_trainingSkill) {
      _trainingSkill = {
        trainingSkillId: trainingSkillId
      };
      employeesTrainingStatus.employees[0].employeeTrainingStatus.push(_trainingSkill);
    }

    _trainingSkill[proptochange] = value;

    if (proptochange === 'recertificationDate' && value.trim() !== "") {
      delete _trainingSkill.needRecertification;
    }

    employeeTrainingStatusDispatch({
      type: actions.populateEmployeesJobReferenceTrainingStatus,
      payload: {
        employees: employeesTrainingStatus.employees,
        trainingSkills: employeesTrainingStatus.trainingSkills
      }
    })

  };

  const handleUpdateItem = async (e, { trainingskillid: trainingSkillId }) => {
    let _trainingSkill = employeesTrainingStatus.employees[0].employeeTrainingStatus.find(eTS => eTS.trainingSkillId === trainingSkillId);

    setIsSaving(true);
    let res = await UpdateEmployeetrainingSkillStatus({ company, externalEmployeeId, trainingSkillStatus: _trainingSkill })
    setIsSaving(false);
    setIsDirty(false);
  }

  function FieldLabel({ children }) {
    return <Label color="grey" style={{ display: "block", width: "fit-content", marginBottom: "5px", marginTop: "5px" }}>{children}</Label>
  }

  function handleClick () {
    setIsOpen(!isOpen);
  }


  return (
    <React.Fragment>
      <Accordion.Title
        active={isOpen}
        onClick={handleClick}
      >
        <Icon name="dropdown" />
        {trainingSkill.name} <DisplaySingleTrainingStatusLabel trainingSkill={trainingSkill} />
      </Accordion.Title>
      <Accordion.Content active={isOpen}>
        <Container>
          {trainingSkill["Days to Train"] && (
            <p>Estimated Training Days: {trainingSkill["Days to Train"]}</p>
          )}
          {trainingSkill["Last Updated"] && (
            <p>Training Last Updated {moment(trainingSkill["Last Updated"]).format('M/D/YYYY')}</p>
          )}
          {trainingSkill?.trainers &&
            (
              <>
              <strong>Trainers</strong>
              <List>
                {trainingSkill?.trainers && trainingSkill?.trainers?.map((e, eIndex) => {
                  return <List.Item key={eIndex}><List.Icon name="user" />{e.employee.firstName} {e.employee.lastName}</List.Item>
                })}
              </List>
              </>
            )}
          <FieldLabel>Trainer</FieldLabel>
          <IsAuthorized permission="hasTrainingAdmin" notAuthorizedContent={<DisplayReadOnly>{trainingSkill.employeeTrainingStatus?.trainer}</DisplayReadOnly>}>
            <DebounceInput placeholder="Trainer Name(s)" value={trainingSkill.employeeTrainingStatus?.trainer || ''} trainingskillid={trainingSkill.trainingSkillId} onChange={handleChangeTrainingChange}
              proptochange="trainer" style={{marginBottom: "10px"}} />
          </IsAuthorized>
          <FieldLabel>Start Date</FieldLabel>
          <IsAuthorized permission="hasTrainingAdmin" notAuthorizedContent={<DisplayReadOnly>{(trainingSkill.employeeTrainingStatus?.trainingStartDate) ? moment(trainingSkill.employeeTrainingStatus.trainingStartDate).format("MM/DD/YY") : ''}</DisplayReadOnly>}>
            <DebounceInput
              inputType="DateInput"
              closable
              proptochange="trainingStartDate"
              trainingskillid={trainingSkill.trainingSkillId}
              placeholder="start"
              dateFormat="MM/DD/YY"
              className="date-picker"
              value={(trainingSkill.employeeTrainingStatus?.trainingStartDate) ? moment(trainingSkill.employeeTrainingStatus.trainingStartDate).format("MM/DD/YY") : ''}
              onChange={handleChangeTrainingChange}
              hideMobileKeyboard={true}
              style={{marginBottom: "10px"}}
            />
          </IsAuthorized>
          <DisplayTrainingLink trainingSkill={trainingSkill} columnName="Training Material Link" text="View Training Material" icon="file outline">
            <DebounceInput
              inputType="DateInput"
              closable
              label={<FieldLabel>Reviewed Training Material</FieldLabel>}
              proptochange="reviewTrainingMaterialDate"
              trainingskillid={trainingSkill.trainingSkillId}
              placeholder="start"
              dateFormat="MM/DD/YY"
              className="date-picker"
              value={(trainingSkill.employeeTrainingStatus?.reviewTrainingMaterialDate) ? moment(trainingSkill.employeeTrainingStatus.reviewTrainingMaterialDate).format("MM/DD/YY") : ''}
              onChange={handleChangeTrainingChange}
              hideMobileKeyboard={true}
              style={{marginBottom: "10px"}}
            />
          </DisplayTrainingLink>
          <DisplayTrainingLink trainingSkill={trainingSkill} columnName="Training Test" text="Take Test">
            <FieldLabel>Test Score or Pass/Fail</FieldLabel>
            <IsAuthorized permission="hasTrainingAdmin" notAuthorizedContent={<DisplayReadOnly>{trainingSkill.employeeTrainingStatus?.testScore || ''}</DisplayReadOnly>}>
              <DebounceInput size="mini"
                placeholder="100% or PASS" value={trainingSkill.employeeTrainingStatus?.testScore || ''} trainingskillid={trainingSkill.trainingSkillId} onChange={handleChangeTrainingChange}
                proptochange="testScore"
                style={{marginBottom: "10px"}} />
            </IsAuthorized>
          </DisplayTrainingLink>
          <FieldLabel>Certification</FieldLabel>
          <IsAuthorized permission="hasTrainingAdmin" notAuthorizedContent={<DisplayReadOnly>{(trainingSkill.employeeTrainingStatus?.certificationDate) ? moment(trainingSkill.employeeTrainingStatus.certificationDate).format("MM/DD/YY") : ''}</DisplayReadOnly>}>
            <DebounceInput
              inputType="DateInput"
              closable
              proptochange="certificationDate"
              trainingskillid={trainingSkill.trainingSkillId}
              placeholder="start"
              dateFormat="MM/DD/YY"
              className="date-picker"
              value={(trainingSkill.employeeTrainingStatus?.certificationDate) ? moment(trainingSkill.employeeTrainingStatus.certificationDate).format("MM/DD/YY") : ''}
              onChange={handleChangeTrainingChange}
              hideMobileKeyboard={true}
              style={{marginBottom: "10px"}}
            />
          </IsAuthorized>
          <DisplayRecertifcationFields employeeTrainingStatus={trainingSkill.employeeTrainingStatus}>
            <>
              <FieldLabel>Trainer (Recertification)</FieldLabel>
              <IsAuthorized permission="hasTrainingAdmin" notAuthorizedContent={trainingSkill.employeeTrainingStatus?.recertificationTrainer || null}>
                <DebounceInput
                  placeholder="Trainer Name(s)"
                  value={trainingSkill.employeeTrainingStatus?.recertificationTrainer || ''}
                  trainingskillid={trainingSkill.trainingSkillId} onChange={handleChangeTrainingChange}
                  proptochange="recertificationTrainer"
                  style={{ marginBottom: "10px" }} />
              </IsAuthorized>
              <FieldLabel>Recertification</FieldLabel>
              <IsAuthorized permission="hasTrainingAdmin" notAuthorizedContent={<DisplayReadOnly>{(trainingSkill.employeeTrainingStatus?.recertificationDate) ? moment(trainingSkill.employeeTrainingStatus.recertificationDate).format("MM/DD/YY") : null}</DisplayReadOnly>}>
                <DebounceInput
                  inputType="DateInput"
                  closable
                  proptochange="recertificationDate"
                  trainingskillid={trainingSkill.trainingSkillId}
                  placeholder="start"
                  dateFormat="MM/DD/YY"
                  className="date-picker"
                  value={(trainingSkill.employeeTrainingStatus?.recertificationDate) ? moment(trainingSkill.employeeTrainingStatus.recertificationDate).format("MM/DD/YY") : ''}
                  onChange={handleChangeTrainingChange}
                  hideMobileKeyboard={true}
                  style={{ marginBottom: "10px" }}
                />
              </IsAuthorized>
            </>
          </DisplayRecertifcationFields>
          <IsAuthorized permission="hasTrainingAdmin">
            <>
            {trainingSkill.employeeTrainingStatus?.certificationDate && (
            <Checkbox
              label="Need recertification?"
              proptochange="needRecertification"
              checked={(trainingSkill.employeeTrainingStatus?.needRecertification) ? true : false}
              trainingskillid={trainingSkill.trainingSkillId}
              onChange={handleChangeTrainingChange}
              style={{display: "block", marginBottom: "10px"}} />
            )}
            </>
          </IsAuthorized>
          <IsAuthorized permission="hasTrainingAdmin">
            <Checkbox
              label="Is certified trainer?"
              proptochange="isCertifiedTrainer"
              checked={(trainingSkill.employeeTrainingStatus?.isCertifiedTrainer) ? true : false}
              trainingskillid={trainingSkill.trainingSkillId}
              onChange={handleChangeTrainingChange}
              style={{display: "block", marginBottom: "25px"}} />
          </IsAuthorized>
          <FieldLabel>Notes</FieldLabel>
          <IsAuthorized permission="hasTrainingAdmin" notAuthorizedContent={<DisplayReadOnly>{trainingSkill.employeeTrainingStatus?.notes || ''}</DisplayReadOnly>}>
            <DebounceInput inputType="TextArea"
              placeholder="Enter any significant notes"
              value={trainingSkill.employeeTrainingStatus?.notes || ''}
              trainingskillid={trainingSkill.trainingSkillId}
              onChange={handleChangeTrainingChange}
              proptochange="notes"
              style={{ marginBottom: "10px" }} />
          </IsAuthorized>
          {isDirty && (
          <Button
            trainingskillid={trainingSkill.trainingSkillId}
            onClick={handleUpdateItem}
            icon
            color="blue"
            style={{ marginBottom: "5px" }}><Icon name="save" /> Save</Button>
          )}
            <Transition visible={isSaving} duration={500}><div>...Saving</div></Transition>
        </Container>
      </Accordion.Content>
    </React.Fragment>
  )
}

function DisplayTrainingLink({ trainingSkill, columnName, children, text, icon }) {
  if (!trainingSkill[columnName]) {
    return <></>;
  }

  const trainingMaterial = trainingSkill[columnName];
  if (trainingMaterial.substring(0, 4).toLowerCase() === 'http') {
    return (
      <>
        <Button as="a" icon={icon ? true : false} primary href={trainingMaterial} target="_blank">
          {icon ? <Icon name={icon} style={{ paddingRight: "20px"}} /> : ''}
          {text}
        </Button>
        {children}
      </>)
  }
  return <>{trainingMaterial}</>
}

function DisplayReadOnly ({ children }) {
  if (!children) {
    return <p style={{ margin: MARGIN_SM}}><em>Not Assigned</em></p>
  }
  return <p style={{ margin: MARGIN_SM}}>{children}</p>
}

function DisplayRecertifcationFields({ employeeTrainingStatus, children}) {
  if (!employeeTrainingStatus) {
    return <></>
  }
  if (employeeTrainingStatus.needRecertification === true || employeeTrainingStatus.recertificationDate) {
    return children;
  }
  return <></>
}