import React from "react";
import { Icon, Label } from "semantic-ui-react";

const ShowBudgetPercentageDifferences = ({ actual, projected }) => {
  let color = "grey";
  let caretDirection = "";

  let differenceValue = actual - projected;
  if (actual < projected) {
    color = "green";
    caretDirection = "caret down";
  } else if (actual > projected) {
    color = "red";
    caretDirection = "caret up";
  }

  function isValidNumber(value) {
    if (isNaN(value) || !isFinite(value)) {
      return false;
    }
    return true;
  }

  return (
    <Label size="small" color={color}>
      {(projected * 100).toFixed(2)}% <Icon name={`${caretDirection}`} />
      {(isValidNumber(differenceValue)) && (
        <>{(Math.abs(differenceValue) * 100).toFixed(2)}%</>
      )}
    </Label>
  );
};

export default ShowBudgetPercentageDifferences