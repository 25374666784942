import React, { useReducer } from "react";
import PropTypes from "prop-types";
import pageDataReducer from "../reducers/page-data/pageDataReducer";

export const PageDataContext = React.createContext({});

const initialState = {
  selectedPage: "",
  seletedPageTitle: ""
};

export default function PageDataProvider ({ children }) {
  const pageData = useReducer(pageDataReducer, initialState);
  return (
    <PageDataContext.Provider value={pageData}>
      { children }
    </PageDataContext.Provider>
  );
}

PageDataProvider.propTypes = {
  children: PropTypes.any
};