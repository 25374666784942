import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useUserData from "../state-manipulation/hooks/useUserData";
import { clearUser } from "../state-manipulation/reducers/user-data/types";

export default function LogOut() {
  const [userData, userDispatch] = useUserData();
  const navigate = useNavigate();

  useEffect(()=> {
    userDispatch({
      type: clearUser
    })
  }, []);

  useEffect(() => {
    if (!userData.token) {
      navigate('/login');
    }
  }, [userData]);

  return (
    <>Logging User Out...</>
  )
}