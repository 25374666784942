import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Header,
  Table,
  Icon,
  Input,
  Confirm,
  Button,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { LG_WIDTH } from "../../constants";
import useUserData from "../../state-manipulation/hooks/useUserData";
import usePageData from "../../state-manipulation/hooks/usePageData";
import { setSelectedPage } from "../../state-manipulation/reducers/page-data/types";
import { clearUser } from "../../state-manipulation/reducers/user-data/types";
import PageHeader from "../common/PageHeader";
import AxiosWrapper from "../../utils/AxiosWrapper";
import ReactToPrint from "react-to-print";
import { useRef } from "react";
import DraftListPrint from "./DraftListPrint";

export default function ManageDraftList({ className }) {
  const [userData, userDispatch] = useUserData();
  const [draftList, setDraftList] = useState([]);
  const [pageData, pageDispatch] = usePageData();
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(0);
  const [timeoutId, setTimeoutId] = useState("");
  const componentRef = useRef();

  const getDraftList = async (company) => {
    try {
      setIsLoading(true);
      let res = await AxiosWrapper({
        method: "GET",
        url: `${process.env.API_URL}/${company}/draft-list`,
        headers: {
          Authorization: `Basic ${userData.token}`,
        },
      }).catch((e) => {
        if (e.response.status === 401) {
          userDispatch({
            type: clearUser,
            payload: {},
          });
        }
      });

      setDraftList(res.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleDeleteClick = (e, { index }) => {
    let _draftList = [...draftList];
    _draftList.splice(index, 1);
    setDraftList(_draftList);
  };

  const handleConfirmDelete = (e) => {
    handleDeleteClick(e, { index: deleteIndex });
    setShowDeleteConfirm(false);
  };

  const handleUpClick = (e, { index }) => {
    let _draftList = [...draftList];
    let beforeIndex = index > 0 ? index - 1 : _draftList.length - 1;
    _draftList = swapArrayElements(_draftList, beforeIndex, index);
    setDraftList(_draftList);
  };
  const handleDownClick = (e, { index }) => {
    let _draftList = [...draftList];
    let afterIndex = index >= _draftList.length - 1 ? 0 : index + 1;
    _draftList = swapArrayElements(_draftList, afterIndex, index);
    setDraftList(_draftList);
  };

  const swapArrayElements = function (sourceArr, indexA, indexB) {
    let arr = [...sourceArr];
    let temp = arr[indexA];
    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
    return arr;
  };

  const handleDraftPropChange = (e, { value, attr, index }) => {
    updateDraftListItem(value, attr, index);
  };

  const updateDraftListItem = (value, prop, index) => {
    let _draftList = [...draftList];
    _draftList[index][prop] = value;
    setDraftList(_draftList);
  };

  const handleAddDraft = () => {
    let _draftList = [...draftList];
    _draftList.push({
      style: "",
      brewery: "",
      name: "",
      location: "",
      abv: "",
      ibu: "",
    });
    setDraftList(_draftList);
  };

  const handleSaveDraftList = () => {
    saveDraftList(userData.selectedOrg.name.toLowerCase(), draftList);
  };

  const handlePrintDraftList = () => {};

  const saveDraftList = async (company, _draftList) => {
    try {
      setIsLoading(true);
      let res = await AxiosWrapper({
        method: "PUT",
        url: `${process.env.API_URL}/${company}/draft-list`,
        data: {
          draftList: _draftList,
        },
        headers: {
          Authorization: `Basic ${userData.token}`,
        },
      });

      setDraftList(res.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: "Draft List",
      },
    });
  }, []);

  useEffect(() => {
    if (userData.selectedOrg) {
      clearTimeout(timeoutId);
      setTimeoutId(
        setTimeout(() => {
          getDraftList(userData.selectedOrg.name.toLowerCase());
        }, 1000)
      );
    }
  }, []);

  useEffect(() => {
    if (userData.selectedOrg) {
      clearTimeout(timeoutId);
      setTimeoutId(
        setTimeout(() => {
          getDraftList(userData.selectedOrg.name.toLowerCase());
        }, 1000)
      );
    }
  }, [userData.selectedOrg]);

  useEffect(() => {}, [draftList]);

  return (
    <div className={"content-container edit-table-container " + className}>
      <PageHeader pageName="Draft List" orgName={userData.selectedOrg?.name} />
      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      <Button onClick={handleAddDraft} color="blue">
        Add Draft
      </Button>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Style</Table.HeaderCell>
            <Table.HeaderCell>Brewery</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Location</Table.HeaderCell>
            <Table.HeaderCell>ABV%</Table.HeaderCell>
            <Table.HeaderCell>IBU</Table.HeaderCell>
            <Table.HeaderCell className="edit-buttons" />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {draftList &&
            draftList.map((draft, draftIndex) => {
              return (
                <Table.Row key={draftIndex}>
                  <Table.Cell textAlign="center">{draftIndex + 1}</Table.Cell>
                  <Table.Cell>
                    <Input
                      value={draft.style}
                      index={draftIndex}
                      attr="style"
                      onChange={handleDraftPropChange}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      value={draft.brewery}
                      index={draftIndex}
                      attr="brewery"
                      onChange={handleDraftPropChange}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      value={draft.name}
                      index={draftIndex}
                      attr="name"
                      onChange={handleDraftPropChange}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      value={draft.location}
                      index={draftIndex}
                      attr="location"
                      onChange={handleDraftPropChange}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      value={draft.abv}
                      index={draftIndex}
                      attr="abv"
                      className="small-field"
                      onChange={handleDraftPropChange}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      value={draft.ibu}
                      index={draftIndex}
                      attr="ibu"
                      className="small-field"
                      onChange={handleDraftPropChange}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Icon
                      name="delete"
                      className="icon-button"
                      index={draftIndex}
                      onClick={() => {
                        setShowDeleteConfirm(true);
                        setDeleteIndex(draftIndex);
                      }}
                    />
                    <Confirm
                      open={showDeleteConfirm}
                      onCancel={() => {
                        setShowDeleteConfirm(false);
                      }}
                      onConfirm={handleConfirmDelete}
                    />
                    <Icon
                      name="caret up"
                      className="icon-button"
                      index={draftIndex}
                      onClick={handleUpClick}
                    />
                    <Icon
                      name="caret down"
                      className="icon-button"
                      index={draftIndex}
                      onClick={handleDownClick}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
      <Button onClick={handleSaveDraftList} color="blue">
        Save Draft List
      </Button>
      <ReactToPrint
        trigger={() => <Button primary>Print Draft List</Button>}
        content={() => componentRef.current}
      />
      <div style={{ display: "none" }}>
        <DraftListPrint ref={componentRef} draftList={draftList} />
      </div>
    </div>
  );
}

ManageDraftList.propTypes = {
  className: PropTypes.string,
};

ManageDraftList.defaultProps = {
  className: "",
};
