import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Segment, Grid, Icon } from 'semantic-ui-react';
import { DateTimeInput } from 'semantic-ui-calendar-react';
import moment from 'moment';

import useDateRangeData from '../../state-manipulation/hooks/useDateRangeData';
import {
  setDateStart,
  setDateEnd
} from '../../state-manipulation/reducers/date-range-data/types';

import './styles/DateRangeSelector.scss';
import Moment from 'react-moment';

export function DateTimeRangeSelectorForm({
  dateRangeData,
  handleFastBackwardClick,
  handleFastForwardClick,
  handleCaretLeftClick,
  handleDateStartChange,
  handleDateEndChange,
  handleCaretRightClick
}) {
  return (
    <Segment className="date-selector">
      <Grid columns="equal" padded="horizontally">
        <Grid.Column verticalAlign="middle">
          <Grid.Row>
            <span className="day-display">
              <Moment format="ddd">{dateRangeData.dateStart}</Moment> -{' '}
              <Moment format="ddd">{dateRangeData.dateEnd}</Moment>
            </span>
            <Icon
              name="fast backward"
              className="fast-backward-button"
              onClick={handleFastBackwardClick}
            />
            <Icon
              name="fast forward"
              className="fast-foward-button"
              onClick={handleFastForwardClick}
            />
          </Grid.Row>
          <Grid.Row>
            <Icon
              name="caret left"
              className="date-adjust-button"
              size="big"
              onClick={handleCaretLeftClick}
            />
            <DateTimeInput
              closable
              dateFormat="MM/DD/YY"
              placeholder="start"
              className="date-start date-time-picker"
              value={dateRangeData.dateStart}
              hideMobileKeyboard={true}
              onChange={handleDateStartChange}
            />
            <DateTimeInput
              closable
              dateFormat="MM/DD/YY"
              placeholder="end"
              className="date-time-picker"
              value={dateRangeData.dateEnd}
              hideMobileKeyboard={true}
              onChange={handleDateEndChange}
            />
            <Icon
              name="caret right"
              className="date-adjust-button"
              size="big"
              onClick={handleCaretRightClick}
            />
          </Grid.Row>
        </Grid.Column>
      </Grid>
    </Segment>
  );
}

DateTimeRangeSelectorForm.propTypes = {
  dateRangeData: PropTypes.object.isRequired,
  handleFastBackwardClick: PropTypes.func,
  handleFastForwardClick: PropTypes.func,
  handleCaretLeftClick: PropTypes.func,
  handleDateStartChange: PropTypes.func,
  handleDateEndChange: PropTypes.func,
  handleCaretRightClick: PropTypes.func
};

DateTimeRangeSelectorForm.defaultProps = {
  handleFastBackwardClick: () => {},
  handleFastForwardClick: () => {},
  handleCaretLeftClick: () => {},
  handleDateStartChange: () => {},
  handleDateEndChange: () => {},
  handleCaretRightClick: () => {}
};

export default function DateTimeRangeSelector() {
  const [dateRangeData, dateRangeDispatch] = useDateRangeData();

  const handleDateStartChange = (e, { value }) => {
    dateRangeDispatch({
      type: setDateStart,
      payload: {
        dateStart: value
      }
    });
  };

  const handleDateEndChange = (e, { value }) => {
    dateRangeDispatch({
      type: setDateEnd,
      payload: {
        dateEnd: value
      }
    });
  };

  const handleCaretLeftClick = () => {
    dateRangeDispatch({
      type: setDateStart,
      payload: {
        dateStart: moment(dateRangeData.dateStart)
          .subtract(1, 'days')
          .format('MM/DD/YY')
      }
    });

    dateRangeDispatch({
      type: setDateEnd,
      payload: {
        dateEnd: moment(dateRangeData.dateEnd)
          .subtract(1, 'days')
          .format('MM/DD/YY')
      }
    });
  };

  const handleCaretRightClick = () => {
    dateRangeDispatch({
      type: setDateStart,
      payload: {
        dateStart: moment(dateRangeData.dateStart)
          .add(1, 'days')
          .format('MM/DD/YY')
      }
    });

    dateRangeDispatch({
      type: setDateEnd,
      payload: {
        dateEnd: moment(dateRangeData.dateEnd)
          .add(1, 'days')
          .format('MM/DD/YY')
      }
    });
  };

  const handleFastBackwardClick = () => {
    dateRangeDispatch({
      type: setDateStart,
      payload: {
        dateStart: moment(dateRangeData.dateStart)
          .subtract(7, 'days')
          .format('MM/DD/YY')
      }
    });

    dateRangeDispatch({
      type: setDateEnd,
      payload: {
        dateEnd: moment(dateRangeData.dateEnd)
          .subtract(7, 'days')
          .format('MM/DD/YY')
      }
    });
  };

  const handleFastForwardClick = () => {
    dateRangeDispatch({
      type: setDateStart,
      payload: {
        dateStart: moment(dateRangeData.dateStart)
          .add(7, 'days')
          .format('MM/DD/YY')
      }
    });

    dateRangeDispatch({
      type: setDateEnd,
      payload: {
        dateEnd: moment(dateRangeData.dateEnd)
          .add(7, 'days')
          .format('MM/DD/YY')
      }
    });
  };

  const dateRangeFormMemo = useMemo(() => {
    return (
      <DateTimeRangeSelectorForm
        dateRangeData={dateRangeData}
        handleFastBackwardClick={handleFastBackwardClick}
        handleFastForwardClick={handleFastForwardClick}
        handleCaretLeftClick={handleCaretLeftClick}
        handleDateStartChange={handleDateStartChange}
        handleDateEndChange={handleDateEndChange}
        handleCaretRightClick={handleCaretRightClick}
      />
    );
  }, [dateRangeData]);

  // useEffect(() => {
  //   dateRangeDispatch({
  //     type: setDateStart,
  //     payload: {
  //       dateStart: moment(dateRangeData.dateStart).add(startDateOffSet,'days').format('MM/DD/YY')
  //     }
  //   });

  //   dateRangeDispatch({
  //     type: setDateEnd,
  //     payload: {
  //       dateEnd: moment(dateRangeData.dateEnd).add(endDateOffset,'days').format('MM/DD/YY')
  //     }
  //   });
  // }, []);

  return <>{dateRangeFormMemo}</>;
}

DateTimeRangeSelector.propTypes = {
  startDateOffSet: PropTypes.number,
  endDateOffset: PropTypes.number
};

DateTimeRangeSelector.defaultProps = {
  startDateOffSet: 0,
  endDateOffset: 0
};
