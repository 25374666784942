import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  Button,
  Checkbox,
  Dimmer,
  Header,
  Loader,
  Segment,
  Table,
} from "semantic-ui-react";
import PageHeader from "../components/common/PageHeader";
import useUserData from "../state-manipulation/hooks/useUserData";
import usePageData from "../state-manipulation/hooks/usePageData";
import { setSelectedPage } from "../state-manipulation/reducers/page-data/types";
import DateRangeContextSelector from "../components/common/DateRangeContextSelector";
import { useContext } from "react";
import { DateRangeContext } from "../context/DateRangeContext";
import { useRef } from "react";
import { GetProductItemSales, GetUniqueProducts } from "../api/ToastAPI";

const BAR_COSTS = [
  "BEER",
  "DRAFT BEERS",
  "DRAFT BEER",
  "SIDE BAR",
  "SPIRITS",
  "WINE",
  "SAKE/SOJU",
  "BAR SYRUPS/FRUIT PRODUCE",
];

const FOOD_COSTS = [
  "BAKED GOODS",
  "BAKERY",
  "DAIRY",
  "DRY GOODS",
  "FROZEN",
  "MEAT",
  "POULTRY",
  "PRODUCE",
  "SEAFOOD",
];

const Colors = [
  "#93E1D8",
  "#336699",
  "#86BBD8",
  "#FFFF99",
  "#00B3E6",
  "#E6B333",
  "#2A547E",
  "#6E9AB1",
  "#273B49",
  "#93E1D8",
  "#336699",
  "#86BBD8",
  "#2F4858",
  "#B5D1CB",
  "#79B9B1",
  "#2A547E",
  "#6E9AB1",
  "#273B49",
];
const Colors2 = [
  "#FF6633",
  "#FFB399",
  "#FF33FF",
  "#FFFF99",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#809980",
  "#E6FF80",
  "#1AFF33",
];

export default function ProductItemSales({ className }) {
  const [, pageDispatch] = usePageData();
  const [userData] = useUserData();
  const { company } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const { dateStart, dateEnd } = useContext(DateRangeContext);
  const [ menuItems, setMenuItems ] = useState([]);
  const [ selectedMenuItems, setSelectedMenuItems ] = useState([]);
  const [ productItemSales, setProductItemSales ] = useState([]);
  const uniqueProductsTimeoutId = useRef();
  const productSalesTimeoutId = useRef();

  const getUniqueProducts = async ({ dateStart, dateEnd, company}) => {
    const res = await GetUniqueProducts({ dateStart, dateEnd, company});
    setMenuItems(res.data);
  }

  const getProductItemSales = async ({ dateStart, dateEnd, company, selectedMenuItems}) => {
    const res = await GetProductItemSales({ dateStart, dateEnd, company, selectedMenuItems});

    let productItemSales = res.data.map(p => {
      p.label = moment(p._id).format("MM/DD"); 
      p.date = p._id;
      p.selectedMenuItems = [...selectedMenuItems];
      p.day = moment(p._id).format("ddd");
      return p;
    })

    setProductItemSales(productItemSales);
  }

  const handleProductItemSelect = ( e, props ) => {
    const { value, checked } = props;

    if (checked) {
      let _selectedMenuItems = [...selectedMenuItems];
      _selectedMenuItems.push(value);
      setSelectedMenuItems(_selectedMenuItems);
    } else {
      let itemIndex = selectedMenuItems.indexOf(value);
      if (itemIndex > -1) {
        let _selectedMenuItems = [...selectedMenuItems];
        _selectedMenuItems.splice(itemIndex, 1);
        setSelectedMenuItems(_selectedMenuItems);
      }
    }
  }

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: "PRODUCT ITEM SALES",
      },
    });
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (dateStart && dateEnd && company) {
      clearTimeout(uniqueProductsTimeoutId.current);
      uniqueProductsTimeoutId.current = setTimeout(() => {
        getUniqueProducts({ dateStart, dateEnd, company});
      }, 1000);
    }
  }, [dateStart, dateEnd, company]);

  useEffect(() => {

    if (selectedMenuItems.length > 0) {
      clearTimeout(productSalesTimeoutId.current);
      productSalesTimeoutId.current = setTimeout(() => {
        getProductItemSales(({ dateStart, dateEnd, company, selectedMenuItems}));
      }, 1000);
    }
  }, [selectedMenuItems, dateStart, dateEnd]);

  return (
    <div className={"content-container " + className}>
      <PageHeader
        pageName="Product Item Sales"
        orgName={userData.selectedOrg?.name}
      />

      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      {!isLoading && <></>}
      <DateRangeContextSelector />
      {productItemSales.length > 0 && (
        <Segment>
          <Header as="h2">Product Item Sales</Header>
          <ResponsiveContainer height={500}>
            <ComposedChart data={productItemSales}>
            <CartesianGrid strokeDasharray="3 3" />
              <Legend verticalAlign="top" height={36} />
              <XAxis dataKey="label" padding={{ left: 100, right: 25 }} minTickGap={10} />
              <YAxis yAxisId="left" label="Qty Sold" />
              <Tooltip content={CustomToolTip} />
              <Line yAxisId="left" type="monotone" dataKey="totalSales" stroke="blue" />
            </ComposedChart>
          </ResponsiveContainer>
        </Segment>
      )}
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <Button onClick={() => {
                setSelectedMenuItems([]);
              }}>Clear</Button>
            </Table.HeaderCell>
            <Table.HeaderCell>
              Menu Item Name
            </Table.HeaderCell>
            <Table.HeaderCell>
              Menu Group
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {menuItems && menuItems.map((mI, mIndex) => {
            return (
              <Table.Row key={mIndex}>
                <Table.Cell><Checkbox value={`${mI["Menu Item"]}|${mI["Menu Group"]}`} checked={selectedMenuItems.includes(`${mI["Menu Item"]}|${mI["Menu Group"]}`)} data-display-group={mI.displayGroup || 'default'} onChange={handleProductItemSelect} /></Table.Cell>
                <Table.Cell>{mI["Menu Item"]}</Table.Cell>
                <Table.Cell>{mI["Menu Group"]}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>

    </div>
  );
}

const CustomToolTip = (props) => {


  const { type, payload, label } = props;

  return (
    <div className="custom-tooltip">
      <p>{payload[0]?.payload.day} - {label}</p>
      <p>Item Qty: {payload[0]?.value}</p>
      <ul>
      {payload[0]?.payload.selectedMenuItems.map((i,k) => {
        return <li key={k}>{i}</li>
      })}
      </ul>
    </div>
  )
};