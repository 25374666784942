import React, { useReducer } from "react";
import PropTypes from "prop-types";
import userDataReducer from "../reducers/user-data/userDataReducer";

export const UserDataContext = React.createContext({});

const orgs = JSON.parse(window.localStorage.getItem("orgs")) || null;

const initialState = {
  orgId: window.localStorage.getItem("orgId") || null,
  isAdmin: window.localStorage.getItem("isAdmin") || false,
  isSuperAdmin: window.localStorage.getItem("isSuperAdmin") || false,
  token: window.localStorage.getItem("token") || null,
  userInfo: JSON.parse(window.localStorage.getItem("userInfo")) || null,
  orgs: orgs,
  selectedOrg: null,
  employeeByPin: null,
  isScreenLocked: true
};

export default function UserDataProvider({ children }) {
  const userData = useReducer(userDataReducer, initialState);
  return (
    <UserDataContext.Provider value={userData}>
      {children}
    </UserDataContext.Provider>
  );
}

UserDataProvider.propTypes = {
  children: PropTypes.any
};