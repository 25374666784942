import AxiosWrapper from '../utils/AxiosWrapper';

export async function GetStockPots({startDate, endDate, company}) {
  try {
    let url = `${process.env.API_URL}/${company}/stock-pots`;

    let options = [];

    if (startDate) {
      options.push(`startDate=${startDate}`);
    }

    if (endDate) {
      options.push(`endDate=${endDate}`);
    }

    if (options.length > 0) {
      url +=  `?${options.join('&')}`;
    }

    let res = await AxiosWrapper({
      method: 'GET',
      url: url,
      isAuthRoute: true
    });
    return res;
  } catch (e) {
    console.error(e);
  }
}
