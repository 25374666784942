import React, { useEffect, useRef, useState } from 'react';
import PropTypes from "prop-types";

import {
  Form,
  Input, TextArea
} from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';

export default function DebounceInput(props) {
  const [ debouncedValue, setDeboucedValue] = useState(props.value);
  const { clearValue } = props;
  const timeoutId = useRef();
  let newProps = {...props};
  delete newProps.value;
  delete newProps.onChange;
  delete newProps.clearValue;


  function handleOnChange (e, inputProps) {
    setDeboucedValue(inputProps.value);
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      inputProps.value = inputProps.value;
      props.onChange(e, inputProps);
    }, props.timeout || 500);

  }

  useEffect(() => {
    if (clearValue === true) {
      setDeboucedValue('');
    }
  }, [clearValue]);

  useEffect(() => {
    setDeboucedValue(props.value);
  }, [props.value]);

  if (props.inputType === 'TextArea') {
    delete newProps.inputType;
    return <Form><TextArea {...newProps} value={debouncedValue} onChange={handleOnChange} /></Form>
  }

  if (props.inputType === 'DateInput') {
    delete newProps.inputType;
    return <DateInput
      {...newProps} value={debouncedValue} onChange={handleOnChange}
    />
  }

  return <Input {...newProps} value={debouncedValue} onChange={handleOnChange} />
}

DebounceInput.propTypes = {
  inputType: PropTypes.string,
  clearValue: PropTypes.bool
}
