import React from "react";
import { Button, FormField, Icon, Label, Popup, Table } from "semantic-ui-react";

function DeleteCostSummaryReportOptions({ data, handleDelete }) {
  function DisplayWrongCloseDateWarning({ groupId }) {

    if (!groupId) {
      return <></>
    }

    let warningMessage = '';

    try {
      let datePartsString = groupId.replace('FOODAGER-','').replace('BEVAGER-','');

      const openDate = new Date(datePartsString.substring(0, 10))
      const openDay = openDate.getDay()
      const closeDate = new Date(datePartsString.substring(10, 20))
      const closeDay = closeDate.getDay()

      if (openDay !== 0) {
        warningMessage += `${groupId.split('-')[0]} start date should be Monday<br />`;
      }

      if (closeDay !== 6) {
        warningMessage += `${groupId.split('-')[0]} close date should be Sunday<br />`;
      }

    } catch (e) {
      console.error("🚀 ~ DisplayWrongCloseDateWarning ~ e:", e)
      warningMessage = null;
      return <span>Something went wrong with the invnentory date</span>
    }

    if (warningMessage) {
      return <Label basic color="red" pointing="above"><span dangerouslySetInnerHTML={{ __html: warningMessage }} /></Label>
    }
    return <></>
  }

  function ShowGroupId({ costSummary }) {
    const groupIds = [];

    Object.keys(costSummary).forEach((key) => {
      if (!costSummary[key].source) {
        return;
      }
      costSummary[key].source.forEach((s) => {
        if (groupIds.indexOf(s.groupId) > -1) {
          return;
        }
        groupIds.push(s.groupId);
      });
    });

    return (
      <>
        {groupIds.map((gId, gIdIndex) => {
          return (
            <React.Fragment key={gIdIndex}>
              <FormField>
                <Button color="red" inverted data-gid={gId} onClick={handleDelete} icon={<Icon name="delete"></Icon>} size="mini" />
                <span style={{ fontSize: "12px"}}>{gId}</span>
                <DisplayWrongCloseDateWarning groupId={gId} />
              </FormField>
            </React.Fragment>
          );
        })}
      </>
    );
  }
  return (
    <Table.Row>
      <Table.Cell>
        COGS Data import
        <Popup
          content={
            <span style={{ fontSize: "12px", fontWeight: "normal" }}>
              This is the Craftable Cost Summary Report that's imported
              <br />
              <br />
              If there is more than one report imported for Foodager/Bevager, delete one of the reports. Keep the one of the date that matches the date of the inventory report in Craftable.
            </span>
          }
          trigger={
            <Icon name="help circle" style={{ verticalAlign: "super" }} />
          }
        />
      </Table.Cell>
      {data?.weeks &&
        data.weeks.map((w, i) => {
          return (
            <Table.Cell key={i} textAlign="right">
              <ShowGroupId costSummary={w.costSummary} />
            </Table.Cell>
          );
        })}
        <Table.Cell />
    </Table.Row>
  );
}

export default DeleteCostSummaryReportOptions;
