import React, { useContext, useEffect, useRef, useState } from "react";
import usePageData from "../state-manipulation/hooks/usePageData";
import { setSelectedPage } from "../state-manipulation/reducers/page-data/types";
import { GetEmployeeStatus } from "../api/TrainingAPI";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Dimmer, Icon, Loader } from "semantic-ui-react";
import { EmployeesTrainingStatusContext, actions } from "../context/EmployeesTrainingStatusContext";
import useUserData from "../state-manipulation/hooks/useUserData";
import DisplayTrainingStatus from "../components/training/DisplayTrainingStatus";
import DebounceInput from "../components/forms/DebounceInput";

export default function TrainingAdminEmployeeDetail({ selectedPage }) {
  const isSearched = useRef();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ searchText, setSearchText ] = useState('');
  const [ clearValue, setClearValue ] = useState(false);
  const [ userData ] = useUserData();
  const { company, externalEmployeeId } = useParams();
  const [ _externalEmployeeId, setExternalEmployeeId ] = useState(null);
  const prevCompany = useRef();
  const [, pageDispatch] = usePageData();
  const navigate = useNavigate();
  const [employeesTrainingStatus, employeeTrainingStatusDispatch] = useContext(EmployeesTrainingStatusContext);

  const getEmployeeStatus = async () => {
    const employeeId = externalEmployeeId || userData.userInfo?.email;

    setIsLoading(true);
    const res = await GetEmployeeStatus({ company,  externalEmployeeId: employeeId});
    setExternalEmployeeId(res.data.employee.externalEmployeeId);

    employeeTrainingStatusDispatch({
      type: actions.populateEmployeesJobReferenceTrainingStatus,
      payload: {
        employees: [res.data.employee],
        trainingSkills: res.data.trainingSkills
      }
    });
    setIsLoading(false);
  };

  const handleSearchInputChange = (e, { value }) => {
    setSearchText(value);
  }

  const handleSearchInputClear = () => {
    setClearValue(true);
    setSearchText('');
  }

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: selectedPage,
      },
    });

    getEmployeeStatus();

    return () => {
      employeeTrainingStatusDispatch({
        type: actions.clear
      })
    }
  }, [, selectedPage]);

  useEffect(() => {
    if (employeesTrainingStatus.fetchNewData) {
      getEmployeeStatus();
    }

  }, [employeesTrainingStatus.fetchNewData]);

  useEffect(() => {
    if (typeof prevCompany.current !== 'undefined' && company !== prevCompany.current) {
      navigate(`/${company}/training/admin`);
    }
    prevCompany.current = company;

  }, [company]);

  useEffect(() => {
    if (externalEmployeeId) {
      setExternalEmployeeId(externalEmployeeId);
    }
  }, [externalEmployeeId]);

  useEffect(() => {
    if (searchText.trim() === '') {
      if (!isSearched.current) {
        return;
      }
    }

    setClearValue(false);

    if (!isSearched.current) {
      isSearched.current = true;
    }
    employeeTrainingStatusDispatch({
      type: actions.populateEmployeesJobReferenceTrainingStatus,
      payload: {
        searchTerm: searchText
      }
    });
  }, [searchText]);


  return (
    <div className={"content-container"}>
      <Dimmer active={isLoading}>
        <Loader active={isLoading} />
      </Dimmer>
      {!isLoading && (
        <>
          <h2>{employeesTrainingStatus?.employees[0]?.firstName} {employeesTrainingStatus?.employees[0]?.lastName}</h2>
          <p><Link to={`/${company}/training/admin`}>&lt;&lt; Back</Link></p>
          <DebounceInput icon={<Icon name={searchText ? "close": "search"} link onClick={handleSearchInputClear} />} placeholder="search" clearValue={clearValue} value={searchText} onChange={handleSearchInputChange} />
          <DisplayTrainingStatus employee={employeesTrainingStatus?.employees[0]} />
        </>
      )}
    </div>
  );
}
