import React from "react";
import ReactMarkdown from "react-markdown";
import { Accordion, Grid, Header, Icon, Segment } from "semantic-ui-react";
import videoEmbed from "../../lib/video-embed-remark";
import { useEffect } from "react";
import { useState } from "react";
import rehypeRaw from "rehype-raw";
import usePageData from "../state-manipulation/hooks/usePageData";
import { setSelectedPage } from "../state-manipulation/reducers/page-data/types";
import remarkGfm from "remark-gfm";
import PageHeader from "../components/common/PageHeader";
import IsAuthorized from "../components/common/IsAuthorized";
import { useParams } from "react-router-dom";
import AxiosWrapper from "../utils/AxiosWrapper";
import Moment from "react-moment";
import ContentList from "../components/ContentList";
import AccessResource from "../components/common/AccessResource";

export default function TeamSiteOverview({
  pageName,
  selectedPage,
}) {
  const [content, setContent] = useState("");
  const [, pageDispatch] = usePageData();
  const { company } = useParams();
  const [announcements, setAnnouncements] = useState([]);
  const [companyEvents, setCompanyEvents] = useState([]);
  const [thirdPartyTools, setThirdPartyTools] = useState([]);
  const [policies, setPolices] = useState([]);
  const [weeklyTasks, setWeeklyTasks] = useState([]);
  const [activeTasks, setActiveTasks] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeCompanyEventIndex, setActiveCompanyEventIndex] = useState(0);

  async function getAnnouncements(company) {
    try {
      let res = await AxiosWrapper({
        method: "GET",
        url: `${process.env.API_URL}/${company}/announcements`,
        isAuthRoute: true,
      });

      setAnnouncements(res.data.data.slice(0, 5));
    } catch (e) {
      console.error(e);
    }
  }

  async function getWeeklyTasks(company) {
    try {
      let res = await AxiosWrapper({
        method: "GET",
        url: `${process.env.LTG_NEXT_APP_URL}/api/weekly-management-tasks`,
        isAuthRoute: true
      });

      setWeeklyTasks(res.data.data);
    } catch (e) {
      console.error(e);
    }
  }

  async function getSOPS(company) {
    try {
      let res = await AxiosWrapper({
        method: "GET",
        url: `${process.env.LTG_NEXT_APP_URL}/api/app-links?categoryFilter=Third%20Party%20Tools&categoryFilter=Policies&companyFilter=${company?.toUpperCase()}&new`,
        isAuthRoute: true
      });

      let _thirdPartyTools = res.data.data.filter(d => d.category === 'Tools');
      let _policies = res.data.data.filter(d => d.category === 'Policies');

      setThirdPartyTools(_thirdPartyTools[0].links);
      if (_policies[0]?.links) {
        setPolices(_policies[0].links);
      }

    } catch (e) {
      console.error(e);
    }
  }

  async function getEvents(company) {
    try {
      let res = await AxiosWrapper({
        method: "GET",
        url: `${process.env.API_URL}/${company}/events`,
        isAuthRoute: true,
      });

      setCompanyEvents(res.data.data);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    console.log(weeklyTasks);
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: selectedPage,
      },
    });
  }, []);

  useEffect(() => {
    getAnnouncements(company);
    getEvents(company);
    getWeeklyTasks();
    getSOPS(company);
  }, [company]);

  function getHeaderColor(_day) {
    const day = _day.toUpperCase();
    const days = [
      "SUNDAY",
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
    ];

    const currentDay = days[new Date().getDay()];

    if (currentDay === day.toUpperCase()) {
      return {
        color: "yellow",
        inverted: true,
        style: {
          marginTop: 0,
        },
      };
    }

    return {
      style: {
        backgroundColor: "#EEE",
        marginTop: 0,
      },
    };
  }

  function handleClick(e, titleProps) {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  }

  function handleCompanyEventClick(e, titleProps) {
    const { index } = titleProps;
    const newIndex = activeCompanyEventIndex === index ? -1 : index;
    setActiveCompanyEventIndex(newIndex);
  }

  function handleTaskClick(e, titleProps) {
    const { index } = titleProps;
    let _activeTasks = {...activeTasks};
    _activeTasks[index] = _activeTasks[index] === true ? false : true;
    setActiveTasks(_activeTasks);
  }


  return (
    <div className={"content-container"}>
      <Header as="h1">{pageName}</Header>
      {/* <ReactMarkdown
          remarkPlugins={[videoEmbed]}
          rehypePlugins={[rehypeRaw]}
          children=
          {"## Team Site"}
        /> */}

      <Grid container columns={2} stackable>
        <Grid.Column>
          <IsAuthorized permission="hasTeamSiteManagerAccess">
            <Segment.Group>
              <Segment inverted color="blue">
                Manager Tasks
              </Segment>
              {weeklyTasks &&
                weeklyTasks.map((wT, wTIndex) => {
                  return (
                    <React.Fragment key={wTIndex}>
                      <Segment
                        size="tiny"
                        {...getHeaderColor(wT.day)}
                        attached="top"
                      >
                        <strong>{wT.day}</strong>
                      </Segment>
                      <Accordion styled>
                        {wT.tasks &&
                          wT.tasks.map((t, tIndex) => {
                            return (
                              <AccessResource key={tIndex} accessCompany={t.company} company={company}>
                                <Accordion.Title
                                  active={activeTasks[`${wT.day}-${tIndex}`]}
                                  index={`${wT.day}-${tIndex}`}
                                  onClick={handleTaskClick}
                                >
                                  <Icon name="dropdown" />
                                  {t.title}
                                </Accordion.Title>
                                <Accordion.Content active={activeTasks[`${wT.day}-${tIndex}`]}>
                                  <ReactMarkdown
                                    remarkPlugins={[videoEmbed, remarkGfm]}
                                    linkTarget="_blank"
                                    rehypePlugins={[rehypeRaw]}
                                    children={t.description}
                                  />
                                </Accordion.Content>
                                </AccessResource>
                            );
                          })}
                      </Accordion>
                    </React.Fragment>
                  );
                })}
            </Segment.Group>
          </IsAuthorized>
          <Segment inverted color="grey" attached="top">
            Events
          </Segment>
          <Accordion styled>
            {companyEvents &&
              companyEvents.map((e, eIndex) => {
                const startDateTime = `${e.start_date} ${e.start_time}`;
                const endDateTime = `${e.end_date} ${e.end_time}`;
                return (
                  <React.Fragment key={eIndex}>
                    <Accordion.Title
                      active={activeCompanyEventIndex === eIndex}
                      index={eIndex}
                      onClick={handleCompanyEventClick}
                    >
                      <Icon name="dropdown" />
                      <Moment format="ddd M/DD h:mm A">{startDateTime}</Moment>-
                      <Moment format="h:mm A">{endDateTime}</Moment> - {e.title}
                    </Accordion.Title>
                    <Accordion.Content
                      active={activeCompanyEventIndex === eIndex}
                    >
                      <ReactMarkdown
                        remarkPlugins={[videoEmbed, remarkGfm]}
                        linkTarget="_blank"
                        rehypePlugins={[rehypeRaw]}
                        children={e.description}
                      />
                    </Accordion.Content>
                  </React.Fragment>
                );
              })}
          </Accordion>
        </Grid.Column>
        <Grid.Column>
          <Segment inverted color="grey" attached="top">
            Announcements
          </Segment>
          <Accordion styled>
            {announcements &&
              announcements.map((a, aIndex) => {
                return (
                  <React.Fragment key={aIndex}>
                    <Accordion.Title
                      active={activeIndex === aIndex}
                      index={aIndex}
                      onClick={handleClick}
                    >
                      <Icon name="dropdown" />
                      <Moment format="ddd M/DD h:mm A">
                        {a.announcement.created}
                      </Moment>{" "}
                      - {a.user.preferred_first_name}{" "}
                      {a.user.preferred_last_name}
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === aIndex}>
                      <ReactMarkdown
                        remarkPlugins={[videoEmbed]}
                        rehypePlugins={[rehypeRaw]}
                        children={a.announcement.message}
                      />
                    </Accordion.Content>
                  </React.Fragment>
                );
              })}
          </Accordion>
        </Grid.Column>
        <Grid.Column>
          <ContentList company={company} content={policies} title="Policies" />
        </Grid.Column>
        <Grid.Column>
          <ContentList company={company} content={thirdPartyTools} title="Tools" />
        </Grid.Column>
      </Grid>
    </div>
  );
}
