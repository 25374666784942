import React from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import { Header, Segment } from "semantic-ui-react";
import { LG_WIDTH } from "../../constants";

export default function PageHeader({
  pageName,
  orgName,
  className,
  appendContent,
}) {
  return (
    <Header as="h1">
      <MediaQuery minWidth={LG_WIDTH}>{pageName}{orgName && (<> for </>)}</MediaQuery>
      {orgName} {appendContent}
    </Header>
  );
}

PageHeader.propTypes = {
  className: PropTypes.string,
};

PageHeader.defaultProps = {
  className: "",
};
