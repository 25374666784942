import React, { useEffect, useState } from 'react';
import MediaQuery from "react-responsive";
import PropTypes from 'prop-types';
import usePageData from '../state-manipulation/hooks/usePageData';
import { setSelectedPage } from '../state-manipulation/reducers/page-data/types';
import {
  Header,
  Segment,
  Dimmer,
  Loader,
  Form,
  Input,
  Button,
  Progress,
  Label,
  Table,
  List,
} from 'semantic-ui-react';
import useUserData from '../state-manipulation/hooks/useUserData';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import PageHeader from '../components/common/PageHeader';
import { LG_WIDTH } from '../constants';
import { GetPrintersInfo, GetProductLabels, PrintProductLabel } from '../api/PrepPrinterAPI';
import { GetEmployees7Shifts } from '../api/EmployeesAPI';



export default function ProductLabelPrinter({ className }) {
  const { company } = useParams();
  const [pageData, pageDispatch] = usePageData();
  const [userData, userDispatch] = useUserData();
  const [isLoading, setIsLoading] = useState(false);
  const [madeOnDate, setMadeOnDate] = useState(moment().format('MM/DD/YY'));
  const [labelList, setLabelList] = useState([]);
  const [labelId, setLabelId] = useState('');
  const [isPrintDisabled, setIsPrintDisabled] = useState(true);
  const [quantity, setQuantity] = useState('');
  const [memo, setMemo] = useState('');
  const [printerInfo, setPrinterInfo] = useState({});
  const [printStatusMessage, setPrintStatusMessage] = useState('');
  const [isPrintError, setIsPrintError] = useState(false);
  const [statusPercentage, setStatusPercentage] = useState(0);

  const getEmployees = async () => {
    try {
      setIsLoading(true);
      let res = await GetEmployees7Shifts({ company: company });
      console.log("🚀 ~ getEmployees ~ res:", res)
    } catch (error) {

    }
  }

  const getProductLabels = async () => {
    try {
      setIsLoading(true);
      let res = await GetProductLabels({ company: company });

      let labelList = res.data.map(l => {
        return {
          text: l.productName,
          key: l.id,
          value: l.id
        }
      });
      setLabelList(labelList);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const getPrintersInfo = async () => {
    try {
      setIsLoading(true);
      let res = await GetPrintersInfo({ company: company });

      if (res.data.length > 0) {
        setPrinterInfo(res.data[0]);
      } else {
        setPrinterInfo({});
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const initiateSSE = () => {
    let url = `https://api.losant.com/applications/${printerInfo.applicationId}/devices/${printerInfo.deviceId}/stateStream?jwt=${printerInfo.token}`;
    let es = new EventSource(url);
    es.addEventListener('deviceState', function (e) {
      let data = JSON.parse(e.data).data;
    });
  };

  const handleMadeOnDateChange = (e, { value }) => {
    setMadeOnDate(value);
  };

  const handleQuantityChange = (e, { value }) => {
    setQuantity(value);
  };

  const handleLabelChange = (e, { value }) => {
    setLabelId(value);
  };

  const handleMemoChange = (e, { value }) => {
    setMemo(value);
  };

  const validateForm = () => {
    if (labelId === '') {
      setIsPrintDisabled(true);
      return;
    }

    if (!printerInfo.deviceId) {
      setIsPrintDisabled(true);
      return;
    }

    if (quantity <= 0) {
      setIsPrintDisabled(true);
      return;
    }
    if (!madeOnDate) {
      setIsPrintDisabled(true);
      return;
    }
    setIsPrintDisabled(false);
  };

  const printProductLabel = async () => {
    try {
      let data = {
        madeOnDate: madeOnDate,
        quantity: quantity,
        labelId: labelId,
        memo: memo,
        deviceId: printerInfo.deviceId,
        employeeByPin: userData.employeeByPin,
      };

      setStatusPercentage(50);
      setIsPrintDisabled(true);
      setPrintStatusMessage('Printing...');
      let res = await PrintProductLabel({ company: company, options: data });

      setIsPrintDisabled(false);
      setIsPrintError(false);
      setStatusPercentage(100);
      resetCheckFields();
      setPrintStatusMessage('Success');
      setTimeout(resetStatusIndicators, 2000);
    } catch (error) {
      setIsPrintDisabled(false);
      setIsPrintError(true);
      setStatusPercentage(100);
      setPrintStatusMessage('Error');
      console.error(error);
    }
  };

  const resetCheckFields = () => {
    setQuantity('');
    setLabelId('');
    setMemo('');
  };

  const resetStatusIndicators = () => {
    setStatusPercentage(0);
    setIsPrintError(false);
    setPrintStatusMessage('');
  };

  const showConnectionStatus = () => {
    let color;
    let printerStatus = 'checking';

    if (printerInfo.connectionInfo?.connected === 1) {
      color = 'green';
      printerStatus = 'connected';
    } else if (printerInfo.connected === 0) {
      color = 'red';
      printerStatus = 'disconnected';
    }

    return <Label color={color}>{printerStatus}</Label>;
  };

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: 'PRODUCT LABEL PRINTER',
      },
    });
  }, []);

  useEffect(() => {
    if (company) {
      getProductLabels()
      getPrintersInfo();
      getEmployees();
    }
  }, [company]);

  useEffect(() => {
    validateForm();
  }, [madeOnDate, quantity, labelId, memo]);

  useEffect(() => {
    if (printerInfo.applicationId) {
      initiateSSE();
    }
  }, [printerInfo]);

  return (
    <div className={'content-container ' + className}>
      <PageHeader pageName="Print Product Labels" orgName={userData.selectedOrg?.name} appendContent={showConnectionStatus()} />
      <Segment>
        <Dimmer active={isLoading}>
          <Loader />
        </Dimmer>
        <Progress percent={statusPercentage} autoSuccess error={isPrintError}>
          {printStatusMessage}
        </Progress>
        <Form>
          <Form.Group widths="equal">
            <DateInput
              closable
              label="Date*"
              placeholder="start"
              dateFormat="MM/DD/YY"
              className="date-picker"
              value={madeOnDate}
              onChange={handleMadeOnDateChange}
              hideMobileKeyboard={true}
            />
            <Form.Input
              fluid
              label="Quantity*"
              placeholder=""
              type="number"
              value={quantity}
              inputMode="numeric"
              pattern="[0-9]*"
              onChange={handleQuantityChange}
            />
          </Form.Group>
          <Form.Dropdown
            placeholder="Select Label"
            fluid
            selection
            value={labelId}
            options={labelList}
            onChange={handleLabelChange}
          />
          <Form.Input
            fluid
            label="Prep Notes"
            type="text"
            placeholder="If anything is relevant. Otherwise, leave blank."
            value={memo}
            onChange={handleMemoChange}
          />
          <Button primary disabled={isPrintDisabled} onClick={printProductLabel}>
            Print Label
          </Button>
          <p>* = required</p>
        </Form>
      </Segment>
    </div>
  );
}

ProductLabelPrinter.propTypes = {
  className: PropTypes.string,
};

ProductLabelPrinter.defaultProps = {
  className: '',
};
