import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from 'recharts';
import useUserData from '../../state-manipulation/hooks/useUserData';
import usePageData from '../../state-manipulation/hooks/usePageData';
import { setSelectedPage } from '../../state-manipulation/reducers/page-data/types';
import {
  Grid,
  Header,
  Segment,
  Dimmer,
  Loader,
  Statistic,
  Menu,
} from 'semantic-ui-react';
import { LG_WIDTH } from '../../constants';
import moment from 'moment';
import AxiosWrapper from '../../utils/AxiosWrapper';

export default function StockPotsLive() {
  const [userData, userDispatch] = useUserData();
  const [pageData, pageDispatch] = usePageData();
  const [isLoading, setIsLoading] = useState(false);
  const [stockPots, setStockPots] = useState([]);
  const [eventSources, setEventSources] = useState([]);
  const [token, setToken] = useState('');
  const [reRender, setReRender] = useState(0);

  const getStockPots = async (company) => {
    try {
      setIsLoading(true);
      let res = await AxiosWrapper({
        method: 'GET',
        url: `${process.env.API_URL}/${company}/stock-pots`,
        headers: {
          Authorization: `Basic ${userData.token}`,
        },
      });

      setStockPots(res.data.devices);
      setToken(res.data.token);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const formatDate = (date) => {
    return moment(date).format('h:mm a');
  };

  const formatTemperature = (temperature) => {
    return formatTemperature.toFixed(2);
  };

  const initiateSSE = (applicationId, deviceId) => {
    let url = `https://api.losant.com/applications/${applicationId}/devices/${deviceId}/stateStream?jwt=${token}`;
    let es = new EventSource(url);
    es.addEventListener('deviceState', function (e) {
      let state = JSON.parse(e.data);
      let _stockPots = JSON.parse(JSON.stringify(stockPots));
      let stockPot = _stockPots.find((sp) => {
        return sp.deviceId === deviceId;
      });

      stockPot.states.push({
        time: state.time,
        value: state.data.temperatureF,
      });

      stockPot.states.shift();

      stockPot.compositeState.temperatureF.value = state.data.temperatureF;
      setStockPots(_stockPots);
    });

    return es;
  };

  const calculateCookTime = (startTime) => {
    if (!startTime) {
      return '- -';
    }
    let cookDuration = moment.duration(new Date().getTime() - new Date(startTime).getTime());

    return cookDuration.format('H:mm:ss');
  }

  const renderStartTime = (startTime) => {
    if (!startTime) {
      return '- -';
    }

    return `${moment(startTime).format('ddd h:mm a')}`;
  };

  useEffect(() => {
    if (userData.selectedOrg) {
      getStockPots(userData.selectedOrg.name.toLowerCase());
    }
  }, [userData.selectedOrg]);

  useEffect(() => {
    if (token && stockPots) {
      let _eventSources = [];
      stockPots.forEach((sp) => {
        let es = initiateSSE(sp.applicationId, sp.deviceId);
        _eventSources.push(es);
      });
      setEventSources(_eventSources);
    }
    return () => {};
  }, [stockPots, token]);

  useEffect(() => {
    return () => {
      eventSources.forEach((es) => {
        es.close();
      });
    };
  }, [eventSources]);

  useEffect(() => {
    let interval = setInterval(() => {
      setReRender(new Date().getTime());
    }, 1000);

    return () => {
      clearInterval(interval);
    }
  }, [stockPots]);

  return (
    <Segment>
      {stockPots &&
        stockPots.map((stockPot, stockPotIndex) => {
          let data = stockPot.states.map((s) => {
            return {
              ...s,
              temperatureF: s.value,
            };
          });

          return (
            <div key={stockPotIndex}>
              <Header as="h2">{stockPot.name}</Header>
              <div style={{ textAlign: 'center' }}>
                <Grid columns={3}>
                  <Grid.Row>
                    <Grid.Column
                      mobile={16}
                      computer={5}
                      verticalAlign="middle"
                    >
                      <Statistic
                        label="Temperature hit 200˚F"
                        size="tiny"
                        value={renderStartTime(stockPot.cookStartTime)}
                      />
                    </Grid.Column>
                    <Grid.Column
                      mobile={16}
                      computer={5}
                      verticalAlign="middle"
                    >
                      <Statistic
                        label="Temperature"
                        value={`${stockPot.compositeState.temperatureF.value.toFixed(
                          2
                        )}˚F`}
                      />
                    </Grid.Column>
                    <Grid.Column
                      mobile={16}
                      computer={5}
                      verticalAlign="middle"
                    >
                      {reRender && (
                        <Statistic
                          label="Cook Duration"
                          size="tiny"
                          value={calculateCookTime(stockPot.cookStartTime)}
                        />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
              <ResponsiveContainer width="100%" height={200}>
                <LineChart
                  data={data}
                  margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                >
                  <XAxis
                    dataKey="time"
                    tickFormatter={formatDate}
                    minTickGap={20}
                  />
                  <YAxis dataKey="temperatureF" />
                  <Tooltip
                    formatter={(val) => `${val.toFixed(2)}˚F`}
                    labelFormatter={(val) =>
                      moment(val).format('ddd MM/DD h:mm a')
                    }
                  />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Line
                    type="monotone"
                    dataKey="temperatureF"
                    stroke="#8884d8"
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          );
        })}
    </Segment>
  );
}
