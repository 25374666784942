const moment = require("moment");

const firstDateOfPeriod = moment("2021-12-27");

function getAllPeriods({ year }) {
  year = year || new Date().getFullYear();
  const yearsInBetween = year - firstDateOfPeriod.year();
  let dateToAdd = firstDateOfPeriod.clone().add((yearsInBetween - 1) * 364, "days");

  // Check if adding 6 more days keeps us in the current year
  if (dateToAdd.clone().add(6, 'days').year() < year) {
    dateToAdd.add(7, 'days'); // Add 7 days if before the same year
  }

  if (yearsInBetween === 1) {
    return getPeriodsFromStartDate({ date: firstDateOfPeriod });
  } else {
    return getPeriodsFromStartDate({ date: dateToAdd });
  }
}

function getPeriodsFromStartDate({ date }) {
  const periods = [];
  let startDate = moment(date).utc(true);
  let endDate = moment(date).add(27, "days");
  for (let i = 0; i < 13; i++) {
    let period = {
      period: i + 1,
      startDate: startDate.format('MM/DD/YYYY'),
      endDate: endDate.format('MM/DD/YYYY'),
    };

    let weeks = [];
    let weekStartDate = startDate.clone();
    let weekEndDate = startDate.clone().add(6, "days");
    for (let j = 0; j < 4; j++) {
      weeks.push({
        week: j + 1,
        startDate: weekStartDate.format('MM/DD/YYYY'),
        endDate: weekEndDate.format('MM/DD/YYYY'),
      });
      weekStartDate.add(7, "days");
      weekEndDate.add(7, "days");
    }

    period.weeks = weeks;
    periods.push(period);
    startDate = startDate.add(4, "week");
    endDate = endDate.add(4, "week");
  }

  return periods;
}

function getPeriodByDate(date) {
  date = moment(new Date(date));
  const year = date.clone().year();

  const periods = getAllPeriods({ year: year });
  let period = periods.find((p) => {
    if (date >= new Date(p.startDate) && date <= new Date(p.endDate)) {
      return true;
    }
    return false;
  });

  if (!period) {
    const nextPeriods = getAllPeriods({ year: year + 1 });
    period = nextPeriods[0];
  }

  return period;
}

function getPeriodByNumberYear({ year, number }) {
  const periods = getAllPeriods({ year: year });
  return periods[number - 1];
}

exports.getAllPeriods = getAllPeriods;
exports.getPeriodByDate = getPeriodByDate;
exports.getPeriodByNumberYear = getPeriodByNumberYear;
