import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Header, Dimmer, Loader, Table, Icon, Segment } from "semantic-ui-react";
import { LG_WIDTH } from "../../constants";
import useUserData from "../../state-manipulation/hooks/useUserData";
import usePageData from "../../state-manipulation/hooks/usePageData";
import { setSelectedPage } from "../../state-manipulation/reducers/page-data/types";
import { GetVendors, DeleteVendor, SetVendorACH } from "../../api/VendorsAPI";
import { useParams } from "react-router-dom";
import PageHeader from "../common/PageHeader";

export default function ManageVendors({ className }) {
  const { company } = useParams();
  const [ userData, userDispatch ] = useUserData();
  const [ pageData, pageDispatch ] = usePageData();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ vendorsList, setVendorsList ] = useState([]);

  const getVendors = async () => {
    try {
      setIsLoading(true);
      let res = await GetVendors({ company: company })

      setVendorsList(res.data);
      setIsLoading(false);

    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const deleteVendor = async (id) => {
    try {
      setIsLoading(true);
      let res = await DeleteVendor({ company: company, id: id});
      setIsLoading(false);

    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const setVendorACH = async (id, isACH) => {
    try {
      setIsLoading(true);
      let res = await SetVendorACH({ company: company, id: id, isACH: isACH});
      getVendors();
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  const handleDeleteVendor = async (contactId) => {
    try {
      await deleteVendor(contactId);
      getVendors(userData.selectedOrg.name.toLowerCase());
    } catch (error) {
      console.error(error);
    }
  };

  const renderACHControl = (vendor) => {
    if (vendor.isACH) {
      return (
        <Icon name="circle check outline" onClick={() => { setVendorACH(vendor.ContactID, false) }} />
      )
    }

    return (
      <Icon name="circle outline" onClick={() => { setVendorACH(vendor.ContactID, true) }} />
    )
  }

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: 'Vendors'
      }
    });
  }, []);

  useEffect(() => {
    if (company) {
      getVendors(company);
    }
  }, [company]);

  return (
    <div className={"content-container edit-table-container " + className}>
      <PageHeader pageName="Vendors" orgName={userData.selectedOrg?.name} />
      <Segment>
      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      <Table unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              Name
            </Table.HeaderCell>
            <Table.HeaderCell>
              is ACH?
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {vendorsList && vendorsList.map((vendor, vendorIndex) => {
            return (
            <Table.Row key={vendorIndex}>
              <Table.Cell>
                { vendor.Name }
              </Table.Cell>
              <Table.Cell>
                { renderACHControl(vendor) }
              </Table.Cell>
              <Table.Cell>
                <Icon name="delete" className="icon-button delete" onClick={() => { handleDeleteVendor(vendor.ContactID); }} />
              </Table.Cell>
            </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      </Segment>
    </div>
  );
}

ManageVendors.propTypes = {
  className: PropTypes.string
};

ManageVendors.defaultProps = {
  className: ''
};
