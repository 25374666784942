import React, { useState, useEffect } from 'react';
import {
  Grid,
  Segment,
  Header,
  Message,
  Image,
  Form,
  Button
} from 'semantic-ui-react';
import { Redirect, Link, useParams } from 'react-router-dom';

import axios from 'axios';
import useUserData from '../state-manipulation/hooks/useUserData';
import {
  setUserToken,
  setUserInfo,
  setIsAdmin,
  setUserOrgs,
  setIsSuperAdmin,
  selectCurrentOrg
} from '../state-manipulation/reducers/user-data/types';

import './style/Login.scss';
import isAnyBoolTrue from '../utils/isAnyBoolTrue';
import ltgLogo from '../images/ltg-logo.svg';

export default function ForgotPassword() {
  const { resetKey } = useParams();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userData, userDispatch] = useUserData();
  const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordResetStatus, setPasswordResetStatus] = useState('request'); // request, request-submitted, try-reset, reset-error, reset-fail, reset-pass
  const [redirectUrl, setRedirectUrl] = useState(false);

  const handleForgotPassword = async () => {
    try {
      let res = await axios({
        method: 'POST',
        url: `${process.env.API_URL}/forgot-password`,
        data: {
          email: email
        }
      });
      setPasswordResetStatus('request-submitted');
    } catch (err) {
      console.error(err);
    }
  };

  const handleResetPassword = async () => {
    try {
      let res = await axios({
        method: 'POST',
        url: `${process.env.API_URL}/reset-password`,
        data: {
          password: password,
          key: resetKey
        }
      });
      console.log(res);
      if (res.data.error) {
        setErrorMessage(res.data.error.message);
        setPasswordResetStatus('reset-error');
      } else {
        setPasswordResetStatus('reset-pass');
      }
    } catch (err) {
      setPasswordResetStatus('reset-fail');
      console.error(err);
    }
  };

  const handleEmailChange = async e => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = e => {
    setPassword(e.target.value);
  };

  useEffect(() => {
    if (resetKey) {
      setPasswordResetStatus('try-reset');
    }
  }, []);

  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      {redirectUrl && <Redirect to={redirectUrl} />}
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal" textAlign="center">
          <Image src={ltgLogo} /> Log-in to your account
        </Header>
        {passwordResetStatus === 'request' ? (
          <Form size="large" onSubmit={handleForgotPassword}>
            <Segment stacked>
              <Form.Input
                fluid
                icon="user"
                type="email"
                iconPosition="left"
                placeholder="E-mail address"
                value={email}
                onChange={handleEmailChange}
              />
              <Button color="teal" fluid size="large" type="submit">
                Submit
              </Button>
            </Segment>
          </Form>
        ) : passwordResetStatus === 'request-submitted' ? (
          <p>Check your email for further instructions.</p>
        ) : passwordResetStatus === 'try-reset' ||
          passwordResetStatus === 'reset-error' ? (
          <Form size="large" onSubmit={handleResetPassword}>
            <Segment stacked>
              {errorMessage && <p>{errorMessage}</p>}
              <Form.Input
                fluid
                icon="user"
                type="password"
                iconPosition="left"
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
              />
              <Button color="teal" fluid size="large" type="submit">
                Change Password
              </Button>
            </Segment>
          </Form>
        ) : passwordResetStatus === 'reset-pass' ? (
          <p>Password has been reset. Please go back to login.</p>
        ) : (
          <p>Password Reset Failed</p>
        )}
        <Message>
          <Link to="/login">Back to Login</Link>
        </Message>
      </Grid.Column>
    </Grid>
  );
}
