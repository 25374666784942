import React from "react";
import { Link } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import AccessResource from "./common/AccessResource";
import IsAuthorized from "./common/IsAuthorized";


function RenderLinkType({ url, target, name }) {
  if (!target) {
    return <Link to={url}>{name}</Link>
  }
  return <a href={url} target={target}>{name}</a>
}

export default function ContentList({ title, content, company }) {
  return (
    <Segment.Group>
      <Segment inverted color="grey">
        {title}
      </Segment>
      {content &&
        content.map((r, rIndex) => {
          const anchorProps = {
            href: r.url,
            target: r.target,
          };

          return (
            <AccessResource key={rIndex} accessCompany={r.company} access={r.access} company={company}>
              <Segment size="tiny">
                <RenderLinkType url={r.url} target={r.target} name={r.name} />
              </Segment>
            </AccessResource>
          );
        })}
    </Segment.Group>
  )
}

