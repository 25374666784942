import axios from "axios";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  Button,
  Dimmer,
  Dropdown,
  Grid,
  Header,
  Icon,
  Image,
  Label,
  Loader,
  Popup,
  Segment,
  Table,
  TableBody,
} from "semantic-ui-react";
import floatingPointFix from "js-floating-point";
import PageHeader from "../components/common/PageHeader";
import useUserData from "../state-manipulation/hooks/useUserData";
import AxiosWrapper from "../utils/AxiosWrapper";
import { getAllPeriods, getPeriodByDate } from "../utils/PeriodCalendar";
import NumberFormat from "react-number-format";
import usePageData from "../state-manipulation/hooks/usePageData";
import { setSelectedPage } from "../state-manipulation/reducers/page-data/types";
import IsAuthorized from "../components/common/IsAuthorized";
import { deleteCostSummaryReport, getPeriodReport } from "../api/PeriodReportsAPI";
import DeleteCostSummaryReportOptions from "../components/period-reports/DeleteCostSummaryReportOptions";
import LaborData from "../components/period-reports/LaborData";
import ShowBudgetPercentageDifferences from "../components/period-reports/ShowBudgetPerentageDifferences";

const BAR_COSTS = [
  "BEER",
  "DRAFT BEERS",
  "DRAFT BEER",
  "SIDE BAR",
  "SPIRITS",
  "WINE",
  "SAKE/SOJU",
  "BAR SYRUPS/FRUIT PRODUCE",
];

const FOOD_COSTS = [
  "BAKED GOODS",
  "BAKERY",
  "DAIRY",
  "DRY GOODS",
  "FROZEN",
  "MEAT",
  "POULTRY",
  "PRODUCE",
  "SEAFOOD",
];

const SOFT_SERVE_COSTS = ["SOFT SERVE"]

const Colors = [
  "#93E1D8",
  "#336699",
  "#86BBD8",
  "#FFFF99",
  "#00B3E6",
  "#E6B333",
  "#2A547E",
  "#6E9AB1",
  "#273B49",
  "#93E1D8",
  "#336699",
  "#86BBD8",
  "#2F4858",
  "#B5D1CB",
  "#79B9B1",
  "#2A547E",
  "#6E9AB1",
  "#273B49",
];
const Colors2 = [
  "#FF6633",
  "#FFB399",
  "#FF33FF",
  "#FFFF99",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#809980",
  "#E6FF80",
  "#1AFF33",
];

export default function PeriodReports({ className }) {

  const [ , pageDispatch] = usePageData();
  const { company } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [yearOptions, setYearOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [cogsPercent, setCogsPercent] = useState([]);
  const [cogsFoodPercent, setCogsFoodPercent] = useState([]);
  const [cogsAlcoholPercent, setCogsAlcoholPercent] = useState([]);
  const [cogsLiquorPercent, setCogsLiquorPercent] = useState([]);
  const [cogsWinePercent, setCogsWinePercent] = useState([]);
  const [cogsBeerPercent, setCogsBeerPercent] = useState([]);
  const [cogsNABevPercent, setCogsNABevPercent] = useState([]);
  const [cogsRetailPercent, setCogsRetailPercent] = useState([]);
  const [inventoryAmounts, setInventoryAmounts] = useState([]);

  const [laborHourlyPercent, setLaborHourlyPercent] = useState([]);

  const [periods, setPeriods] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState();

  const [userData] = useUserData();

  const [salesByPeriod, setSalesByPeriod] = useState([]);
  const [primeCostData, setPrimeCostData] = useState();

  const [showBreakdown, setShowBreakdown] = useState(false);

  const handleDeleteCostSummaryReport = async (e) => {
    e.preventDefault()
    const groupId = e.currentTarget.getAttribute('data-gid');
    await deleteCostSummaryReport({ groupId, company })
    await getPrimeCostData({ year: selectedYear, period: selectedPeriod });
    return false;
  }

  const getSalesByPeriod = async () => {
    let data = [
      {
        date: "10/3",
        dineIn: 200,
        carryOut: 100,
        budget: 500,
      },
      {
        date: "10/10",
        dineIn: 400,
        carryOut: 150,
        budget: 600,
      },
    ];

    setSalesByPeriod(data);
  };

  const getPrimeCostData = async ({ year, period }) => {
    setIsLoading(true);
    let res = await getPeriodReport({ year: year, period: period, company: company});
    setIsLoading(false);

    setPrimeCostData(res.data);
    const cogsPercent = res.data.budgetPercentages.find((r) => {
      return r[0] === "COGS" && r[1] === "COGS";
    });

    setCogsPercent(cogsPercent);

    const _cogsFoodPercent = res.data.budgetPercentages.find((r) => {
      return r[0] === "COGS" && r[1] === "Sales, Food";
    });

    setCogsFoodPercent(_cogsFoodPercent);

    const _cogsAlocoholPercent = res.data.budgetPercentages.find((r) => {
      return r[0] === "COGS" && r[1] === "Alcohol";
    });

    setCogsAlcoholPercent(_cogsAlocoholPercent);

    const _cogsLiqourPercent = res.data.budgetPercentages.find((r) => {
      return r[0] === "COGS" && r[1] === "Sales, Liquor";
    });

    setCogsLiquorPercent(_cogsLiqourPercent);

    const _cogsWinePercent = res.data.budgetPercentages.find((r) => {
      return r[0] === "COGS" && r[1] === "Sales, Wine";
    });

    setCogsWinePercent(_cogsWinePercent);

    const _cogsBeerPercent = res.data.budgetPercentages.find((r) => {
      return r[0] === "COGS" && r[1] === "Sales, Beer";
    });

    setCogsBeerPercent(_cogsBeerPercent);

    const _cogsNABevPercent = res.data.budgetPercentages.find((r) => {
      return r[0] === "COGS" && r[1] === "Sales, Non-Alcoholic";
    });

    setCogsNABevPercent(_cogsNABevPercent);

    const _cogsRetailPercent = res.data.budgetPercentages.find((r) => {
      return r[0] === "COGS" && r[1] === "Sales, Retail";
    });

    setCogsRetailPercent(_cogsRetailPercent);

    const laborPercent = res.data.budgetPercentages.find((r) => {
      return r[0] === "LABOR" && r[1] === "Hourly";
    });

    setLaborHourlyPercent(laborPercent);


    let _inventoryAmounts = res.data.weeks.map(w => {
      let retVal = {};
      retVal.date = w.endDate;
      Object.keys(w.costSummary).forEach(key => {
        if (key !== 'total') {
          w.costSummary[key]?.source?.forEach(s => {
            retVal[`${s.category.replace(" ", "_").toLowerCase()}`] = s.endAmtActual / 100;
          })
        }
      })
      return retVal;
    });

    setInventoryAmounts(_inventoryAmounts);
  };

  function getPeriods() {
    const currentPeriod = getPeriodByDate(new Date());
    setSelectedPeriod(currentPeriod.period);
    setSelectedYear(Number(currentPeriod.endDate.split("/").slice(-1)));
  }

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: 'Period Reports',
      },
    });
  }, []);

  useEffect(() => {
    getSalesByPeriod();
    getPeriods();
    let currentYear = new Date().getFullYear();
    let _years = [];

    for (let i = currentYear + 1; i >= currentYear - 3; i--) {
      _years.push({
        key: i,
        value: i,
        text: i,
      });
    }

    setYearOptions(_years);
  }, []);

  useEffect(() => {
    let _periods = getAllPeriods({ year: selectedYear });

    _periods = _periods.map((p) => {
      return {
        key: p.period,
        text: `Period ${p.period}: Week ${moment(p.startDate)
          .utc(false)
          .format("M/DD")}`,
        value: p.period,
      };
    });

    setPeriods(_periods);

  }, [selectedYear]);

  useEffect(() => {
    if (selectedYear && selectedPeriod) {
      getPrimeCostData({ year: selectedYear, period: selectedPeriod });
    }
  }, [selectedYear, selectedPeriod, company]);

  function getBudgetPercentage(name) {
    if (name === "food") {
      return cogsFoodPercent[2];
    } else if (name === "bar") {
      return cogsAlcoholPercent[2];
    } else if (name === "naBev") {
      return cogsNABevPercent[2];
    }

    return 0;
  }

  function ShowServicePart({ name, services }) {
    const serviceInfo = services.find(wsvc => wsvc.name === name)
    if (!serviceInfo) {
      return <></>;
    }
    return (
      <>
        <NumberFormat
          thousandSeparator={true}
          value={Math.round(
            serviceInfo.grossTotal
          )}
          displayType="text"
        />
        <span style={{ fontSize: "12px", display: "block" }}>
          Net: <NumberFormat
            thousandSeparator={true}
            value={Math.round(
              serviceInfo.netTotal
            )}
            displayType="text"
          />
        </span>
      </>
    )
  }

  function ShowGrossSalesByDay({ salesDays }) {
    return (
      <>
      <Table unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Day</Table.HeaderCell>
            <Table.HeaderCell>Gross Sales</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
        {Object.keys(salesDays).map((key, i) => {
          return (
            <Table.Row key={i}>
              <Table.Cell>{moment(key).format('ddd MM/DD')}</Table.Cell>
              <Table.Cell textAlign="right">
                <NumberFormat
                  value={Math.round(salesDays[key].grossSales)}
                  thousandSeparator
                  displayType="text"
                />
              </Table.Cell>
            </Table.Row>
          );
        })}
        </Table.Body>
      </Table>
      <p style={{fontSize: "10px"}}>*Data is pulled from ltg-next-app tool, which queries sales data from mongodb, which gets the data from a daily job that grabs the nightly reports from Toast. Sales data comes from "ItemSelectionDetails.csv" report.</p>
      </>
    );
  }

  return (
    <div className={"content-container " + className}>
      <PageHeader
        pageName="Period Report"
        orgName={userData.selectedOrg?.name}
      />
      <Segment>
        <Dropdown
          selection
          compact
          value={selectedYear}
          options={yearOptions}
          onChange={(e, { value }) => setSelectedYear(value)}
        />{" "}
        <Dropdown
          selection
          value={selectedPeriod}
          options={periods}
          onChange={(e, { value }) => setSelectedPeriod(value)}
        />
      </Segment>
      <Header as="h2">Prime Cost Summary</Header>
      <Button onClick={() => setShowBreakdown(!showBreakdown)}>
        {showBreakdown ? "Hide" : "Show"} Details
      </Button>

      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      {!isLoading && (
        <Table definition celled compact unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              {primeCostData?.weeks &&
                primeCostData.weeks.map((w, i) => {
                  return (
                    <Table.HeaderCell key={i}>
                      Week {w.week} - {moment(w.startDate).format("M/D")}
                    </Table.HeaderCell>
                  );
                })}
              <Table.HeaderCell>Period To Date</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Gross Sales</Table.Cell>
              {primeCostData?.weeks &&
                primeCostData.weeks.map((w, i) => {
                  return (
                    <Table.Cell key={i} textAlign="right">
                      {w.sales.grossSales > 0 && (
                        <>
                          <Popup content={<ShowGrossSalesByDay salesDays={w.sales.salesDays} />}
                          trigger={
                          <NumberFormat
                            thousandSeparator={true}
                            value={Math.round(w.sales.grossSales)}
                            displayType="text"
                          />} />
                          <span style={{ fontSize: "11px", display: "block", marginBottom: "-20px"}}>Net: <NumberFormat
                            thousandSeparator={true}
                            value={Math.round(w.sales.netSales)}
                            displayType="text"
                          /></span>
                          <ShowDifference
                            actual={w.sales.grossSales}
                            projected={w.salesForecast}
                          />
                        </>
                      )}
                      <br />
                    </Table.Cell>
                  );
                })}
              <Table.Cell textAlign="right">
                {primeCostData?.periodToDate?.sales?.grossSales > 0 && (
                  <>
                    <NumberFormat
                      thousandSeparator={true}
                      value={primeCostData?.periodToDate?.sales?.grossSales}
                      displayType="text"
                    />
                    <span style={{ fontSize: "11px", display: "block", marginBottom: "-20px" }}>Net: <NumberFormat
                      thousandSeparator={true}
                      value={primeCostData?.periodToDate?.sales?.netSales}
                      displayType="text"
                    /></span>
                    <ShowDifference
                      actual={primeCostData?.periodToDate?.sales?.grossSales}
                      projected={primeCostData?.periodToDate?.salesForecast}
                    />
                  </>
                )}
              </Table.Cell>
            </Table.Row>
            <ShowSalesDetails data={primeCostData} show={showBreakdown} />
            <Table.Row>
              <Table.Cell>COGS
              </Table.Cell>
              {primeCostData?.weeks &&
                primeCostData.weeks.map((w, i) => {
                  return (
                    <Table.Cell key={i} textAlign="right">
                      {Math.abs(w.costSummary.total.consumedAmt) > 0 && (
                        <>
                          <ShowTotalCOGS
                            field={w.costSummary.total}
                            prop="consumedAmt"
                          />{" "}
                          (
                          {(
                            (w.costSummary.total.consumedAmt /
                              100 /
                              w.sales.grossSales) *
                            100
                          ).toFixed(2)}
                          %)
                          <br />
                          <ShowBudgetPercentageDifferences
                            actual={
                              w.costSummary.total.consumedAmt /
                              100 /
                              w.sales.grossSales
                            }
                            projected={cogsPercent[2]}
                          />
                        </>
                      )}
                    </Table.Cell>
                  );
                })}
              <Table.Cell textAlign="right">
                {Math.abs(
                  primeCostData?.periodToDate?.costSummary?.total.consumedAmt
                ) > 0 && (
                  <>
                    <ShowTotalCOGS
                      field={primeCostData?.periodToDate?.costSummary?.total}
                      prop="consumedAmt"
                    />{" "}
                    (
                    {(
                      (primeCostData?.periodToDate?.costSummary?.total
                        .consumedAmt /
                        100 /
                        primeCostData?.periodToDate?.sales.grossSales) *
                      100
                    ).toFixed(2)}
                    %)
                    <br />
                    <ShowBudgetPercentageDifferences
                      actual={
                        primeCostData?.periodToDate?.costSummary?.total
                          .consumedAmt /
                        100 /
                        primeCostData?.periodToDate?.sales.grossSales
                      }
                      projected={cogsPercent[2]}
                    />
                  </>
                )}
              </Table.Cell>
            </Table.Row>
            <ShowCOGSDetails
              data={primeCostData}
              show={showBreakdown}
              getBudgetPercentage={getBudgetPercentage}
            />
            <LaborData data={primeCostData} laborHourlyPercent={laborHourlyPercent} />
            <Table.Row>
              <Table.Cell>Prime Costs (Hourly Labor)</Table.Cell>
              {primeCostData?.weeks &&
                primeCostData.weeks.map((w, i) => {
                  return (
                    <Table.Cell key={i} textAlign="right">
                      {w.primeCost.total.actualTotal > 0 && (
                        <>
                          <NumberFormat
                            thousandSeparator={true}
                            value={Math.round(w.primeCost.total.actualTotal)}
                            displayType="text"
                          />{" "}
                          (
                          {(w.primeCost.total.actualTotalPercent * 100).toFixed(
                            2
                          )}
                          %)
                          <br />
                          <ShowBudgetPercentageDifferences
                            actual={
                              w.primeCost.total.actualTotal / w.sales.grossSales
                            }
                            projected={floatingPointFix(
                              laborHourlyPercent[2] + cogsPercent[2]
                            )}
                          />
                        </>
                      )}
                    </Table.Cell>
                  );
                })}
              <Table.Cell textAlign="right">
                {primeCostData?.periodToDate?.primeCost.total.actualTotal >
                  0 && (
                  <>
                    <NumberFormat
                      thousandSeparator={true}
                      value={Math.round(
                        primeCostData?.periodToDate?.primeCost.total.actualTotal
                      )}
                      displayType="text"
                    />{" "}
                    (
                    {(
                      primeCostData?.periodToDate?.primeCost.total
                        .actualTotalPercent * 100
                    ).toFixed(2)}
                    %)
                    <br />
                    <ShowBudgetPercentageDifferences
                      actual={
                        primeCostData?.periodToDate?.primeCost.total
                          .actualTotal /
                        primeCostData?.periodToDate?.sales.grossSales
                      }
                      projected={floatingPointFix(
                        laborHourlyPercent[2] + cogsPercent[2]
                      )}
                    />
                  </>
                )}
              </Table.Cell>
            </Table.Row>
            {primeCostData?.periodToDate?.sales?.services && primeCostData.periodToDate.sales.services.map((s, sIndex) => {
              return (
                <Table.Row key={sIndex}>
                  <Table.Cell>
                    {s.name}
                  </Table.Cell>
                  {primeCostData.weeks.map((w, wIndex) => {
                    return (
                      <Table.Cell key={wIndex} textAlign="right">
                        <ShowServicePart name={s.name} services={w.sales.services} />
                      </Table.Cell>
                    )
                  })}
                  <Table.Cell textAlign="right">
                    <NumberFormat
                      thousandSeparator={true}
                      value={Math.round(
                        s.grossTotal
                      )}
                      displayType="text"
                    />
                    <span style={{ fontSize: "12px", display: "block" }}>
                      Net: <NumberFormat
                        thousandSeparator={true}
                        value={Math.round(
                          s.netTotal
                        )}
                        displayType="text"
                      /></span>
                  </Table.Cell>
                </Table.Row>
              )
            })}
            <DeleteCostSummaryReportOptions data={primeCostData} handleDelete={handleDeleteCostSummaryReport} />
          </Table.Body>
        </Table>
      )}
      <IsAuthorized permission="hasSalesViewBudget">
        <p><a href={primeCostData?.googleSheetUrl} target="_blank">View Budget</a></p>
      </IsAuthorized>

        <p>Notes</p>
        <ul>
          <li>Hover over amount values to see breakdown. For dev: If items are in the wrong category, add it to the array in `CraftTableUtils.js` inside of ltg-next-app</li>
          <li>Changing Sales Categories names in Toast may affect Gross Sales breakdowns. Dev note: If it is changed, edit `ToastDataUtils.js` insid eof ltg-next-app</li>
        </ul>

      <Header as="h2">Inventory</Header>
      <Grid stackable>
        <Grid.Column>
          <Segment>
          <Header as="h3">Ending Inventory</Header>
            <ResponsiveContainer height={500}>
              <ComposedChart data={inventoryAmounts}>
                <CartesianGrid strokeDasharray="3 3" />
                <Legend verticalAlign="top" height={36} />
                <XAxis
                  dataKey="date"
                  padding={{ left: 25, right: 25 }}
                  minTickGap={10}
                />
                <YAxis yAxisId="left" />
                <Tooltip content={CustomToolTip} />
                {FOOD_COSTS.map((k, i) => {
                  return (
                    <Bar
                      key={k}
                      yAxisId="left"
                      maxBarSize={20}
                      dataKey={`${k.toLowerCase()}`}
                      stackId="food"
                      type="food"
                      fill={Colors2[i]}
                    />
                  )
                })}
                {BAR_COSTS.map((k, i) => {
                  return (
                    <Bar
                      key={k}
                      yAxisId="left"
                      maxBarSize={20}
                      dataKey={`${k.toLowerCase()}`}
                      stackId="bar"
                      type="bar"
                      fill={Colors[i]}
                    />
                  )
                })}
              </ComposedChart>
            </ResponsiveContainer>
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  );
}

const CustomToolTip = (props) => {
  const { type, payload, label } = props;

  return (
    <div className="custom-tooltip">
      <p className="label">{`${label}`}</p>
      <p>
        {payload &&
          payload.map((p, pIndex) => {
            let displayValue = p.value;
            let cat = '';
            if (FOOD_COSTS.includes(p.dataKey.toUpperCase())) {
              cat = 'Food';
            } else if (BAR_COSTS.includes(p.dataKey.toUpperCase())) {
              cat = 'Bar';
            }

            return (
              <span key={pIndex} style={{ color: p.color, display: "block" }}>
                {cat} - {p.dataKey} {displayValue}
              </span>
            );
          })}
      </p>
    </div>
  );
};

const ShowDifference = ({ actual, projected }) => {
  if (actual === 0) {
    return "";
  }
  if (actual > projected) {
    return (
      <>
        <br />
        <Label size="small" color="green">
          <NumberFormat
            thousandSeparator={true}
            value={Math.round(projected)}
            displayType="text"
          ></NumberFormat>
          <Icon name="caret up" />
          {((actual / projected - 1) * 100).toFixed(2)}%`
        </Label>
      </>
    );
  }

  return (
    <>
      <br />
      <Label size="small" color="red">
        <NumberFormat
          thousandSeparator={true}
          value={Math.round(projected)}
          displayType="text"
        ></NumberFormat>
        <Icon name="caret down" />
        {((1 - actual / projected) * 100).toFixed(2)}%
      </Label>
    </>
  );
};

const ShowTotalCOGS = ({ field, prop }) => {
  field = field || {};

  return (
    <NumberFormat
      thousandSeparator={true}
      value={Math.round(field[prop] / 100) || 0}
      displayType="text"
    />
  );
};


const ShowSalesDetails = ({ data, show }) => {
  if (!data) {
    return <></>;
  }

  let revenueCenters = data.periodToDate.sales.revenueCenters.map((rc) => {
    return rc.name;
  });

  return (
    <>
      {revenueCenters &&
        revenueCenters.map((rc, i) => {
          let revenueCenter = data.periodToDate.sales.revenueCenters.find(
            (_rc) => {
              return _rc.name === rc;
            }
          );
          return (
            <React.Fragment key={i}>
              {show && (
                <Table.Row>
                  <Table.Cell style={{ backgroundColor: "#FFF" }}>
                    {rc}
                  </Table.Cell>
                  <DisplayWeeks
                    weeks={data.weeks}
                    name={rc}
                    prop="grossTotal"
                  />
                  <Table.Cell textAlign="right">
                    <Popup
                      content={<DisplaySalesCategoriesByRevenueCenter revenueCenter={revenueCenter.name} salesCategories={data.periodToDate.sales.salesCategories} />}
                      trigger={
                        <NumberFormat
                        value={Math.round(revenueCenter?.grossTotal)}
                        thousandSeparator
                        displayType="text"
                      />
                      }
                    />
                   {" "}
                    <Label size="small">
                      {(
                        (revenueCenter?.grossTotal /
                          data.periodToDate.sales.grossSales) *
                        100
                      ).toFixed(2)}
                      %
                    </Label>
                  </Table.Cell>
                </Table.Row>
              )}
            </React.Fragment>
          );
        })}
    </>
  );

  function DisplaySalesCategoriesByRevenueCenter({ revenueCenter, salesCategories }) {
    let salesCategoriesFiltered = salesCategories.filter(sc => sc.revenueCenter === revenueCenter);
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              Category
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              Gross
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              Net
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              Orders
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {salesCategoriesFiltered && salesCategoriesFiltered.map((sc, scI) => {
            return (
              <Table.Row key={scI}>
                <Table.Cell>{sc.name}</Table.Cell>
                <Table.Cell textAlign="right"><NumberFormat
                  value={Math.round(sc.grossTotal)}
                  thousandSeparator
                  displayType="text"
                />
                </Table.Cell>
                <Table.Cell textAlign="right"><NumberFormat
                  value={Math.round(sc.netTotal)}
                  thousandSeparator
                  displayType="text"
                /></Table.Cell>
                <Table.Cell textAlign="right"><NumberFormat
                  value={sc.orders}
                  thousandSeparator
                  displayType="text"
                /></Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    )
  }

  function DisplayWeeks({ weeks, name, prop }) {
    return (
      <>
        {weeks &&
          weeks.map((w, i) => {
            let revenueCenter = w.sales.revenueCenters.find((rc) => {
              return rc.name === name;
            });
            if (!revenueCenter) {
              return <Table.Cell key={i}></Table.Cell>;
            }

            return (
              <Table.Cell key={i} textAlign="right">
                <Popup
                  content={<DisplaySalesCategoriesByRevenueCenter revenueCenter={revenueCenter.name} salesCategories={w.sales.salesCategories} />}
                  trigger={
                    <NumberFormat
                      value={Math.round(revenueCenter[prop])}
                      thousandSeparator
                      displayType="text"
                    />
                  }
                />
                {" "}
                <Label size="small">
                  {((revenueCenter[prop] / w.sales.grossSales) * 100).toFixed(
                    2
                  )}
                  %
                </Label>
              </Table.Cell>
            );
          })}
      </>
    );
  }
};

const COGS_SALES_CATEGORIES_MAP = {
  food: "FOOD",
  bar: "BAR",
  naBev: "NA BEVERAGE",
  softServe: "SOFT SERVE"
};

const ShowCOGSDetails = ({ data, show, getBudgetPercentage }) => {
  if (!data) {
    return <></>;
  }

  if (!data.periodToDate.costSummary) {
    return <></>;
  }

  let cogs = Object.keys(data.periodToDate.costSummary).filter((key) => {
    return key !== "total";
  });

  return (
    <>
      {cogs &&
        cogs.map((cog, i) => {
          let _propValue = data.periodToDate.sales.revenueCenters.find((sc) => {
            return sc.name === COGS_SALES_CATEGORIES_MAP[cog];
          });

          const cogsDisplayName = COGS_SALES_CATEGORIES_MAP[cog] || "OTHER";

          _propValue = _propValue ? _propValue.grossTotal : null;
          return (
            <React.Fragment key={i}>
              {show && data.periodToDate?.costSummary[cog]?.consumedAmt && (
                <Table.Row>
                  <Table.Cell style={{ backgroundColor: "#FFF" }}>
                    {cogsDisplayName}
                  </Table.Cell>
                  <DisplayWeeks
                    weeks={data.weeks}
                    name={cog}
                    prop="consumedAmt"
                  />
                  <Table.Cell textAlign="right">
                    <NumberFormat
                      value={Math.round(
                        data.periodToDate?.costSummary[cog]?.consumedAmt / 100
                      )}
                      thousandSeparator
                      displayType="text"
                    />{" "}
                    {_propValue && (
                      <>
                        (
                        {(
                          (data.periodToDate?.costSummary[cog].consumedAmt /
                            100 /
                            _propValue) *
                          100
                        ).toFixed(2)}
                        %)
                        <br />
                        <ShowBudgetPercentageDifferences
                          actual={
                            data.periodToDate?.costSummary[cog]?.consumedAmt /
                            100 /
                            _propValue
                          }
                          projected={getBudgetPercentage(cog)}
                        />
                      </>
                    )}
                  </Table.Cell>
                </Table.Row>
              )}
            </React.Fragment>
          );
        })}
    </>
  );

  function DisplayWeeks({ weeks, name, prop }) {
    return (
      <>
        {weeks &&
          weeks.map((w, i) => {
            let cog = w.costSummary[name];
            if (!cog) {
              return <Table.Cell key={i} textAlign="right"></Table.Cell>;
            }

            let _propValue = w.sales.revenueCenters.find((sc) => {
              return sc.name === COGS_SALES_CATEGORIES_MAP[name];
            });

            _propValue = _propValue ? _propValue.grossTotal : null;

            return (
              <Table.Cell
                key={i}
                textAlign="right"
                negative={cog?.source?.find((c) => c.consumedAmt < 0) ? true : false}
              >
                {Math.abs(cog[prop]) > 0 && (
                  <>
                    <Popup
                      hoverable={true}
                      content={<ShowInventoryDetails cog={cog} />}
                      trigger={
                        <NumberFormat
                          value={Math.round(cog[prop] / 100)}
                          thousandSeparator
                          displayType="text"
                        />
                      }
                    />{" "}
                    {_propValue && (
                      <>
                        ({((cog[prop] / 100 / _propValue) * 100).toFixed(2)}%)
                        <br />
                        <ShowBudgetPercentageDifferences
                          actual={cog[prop] / 100 / _propValue}
                          projected={getBudgetPercentage(name)}
                        />
                      </>
                    )}
                  </>
                )}
              </Table.Cell>
            );
          })}
      </>
    );
  }

  function ShowInventoryDetails({ cog }) {

    return (
      <Table unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Category</Table.HeaderCell>
            <Table.HeaderCell>Starting</Table.HeaderCell>
            <Table.HeaderCell>Received</Table.HeaderCell>
            <Table.HeaderCell>Ending</Table.HeaderCell>
            <Table.HeaderCell>Consumed</Table.HeaderCell>
            <Table.HeaderCell>Platform</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
        {cog.source.map((c, i) => {
          return (
            <Table.Row key={i}>
              <Table.Cell>{c.category}</Table.Cell>
              <Table.Cell textAlign="right">
                <NumberFormat
                  value={Math.round(c.startAmt / 100)}
                  thousandSeparator
                  displayType="text"
                />
              </Table.Cell>
              <Table.Cell textAlign="right">
                <NumberFormat
                  value={Math.round(c.rcvAmt / 100)}
                  thousandSeparator
                  displayType="text"
                />
              </Table.Cell>
              <Table.Cell textAlign="right">
                <NumberFormat
                  value={Math.round(c.endAmtActual / 100)}
                  thousandSeparator
                  displayType="text"
                />
              </Table.Cell>
              <Table.Cell textAlign="right">
                <NumberFormat
                  value={Math.round(c.consumedAmt / 100)}
                  thousandSeparator
                  displayType="text"
                />
              </Table.Cell>
              <Table.Cell textAlign="right">{c.platform}</Table.Cell>
            </Table.Row>
          );
        })}
        </Table.Body>
      </Table>
    );
  }

  
};
