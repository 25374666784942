import AxiosWrapper from '../utils/AxiosWrapper';

export async function GetSalesByDay({startDate, endDate, selectedDays, company}) {
  try {
    let url = `${process.env.API_URL}/${company}/sales-by-day`;

    let options = [];

    if (startDate) {
      options.push(`startDate=${startDate}`);
    }

    if (endDate) {
      options.push(`endDate=${endDate}`);
    }

    if (selectedDays) {
      options.push(`days=${selectedDays.join(',')}`);
    }

    if (options.length > 0) {
      url +=  `?${options.join('&')}`;
    }

    let res = await AxiosWrapper({
      method: 'GET',
      url: url,
      isAuthRoute: true
    });
    return res;
  } catch (e) {
    console.error(e);
  }
}
