import React, { useState, useEffect } from "react";
import { Form, Grid, Input, Label } from "semantic-ui-react";
import { mutliplyEmpty } from "../../utils/MathUtils";

export default function CashCalculator({
  cashCount: cashCountSource,
  onChange,
  readOnly
}) {
  const [cashCount, setCashCount] = useState(cashCountSource);

  const handleCashCountChange = (e, { value, proptochange }) => {
    const _cashCount = { ...cashCount };
    _cashCount[proptochange] = value;
    setCashCount(_cashCount);
  };

  useEffect(() => {
    const {
      count100,
      count50,
      count20,
      count10,
      count5,
      count2,
      count1,
      countChange50,
      countChange25,
      countChange10,
      countChange5,
      countChange1,
    } = cashCount;
    let _totalBills =
      mutliplyEmpty(count100, 100) * 100 +
      mutliplyEmpty(count50, 50) * 100 +
      mutliplyEmpty(count20, 20) * 100 +
      mutliplyEmpty(count10, 10) * 100 +
      mutliplyEmpty(count5, 5) * 100 +
      mutliplyEmpty(count2, 2) * 100 +
      mutliplyEmpty(count1, 1) * 100;
    let _total = _totalBills +
      mutliplyEmpty(countChange50, 0.5) * 100 +
      mutliplyEmpty(countChange25, 0.25) * 100 +
      mutliplyEmpty(countChange10, 0.1) * 100 +
      mutliplyEmpty(countChange5, 0.05) * 100 +
      mutliplyEmpty(countChange1, 0.01) * 100;
    cashCount.total = _total/100;
    cashCount.totalBills = _totalBills/100;
    onChange(cashCount);
  }, [
    cashCount.count100,
    cashCount.count50,
    cashCount.count20,
    cashCount.count10,
    cashCount.count5,
    cashCount.count2,
    cashCount.count1,
    cashCount.countChange50,
    cashCount.countChange25,
    cashCount.countChange10,
    cashCount.countChange5,
    cashCount.countChange1,
  ]);

  useEffect(() => {
    setCashCount({ ...cashCountSource });
  }, [
    cashCountSource.count100,
    cashCountSource.count50,
    cashCountSource.count20,
    cashCountSource.count10,
    cashCountSource.count5,
    cashCountSource.count2,
    cashCountSource.count1,
    cashCountSource.countChange50,
    cashCountSource.countChange25,
    cashCountSource.countChange10,
    cashCountSource.countChange5,
    cashCountSource.countChange1,
  ]);

  return (
    <Form>
      <Grid stackable columns={2}>
        <Grid.Column>
          <Form.Field>
            <Input
              value={cashCount.count100 || ""}
              proptochange="count100"
              onChange={handleCashCountChange}
              type="number"
              labelPosition="left"
              readOnly={readOnly}
            >
              <Label>$100</Label>
              <input inputMode="numeric" pattern="[0-9]*" />
            </Input>
          </Form.Field>
          <Form.Field>
            <Input
              value={cashCount.count50 || ""}
              proptochange="count50"
              onChange={handleCashCountChange}
              type="number"
              labelPosition="left"
              readOnly={readOnly}
            >
              <Label>$50</Label>
              <input inputMode="numeric" pattern="[0-9]*" />
            </Input>
          </Form.Field>
          <Form.Field>
            <Input
              value={cashCount.count20 || ""}
              proptochange="count20"
              onChange={handleCashCountChange}
              type="number"
              labelPosition="left"
              readOnly={readOnly}
            >
              <Label>$20</Label>
              <input inputMode="numeric" pattern="[0-9]*" />
            </Input>
          </Form.Field>
          <Form.Field>
            <Input
              value={cashCount.count10 || ""}
              proptochange="count10"
              onChange={handleCashCountChange}
              type="number"
              labelPosition="left"
              readOnly={readOnly}
            >
              <Label>$10</Label>
              <input inputMode="numeric" pattern="[0-9]*" />
            </Input>
          </Form.Field>
          <Form.Field>
            <Input
              value={cashCount.count5 || ""}
              proptochange="count5"
              onChange={handleCashCountChange}
              type="number"
              labelPosition="left"
              readOnly={readOnly}
            >
              <Label>$5</Label>
              <input inputMode="numeric" pattern="[0-9]*" />
            </Input>
          </Form.Field>
          <Form.Field>
            <Input
              value={cashCount.count2 || ""}
              proptochange="count2"
              onChange={handleCashCountChange}
              type="number"
              labelPosition="left"
              readOnly={readOnly}
            >
              <Label>$2</Label>
              <input inputMode="numeric" pattern="[0-9]*" />
            </Input>
          </Form.Field>
          <Form.Field>
            <Input
              value={cashCount.count1 || ""}
              proptochange="count1"
              onChange={handleCashCountChange}
              type="number"
              labelPosition="left"
              readOnly={readOnly}
            >
              <Label>$1</Label>
              <input inputMode="numeric" pattern="[0-9]*" />
            </Input>
          </Form.Field>
        </Grid.Column>
        <Grid.Column>
          <Form.Field>
            <Input
              value={cashCount.countChange50 || ""}
              proptochange="countChange50"
              onChange={handleCashCountChange}
              type="number"
              labelPosition="left"
              readOnly={readOnly}
            >
              <Label>.50</Label>
              <input inputMode="numeric" pattern="[0-9]*" />
            </Input>
          </Form.Field>
          <Form.Field>
            <Input
              value={cashCount.countChange25 || ""}
              proptochange="countChange25"
              onChange={handleCashCountChange}
              type="number"
              labelPosition="left"
              readOnly={readOnly}
            >
              <Label>.25</Label>
              <input inputMode="numeric" pattern="[0-9]*" />
            </Input>
          </Form.Field>
          <Form.Field>
            <Input
              value={cashCount.countChange10 || ""}
              proptochange="countChange10"
              onChange={handleCashCountChange}
              type="number"
              labelPosition="left"
              readOnly={readOnly}
            >
              <Label>.10</Label>
              <input inputMode="numeric" pattern="[0-9]*" />
            </Input>
          </Form.Field>
          <Form.Field>
            <Input
              value={cashCount.countChange5 || ""}
              proptochange="countChange5"
              onChange={handleCashCountChange}
              type="number"
              labelPosition="left"
              readOnly={readOnly}
            >
              <Label>.05</Label>
              <input inputMode="numeric" pattern="[0-9]*" />
            </Input>
          </Form.Field>
          <Form.Field>
            <Input
              value={cashCount.countChange1 || ""}
              proptochange="countChange1"
              onChange={handleCashCountChange}
              type="number"
              labelPosition="left"
              readOnly={readOnly}
            >
              <Label>.01</Label>
              <input inputMode="numeric" pattern="[0-9]*" />
            </Input>
          </Form.Field>
        </Grid.Column>
      </Grid>
    </Form>
  );
}

CashCalculator.defaultProps = {
  cashCount: {},
  readOnly: false
};