import AxiosWrapper from '../utils/AxiosWrapper';

export async function GetPurchaseAccounts({ company }) {
  try {
    let url = `${process.env.API_URL}/${company}/purchase-accounts`;

    let res = await AxiosWrapper({
      method: 'GET',
      url: url,
      isAuthRoute: true,
    });

    return res;
  } catch (e) {
    console.error(e);
  }
}
