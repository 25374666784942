import React from "react";
import { Link, useParams } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";
import usePageData from "../../state-manipulation/hooks/usePageData";
import useUserData from "../../state-manipulation/hooks/useUserData";
import IsAuthorized from "../common/IsAuthorized";

export default function BarToolsMenu({ onClick }) {
  let { company } = useParams();
  const [pageData] = usePageData();
  const [userData, userDispatch] = useUserData();

  if (!company) {
    company = userData.orgs[0].name.toLowerCase();
  }

  return (
    <IsAuthorized permission="hasBarTools">
      <Menu.Item>
        <Menu.Header>Bar Tools</Menu.Header>
        <Menu.Menu>
          <IsAuthorized permission="hasBarToolsDraftList">
            <Menu.Item
              as={Link}
              to={"/" + company + "/bar-tools/draft-list"}
              onClick={onClick}
              active={
                pageData.selectedPage.toUpperCase() === "DRAFT LIST"
                  ? true
                  : false
              }
            >
              <Icon name="beer" />
              Draft List
            </Menu.Item>
          </IsAuthorized>
          <IsAuthorized permission="hasBarToolsPriceCalculator">
            <Menu.Item
              as={Link}
              to={"/" + company + "/bar-tools/pricing-calculator"}
              onClick={onClick}
              active={
                pageData.selectedPage.toUpperCase() === "PRICING CALCULATOR"
                  ? true
                  : false
              }
            >
              <Icon name="beer" />
              Price Calculator
            </Menu.Item>
          </IsAuthorized>
        </Menu.Menu>
      </Menu.Item>
    </IsAuthorized>
  );
}
