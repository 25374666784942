import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Container, Header, Loader, Segment } from "semantic-ui-react";
import usePageData from "../state-manipulation/hooks/usePageData";
import { setSelectedPage } from "../state-manipulation/reducers/page-data/types";
import AxiosWrapper from "../utils/AxiosWrapper";
import ContentList from "../components/ContentList";
import IsAuthorized from "../components/common/IsAuthorized";

export default function PayrollExport({ selectedPage, pageName }) {
  const { company } = useParams();
  const [toastFileName, setToastFileName] = useState("");
  const [tipHausFileName, setTipHausFileName] = useState("");
  const [, pageDispatch] = usePageData();

  const payrollFileInputRef = useRef();
  const tipFileInputRef = useRef();

  const processToastPayroll = async ({ report, saveFileName }) => {
    const url = `${process.env.LTG_NEXT_APP_URL}/api/format-tools/payroll-report`;
    var formData = new FormData();
    formData.append("report", report);
    formData.append("save_file_name", saveFileName);
    let res = await fetch(url, {
      method: "POST",
      body: formData,
    });

    let blob = await res.blob();

    var file = window.URL.createObjectURL(blob);
    var link = document.createElement("a");
    link.setAttribute("href", file);
    link.setAttribute("download", saveFileName);
    link.click();
    link.remove();
  };

  const processTipHausTips = async ({ report, saveFileName }) => {
    const url = `${process.env.LTG_NEXT_APP_URL}/api/format-tools/tip-report`;
    var formData = new FormData();
    formData.append("report", report);
    formData.append("save_file_name", saveFileName);
    let res = await fetch(url, {
      method: "POST",
      body: formData,
    });

    let blob = await res.blob();

    var file = window.URL.createObjectURL(blob);
    var link = document.createElement("a");
    link.setAttribute("href", file);
    link.setAttribute("download", saveFileName);
    link.click();
    link.remove();
  };

  const handlePayrollFileChange = () => {
    const fileName = payrollFileInputRef.current.files[0].name;
    let saveFileName = fileName.replace("PayrollExport", `${company}_toast`);
    saveFileName = saveFileName.replace("csv", "xls");
    processToastPayroll({
      report: payrollFileInputRef.current.files[0],
      saveFileName: saveFileName,
    });
    setToastFileName(fileName);
  };

  const handleTipFileChange = () => {
    const fileName = tipFileInputRef.current.files[0].name;
    let saveFileName = fileName.replace("tiphaus", `${company}_tip`);
    saveFileName = saveFileName.replace("csv", "xls");
    processTipHausTips({
      report: tipFileInputRef.current.files[0],
      saveFileName: saveFileName,
    });
    setTipHausFileName(fileName);
  };

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: selectedPage,
      },
    });
  }, []);

  return (
    <div className={"content-container"}>
      <Header as="h1">{pageName}</Header>

      <Segment>
        <Header as="h2">Toast report</Header>
        <p>
          Export the "Labor Summary" report from Toast for the last payroll
          period
        </p>
        <p>{toastFileName}</p>
        <Button
          content="Choose File"
          labelPosition="left"
          icon="file"
          onClick={() => payrollFileInputRef.current.click()}
        />
        <input
          ref={payrollFileInputRef}
          type="file"
          hidden
          onInput={handlePayrollFileChange}
        />
        <Button content="Run again" onClick={handlePayrollFileChange} />
      </Segment>
      <Segment>
        <Header as="h2">Tip Haus Breakdown Report</Header>
        <p>
          Export the "Breakdown" report from TipHaus for the last payroll period
        </p>
        <p>{tipHausFileName}</p>
        <Button
          content="Choose File"
          labelPosition="left"
          icon="file"
          onClick={() => tipFileInputRef.current.click()}
        />
        <input
          ref={tipFileInputRef}
          type="file"
          hidden
          onInput={handleTipFileChange}
        />
        <Button content="Run again" onClick={handleTipFileChange} />
      </Segment>
    </div>
  );
}
