import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Header, Table, Tab, Checkbox, Button } from "semantic-ui-react";
import ReactToPrint from 'react-to-print';

class DailyTasksPrintable extends React.Component {

  render() {
    return (
      <div>
        <Header as="h1">Daily Tasks</Header>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Task Name</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Clean Desk</Table.Cell>
              <Table.Cell>
                <Checkbox>Test</Checkbox>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default function DailyTasks({ className }) {
  const componentRef = useRef();
  return (
    <div className={"content-container " + className}>
      <DailyTasksPrintable className={className} ref={componentRef} />
      <ReactToPrint
        pageStyle="@page { size: 8.5in 8.5in;  margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }"
        trigger={() => <button>Print this out!</button>}
        content={() => componentRef.current}
      />
    </div>
  );
}

DailyTasks.propTypes = {
  className: PropTypes.string
};

DailyTasks.defaultProps = {
  className: ''
};