import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Message } from "semantic-ui-react";

import "./styles/DateRangeSelector.scss";
import { DateRangeContext } from "../../context/DateRangeContext";
import { DateTimeRangeSelectorForm } from "./DateTimeRangeSelector";
import { DateMenuForm } from "./DateMenu";
import { DateRangeSelectorForm } from "./DateRangeSelector";


export default function DateRangeContextSelector({}) {
  const { dateStart, setDateStart, dateEnd, setDateEnd, days, setDays } = useContext(DateRangeContext);

  const currentHour = moment().tz("America/New_York").hours();
  const defaultStartDate =
    currentHour >= 0 && currentHour < 4
      ? moment().subtract(1, "days")
      : moment();
  const defaultEndDate =
    currentHour >= 0 && currentHour < 4
      ? moment().subtract(1, "days")
      : moment();

  const handleDateStartChange = (e, { value }) => {
    setDateStart(value);
  };

  const handleDateEndChange = (e, { value }) => {
    setDateEnd(value);
  };

  const handleCaretLeftClick = () => {
    setDateStart(moment(dateStart).subtract(1, "days").format("MM/DD/YY"));
    setDateEnd(moment(dateEnd).subtract(1, "days").format("MM/DD/YY"));
  };

  const handleCaretRightClick = () => {
    setDateStart(moment(dateStart).add(1, "days").format("MM/DD/YY"));
    setDateEnd(moment(dateEnd).add(1, "days").format("MM/DD/YY"));
  };

  const handleFastBackwardClick = () => {
    setDateStart(moment(dateStart).subtract(7, "days").format("MM/DD/YY"));
    setDateEnd(moment(dateEnd).subtract(7, "days").format("MM/DD/YY"));
  };

  const handleFastForwardClick = () => {
    setDateStart(moment(dateStart).add(7, "days").format("MM/DD/YY"));
    setDateEnd(moment(dateEnd).add(7, "days").format("MM/DD/YY"));
  };
  // START: Functions for DateMenuForm

  const handleTodayClick = () => {
    setDateStart(defaultStartDate.format("MM/DD/YY"));
    setDateEnd(defaultEndDate.format("MM/DD/YY"));
  };

  const handleYesterdayClick = () => {
    setDateStart(defaultStartDate.subtract(1, "days").format("MM/DD/YY"));
    setDateEnd(defaultEndDate.subtract(1, "days").format("MM/DD/YY"));
  };

  const handle52WeeksAgoClick = () => {
    setDateStart(defaultStartDate.subtract(52, "weeks").format("MM/DD/YY"));
    setDateEnd(defaultEndDate.subtract(52, "weeks").format("MM/DD/YY"));
  };

  const handleWeekToDateClick = () => {
    let _startDate =
      defaultStartDate.format("ddd").toUpperCase() === "SUN"
        ? defaultStartDate.subtract(6, "days").format("MM/DD/YY")
        : defaultStartDate.startOf("week").add(1, "days").format("MM/DD/YY");
    setDateStart(_startDate);
    setDateEnd(defaultEndDate.format("MM/DD/YY"));
  };

  const handleLastWeekClick = () => {
    setDateStart(
      defaultStartDate.startOf("week").subtract(6, "days").format("MM/DD/YY")
    );
    setDateEnd(defaultEndDate.startOf("week").format("MM/DD/YY"));
  };

  const handleLast7DaysClick = () => {
    setDateStart(defaultStartDate.subtract(7, "days").format("MM/DD/YY"));
    setDateEnd(defaultEndDate.format("MM/DD/YY"));
  };

  const handleLastMonthClick = () => {
    setDateStart(
      defaultStartDate.startOf("month").subtract(1, "month").format("MM/DD/YY")
    );
    setDateEnd(
      defaultEndDate.endOf("month").subtract(1, "month").format("MM/DD/YY")
    );
  };

  const handleLast30DaysClick = () => {
    setDateStart(defaultStartDate.subtract(30, "days").format("MM/DD/YY"));
    setDateEnd(defaultEndDate.format("MM/DD/YY"));
  };

  const handleLast60DaysClick = () => {
    setDateStart(defaultStartDate.subtract(60, "days").format("MM/DD/YY"));
    setDateEnd(defaultEndDate.format("MM/DD/YY"));
  };

  const handleLast90DaysClick = () => {
    setDateStart(defaultStartDate.subtract(90, "days").format("MM/DD/YY"));
    setDateEnd(defaultEndDate.format("MM/DD/YY"));
  };

  const handleMonthToDateClick = () => {
    setDateStart(defaultStartDate.startOf("month").format("MM/DD/YY"));
    setDateEnd(defaultEndDate.format("MM/DD/YY"));
  };


  return (
    <>
      <DateMenuForm
        handleTodayClick={handleTodayClick}
        handleYesterdayClick={handleYesterdayClick}
        handle52WeeksAgoClick={handle52WeeksAgoClick}
        handleWeekToDateClick={handleWeekToDateClick}
        handleLastWeekClick={handleLastWeekClick}
        handleLast7DaysClick={handleLast7DaysClick}
        handleMonthToDateClick={handleMonthToDateClick}
        handleLastMonthClick={handleLastMonthClick}
        handleLast30DaysClick={handleLast30DaysClick}
        handleLast60DaysClick={handleLast60DaysClick}
        handleLast90DaysClick={handleLast90DaysClick}
      />
      {days > 366 && (
        <Message warning>Date range must be 366 days or less.</Message>
      )}
      {days < 0 && (
        <Message warning>
          End date must be the same or after start date.
        </Message>
      )}
      <DateRangeSelectorForm
        dateRangeData={{ dateStart: dateStart, dateEnd: dateEnd }}
        handleFastBackwardClick={handleFastBackwardClick}
        handleFastForwardClick={handleFastForwardClick}
        handleCaretLeftClick={handleCaretLeftClick}
        handleDateStartChange={handleDateStartChange}
        handleDateEndChange={handleDateEndChange}
        handleCaretRightClick={handleCaretRightClick}
      />
    </>
  );
}
