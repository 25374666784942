import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import 'moment-timezone';
import { Segment, Header, Loader, Dimmer, Table, Message, Tab, Divider } from "semantic-ui-react";
import useUserData from "../../state-manipulation/hooks/useUserData";
import usePageData from "../../state-manipulation/hooks/usePageData";

import { LG_WIDTH } from "../../constants";
import { setSelectedPage } from "../../state-manipulation/reducers/page-data/types";
import DateMenu from "../common/DateMenu";
import DateRangeSelector from "../common/DateRangeSelector";
import useDateRangeData from "../../state-manipulation/hooks/useDateRangeData";
import Moment from "react-moment";
import { clearUser } from "../../state-manipulation/reducers/user-data/types";
import { useParams } from "react-router-dom";
import { GetLabor, GetShifts } from "../../api/LaborAPI";
import PageHeader from "../common/PageHeader";


export default function ViewLabor({ className }) {
  const { company } = useParams();
  const [ userData, userDispatch ] = useUserData();
  const [ pageData, pageDispatch ] = usePageData();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ totalLabor, setTotalLabor ] = useState(0);
  const [ employeeShifts, setEmployeeShifts ] = useState([]);
  const [ clockInNotScheduled, setClockInsNotScheduled ] = useState([]);
  const [ dateRangeData, dateRangeDispatch ] = useDateRangeData();
  const minutesOffset = -1 * parseInt(moment(dateRangeData.dateStart).tz('America/New_York').utcOffset());
  const [ days, setDays ] = useState(0);
  const [ timeOutId, setTimeoutId ] = useState('');

  const getLabor = async (_dateStart, _dateEnd) => {
    try {
      setIsLoading(true);

      let res = await GetShifts({ company: company, startDate: _dateStart, endDate: _dateEnd});

      setClockInsNotScheduled(res.data.data.clockInsNotScheduled);

      setEmployeeShifts(res.data.data.employeeShifts);
      setIsLoading(false);

    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const renderClockIns = (c) => {
    let clockIn = c.clockedInTime;
    let clockOut = c.clockedOutTime ? moment(c.clockedOutTime).format('h:mm a') : 'Not Clocked Out';
    let originalClockOutDisplay;

    if (c.originalClockedInTime || c.originalClockedOutTime) {
      if ((moment(c.originalClockedInTime).format('h:mm a') !== moment(c.clockedInTime).format('h:mm a')) || (moment(c.originalClockedOutTime).format('h:mm a') !== moment(c.clockedOutTime).format('h:mm a'))) {
        let ogClockIn = c.originalClockedInTime || c.clockedInTime;
        let ogClockOut = c.originalClockedOutTime || c.clockedOutTime;
        originalClockOutDisplay = <strong className="flag">{moment(ogClockIn).format('h:mm a')}-{moment(ogClockOut).format('h:mm a')} adj by {c.editorFirstName} {c.editorLastName}</strong>;
      }
    }

    return (
      <>
      <Moment format="h:mm a">{clockIn}</Moment>-
      {clockOut} {c.jobName}
      {originalClockOutDisplay && (
        <><br />{originalClockOutDisplay}</>
      )}
      </>
    )
  };

  const renderShiftsByDate = (shiftsByDate) => {
    return (
      <Table size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Shift</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Sch</Table.HeaderCell>
            <Table.HeaderCell>Act</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {shiftsByDate.map((sd, sdIndex) => {
            return (
              <Table.Row key={sdIndex}>
                <Table.Cell><Moment format="ddd MM/DD">{sd.date}</Moment></Table.Cell>
                <Table.Cell>
                  {sd.shifts.map((s, sIndex) => {
                    return (
                      <React.Fragment key={sIndex}>
                        <Moment format="h:mm a">{s.startTime}</Moment>-
                        <Moment format="h:mm a">{s.endTime}</Moment> {s.jobName}
                        <br />
                      </React.Fragment>
                    )
                  })}
                  <Divider />
                  {sd.clockIns.map((c, cIndex) => {
                  return (
                    <React.Fragment key={cIndex}>
                      {renderClockIns(c)}
                      <br />
                    </React.Fragment>
                  );
                })}
                </Table.Cell>
                <Table.Cell negative={sd.clockInStatus !== "ON TIME" ? true : false}>{sd.clockInStatus} ({sd.clockInDiff}m) {sd.isCorrectRole === false && (
                  <><br /><strong className="flag">Wrong Role</strong>
                  </>
                )}</Table.Cell>
                <Table.Cell>{sd.totalTimeScheduled}</Table.Cell>
                <Table.Cell negative={sd.totalDifference > .5 ? true : false}>{sd.totalTimeClockedIn.toFixed(2)} ({sd.totalDifference.toFixed(2)}hr)</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    )
  };

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: 'Labor'
      }
    });
  }, []);

  useEffect(() => {
    let _days = moment(dateRangeData.dateEnd).diff(moment(dateRangeData.dateStart))/1000/60/60/24;

    if (_days >= 0 && _days <= 6 && company) {
      clearTimeout(timeOutId);
      setTimeoutId(setTimeout(()=>{
        getLabor(dateRangeData.dateStart, dateRangeData.dateEnd);
      }, 1000));
    }
    setDays( _days );
  }, [ dateRangeData, company ]);

  return (
    <div className={"content-container " + className}>
      <DateMenu />
      <PageHeader pageName="Labor Actual vs Scheduled" orgName={userData.selectedOrg?.name} />
      {(days > 6) && (
        <Message warning>Date range must be 7 days or less.</Message>
      )}
      {(days < 0) && (
        <Message warning>End date must be the same or after start date.</Message>
      )}
      <DateRangeSelector />
      <Segment>
        <Dimmer active={isLoading}>
          <Loader />
        </Dimmer>
        {clockInNotScheduled.length > 0 && (
          <div>
            <Header as="h2">Clock-Ins not Scheduled</Header>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>First Name</Table.HeaderCell>
                  <Table.HeaderCell>Last Name</Table.HeaderCell>
                  <Table.HeaderCell>Job</Table.HeaderCell>
                  <Table.HeaderCell>Clock-In</Table.HeaderCell>
                  <Table.HeaderCell>Clock-Out</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {clockInNotScheduled && clockInNotScheduled.map((ts, tsIndex) => {
                  return (
                    <Table.Row key={tsIndex}>
                      <Table.Cell>
                        {ts.firstName}
                      </Table.Cell>
                      <Table.Cell>
                        {ts.lastName}
                      </Table.Cell>
                      <Table.Cell>
                        {ts.jobName}
                      </Table.Cell>
                      <Table.Cell>
                        {(ts.originalClockedInTime && ts.originalClockedInTime.substring(0,19) !== ts.clockedInTime.substring(0,19)) &&
                          <strong className="flag">Clock in adjusted<br /></strong>
                        }
                        {ts.clockedInTime && ts.clockedInTime.substr(11,5) === '04:00' &&
                          <strong className="flag">Did not clock in<br /></strong>
                        }
                        <Moment format="ddd h:mm:ss a" tz="America/New_York">
                        {ts.clockedInTime}
                        </Moment>
                        {(ts.originalClockedInTime && ts.originalClockedInTime.substring(0,19) !== ts.clockedInTime.substring(0,19)) &&
                          <em className="warning"><br />Original:
                            <Moment format="ddd h:mm:ss a" tz="America/New_York">
                            {ts.originalClockedInTime}
                            </Moment>
                            <br />
                            Adjusted by {ts.editorFirstName} {ts.editorLastName}
                          </em>
                        }
                      </Table.Cell>
                      <Table.Cell>
                        {ts.i_clocked_out_by_user !== ts.i_employee_id &&
                          <strong className="flag">Did not clock out</strong>
                        }
                        {ts.clockedOutTime &&
                          <div>
                          <Moment format="ddd h:mm:ss a" tz="America/New_York">
                          {ts.clockedOutTime}
                          </Moment>
                          {(ts.originalClockedOutTime && ts.originalClockedOutTime.substring(0,19) !== ts.clockedOutTime.substring(0,19)) &&
                            <em className="warning"><br />Original:
                              <Moment format="ddd h:mm:ss a"  tz="America/New_York">
                              {ts.originalClockedOutTime}
                              </Moment>
                              <br />
                              Adjusted by {ts.editorFirstName} {ts.editorLastName}
                            </em>
                          }
                          </div>
                        }

                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        )}
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>First Name</Table.HeaderCell>
              <Table.HeaderCell>Last Name</Table.HeaderCell>
              <Table.HeaderCell>Sch Hrs</Table.HeaderCell>
              <Table.HeaderCell>Act Hrs</Table.HeaderCell>
              <Table.HeaderCell>Diff</Table.HeaderCell>
              <Table.HeaderCell>Shifts</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {employeeShifts && employeeShifts.map((es, tsIndex) => {
              return (
                <Table.Row key={tsIndex}>
                  <Table.Cell>
                    {es.firstName}
                  </Table.Cell>
                  <Table.Cell>
                    {es.lastName}
                  </Table.Cell>
                  <Table.Cell>
                    {es.totalTimeScheduled}
                  </Table.Cell>
                  <Table.Cell>
                    {es.totalTimeClockedIn.toFixed(2)}
                  </Table.Cell>
                  <Table.Cell negative={es.totalDifference > .5 ? true : false}>
                    {es.totalDifference.toFixed(2)}
                  </Table.Cell>
                  <Table.Cell>
                    {renderShiftsByDate(es.shiftsByDate)}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Segment>
    </div>
  );
}

ViewLabor.propTypes = {
  className: PropTypes.string
};

ViewLabor.defaultProps = {
  className: 'labor-container'
};