import React from "react";
import { Link, useParams } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";
import usePageData from "../../state-manipulation/hooks/usePageData";
import useUserData from "../../state-manipulation/hooks/useUserData";
import IsAuthorized from "../common/IsAuthorized";

export default function IoTMenu({ onClick }) {
  let { company } = useParams();
  const [pageData] = usePageData();
  const [userData, userDispatch] = useUserData();

  if (!company) {
    company = userData.orgs[0].name.toLowerCase();
  }

  return (
    <IsAuthorized permission="hasIoT">
      <Menu.Item>
        <Menu.Header>IoT</Menu.Header>
        <Menu.Menu>
          <IsAuthorized permission="hasIoTStockPots">
            <Menu.Item
              as={Link}
              to={"/" + company + "/stock-pots/live"}
              onClick={onClick}
              active={
                pageData.selectedPage.toUpperCase() === "STOCK POTS"
                  ? true
                  : false
              }
            >
              <Icon name="thermometer half" />
              Stock Pots
            </Menu.Item>
          </IsAuthorized>
          <IsAuthorized permission="hasIoTRefrigerators">
            <Menu.Item
              as={Link}
              to={"/" + company + "/refrigerators"}
              onClick={onClick}
              active={
                pageData.selectedPage.toUpperCase() === "REFRIGERATORS"
                  ? true
                  : false
              }
            >
              <Icon name="thermometer half" />
              Refrigerators
            </Menu.Item>
          </IsAuthorized>
        </Menu.Menu>
      </Menu.Item>
    </IsAuthorized>
  );
}
