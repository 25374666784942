import React from "react";
import { Outlet, Route } from "react-router-dom";
import MarkdownPage from "../pages/MarkdownPage";
import TeamOverviewSource from "../content/team-site/overview.md";
import WeeklyManagementTasksSource from "../content/team-site/weekly-management-tasks.md";
import EmergenciesSource from "../content/team-site/emergencies.md";
import TeamSiteOverview from "../pages/TeamSiteOverview";
import { createElement } from "react";
import Contacts from "../pages/Contacts";
import TeamSiteSOPs from "../pages/TeamSiteSops";
import SOPPage from "../pages/SOPPage";

export const TeamSiteRoutes = () => {
  return (
    <Route path="team-site" element={<Outlet />}>
      {TeamSiteRouteData.map((r, rIndex) => {
        const CustomComponent = r.component;
        return (
          <Route
            exact
            path={r.path}
            key={rIndex}
            element={<CustomComponent {...r.data} />}
          />
        );
      })}
      {/* <Route exact path="sops/:slug" element={<SOPPage />} /> */}
    </Route>
  );
};

export const TeamSiteRouteData = [
  {
    path: "",
    component: TeamSiteOverview,
    data: {
      pageName: "Team Site Overview",
      selectedPage: "TEAM SITE OVERVIEW",
      policies: [{
        "name": "Employee Handbook",
        "url": "https://docs.google.com/document/d/1M-CsHn-IXSFn62x2arbUT9VYOqmHhvsWPscPZJ9WOTU/edit?usp=sharing",
        "target": "_blank",
      },{
        "name": "Request Off/Trading Shipgs/Calling Off",
        "url": "https://docs.google.com/document/d/1M-CsHn-IXSFn62x2arbUT9VYOqmHhvsWPscPZJ9WOTU/edit#heading=h.8zxsjr994bp3",
        "target": "_blank",
      },{
        "name": "Harrassment",
        "url": "https://docs.google.com/document/d/1M-CsHn-IXSFn62x2arbUT9VYOqmHhvsWPscPZJ9WOTU/edit#heading=h.d72sqkoh1l6v",
        "target": "_blank",
      }],
      thirdPartyTools: [
        {
          "name": "7 Shifts",
          "url": "https://www.7shifts.com",
          "target": "_blank"
        },
        {
          "name": "Craftable",
          "url": "https://www.craftable.com",
          "target": "_blank"
        },
        {
          "name": "Slack",
          "url": "https://langthanggroup.slack.com/",
          "target": "_blank",
          "access": 'hasTeamSiteShiftLeadAccess'
        },
        {
          "name": "Team Site Old - Hi-Mark",
          "url": "http://team.thehimark.com/",
          "target": "_blank",
          "company": "HI-MARK"
        },
        {
          "name": "Team Site Old - Hapa",
          "url": "http://team.quanhapa.com/",
          "target": "_blank",
          "company": "HAPA"
        },
        {
          "name": "Team Site Old - PLT",
          "url": "http://team.pholangthang.com/",
          "target": "_blank",
          "company": "PLT"
        },
        {
          "name": "Tip Calculator - Hapa",
          "url": "https://docs.google.com/spreadsheets/d/1ZMT5eHq-FsWsTY2JpNwrRfdQ7WfPHW0i3oBkQ5APfWc/edit#gid=1020710181",
          "target": "_blank",
          "access": 'hasTeamSiteShiftLeadAccess',
          "company": "HAPA"
        },
        {
          "name": "Tip Calculator - Hi-Mark",
          "url": "https://docs.google.com/spreadsheets/u/1/d/1T-XK8yCXJFjOLIHHh0jBwFTIYNTI3Z_Jf4yHMv7ZKDQ/edit?usp=sharing",
          "target": "_blank",
          "access": 'hasTeamSiteShiftLeadAccess',
          "company": "HI-MARK"
        },
        {
          "name": "Tip Calculator - PLT",
          "url": "https://docs.google.com/spreadsheets/d/1KRhIil3hICzoezw48oK4U8DsioqKBxNgwOjI5Sh8kPU/edit#gid=1020710181",
          "target": "_blank",
          "access": 'hasTeamSiteShiftLeadAccess',
          "company": "PLT"
        },
        {
          "name": "Toast",
          "url": "https://www.toasttab.com/",
          "target": "_blank",
          "access": 'hasTeamSiteManagerAccess'
        }
      ]
    },
  },
  {
    path: "contacts",
    component: Contacts,
    data: {
      selectedPage: "Contacts",
      pageName: "Contacts"
    },
  },
  {
    path: "emergencies",
    component: MarkdownPage,
    data: {
      selectedPage: "Team Site Emergencies",
      pageSource: EmergenciesSource
    },
  },
  {
    path: "sops",
    component: TeamSiteSOPs,
    data: {
      selectedPage: "SOPS",
      pageName: "Standard Operating Procedures",
    },
  },
  {
    path: "sops/:slug",
    component: SOPPage,
    data: {
      selectedPage: "SOPS",
    },
  }
];
