import React, { useContext, useEffect, useReducer, useState } from "react";
import PageHeader from "../components/common/PageHeader";
import usePageData from "../state-manipulation/hooks/usePageData";
import { setSelectedPage } from "../state-manipulation/reducers/page-data/types";
import { GetEmployeesStatus, ImportToastEmployees, ImportTrainingSkills } from "../api/TrainingAPI";
import { Link, useParams } from "react-router-dom";
import { Button, Dimmer, List, Loader, Table } from "semantic-ui-react";
import { EmployeesTrainingStatusContext, actions } from "../context/EmployeesTrainingStatusContext";
import DisplayTrainingStatusLabel from "../components/training/DisplayTrainingStatusLabel";
import IsAuthorized from "../components/common/IsAuthorized";


export default function TrainingAdmin({ selectedPage }) {
  const [ isLoading, setIsLoading ] = useState(true);
  const { company } = useParams();
  const [, pageDispatch] = usePageData();
  const [ employeesTrainingStatus, employeeTrainingStatusDispatch] = useContext(EmployeesTrainingStatusContext);


  const getEmployeesStatus = async () => {
    setIsLoading(true);
    const res = await GetEmployeesStatus({ company })

    employeeTrainingStatusDispatch({
      type: actions.populateEmployeesJobReferenceTrainingStatus,
      payload: {
        employees: res.data.employees,
        trainingSkills: res.data.trainingSkills
      }
    });
    setIsLoading(false);
  };

  const handleImportTrainingSkills = async (e) => {
    let res = await ImportTrainingSkills();
  }

  const handleImportEmployees = async () => {
    let res = await ImportToastEmployees();
  };

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: selectedPage,
      },
    });

    return () => {
      employeeTrainingStatusDispatch({
        type: actions.clear
      });
    }
  }, []);

  useEffect(() => {
    getEmployeesStatus();
  }, [company]);

  return (
    <div className={"content-container"}>
      <Dimmer active={isLoading}>
        <Loader active={isLoading} />
      </Dimmer>
      <PageHeader pageName="Training Admin" />
      {!isLoading && (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Training Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {employeesTrainingStatus.employees && employeesTrainingStatus.employees.map((e, eIndex) => {
            return (
              <Table.Row key={eIndex}>
                <Table.Cell>
                  {e.externalEmployeeId && (<Link to={`./employees/${e.externalEmployeeId}`}>{e.firstName} {e.lastName}</Link>)}
                  {!e.externalEmployeeId && (<em title="Missing externalEmployeeId">{e.firstName} {e.lastName}</em>)}
                </Table.Cell>
                <Table.Cell><DisplayTrainingStatus jobReferences={e.jobReferences} /></Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      )}
      <IsAuthorized permission="hasTrainingSettings">
        <Button as="a" href="https://docs.google.com/spreadsheets/d/1lVdpVVYDZo7v4aDXf-MYaq0LgO1ge94d04BvcebhA6k/edit#gid=1391453722" target="_blank">Training Template</Button>
        <Button onClick={handleImportTrainingSkills}>Import Training Template</Button>
        <Button onClick={handleImportEmployees}>Import Employees</Button>
      </IsAuthorized>
    </div>
  );
}

function DisplayTrainingStatus({ jobReferences }) {
  const filteredJobReferneces = jobReferences.filter(jR => !jR.notAssigned);
  return (
    <List>
      {filteredJobReferneces && filteredJobReferneces.map((jR, jRIndex) => {
        return <DisplayTrainingStatusByPosition key={jRIndex} jobReference={jR} />
      })}
    </List>
  )
}

function DisplayTrainingStatusByPosition({ jobReference }) {
  if (!jobReference.trainingSkills) {
    return <></>
  }
  return (
    <List.Item>{jobReference.title} <DisplayTrainingStatusLabel trainingSkillsCount={jobReference.trainingStatus.totalSkills} trainingStatus={jobReference.trainingStatus} /></List.Item>
  )
}