import axios from 'axios';
import PropTypes from 'prop-types';

export default async function AxiosWrapper({
  isAuthRoute: isAuthRoute,
  ...options
}) {
  let _axiosOptions = options;
  if (isAuthRoute) {
    if (!_axiosOptions.headers) {
      _axiosOptions.headers = {};
    }
    _axiosOptions.headers.Authorization = `Basic ${window.localStorage.getItem(
      'token'
    )}`;
  }

  try {
    let res = await axios(_axiosOptions);

    return res;
  } catch (error) {
    console.error(error);
  }
}

export function AxiosAbort () {
  const axiosController = new AbortController();
  axiosController.abort();
}

AxiosWrapper.propTypes = {
  options: PropTypes.object.isRequired,
  isAuthRoute: PropTypes.bool
};

AxiosWrapper.defaultProps = {
  isAuthRoute: false
};
