import React, { useEffect, useState } from "react";
import DisplayTrainingSkillsGroup from "./DisplayTrainingSkillsGroup";
import { Accordion } from "semantic-ui-react";
import DisplayTrainingStatusLabel from "./DisplayTrainingStatusLabel";

export default function DisplayTrainingStatusByPosition({ jobReference, employee }) {
  const displayOrder = ['Week 1 Training', 'Week 2 Training', 'Week 3 Training', 'Week 4 Training', 'Week 5 Training'];

  if (!jobReference.trainingSkills) {
    return <></>
  }

  return (
    <>
      <h3>{jobReference.code} <DisplayTrainingStatusLabel trainingSkillsCount={jobReference.trainingStatus.totalSkills} trainingStatus={jobReference.trainingStatus} /></h3>
      <Accordion styled fluid
        exclusive={false}
        >
        {jobReference.trainingSkillsByType && displayOrder.map((key) => {
          if (!jobReference.trainingSkillsByType[key]) {
            return;
          }
          return (
            <Accordion.Accordion style={{marginTop:0}} key={key} >
            <DisplayTrainingSkillsGroup employee={employee} trainingStatus={jobReference.trainingSkillsByType[key].trainingStatus} trainingSkills={jobReference.trainingSkillsByType[key].trainingSkills} name={key} />
            </Accordion.Accordion>
          );
        })}
      </Accordion>
      <h5>Skills by Category</h5>
      <Accordion styled fluid
        exclusive={false}
        >
      {jobReference.trainingSkillsByType && Object.keys(jobReference.trainingSkillsByType).map((key) => {
          if (displayOrder.indexOf(key) > -1) {
            return;
          }
          return (
            <Accordion.Accordion style={{marginTop:0}} key={key} >
            <DisplayTrainingSkillsGroup employee={employee} trainingStatus={jobReference.trainingSkillsByType[key].trainingStatus} trainingSkills={jobReference.trainingSkillsByType[key].trainingSkills} name={key} />
            </Accordion.Accordion>
          );
        })}
      </Accordion>
    </>
  )
}
