import React from "react";
import { Link, useParams } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";
import usePageData from "../../state-manipulation/hooks/usePageData";
import useUserData from "../../state-manipulation/hooks/useUserData";
import IsAuthorized from "../common/IsAuthorized";

export default function EmployeesMenu({ onClick }) {
  let { company } = useParams();
  const [pageData] = usePageData();
  const [userData, userDispatch] = useUserData();

  if (!company) {
    company = userData.orgs[0].name.toLowerCase();
  }

  return (
    <IsAuthorized permission="hasEmployees">
      <Menu.Item>
        <Menu.Header>Employees</Menu.Header>
        <Menu.Menu>
          <IsAuthorized permission="hasEmployeesLabor">
            <Menu.Item
              as={Link}
              to={"/" + company + "/labor"}
              onClick={onClick}
              active={
                pageData.selectedPage.toUpperCase() === "LABOR" ? true : false
              }
            >
              <Icon name="hand rock" />
              Labor Actual vs Scheduled
            </Menu.Item>
          </IsAuthorized>
        </Menu.Menu>
      </Menu.Item>
    </IsAuthorized>
  );
}
