import React from "react";
import { Link, useParams } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";
import usePageData from "../../state-manipulation/hooks/usePageData";
import useUserData from "../../state-manipulation/hooks/useUserData";
import IsAuthorized from "../common/IsAuthorized";

export default function TeamSiteMenu({ onClick }) {
  let { company } = useParams();
  const [pageData] = usePageData();
  const [userData, userDispatch] = useUserData();

  if (!company) {
    company = userData.orgs[0].name.toLowerCase();
  }

  return (
    <IsAuthorized permission="hasTeamSite">
      <Menu.Item>
        <Menu.Header>Team Site</Menu.Header>
        <Menu.Menu>
          <Menu.Item
            as={Link}
            to={"/" + company + "/team-site"}
            onClick={onClick}
            active={
              pageData.selectedPage.toUpperCase() === "TEAM SITE OVERVIEW"
                ? true
                : false
            }
          >
            <Icon name="gamepad" />
            Overview
          </Menu.Item>
          <Menu.Item
            as={Link}
            to={"/" + company + "/team-site/sops"}
            onClick={onClick}
            active={
              pageData.selectedPage.toUpperCase() === "SOPS"
                ? true
                : false
            }
          >
            <Icon name="gamepad" />
            S.O.P.s
          </Menu.Item>
          <Menu.Item
            as={Link}
            to={"/" + company + "/team-site/contacts"}
            onClick={onClick}
            active={
              pageData.selectedPage.toUpperCase() === "CONTACTS"
                ? true
                : false
            }
          >
            <Icon name="gamepad" />
            Contacts
          </Menu.Item>
          {/* <Menu.Item
            as={Link}
            to={"/" + company + "/team-site/policies"}
            onClick={onClick}
            active={
              pageData.selectedPage.toUpperCase() === "TEAM SITE POLICIES"
                ? true
                : false
            }
          >
            <Icon name="gamepad" />
            Training &amp; Guides
          </Menu.Item> */}
          <Menu.Item
            as={Link}
            to={"/" + company + "/team-site/emergencies"}
            onClick={onClick}
            active={
              pageData.selectedPage.toUpperCase() === "TEAM SITE EMERGENCIES"
                ? true
                : false
            }
          >
            <Icon name="gamepad" />
            Emergencies
          </Menu.Item>
        </Menu.Menu>
      </Menu.Item>
    </IsAuthorized>
  );
}
