import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import DateMenu from "../components/common/DateMenu";
import { Header, Message, Table, Dimmer, Loader } from "semantic-ui-react";
import { MD_WIDTH, LG_WIDTH } from "../constants";
import useUserData from "../state-manipulation/hooks/useUserData";
import usePageData from "../state-manipulation/hooks/usePageData";
import useDateRangeData from "../state-manipulation/hooks/useDateRangeData";
import DateRangeSelector from "../components/common/DateRangeSelector";
import { GetTaskLists } from "../api/TaskListAPI";
import Moment from "react-moment";
import moment from "moment";
import { setSelectedPage } from "../state-manipulation/reducers/page-data/types";
import PageHeader from "../components/common/PageHeader";

export default function TaskLists({ className }) {
  const { company } = useParams();
  const [taskLists, setTaskLists] = useState([]);
  const [userData, userDispatch] = useUserData();
  const [pageData, pageDispatch] = usePageData();
  const [dateRangeData, dateRangeDispatch] = useDateRangeData();
  const [days, setDays] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [timeOutId, setTimeoutId] = useState("");
  const [tableSort, setTableSort] = useState({
    column: "name",
    direction: "ascending",
  });

  const getTaskLists = async () => {
    try {
      setIsLoading(true);
      let res = await GetTaskLists(company, {
        startDate: dateRangeData.dateStart,
        endDate: dateRangeData.dateEnd,
      });
      console.log("res");
      console.log(res);
      setTaskLists(res.data);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  const handleSort = (clickedColumn) => () => {
    let { column, direction } = { ...tableSort };
    let _taskLists = [...taskLists];

    if (column !== clickedColumn) {
      direction = "ascending";
    } else {
      direction = direction === "ascending" ? "descending" : "ascending";
    }

    if (direction === "ascending") {
      _taskLists.sort((a, b) => {
        let aVal = a[clickedColumn];
        let bVal = b[clickedColumn];

        if (clickedColumn === "assigneeRoles") {
          aVal = aVal.join(",");
          bVal = bVal.join(",");
        } else if (typeof aVal === "string") {
          aVal = aVal.toUpperCase(); // ignore upper and lowercase
          bVal = bVal.toUpperCase(); // ignore upper and lowercase
        }

        if (aVal < bVal) {
          return -1;
        }
        if (aVal > bVal) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    } else {
      _taskLists.sort((a, b) => {
        let aVal = a[clickedColumn];
        let bVal = b[clickedColumn];

        if (typeof aVal === "string") {
          aVal = aVal.toUpperCase(); // ignore upper and lowercase
          bVal = bVal.toUpperCase(); // ignore upper and lowercase
        }

        if (aVal > bVal) {
          return -1;
        }
        if (aVal < bVal) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    }

    setTableSort({
      column: clickedColumn,
      direction: direction,
    });

    setTaskLists(_taskLists);
  };

  useEffect(() => {
    getTaskLists();
  }, []);

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: "Task Lists",
      },
    });
  }, []);

  useEffect(() => {
    let _days =
      moment(dateRangeData.dateEnd).diff(moment(dateRangeData.dateStart)) /
      1000 /
      60 /
      60 /
      24;

    if (_days >= 0 && _days <= 60 && userData.selectedOrg) {
      clearTimeout(timeOutId);
      setTimeoutId(
        setTimeout(() => {
          getTaskLists(company, dateRangeData.dateStart, dateRangeData.dateEnd);
        }, 1000)
      );
    }
    setDays(_days);
  }, [dateRangeData, company]);

  return (
    <div className={"content-container " + className}>
      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      <DateMenu />
      <PageHeader pageName="Daily Task Lists" orgName={userData.selectedOrg?.name} />
      {days > 60 && (
        <Message warning>Date range must be 60 days or less.</Message>
      )}
      {days < 0 && (
        <Message warning>
          End date must be the same or after start date.
        </Message>
      )}
      <DateRangeSelector />
      <Table sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={tableSort.column === "name" ? tableSort.direction : null}
              onClick={handleSort("name")}
            >
              Name
            </Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell
              sorted={
                tableSort.column === "percentComplete"
                  ? tableSort.direction
                  : null
              }
              onClick={handleSort("percentComplete")}
            >
              Status
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={
                tableSort.column === "startTime" ? tableSort.direction : null
              }
              onClick={handleSort("startTime")}
            >
              Start Time
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={
                tableSort.column === "endTime" ? tableSort.direction : null
              }
              onClick={handleSort("endTime")}
            >
              End Time
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={
                tableSort.column === "assigneeRoles"
                  ? tableSort.direction
                  : null
              }
              onClick={handleSort("assigneeRoles")}
            >
              Assigned To
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {taskLists &&
            taskLists.map((tl, tlIndex) => {
              if (!tl.totalTasksComplete) {
                tl.totalTasksComplete = 0;
              }
              if (!tl.percentComplete) {
                tl.percentComplete = 0;
              }
              return (
                <Table.Row key={tlIndex}>
                  <Table.Cell>
                    <Link to={`/${company}/task-lists/${tl._id.$oid}`}>
                      {tl.name}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    <Moment format="ddd M/D/YYYY">{tl.date}</Moment>
                  </Table.Cell>
                  <Table.Cell>
                    {tl.totalTasksComplete} of {tl.totalTasks} (
                    {Math.round(tl.percentComplete * 100)}%)
                  </Table.Cell>
                  <Table.Cell>{tl.startTime}</Table.Cell>
                  <Table.Cell>{tl.endTime}</Table.Cell>
                  <Table.Cell>{tl.assigneeRoles.join("/")}</Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
      {taskLists && taskLists.length === 0 && (
        <p>
          Generate a daily task list from{" "}
          <Link to={`/${company}/admin/task-list-templates`}>
            Task List Templates
          </Link>
        </p>
      )}
    </div>
  );
}

TaskLists.propTypes = {
  className: PropTypes.string,
};

TaskLists.defaultProps = {
  className: "",
};
