import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Container, Loader, Segment } from "semantic-ui-react";
import IsAuthorized from "../components/common/IsAuthorized";
import AxiosWrapper from "../utils/AxiosWrapper";
import MarkdownPage from "./MarkdownPage";

export default function SOPPage() {
  const { slug } = useParams();
  const [pageName, setPageName] = useState("");
  const [pageSource, setPageSource] = useState();
  const [SOPData, setSOPData] = useState();
  const location = useLocation();

  const getSOPBySlug = async (slug) => {
    try {
      const res = await AxiosWrapper({
        url: `${process.env.LTG_NEXT_APP_URL}/api/sops/${slug}`,
        method: "GET",
        isAuthRoute: true
      });
      console.log("🚀 ~ file: SOPPage.jsx:24 ~ getSOPBySlug ~ res:", res)

      if (!res.data.data || !res.data.contents) {
        throw(new Error('No data returned'))
      }
      setSOPData(res.data.data);
      setPageSource(res.data.contents);

    } catch (e) {
      console.log(e);

    }
  };

  useEffect(() => {
    getSOPBySlug(slug);
  }, [slug]);

  return (
    <>
      {!pageSource && <Loader content="Loading..." active />}
      {pageSource && (
        <>
          <MarkdownPage
            pageName={pageName}
            pageSource={pageSource}
            selectedPage="SOPS"
          />
          {SOPData?.googleDocId && (
            <div className={"content-container"}>
              <Segment>
                <p>
                  <a
                    href={`https://docs.google.com/document/d/${SOPData.googleDocId}`}
                    target="_blank"
                  >
                    View Doc in Google Drive
                  </a>
                  |
                  <a href={`${process.env.LTG_NEXT_APP_URL}/api/qrcode?url=${encodeURIComponent(`${process.env.APP_URL}/all/sops/${slug}`)}&fileName=${slug}.svg&type=svg`}>Generate QR Code SVG</a> 
                   | 
                  <a href={`${process.env.LTG_NEXT_APP_URL}/api/qrcode?url=${encodeURIComponent(`${process.env.APP_URL}/all/sops/${slug}`)}&fileName=${slug}.png&type=png`}>Generate QR Code PNG</a>

                  | 
                  <a href={`${process.env.LTG_NEXT_APP_URL}/api/qrcode?url=${encodeURIComponent(`${process.env.APP_URL}/all/sops/${slug}`)}&fileName=${slug}.png&type=pdf&text=${encodeURIComponent(SOPData.name)}`}>Generate QR Code PDF</a>
                </p>
              </Segment>
            </div>
          )}
        </>
      )}
    </>
  );
}
