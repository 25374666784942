import React, { createContext, useReducer } from "react";
export const actions = {
  setEmployees: 'SET_EMPLOYEES',
  populateEmployeesJobReferenceTrainingStatus: 'POPULATE_EMPLOYEES_JOB_REFERENCE_TRAINING_STATUS',
  fetchNewData: 'FETCH_NEW_DATA',
  search: 'SEARCH',
  clear: 'CLEAR'
}

export const EmployeesTrainingStatusContext = createContext({});

const initialState = {
  employees: [],
  searchTerm: null,
  trainingSkills: [],
  isSearching: false
}

export const EmployeesTrainingStatusProvider = ({ children }) => {
  const [ employeesTrainingStatus, dispatch ] = useReducer(employeesTrainingStatusDataReducer, initialState);


  return (
    <EmployeesTrainingStatusContext.Provider value={[employeesTrainingStatus, dispatch]}>
      {children}
    </EmployeesTrainingStatusContext.Provider>
  );
}

function employeesTrainingStatusDataReducer(
  state,
  { type, payload }
) {
  switch (type) {
    case actions.setEmployees:
      return {
        ...state,
        employees: payload.employees
      };
    case actions.populateEmployeesJobReferenceTrainingStatus:

      if (!state.masterTrainingSkills) {
        state.masterTrainingSkills = JSON.parse(JSON.stringify(payload.trainingSkills));
      }

      if (typeof payload.searchTerm !== 'undefined') {
        state.searchTerm = payload.searchTerm;
        state.isSearching = true;
      }


      let _trainingSkills;


      if (state.searchTerm && state.searchTerm.trim() !== "") {
        _trainingSkills = searchTrainingSkills({ searchTerm: state.searchTerm, trainingSkills: JSON.parse(JSON.stringify(state.masterTrainingSkills)) });
      } else {
        _trainingSkills = JSON.parse(JSON.stringify(state.masterTrainingSkills))
      }

      let _employees = payload.employees || state.employees;

      return {
        ...state,
        employees: populateEmployeesJobReferenceTrainingStatus({ employees: _employees, trainingSkills: _trainingSkills}),
        trainingSkills: _trainingSkills,
      };
    case actions.fetchNewData:
      return {
        ...state,
        fetchNewData: true
      }
    case actions.clear:
      return initialState;
    default:
      throw new Error(
        `Unknown action ${type} in employeesTrainingStatusDataReducer`
      );
  }
}

function sorttrainingSkillsByPosition(trainingSkills) {
  if (trainingSkills.length === 0) {
    return;
  }

  let _trainingSkillsByPosition = {};
  let trainingPositionKeys = [];
  Object.keys(trainingSkills[0]).forEach((key) => {
    if (typeof trainingSkills[0][key] === "boolean") {
      trainingPositionKeys.push(key);
      _trainingSkillsByPosition[key] = [];
    }
  });

  trainingSkills.forEach((trainingSkill) => {
    let employeetrainingSkill = employeeTrainingStatus.find(eTS => eTS.trainingSkillId === trainingSkill.trainingSkillId);

    if (employeetrainingSkill) {
      trainingSkill.employeetrainingSkill = employeetrainingSkill;
    }

    trainingPositionKeys.forEach((key) => {
      if (trainingSkill[key] === true) {
        _trainingSkillsByPosition[key].push(trainingSkill);
      }
    });
  });

  return _trainingSkillsByPosition;
}

function getTrainingPositionKeys(trainingSkills) {
  if (trainingSkills.length === 0) {
    return [];
  }
  let trainingPositionKeys = [];
  Object.keys(trainingSkills[0]).forEach((key) => {
    if (typeof trainingSkills[0][key] === "boolean") {
      trainingPositionKeys.push(key);
    }
  });

  return trainingPositionKeys;
}

function populateEmployeesJobReferenceTrainingStatus({ employees, trainingSkills }) {
  const trainingPositionKeys = getTrainingPositionKeys(trainingSkills);

  employees = employees.map(e => {
    let trainingSkillsWithStatus = trainingSkills.map(tI => {
      let employeeTrainingStatus =  e.employeeTrainingStatus.find(eTS => eTS.trainingSkillId === tI.trainingSkillId);

      if (employeeTrainingStatus) {
        tI.employeeTrainingStatus = employeeTrainingStatus;
      } else {
        delete tI.employeeTrainingStatus;
      }

      return tI;
    });

    // fill in jobReference not assigned

    trainingPositionKeys.forEach(key => {
      if (!e.jobReferences.find(jR => jR.code === key)) {
        e.jobReferences.push({
          code: key,
          notAssigned: true
        });
      }
    })

    e.jobReferences = e.jobReferences.map(jR => {
      if (jR.code) {
        jR.trainingSkills = trainingSkillsWithStatus.filter(tI => tI[jR.code] === true);

        jR.trainingStatus = {
          started: 0,
          completed: 0,
          needRecertification: 0,
          totalSkills: 0
        };

        // This is used to track unique skills
        let skillIds = [];

        jR.trainingSkills.forEach(tS => {

          if (skillIds.includes(tS.trainingSkillId)) {
            return;
          }

          jR.trainingStatus.totalSkills++;
          skillIds.push(tS.trainingSkillId);

          if (!tS.employeeTrainingStatus) {
            return;
          }

          if (isStarted(tS.employeeTrainingStatus)) {
            jR.trainingStatus.started++;
          }
          if (isComplete(tS.employeeTrainingStatus)) {
            jR.trainingStatus.completed++;
          }

          if (tS.employeeTrainingStatus.needRecertification) {
            jR.trainingStatus.needRecertification++;
          }
        })

        jR.trainingSkillsByType = jR.trainingSkills.reduce(function (map, obj) {
          if (!map[obj.type]) {
            map[obj.type] = {
              trainingStatus: {
                started: 0,
                completed: 0,
                trainingCompleted: false,
                needRecertification: 0
              },
              trainingSkills: []
            };
          }

          if (isStarted(obj.employeeTrainingStatus)) {
            map[obj.type].trainingStatus.started++;
          }

          if (isComplete(obj.employeeTrainingStatus)) {
            map[obj.type].trainingStatus.completed++;
          }

          if (obj.employeeTrainingStatus?.needRecertification) {
            map[obj.type].trainingStatus.needRecertification++;
          }

          map[obj.type].trainingSkills.push(obj);

          return map;
        }, {});

        Object.keys(jR.trainingSkillsByType).forEach(key => {
          if (jR.trainingSkillsByType[key].trainingStatus.completed === jR.trainingSkillsByType[key].trainingSkills.length) {
            jR.trainingSkillsByType[key].trainingStatus.trainingCompleted = true;
          }
        });
      }
      return jR;
    })
    return e;
  })

  return employees;
}

function isComplete(employeeTrainingStatus) {
  if (employeeTrainingStatus?.certificationDate && employeeTrainingStatus.certificationDate.trim() !== "") {
    return true;
  }

  return false;
}

function isStarted(employeeTrainingStatus) {
  if (employeeTrainingStatus?.trainingStartDate && employeeTrainingStatus.trainingStartDate.trim() !== "") {
    return true;
  }

  return false;
}

function searchTrainingSkills({ searchTerm, trainingSkills }) {
  if (searchTerm.trim() === "") {
    return trainingSkills;
  }
  return trainingSkills.filter(tS => tS.name?.toUpperCase().indexOf(searchTerm.toUpperCase()) > -1);
}