import React from "react";
import { Label } from "semantic-ui-react";

export default function DisplaySingleTrainingStatusLabel({ trainingSkill }) {
  if (!trainingSkill.employeeTrainingStatus) {
    return '';
  }

  const { employeeTrainingStatus } = trainingSkill;

  if (employeeTrainingStatus.needRecertification) {
    return <Label color="orange">Need to Recertify</Label>
  }

  if (employeeTrainingStatus.certificationDate && employeeTrainingStatus.certificationDate.trim() !== "") {
    if (employeeTrainingStatus.isCertifiedTrainer) {
      return <><Label color="green">Completed</Label> <Label color="blue">Trainer</Label></>
    }
    return <Label color="green">Completed</Label>
  }

  if (employeeTrainingStatus.trainingStartDate && employeeTrainingStatus.trainingStartDate.trim() !== "") {
    return <Label color="yellow">Started</Label>
  }

  return <></>
}
