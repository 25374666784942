import React, { useState, useEffect } from 'react';
import {
  Menu,
  Loader,
  Header,
  Segment,
  Grid,
  Message,
  Table,
  Tab,
  Dimmer,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Bar,
  Line,
  Tooltip,
  Cell,
  CartesianGrid,
  Legend,
} from 'recharts';

import './styles/Sales.scss';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import useUserData from '../../state-manipulation/hooks/useUserData';
import usePageData from '../../state-manipulation/hooks/usePageData';
import { setSelectedPage } from '../../state-manipulation/reducers/page-data/types';
import { LG_WIDTH } from '../../constants';
import NumberFormat from 'react-number-format';
import PieChart from '../charts/PieChart';

import DateRangeSelector from '../common/DateRangeSelector';
import useDateRangeData from '../../state-manipulation/hooks/useDateRangeData';
import { DateMenuForm } from '../common/DateMenu';
import { DateRangeSelectorForm } from '../common/DateRangeSelector';
import { clearUser } from '../../state-manipulation/reducers/user-data/types';
import { GetSalesByDay } from '../../api/SalesAPI';
import { useParams } from 'react-router-dom';
import PageHeader from '../common/PageHeader';
import { AxiosAbort } from '../../utils/AxiosWrapper';

export default function SalesStats({ className }) {
  const [pageData, pageDispatch] = usePageData();
  const { company } = useParams();
  const [dateRangeData, dateRangeDispatch] = useDateRangeData();

  const currentHour = moment().tz('America/New_York').hours();
  const defaultStartDate =
    currentHour >= 0 && currentHour < 4
      ? moment().subtract(1, 'days')
      : moment();
  const defaultEndDate =
    currentHour >= 0 && currentHour < 4
      ? moment().subtract(1, 'days')
      : moment();
  const [dateStart, setDateStart] = useState(
    moment(defaultStartDate).add(-5, 'days').format('MM/DD/YY')
  );
  const [dateEnd, setDateEnd] = useState(defaultEndDate.format('MM/DD/YY'));

  const [days, setDays] = useState(0);
  const [userData, userDispatch] = useUserData();
  const [salesByDays, setSalesByDays] = useState([]);
  const [salesByDayGroup, setSalesByDayGroup] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [averageSales, setAverageSales] = useState(0);
  const [averageLunchSales, setAverageLunchSales] = useState(0);
  const [averageDinnerSales, setAverageDinnerSales] = useState(0);
  const [averageSalesGroup, setAverageSalesGroup] = useState(0);
  const [averageLunchSalesGroup, setAverageLunchSalesGroup] = useState(0);
  const [averageDinnerSalesGroup, setAverageDinnerSalesGroup] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [timeOutId, setTimeoutId] = useState('');

  const getSalesByDay = async ({startDate, endDate}) => {
    try {
      setIsLoading(true);
      let res = await GetSalesByDay({
        company: company,
        startDate: startDate,
        endDate: endDate,
        selectedDays: selectedDays
      });


      res.data.items = res.data.items.map(i => {
        i.xAxisLabel = `${i.day} ${i.date.substring(0,5)}`;
        i.averageSales = res.data.averageSales;
        return i;
      });

      setSalesByDays(res.data.items);
      setAverageSales(res.data.averageSales);
      setAverageLunchSales(res.data.averageLunchSales);
      setAverageDinnerSales(res.data.averageDinnerSales);


      res.data.salesByDayGroup.items = res.data.salesByDayGroup.items.map(i => {
        i.xAxisLabel = `${i.day} ${i.date.substring(0,5)}`;
        i.averageSales = res.data.salesByDayGroup.averageSales;
        return i;
      });

      setSalesByDayGroup(res.data.salesByDayGroup.items);
      setAverageSalesGroup(res.data.salesByDayGroup.averageSales);
      setAverageLunchSalesGroup(res.data.salesByDayGroup.averageLunchSales);
      setAverageDinnerSalesGroup(res.data.salesByDayGroup.averageDinnerSales);

    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleDateStartChange = (e, { value }) => {
    setDateStart(value);
  };

  const handleDateEndChange = (e, { value }) => {
    setDateEnd(value);
  };

  const handleCaretLeftClick = () => {
    setDateStart(moment(dateStart).subtract(1, 'days').format('MM/DD/YY'));
    setDateEnd(moment(dateEnd).subtract(1, 'days').format('MM/DD/YY'));
  };

  const handleCaretRightClick = () => {
    setDateStart(moment(dateStart).add(1, 'days').format('MM/DD/YY'));
    setDateEnd(moment(dateEnd).add(1, 'days').format('MM/DD/YY'));
  };

  const handleFastBackwardClick = () => {
    setDateStart(moment(dateStart).subtract(7, 'days').format('MM/DD/YY'));
    setDateEnd(moment(dateEnd).subtract(7, 'days').format('MM/DD/YY'));
  };

  const handleFastForwardClick = () => {
    setDateStart(moment(dateStart).add(7, 'days').format('MM/DD/YY'));
    setDateEnd(moment(dateEnd).add(7, 'days').format('MM/DD/YY'));
  };
  // START: Functions for DateMenuForm

  const handleTodayClick = () => {
    setDateStart(defaultStartDate.format('MM/DD/YY'));
    setDateEnd(defaultEndDate.format('MM/DD/YY'));
  };

  const handleYesterdayClick = () => {
    setDateStart(defaultStartDate.subtract(1, 'days').format('MM/DD/YY'));
    setDateEnd(defaultEndDate.subtract(1, 'days').format('MM/DD/YY'));
  };

  const handle52WeeksAgoClick = () => {
    setDateStart(defaultStartDate.subtract(52, 'weeks').format('MM/DD/YY'));
    setDateEnd(defaultEndDate.subtract(52, 'weeks').format('MM/DD/YY'));
  };

  const handleWeekToDateClick = () => {
    let _startDate =
      defaultStartDate.format('ddd').toUpperCase() === 'SUN'
        ? defaultStartDate.subtract(6, 'days').format('MM/DD/YY')
        : defaultStartDate.startOf('week').add(1, 'days').format('MM/DD/YY');
    setDateStart(_startDate);
    setDateEnd(defaultEndDate.format('MM/DD/YY'));
  };

  const handleLastWeekClick = () => {
    setDateStart(
      defaultStartDate.startOf('week').subtract(6, 'days').format('MM/DD/YY')
    );
    setDateEnd(defaultEndDate.startOf('week').format('MM/DD/YY'));
  };

  const handleLast7DaysClick = () => {
    setDateStart(defaultStartDate.subtract(7, 'days').format('MM/DD/YY'));
    setDateEnd(defaultEndDate.format('MM/DD/YY'));
  };

  const handleLastMonthClick = () => {
    setDateStart(
      defaultStartDate.startOf('month').subtract(1, 'month').format('MM/DD/YY')
    );
    setDateEnd(
      defaultEndDate.endOf('month').subtract(1, 'month').format('MM/DD/YY')
    );
  };

  const handleLast30DaysClick = () => {
    setDateStart(defaultStartDate.subtract(30, 'days').format('MM/DD/YY'));
    setDateEnd(defaultEndDate.format('MM/DD/YY'));
  };

  const handleLast60DaysClick = () => {
    setDateStart(defaultStartDate.subtract(60, 'days').format('MM/DD/YY'));
    setDateEnd(defaultEndDate.format('MM/DD/YY'));
  };

  const handleLast90DaysClick = () => {
    setDateStart(defaultStartDate.subtract(90, 'days').format('MM/DD/YY'));
    setDateEnd(defaultEndDate.format('MM/DD/YY'));
  };

  const handleMonthToDateClick = () => {
    setDateStart(defaultStartDate.startOf('month').format('MM/DD/YY'));
    setDateEnd(defaultEndDate.format('MM/DD/YY'));
  };

  // END: Functions for DateMenuForm

  const handleDayClick = (e, {day}) => {
    console.log("handleDayClick -> day", day)
    let _selectedDays = [...selectedDays];
    let dayIndex = _selectedDays.indexOf(day);
    console.log("handleDayClick -> dayIndex", dayIndex)
    if (dayIndex > -1) {
      console.log('remove')
      _selectedDays.splice(dayIndex, 1);
    } else {
      _selectedDays.push(day);
    }
    setSelectedDays(_selectedDays);
  };

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: 'Sales Stats',
      },
    });
  }, []);

  useEffect(() => {
    let _days =
      moment(dateEnd).diff(moment(dateStart)) /
      1000 /
      60 /
      60 /
      24;

    if (_days >= 0 && _days <= 366 && userData.selectedOrg) {
      clearTimeout(timeOutId);
      setTimeoutId(
        setTimeout(() => {
          getSalesByDay({
            startDate: dateStart,
            endDate: dateEnd,
            selectedDays: selectedDays
          });
        }, 1000)
      );
    }
    setDays(_days);

    return () => {
      clearTimeout(timeOutId);
      AxiosAbort();
    }
  }, [dateStart, dateEnd, company, selectedDays]);


  return (
    <div className={'content-container ' + className}>
      <PageHeader pageName="Sales Stats" orgName={userData.selectedOrg?.name} />
      {days > 366 && (
        <Message warning>Date range must be 366 days or less.</Message>
      )}
      {days < 0 && (
        <Message warning>
          End date must be the same or after start date.
        </Message>
      )}
      <DateMenuForm
        handleTodayClick={handleTodayClick}
        handleYesterdayClick={handleYesterdayClick}
        handle52WeeksAgoClick={handle52WeeksAgoClick}
        handleWeekToDateClick={handleWeekToDateClick}
        handleLastWeekClick={handleLastWeekClick}
        handleLast7DaysClick={handleLast7DaysClick}
        handleMonthToDateClick={handleMonthToDateClick}
        handleLastMonthClick={handleLastMonthClick}
        handleLast30DaysClick={handleLast30DaysClick}
        handleLast60DaysClick={handleLast60DaysClick}
        handleLast90DaysClick={handleLast90DaysClick}
      />
      {days > 366 && (
        <Message warning>Date range must be 366 days or less.</Message>
      )}
      {days < 0 && (
        <Message warning>
          End date must be the same or after start date.
        </Message>
      )}
      <DateRangeSelectorForm
        dateRangeData={{ dateStart: dateStart, dateEnd: dateEnd }}
        handleFastBackwardClick={handleFastBackwardClick}
        handleFastForwardClick={handleFastForwardClick}
        handleCaretLeftClick={handleCaretLeftClick}
        handleDateStartChange={handleDateStartChange}
        handleDateEndChange={handleDateEndChange}
        handleCaretRightClick={handleCaretRightClick}
      />
      <Menu className="date-menu" fluid>
        <Menu.Item color="blue" onClick={handleDayClick} day="MON" active={selectedDays.indexOf('MON') > -1 ? true : false}>MON</Menu.Item>
        <Menu.Item color="blue" onClick={handleDayClick} day="TUE" active={selectedDays.indexOf('TUE') > -1 ? true : false}>TUE</Menu.Item>
        <Menu.Item color="blue" onClick={handleDayClick} day="WED" active={selectedDays.indexOf('WED') > -1 ? true : false}>WED</Menu.Item>
        <Menu.Item color="blue" onClick={handleDayClick} day="THU" active={selectedDays.indexOf('THU') > -1 ? true : false}>THU</Menu.Item>
        <Menu.Item color="blue" onClick={handleDayClick} day="FRI" active={selectedDays.indexOf('FRI') > -1 ? true : false}>FRI</Menu.Item>
        <Menu.Item color="blue" onClick={handleDayClick} day="SAT" active={selectedDays.indexOf('SAT') > -1 ? true : false}>SAT</Menu.Item>
        <Menu.Item color="blue" onClick={handleDayClick} day="SUN" active={selectedDays.indexOf('SUN') > -1 ? true : false}>SUN</Menu.Item>
      </Menu>
      {salesByDays && (
        <Segment>
          <Header as="h2">Daily Sales</Header>
          <ResponsiveContainer height={500}>
            <ComposedChart data={salesByDays}>
            <CartesianGrid strokeDasharray="3 3" />
              <Legend verticalAlign="top" height={36} />
              <XAxis dataKey="xAxisLabel" padding={{ left: 25, right: 25 }} minTickGap={10} />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" label="˚F" />
              <Tooltip content={CustomToolTip} />
              <Bar yAxisId="left" maxBarSize={20} dataKey="total">
                {salesByDays.map((d, dIndex) => (
                  <Cell
                    key={dIndex}
                    fill={['rain'].indexOf(d.weatherValue) > -1 ? '#438945' : '#3cbcc3'}
                  />
                ))}
              </Bar>
              <Line yAxisId="right" type="monotone" dataKey="temperatureHigh" stroke="#ff7300" />
              <Line yAxisId="right" type="monotone" dataKey="temperatureLow" stroke="blue" />
            </ComposedChart>
          </ResponsiveContainer>
          <Header as="p" textAlign="center"><span style={{color: '#438945'}}>Rainy Weather</span> - <span style={{color: '#3cbcc3'}}>Not Rainy Weather</span></Header>
          <Header as="h2" textAlign="center">Average Sales: <NumberFormat thousandSeparator={true} value={averageSales} displayType="text"></NumberFormat></Header>
        </Segment>
      )}
      {salesByDays && (
        <Segment>
          <Header as="h2">Lunch vs Dinner</Header>
          <ResponsiveContainer height={500}>
            <ComposedChart data={salesByDays}>
            <CartesianGrid strokeDasharray="3 3" />
              <Legend verticalAlign="top" height={36} />
              <XAxis dataKey="xAxisLabel" padding={{ left: 100, right: 25 }} minTickGap={10} />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" label="˚F" />
              <Tooltip content={CustomToolTip} />
              <Bar yAxisId="left" maxBarSize={20} dataKey="lunch" fill="red" />
              <Bar yAxisId="left" maxBarSize={20} dataKey="dinner" fill="blue" />
              <Bar yAxisId="left" maxBarSize={20} dataKey="lateNight" fill="orange" />
              <Line yAxisId="right" type="monotone" dataKey="temperatureHigh" stroke="#ff7300" />
              <Line yAxisId="right" type="monotone" dataKey="temperatureLow" stroke="blue" />
            </ComposedChart>
          </ResponsiveContainer>
          <Header as="p" textAlign="center"><span style={{color: '#438945'}}>Rainy Weather</span> - <span style={{color: '#3cbcc3'}}>Not Rainy Weather</span></Header>
          <Header as="h2" textAlign="center">Average Sales: <NumberFormat thousandSeparator={true} value={averageSales} displayType="text"></NumberFormat></Header>
          <Header as="h2" textAlign="center">Average Lunch Sales: <NumberFormat thousandSeparator={true} value={averageLunchSales} displayType="text"></NumberFormat> (<NumberFormat thousandSeparator={true} value={(averageLunchSales/averageSales*100).toFixed(2)} suffix="%" displayType="text"></NumberFormat>)</Header>
          <Header as="h2" textAlign="center">Average Dinner Sales: <NumberFormat thousandSeparator={true} value={averageDinnerSales} displayType="text"></NumberFormat>  (<NumberFormat thousandSeparator={true} value={(averageDinnerSales/averageSales*100).toFixed(2)} suffix="%" displayType="text"></NumberFormat>)</Header>
        </Segment>
      )}
      {salesByDayGroup && (
        <Segment>
          <Header as="h2">Weekly Sales</Header>
          {selectedDays.length > 0 && (
            <Header as="p">{`${selectedDays.join(', ')}`}</Header>
          )}
          <ResponsiveContainer height={500}>
            <ComposedChart data={salesByDayGroup}>
            <CartesianGrid strokeDasharray="3 3" />
              <Legend verticalAlign="top" height={36} />
              <XAxis dataKey="xAxisLabel" padding={{ left: 25, right: 25 }} minTickGap={10} />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" label="˚F" />
              <Tooltip content={CustomToolTip} />
              <Bar yAxisId="left" maxBarSize={20} dataKey="total">
                {salesByDays.map((d, dIndex) => (
                  <Cell
                    key={dIndex}
                    fill={['rain'].indexOf(d.weatherValue) > -1 ? '#438945' : '#3cbcc3'}
                  />
                ))}
              </Bar>
              <Line yAxisId="right" type="monotone" dataKey="temperatureHigh" stroke="#ff7300" />
              <Line yAxisId="right" type="monotone" dataKey="temperatureLow" stroke="blue" />
            </ComposedChart>
          </ResponsiveContainer>
          <Header as="p" textAlign="center"><span style={{color: '#438945'}}>Rainy Weather</span> - <span style={{color: '#3cbcc3'}}>Not Rainy Weather</span></Header>
          <Header as="h2" textAlign="center">Average Sales: <NumberFormat thousandSeparator={true} value={averageSalesGroup} displayType="text"></NumberFormat></Header>
        </Segment>
      )}
      {salesByDayGroup && (
        <Segment>
          <Header as="h2">Weekly Lunch vs Dinner</Header>
          {selectedDays.length > 0 && (
            <Header as="p">{`${selectedDays.join(', ')}`}</Header>
          )}
          <ResponsiveContainer height={500}>
            <ComposedChart data={salesByDayGroup}>
            <CartesianGrid strokeDasharray="3 3" />
              <Legend verticalAlign="top" height={36} />
              <XAxis dataKey="xAxisLabel" padding={{ left: 100, right: 25 }} minTickGap={10} />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" label="˚F" />
              <Tooltip content={CustomToolTip} />
              <Bar yAxisId="left" maxBarSize={20} dataKey="lunch" fill="red" />
              <Bar yAxisId="left" maxBarSize={20} dataKey="dinner" fill="blue" />
              <Bar yAxisId="left" maxBarSize={20} dataKey="lateNight" fill="orange" />
              <Line yAxisId="right" type="monotone" dataKey="temperatureHigh" stroke="#ff7300" />
              <Line yAxisId="right" type="monotone" dataKey="temperatureLow" stroke="blue" />
            </ComposedChart>
          </ResponsiveContainer>
          <Header as="p" textAlign="center"><span style={{color: '#438945'}}>Rainy Weather</span> - <span style={{color: '#3cbcc3'}}>Not Rainy Weather</span></Header>
          <Header as="h2" textAlign="center">Average Sales: <NumberFormat thousandSeparator={true} value={averageSalesGroup} displayType="text"></NumberFormat></Header>
          <Header as="h2" textAlign="center">Average Lunch Sales: <NumberFormat thousandSeparator={true} value={averageLunchSalesGroup} displayType="text"></NumberFormat> (<NumberFormat thousandSeparator={true} value={(averageLunchSalesGroup/averageSalesGroup*100).toFixed(2)} suffix="%" displayType="text"></NumberFormat>)</Header>
          <Header as="h2" textAlign="center">Average Dinner Sales: <NumberFormat thousandSeparator={true} value={averageDinnerSalesGroup} displayType="text"></NumberFormat>  (<NumberFormat thousandSeparator={true} value={(averageDinnerSalesGroup/averageSalesGroup*100).toFixed(2)} suffix="%" displayType="text"></NumberFormat>)</Header>
        </Segment>
      )}
    </div>
  );
}

SalesStats.propTypes = {
  className: PropTypes.string,
};

SalesStats.defaultProps = {
  className: '',
};

const CustomToolTip = ({ type, payload, label }) => {

  return (
    <div className="custom-tooltip">
      <p className="label">{`${label}`}</p>
      <p>
      {payload && payload.map((p, pIndex) => {
        let displayValue = p.value;

        if (p.dataKey === 'total' && p.payload.averageSales) {
          let salesDifference = (((p.value - p.payload.averageSales)/p.payload.averageSales) * 100).toFixed(2);
          let comparedTo = (salesDifference > 0) ? "above" : "below";
          displayValue = `${displayValue} (${salesDifference}% ${comparedTo} average)`;
        }

        return (
          <span key={pIndex} style={{color: p.color, display: 'block'}}>{p.dataKey} {displayValue}</span>
        );
      })}
      </p>
    </div>
  )
};

CustomToolTip.propTypes = {
  type: PropTypes.string,
  payload: PropTypes.array,
  label: PropTypes.string
};