import React from "react";
import ReactMarkdown from "react-markdown";
import { Header, Segment } from "semantic-ui-react";
import videoEmbed from "../../lib/video-embed-remark";
import { useEffect } from "react";
import { useState } from "react";
import rehypeRaw from "rehype-raw";
import usePageData from "../state-manipulation/hooks/usePageData";
import { setSelectedPage } from "../state-manipulation/reducers/page-data/types";
import { useMediaQuery } from "react-responsive";
import { LG_WIDTH } from "../constants";

export default function MarkdownPage({ pageName, pageSource, selectedPage }) {
  const [content, setContent] = useState("");
  const [, pageDispatch] = usePageData();
  const isMobile = useMediaQuery({ query: `(max-width: ${LG_WIDTH}px`})

  useEffect(() => {
    // console.log(selectedPage);
    // const _fetch = async () => {
    //   const req = await fetch(pageSource);
    //   const _content = await req.text();
    //   setContent(_content);
    // };

    // _fetch();

    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: selectedPage,
      },
    });
  }, [pageSource]);

  useEffect(() => {
    setTimeout(() => {
      let hash = window.location.hash;
      if (hash) {
        let element = document.getElementById(hash.substr(1));
        if (element) {
          element.scrollIntoView();
        }
      } else {
       window.scrollTo(0, 0);
      }
    }, 500)
  }, [pageSource]);

  return (
    <div className={"content-container markdown-page"}>
      <Header as="h1">{pageName}</Header>
      <Segment padded={ !isMobile ? 'very' : true}>
        <ReactMarkdown
          remarkPlugins={[videoEmbed]}
          rehypePlugins={[rehypeRaw]}
          children={pageSource}
        />
      </Segment>
    </div>
  );
}
