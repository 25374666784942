import React, { useEffect, useState } from "react";
import { async } from "regenerator-runtime";
import { Button, Form, Header, Input } from "semantic-ui-react";
import usePageData from "../state-manipulation/hooks/usePageData";
import { setSelectedPage } from "../state-manipulation/reducers/page-data/types";

export default function QRCodeGenerator({ selectedPage, pageName }) {
  const [, pageDispatch] = usePageData();
  const [title, setTitle] = useState("");
  const [QRCodeOutput, setQRCodeOutput] = useState("");
  const [type, setType] = useState("png");

  const slugify = (str) =>
    str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/[\s_-]+/g, "-")
      .replace(/^-+|-+$/g, "");

  const handleQRCodeGeneration = async () => {

    try {
      let res = await fetch(
        `${process.env.LTG_NEXT_APP_URL}/api/qrcode?url=${encodeURIComponent(
          `${QRCodeOutput}`
        )}&fileName=${slugify(title)}.png&type=${type}&text=${title}`
      );
      let blob = await res.blob();
      var file = window.URL.createObjectURL(blob);
      var link = document.createElement("a");
      link.setAttribute("href", file);
      link.setAttribute("download", `${slugify(title)}.${type}`);
      link.click();
      link.remove();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: selectedPage,
      },
    });
  }, []);

  return (
    <div className={"content-container"}>
      <Header as="h1">{pageName}</Header>
      <Form>
        <Form.Field>
          <label>Title</label>
          <Input
            type="text"
            value={title}
            onChange={(e, { value }) => {
              setTitle(value);
            }}
          />
        </Form.Field>
        <Form.Field>
          <label>QR Code Output</label>
          <Input
            type="text"
            value={QRCodeOutput}
            onChange={(e, { value }) => {
              setQRCodeOutput(value);
            }}
          />
        </Form.Field>
        <Form.Group>
          <label>type</label>
          <Form.Radio
            label="png"
            value="png"
            checked={type === "png"}
            onChange={(e, { value }) => {
              setType(value);
            }}
          />
          <Form.Radio
            label="pdf"
            value="pdf"
            checked={type === "pdf"}
            onChange={(e, { value }) => {
              setType(value);
            }}
          />
        </Form.Group>
        <p>Product Name | Product Size | Expiration Date</p>
        <Button onClick={handleQRCodeGeneration}>Generate</Button>
      </Form>
    </div>
  );
}
