import React, { useEffect, useState } from "react";
import StandardLayout from "../layouts/StandardLayout";
import { LG_WIDTH } from "../constants";
import {
  useWindowWidth,
} from '@react-hook/window-size'

export default function ApplicationNavigation ({children}) {
  const onlyWidth = useWindowWidth();
  const [animationType, setAnimationType] = useState('push');
  const [alwaysVisible, setAlwaysVisible] = useState(true);
  const [neverDimmed, setNeverDimmed] = useState(true);

  useEffect(() => {
    if (onlyWidth < LG_WIDTH) {
      setAnimationType('overlay');
      setAlwaysVisible(false);
      setNeverDimmed(false)
    } else {
      setAnimationType('push');
      setAlwaysVisible(true);
      setNeverDimmed(true)
    }
  }, [onlyWidth]);

  return (
    <StandardLayout animationType={animationType} alwaysVisible={alwaysVisible} neverDimmed={neverDimmed}>{children}</StandardLayout>
  );
}
