import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dropdown,
  Table,
  Grid,
  Header,
  Dimmer,
  Loader,
  Icon,
  Checkbox,
  Modal,
  Form,
  Input,
  TextArea,
  Radio,
  TableBody,
} from "semantic-ui-react";
import useUserData from "../state-manipulation/hooks/useUserData";
import { LG_WIDTH } from "../constants";
import { GetTaskListTemplateMerged } from "../api/TaskListTemplateAPI";
import { useParams, useLocation, useHistory } from "react-router-dom";
import useQuery from "../hooks/useQuery";
import { PostTaskList } from "../api/TaskListAPI";
import PageHeader from "../components/common/PageHeader";

export default function TaskListTemplateMerged({ className }) {
  const [userData] = useUserData();
  const { company } = useParams();
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQuery();
  const [isLoading, setIsLoading] = useState();
  const [day, setDay] = useState(queryParams.get("day") || "");
  const [timeOutId, setTimeoutId] = useState("");
  const [taskListTemplates, setTaskListTemplates] = useState([]);

  const getTaskListTemplateMerged = () => {
    clearTimeout(timeOutId);
    setTimeoutId(
      setTimeout(async () => {
        let options = {
          company: company,
        };

        if (day) {
          options.day = day;
        }

        let res = await GetTaskListTemplateMerged(options);
        console.log("getTaskListTemplateMerged -> res", res);
        setTaskListTemplates(res.data);
      }),
      500
    );
  };

  const generateTaskList = async (_taskList) => {
    try {
      setIsLoading(true);
      let res = await PostTaskList(_taskList, company);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  const handleGenerateTaskList = async (e, { tasklist }) => {
    let _taskList = { ...tasklist };
    delete _taskList._id;
    console.log("handleGenerateTaskList -> _taskList", _taskList);

    await generateTaskList(_taskList);
    history.push(`/${company}/task-lists`);
  };

  useEffect(() => {
    getTaskListTemplateMerged();

    return () => {
      clearTimeout(timeOutId);
    };
  }, []);

  useEffect(() => {
    getTaskListTemplateMerged();
  }, [day]);

  useEffect(() => {
    if (!location.search) {
      setDay("");
    }
  }, [location]);

  return (
    <div className={"content-container " + className}>
      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      <PageHeader pageName="Task Lists Merged" orgName={userData.selectedOrg?.name} />
      {taskListTemplates.map((tl, tlIndex) => {
        return (
          <Grid key={tlIndex} columns={1}>
            <Grid.Column>
              <h3>{tl.name}</h3>
              <p>Key: {tl.key}</p>
            </Grid.Column>
            <Grid.Column>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Task</Table.HeaderCell>
                    <Table.HeaderCell>Additional Fields</Table.HeaderCell>
                    <Table.HeaderCell>Template</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {tl.tasks.map((t, tIndex) => {
                    return (
                      <Table.Row key={tIndex}>
                        <Table.Cell>{t.name}</Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell>{t.template}</Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Button onClick={handleGenerateTaskList} tasklist={tl}>
                Generate Task List
              </Button>
            </Grid.Column>
          </Grid>
        );
      })}
    </div>
  );
}

TaskListTemplateMerged.propTypes = {
  className: PropTypes.string,
};

TaskListTemplateMerged.defaultProps = {
  className: "",
};
