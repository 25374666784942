export default function isAnyBoolTrue(testVal) {
  if (!testVal) {
    return false;
  }

  if (testVal === "1") {
    return true;
  } else if (testVal === true) {
    return true;
  } else if (testVal.toString().toUpperCase() === "TRUE") {
    return true;
  } else if (testVal.toString().toUpperCase() === "YES") {
    return true;
  } else if (testVal.toString().toUpperCase() === "Y") {
    return true;
  }

  return false;
}