import React, { useReducer } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "moment-timezone";

import dateRangeDataReducer from "../reducers/date-range-data/dateRangeDataReducer";
export const DateRangeContext = React.createContext({});

const currentHour = moment().tz("America/New_York").hours();
const defaultStartDate = (currentHour >= 0 && currentHour  < 4) ? moment().subtract(1, "days") : moment();
const defaultEndDate = (currentHour >= 0 && currentHour  < 4)  ? moment().subtract(1, "days") : moment();

const initialState = {
  dateStart: defaultStartDate.format('MM/DD/YY'),
  dateEnd: defaultEndDate.format('MM/DD/YY'),
  isLastDayToday: true
};

export default function DateRangeDataProvider({ children }) {
  const dateRangeData = useReducer(dateRangeDataReducer, initialState);
  return (
    <DateRangeContext.Provider value={dateRangeData}>
      {children}
    </DateRangeContext.Provider>
  );
}

DateRangeDataProvider.propTypes = {
  children: PropTypes.any
};