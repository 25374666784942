import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Header, Table, Icon, Input, Confirm, Button, Dimmer, Loader, Form, Dropdown } from "semantic-ui-react";
import { LG_WIDTH } from "../../constants";
import useUserData from "../../state-manipulation/hooks/useUserData";
import usePageData from "../../state-manipulation/hooks/usePageData";
import { setSelectedPage } from "../../state-manipulation/reducers/page-data/types";
import PageHeader from "../common/PageHeader";

export default function PricingCalculator({className}) {
  const [ userData, userDispatch ] = useUserData();
  const [ pageData, pageDispatch ] = usePageData();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ price, setPrice ] = useState(0);
  const [ bottleSize, setBottleSize ] = useState(0);
  const [ shotPrice, setShotPrice ] = useState(0);
  const [ markupPrice, setMarkupPrice ] = useState(0);


  const handleDropDownChange = (e, {value}) => {
    setBottleSize(value);
  };

  const handlePriceChange = (e, {value}) => {
    setPrice(value);
  };

  const shotSize = 44.36

  useEffect(() => {
    pageDispatch({
      type: setSelectedPage,
      payload: {
        selectedPage: 'Pricing Calculator'
      }
    });
  }, []);

  useEffect(() => {

  }, [userData]);

  useEffect(() => {
    console.log('SET SHOT PRICE');
    setShotPrice(price/bottleSize * shotSize);

  }, [price]);

  const bottleSizes = [
    {
      key: '750',
      text: '750ml',
      value: '750'
    },
    {
      key: '1000',
      text: '1L',
      value: '1000'
    }
  ];

  return (
  <div className={"content-container " + className}>
    <PageHeader pageName="Pricing Calculator" orgName={userData.selectedOrg?.name} />
    <Dimmer active={isLoading}>
      <Loader />
    </Dimmer>
    <Form>
      <Form.Field>
        <label>Size</label>
        <Dropdown options={bottleSizes}
          selection
          onChange={handleDropDownChange}></Dropdown>
      </Form.Field>
      <Form.Field>
        <label>Price</label>
        <Form.Input onChange={handlePriceChange}></Form.Input>
      </Form.Field>
    </Form>
    <p>Cost Price for 1.5oz shot: {shotPrice}</p>
    <p>Suggested Price for 1.5oz shot rounded (250%): {shotPrice * 2.5}  or {Math.round(shotPrice * 2.5)}</p>
    <p>Suggested Price for 1.5oz shot rounded (300%): {shotPrice * 3}  or {Math.round(shotPrice * 3)}</p>
    <p>Suggested Price for 1.5oz shot rounded (350%): {shotPrice * 3.5}  or {Math.round(shotPrice * 3.5)}</p>
  </div>
  );
}

PricingCalculator.propTypes = {
  className: PropTypes.string
};

PricingCalculator.defaultProps = {
  className: ''
};